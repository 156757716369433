import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API } from "../../../../backend";
import Loader from "../../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import EventLayout from "../../../../components/EventLayout";
import Drawer from "@material-ui/core/Drawer";
import { FormControl } from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import { useHistory, useLocation } from "react-router";
import Grid from "@material-ui/core/Grid";
import logoSm from "../../../../assets/images/logo-sm.png";
import { DropzoneArea } from "material-ui-dropzone";
import SessionSidebar from "./VirtualboothSidebar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import Select from "react-select";

const moment = require("moment");
const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Heading,
    Indent,
    Table,
    TableToolbar,
    Link,
    List,
    MediaEmbed,
    CodeBlock,
    ImageResize,
    ImageToolbar,
    ImageStyle,
  ],
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "mediaEmbed",
    "insertTable",
    "codeBlock",
  ],
  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },

  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  codeBlock: {
    languages: [
      // Do not render the CSS class for the plain text code blocks.
      { language: "plaintext", label: "Plain text", class: "" },

      // Use the "php-code" class for PHP code blocks.
      { language: "php", label: "PHP", class: "php-code" },

      // Use the "js" class for JavaScript code blocks.
      // Note that only the first ("js") class will determine the language of the block when loading data.
      {
        language: "javascript",
        label: "JavaScript",
        class: "js javascript js-code",
      },

      // Python code blocks will have the default "language-python" CSS class.
      { language: "python", label: "Python" },
    ],
  },
};
const useStyles = makeStyles({
  root: {
    minHeight: 206,
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardbody: {
    padding: "0 10px 10px 10px",
    borderRadius: "8px",
  },
  eventbanner: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "100px",
    objectFit: "fill",
    maxWidth: "100%",
  },
  eventName: {
    fontWeight: 500,
    display: "-webkit-box",
    overflow: "hidden",
    "-mozBoxOrient": "vertical",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": 2,
  },
  moreOptions: {
    background: "#ffffff",
    position: "absolute",
    right: "10px",
    top: "6px",
    borderRadius: "50px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f44d5e",
    fontSize: "20px",
  },
  optionMenu: {
    boxShadow: "none",
    border: "1px solid #dbdbdb",
    "& div": {
      boxShadow: "none",
      border: "1px solid #dbdbdb",
    },
  },
  optionMenuItem: {
    boxShadow: "none",
  },
  createEventDrawer: {
    width: "40%",
  },
  creatEventHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    overflowX: "hidden",
  },
  spacingCreateEvent: {
    padding: "10px 30px 10px 30px",
    height: "calc(100vh - 142px)",
    overflow: "auto",
  },
  eventType: {
    width: "50%",
    textAlign: "center",
    color: "gray",
    marginBottom: 0,
    padding: "8px",
  },
  border: {
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
  },
  selectedEventType: {
    background: "#f44d5e",
    color: "#ffffff",
    width: "50%",
    textAlign: "center",
    marginBottom: 0,
    padding: "8px",
  },
  textField: {
    marginRight: 10,
    border: "1px solid rgba(53, 53, 53, 0.17)",
    borderRadius: "4px",
    color: "#353535",
    width: "inherit",
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function VirtualBoothProductVideos(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const eventId = props.match.params.eventId;
  const sessionId = props.match.params.sessionId;
  const boothId = props.match.params.boothId;
  const [drawer, setDrawer] = React.useState({
    speakers: false,
    sponsers: false,
    addSession: false,
    addVideo: false,
  });
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [sessionDates, setSessionDates] = React.useState([]);
  const [datetabsValue, setdatetabsValue] = React.useState("");
  const [openPosterDel, setopenPosterDel] = React.useState({
    show: false,
    data: {},
    imagetype: "",
  });
  const [scroll, setScroll] = React.useState("paper");
  const [check, setCheck] = React.useState(false);
  // const [pageBannerImg, setpageBannerImg] = React.useState("");
  const [productVideos, setproductVideos] = useState([]);
  const [comapanyTitle, setComapanyTitle] = useState("");
  const [contactUsEmail, setContactUsEmail] = useState("");
  // const [logo, setLogo] = useState([]);
  const [aboutUs, setAboutUs] = useState("");
  const [pageBanner, setPageBanner] = useState([]);
  const [smallBanner, setSmallBanner] = useState([]);
  const [spotLightBanner, setSpotLightBanner] = useState([]);
  const [sponseredProductLabel, setSponseredProductLabel] = useState("");
  const [files, setFiles] = useState([]);
  const [priority, setPriority] = useState(0);
  const [contactNumber, setContactNumber] = useState({
    countryCode: "",
    countryName: "",
    phoneNumber: "",
  });
  const [sponsershipCategoryList, setsponsershipCategoryList] = useState([]);
  const [sponsershipType, setSponsershipType] = useState({});
  const [address, setAddress] = useState("");
  const [isfeatured, setIsFeatured] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  const [createCategory, setCreateCategory] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [isEditCategory, setIsEditCategory] = React.useState("");
  const [boothData, setBoothData] = React.useState([]);
  const [productVideoS3link, setProductVideoS3link] = React.useState([]);
  const [boothSize, setBoothSize] = useState("large");
  const [currentSelectImage, setCurrentSelectImage] = useState("image");
  const [website, setWebsite] = React.useState("");
  const [linkedIn, setLinkedIn] = React.useState("");
  const [twitter, setTwitter] = React.useState("");
  const [fb, setfb] = React.useState("");
  const [insta, setInsta] = React.useState("");
  const [whatsApp, setWhatsApp] = React.useState("");
  // const [smallBannerIMg, setSmallBannerImg] = React.useState("");

  const [selectedCation, setselectedCaption] = React.useState("");
  const [videoType, setSelectedVideoType] = React.useState("");
  const [streamLink, setStreamLink] = React.useState("");
  let location = useLocation();
  var temp = location.pathname.split("/");
  var activeNav = temp[3];

  const deleteCategory = (category) => {
    axios
      .delete(
        `${BOOTCAMP_API}/events/deleteSponserCategoryData/${category._id}`
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setsponsershipCategoryList((prev) =>
            prev.filter((x) => {
              return x._id !== category._id;
            })
          );
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const addProductImages = async () => {
    const formData = new FormData();
    productVideos.filter((x) => {
      formData.append("", x);
    });
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    await axios
      .post(`${BOOTCAMP_API}/events/upload/${props.match.params.eventId}`, formData, config)
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          const s3URls = [...productVideoS3link];
          response.data.result.data.filter((x) => {
            s3URls.push(x.s3_url);
          });
          console.log(s3URls);
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          const productVideos = {
            productVideos: s3URls,
          };
          axios
            .put(
              `${BOOTCAMP_API}/events/updateCompanyExhibitors/${boothData.companyId._id}`,
              productVideos
            )
            .then((response) => {
              if (response.data.error) {
                setErrorStatus(true);
                setErrormesage(response.data.result.message);
              } else {
                setSuccessMessage(true);
                setMessageData(response.data.result.message);
                setProductVideoS3link(response.data.result.data.productVideos);
                setproductVideos([]);
              }
            })
            .catch((error) => {
              setErrorStatus(true);
              console.log(error);
            });
        }
      })
      .catch((error) => {
        setErrorStatus(true);
        console.log(error);
      });
  };
  const deletePoster = () => {
    const deletePosterLink = {
      videoIdToDelete: openPosterDel.data,
    };
    axios
      .delete(`${BOOTCAMP_API}/events/deleteProductVideo/${boothData._id}`, {
        data: deletePosterLink,
      })
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setBoothData((prev) => ({
            ...prev,
            [openPosterDel.imagetype]: "",
          }));
          setopenPosterDel({ ...openPosterDel, show: false });
          getBoothData();
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const resetAllData = () => {};
  const getBoothData = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificCompanyExhibitors/${props.match.params.boothId}`
      )
      .then((response) => {
        setBoothData(response.data.result.data);
        setProductVideoS3link(
          response.data.result.data?.companyId?.productVideos
        );
        addDataToInputField(response.data.result.data);
      })
      .catch((err) => console.log(err));
  };
  const addDataToInputField = (data) => {
    setComapanyTitle(data.companyId?.comapanyTitle);
    setContactNumber(data.companyId?.contactNumber?.phoneNumber);
    setSponsershipType({
      label: data.sponsershipType.title,
      value: data.sponsershipType._id,
    });
    setBoothSize(data.companyId.boothSize);
    setSponseredProductLabel(data.companyId.sponseredProductLabel);
    setAboutUs(data.companyId.aboutUs);
    setContactUsEmail(data.companyId.contactUsEmail);
    setAddress(data.companyId.address);
    setfb(data.companyId.fb);
    setInsta(data.companyId.Instagram);
    setLinkedIn(data.companyId.linkedIn);
    setWebsite(data.companyId.website);
    setWhatsApp(data.companyId.whatsApp);
    setIsPublish(data.companyId.publish);
    setIsFeatured(data.companyId.featured);
    // setLogo(data.companyId.logo);
    // setpageBannerImg(data.companyId.pageBanner);
    // setSmallBannerImg(data.companyId.smallBanner);
    // setfileImg(data.companyId.files);
  };
  const getEventData = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
        let eventResponse = response.data.result.data.dates;
        let sessionDates = [];
        eventResponse.filter((x, index) => {
          let data = {};
          data.value = x;
          data.label = `Day ${index + 1} - ${moment(x).format("Do MMM YYYY")}`;
          sessionDates.push(data);
        });
        setdatetabsValue(sessionDates[0].label);
        setSessionDates(sessionDates);
      })
      .catch((err) => console.log(err));
  };

  const addVideoOpen = () => {
    setDrawer({ ...drawer, addVideo: true });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  const options = [
    { value: "youtube", label: "YouTube" },
    { value: "vimeo", label: "Vimeo" },
    { value: "others", label: "Others" },
  ];

  const saveStreamOptions = () => {
    const streamOptionsBody = {
      eventId: props.match.params.eventId,
      productVideos: {
        caption: selectedCation,
        streamSource: videoType.value,
        videoLink: streamLink,
      },
    };

    axios
      .put(
        `${BOOTCAMP_API}/events/updateCompanyExhibitors/${boothData.companyId._id}`,
        streamOptionsBody
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setDrawer({ ...drawer, addVideo: false });
          getBoothData();
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getEventData();
    getBoothData();
  }, []);

  return (
    <EventLayout>
      {messageData && (
        <Snackbar
          open={successMessage}
          onClose={() => setSuccessMessage(false)}
        >
          <>
            <Alert onClose={() => setSuccessMessage(false)} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar open={errorStatus} onClose={() => setErrorStatus(false)}>
          <>
            <Alert onClose={() => setErrorStatus(false)} severity="error">
              {errorMessage}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        {Object.keys(eventData).length > 0 ? (
          <div className="w-100 event-overview">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <br />
                <ul className="eventsBreadcrumb p-0 d-flex align-items-center">
                  <li
                    className="cursor-pointer"
                    onClick={() =>
                      history.push(
                        `/event/${props.match.params.eventId}/${activeNav}`
                      )
                    }
                  >
                    {activeNav === "virtual-booth" ? "Virtual Booths" : "Sponsors"}
                  </li>
                  <li style={{ color: "#353535" }}>{eventData.title}</li>
                </ul>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SessionSidebar eventId={eventId} boothId={boothId} boothType={activeNav} />
              </Grid>
              <Grid item xs={8}>
                <div className="event-cardHld">
                  <h3>Product Videos</h3>
                  <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
                  <div className="row mb-3">
                    {/* {productVideoS3link?.map((x, index) => (
                      <div className="col-4 ImgHld" key={index}>
                        <img src={x} alt="" className="img-fluid" />
                        <div className="Imagesoverlay mx-2 d-flex">
                          <span
                            onClick={() =>
                              setopenPosterDel({
                                ...openPosterDel,
                                // show: true,
                                // imagetype: "logo",
                                // data: boothData?.companyId?.logo,
                              })
                            }
                          >
                            <i
                              className="mdi mdi-delete brand-color"
                              style={{ fontSize: "30px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    ))} */}
                    {productVideoS3link.map((x, index) => (
                      <div
                        className="ImgHld col-4 d-flex flex-column"
                        key={index}
                      >
                        <video
                          controls
                          controlsList={"nodownload"}
                          className="col-lg-12"
                        >
                          <source
                            src={x}
                            type="video/mp4"
                            className="eventCurvedBorder"
                          />
                        </video>
                        <p className="m-auto">
                          {`${
                            x.caption.length > 15
                              ? x.caption.slice(0, 15) + "..."
                              : x.caption
                          }`}
                        </p>
                        <div className="Imagesoverlay d-flex">
                          <span
                            // onClick={() =>
                            //   setOpenImgDel({
                            //     ...openImgDel,
                            //     show: true,
                            //     data: welcomeVideoLink,
                            //     type: "welcomeVideo",
                            //   })
                            // }
                            onClick={() =>
                              setopenPosterDel({
                                ...openPosterDel,
                                show: true,
                                data: x._id,
                              })
                            }
                          >
                            <i
                              className="mdi mdi-delete brand-color"
                              style={{ fontSize: "30px" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p className="event-label">
                    Add videos of your products from Youtube, Vimeo. Max 20
                    videos are allowed. If the youtube video that you provide
                    contains copyrighted material, it might not play.
                  </p>
                  <div className="addSession">
                    <div className="addSession d-flex align-items-center justify-content-center">
                      {/* <DropzoneArea
                        acceptedFiles={[".mp4", ".mkv", ".avi"]}
                        onChange={(files) => setproductVideos(files)}
                        maxFileSize={59242880}
                        filesLimit={20}
                      /> */}
                      <button
                        className="event-primary-btn"
                        onClick={addVideoOpen}
                      >
                        ADD VIDEO
                      </button>
                    </div>
                  </div>

                  {/* <br />
                  <div className=" mr-auto d-flex justify-content-end">
                    <button
                      className="event-primary-btn"
                      onClick={addProductImages}
                    >
                      SAVE
                    </button>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
      </Row>

      <Drawer
        anchor="right"
        open={drawer.addVideo}
        onClose={toggleDrawer("addVideo", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Add Video</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("addVideo", false)}
                onKeyDown={toggleDrawer("addVideo", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <div className="row">
              <div className="col-12 mt-3 mb-3">
                <label htmlFor="title" className="event-label">
                  Caption
                </label>
                <input
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder="Enter Caption"
                  onChange={(e) => setselectedCaption(e.target.value)}
                />
              </div>

              <div className="col-4">
                <label htmlFor="title" className="event-label">
                  Video Link
                </label>
                <div>
                  <Select
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select"
                    isSearchable={false}
                    onChange={(value) => setSelectedVideoType(value)}
                  />
                </div>
              </div>

              <div className="col-8" style={{ marginTop: "8px" }}>
                <label htmlFor="title" className="event-label"></label>
                <input
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder="https://"
                  onChange={(e) => setStreamLink(e.target.value)}
                />
              </div>
            </div>
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn} onClick={saveStreamOptions}>
              ADD
            </button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("addVideo", false)}
              onKeyDown={toggleDrawer("addVideo", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>

      <Dialog
        open={openPosterDel.show}
        onClose={() => setopenPosterDel({ ...openPosterDel, show: false })}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong>Video deleted once can't be retrieved.</strong>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete this Video"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setopenPosterDel({ ...openPosterDel, show: false })}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deletePoster}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </EventLayout>
  );
}
