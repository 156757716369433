import axios from 'axios';
import {BOOTCAMP_API} from '../../backend';

function GetAuthorizationConfig() {
    return {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.getItem('jwt') ? JSON.parse(localStorage.getItem('jwt')).token : null
        },
    }
}

const QuestionSetService = {
    GetAllQuestionSets: (page_number) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${BOOTCAMP_API}/admin/mock_question_set/?current_page=${page_number}`, GetAuthorizationConfig())
                .then((response) => {
                    return resolve(response.data.result);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    },
    PublishOrUnpublishAQuestionSet: (questionSetId, doPublish) => {
        return new Promise((resolve, reject) => {
            axios
                .put(doPublish ? `${BOOTCAMP_API}/admin/mock_question_set/${questionSetId}/publish`: `${BOOTCAMP_API}/admin/mock_question_set/${questionSetId}/unpublish`, {}, GetAuthorizationConfig())
                .then((response) => {
                    return resolve(response.data.result.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    },
    CreateQuestionSet: (questionSet) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${BOOTCAMP_API}/admin/mock_question_set/`, questionSet, GetAuthorizationConfig())
                .then((response) => {
                    return resolve(response.data.result.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    },
    UpdateQuestionSet: (questionSetId, questionSet) => {
        return new Promise((resolve, reject) => {
            axios
                .put(`${BOOTCAMP_API}/admin/mock_question_set/${questionSetId}`, questionSet, GetAuthorizationConfig())
                .then((response) => {
                    return resolve(response.data.result.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    },
    FetchQuestionSetBySlug: (questionSetSlug) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${BOOTCAMP_API}/admin/mock_question_set/${questionSetSlug}/fetch`)
                .then((response) => {
                    return resolve(response.data.result.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    },
    GetAllQuestionSetsMlSkill: (subcategoryId) => {

        return new Promise((resolve,reject) => {
            axios
                .get(`${BOOTCAMP_API}/mock_question_set/getAllQuestionSetMachinehackCms?mlSkillId=${subcategoryId}`)
                .then((response) => {
                    return resolve(response.data.result.category);
                })
                .catch((err)=>{
                    return reject(err);
                })
        })
    },
    GetAllQuestionSetsWithSubCategory: (subcategoryId) => {

        return new Promise((resolve,reject) => {
            axios
                .get(`${BOOTCAMP_API}/mock_question_set/getAllQuestionSetMachinehackCms?subcatId=${subcategoryId}`)
                .then((response) => {
                    return resolve(response.data.result.category);
                })
                .catch((err)=>{
                    return reject(err);
                })
        })
    },
    GetAllQuestionSetsWithoutsubCategory: () => {

        return new Promise((resolve,reject) => {
            axios
                .get(`${BOOTCAMP_API}/mock_question_set/getAllQuestionSetMachinehackCms`)
                .then((response) => {
                    return resolve(response.data.result.category);
                })
                .catch((err)=>{
                    return reject(err);
                })
        })
    },
    GetSpecificCategoryDetails: (categoryId) =>{
        return new Promise((resolve,reject) => {

            axios
                .get(`${BOOTCAMP_API}/assessmentMock/getSpecificAssessmentCategory/${categoryId}`,GetAuthorizationConfig())
                .then((response) =>{
                    return resolve(response.data.result.category)
                })
                .catch((err) =>{
                    reject(err);
                })
        })
        
        
    }

}


export default QuestionSetService;