import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Loader from "../../../../components/Loader";
import AuthLayout from "../../../../components/AuthLayout";
import Header from "../../../Header";
import { useHistory } from "react-router-dom";
import EasyMDE from 'easymde';
import QuestionService from '../../../../services/mocks/QuestionService';
import QuestionSetService from '../../../../services/mocks/QuestionSetService';
import Skills from "../Skills/Skill";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        // textAlign: "center",
        color: theme.palette.text.secondary,
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 400,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 420,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateCodingTypeQuestion(props) {
    const classes = useStyles();
    const history = useHistory();
    let MDE;
    // let CodeMDE;
    // let PreCodeMDE;
   const [fullscreen, setFullsreen] = useState(false);
    const [messageData, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [difficulty, setDifficulty] = useState("");
    const [duration, setDuration] = useState(0);
    const [score, setScore] = useState(0);
    const [stdin, setStdin] = useState("");
    const [stdout, setStdout] = useState("");
    const [code, setCode] = useState("");
    const [questionSet, setQuestionSet] = useState({});
    const [onSubmit, setOnSubmit] = useState(false);
    const [preCode, setPreCode] = useState("");
    const [skillSet, setSkillSet] = useState([]);

    const handleClose = () => {
        setStatus(!status);
    };

    const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };

    const handleLoader = () => {
        setStatusLoader(!statusLoader);
    };

    useEffect( () => {
        MDE = new EasyMDE({
            element: document.getElementById('body'),
            autoDownloadFontAwesome: true,
            autofocus: true,
            status: false,
            singleLineBreaks: false,
            onToggleFullScreen: (opend) => setFullsreen(opend)
        });
        MDE.codemirror.on('change', () => {
            setDescription(MDE.value())
        });
        // CodeMDE = new EasyMDE({
        //     element: document.getElementById('code'),
        //     autoDownloadFontAwesome: true,
        //     autofocus: true,
        //     status: false,
        //     singleLineBreaks: false,
        //     onToggleFullScreen: (opend) => setFullsreen(opend)
        // });
        // CodeMDE.codemirror.on('change', () => {
        //     setCode(CodeMDE.value());
        // });
        // PreCodeMDE = new EasyMDE({
        //     element: document.getElementById('preCode'),
        //     autoDownloadFontAwesome: true,
        //     autofocus: true,
        //     status: false,
        //     singleLineBreaks: false,
        //     onToggleFullScreen: (opend) => setFullsreen(opend)
        // });
        // PreCodeMDE.codemirror.on('change', () => {
        //     setPreCode(PreCodeMDE.value());
        // });
        (async () => {
            try {
                const response = await QuestionSetService.FetchQuestionSetBySlug(props.match.params.questionSetSlug)
                if(response){
                    setQuestionSet(response);
                } else {
                    setErrorStatus(true);
                    setErrorMessage('Exception in fetching Question Set!');
                    setStatusLoader(false);
                }
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);


    const CreateQuestion = async () => {
        try {
            setOnSubmit(true);
            if (title === "" || description === "" || parseInt(duration) === 0 || difficulty === "" || parseInt(score) === 0 || code === "" || stdin === "" || stdout === "" || skillSet?.length === 0) {
                setErrorStatus(true);
                setErrorMessage("Field Missing! All Feild Required !!");
            } else {
                try {
                    const new_question = {
                        type: 'CODING',
                        title: title,
                        description: description,
                        duration: duration, //in seconds
                        difficulty: difficulty,
                        score: score,
                        coding: {
                            pre_code: preCode,
                            stdin: stdin,
                            stdout: stdout,
                            code: code
                        },
                        skills: skillSet,
                    };
                    const response = await QuestionService.CreateQuestion(questionSet._id, new_question);
                    if (response) {
                        setStatus(true);
                        setMessage('Created Question Successfully!');
                        setStatusLoader(false);
                        setTimeout(() => {
                            history.push(`/getAllQuestions/${props.match.params.questionSetSlug}`);
                        }, 2500);
                    } else {
                        setErrorStatus(true);
                        setErrorMessage('Exception in creating new Question Set!');
                        setStatusLoader(false);
                    }
                } catch (error) {
                    setErrorStatus(true);
                    setErrorMessage(error);
                    setStatusLoader(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <AuthLayout isMDEFullScreen={fullscreen && fullscreen === true ? true : false}>

            <Header name="Create Coding Type Question" />

            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        {status && (
                            <Snackbar
                                open={status}
                                autoHideDuration={15000}
                                onClose={handleClose}
                            >
                                <Alert onClose={handleClose} severity="success">
                                    {messageData}
                                </Alert>
                            </Snackbar>
                        )}


                        {errorStatus && (
                            <Snackbar
                                open={errorStatus}
                                autoHideDuration={15000}
                                onClose={handleCloseError}
                            >
                                <Alert
                                    onClose={handleCloseError}
                                    severity="error"
                                >
                                    {errorMessage}
                                </Alert>
                            </Snackbar>
                        )}


                        {statusLoader && (
                            <Snackbar
                                open={statusLoader}
                                // autoHideDuration={95000}
                                onClose={handleLoader}
                            >
                                <Alert onClose={handleLoader} severity="info">
                                    <Loader />
                                </Alert>
                            </Snackbar>
                        )}


                        <Paper className={classes.paper}>
                            <h2>Title</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Title"
                                multiline
                                minRows={3}
                                variant="outlined"
                                fullWidth={true}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />

                            <br />
                            <br />
                            <h2>Description</h2>

                            <textarea id="body" name="body" value={MDE?.value()} />

                            <br />
                            <br />

                            <h2>Pre Code</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Pre Code"
                                multiline
                                minRows={5}
                                variant="outlined"
                                fullWidth={true}
                                value={preCode}
                                onChange={(e) => {
                                    setPreCode(e.target.value);
                                }}
                            />

                            {/* <textarea id="preCode" name="preCode" value={PreCodeMDE?.value()} /> */}

                            <br />
                            <br />

                            <h2>Code</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Code"
                                multiline
                                minRows={3}
                                variant="outlined"
                                fullWidth={true}
                                value={code}
                                onChange={(e) => {
                                    setCode(e.target.value);
                                }}
                            />

                            {/* <textarea id="code" name="code" value={CodeMDE?.value()} /> */}

                            <br />
                            <br />

                            <h2>Difficulty</h2>

                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Difficulty
								</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={difficulty}
                                    onChange={(e) => {
                                        setDifficulty(e.target.value);
                                    }}
                                    label="Difficulty"
                                >
                                    <MenuItem value="BEGINNER">
                                        Beginner
                                </MenuItem>
                                    <MenuItem value="INTERMEDIATE">
                                        Intermediate
                                </MenuItem>
                                    <MenuItem value="ADVANCED">
                                        Advanced
                                </MenuItem>
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <h2>Duration (in seconds)</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Duration (in seconds)"
                                type='number'
                                minRows={1}
                                variant="outlined"
                                fullWidth={true}
                                value={duration}
                                onChange={(e) => {
                                    setDuration(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <h2>Score</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Score"
                                type='number'
                                minRows={1}
                                variant="outlined"
                                fullWidth={true}
                                value={score}
                                onChange={(e) => {
                                    setScore(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <Skills skillSet={skillSet} setSkillSet={setSkillSet} />
                            <br />
                            <br />

                            <h2>STDIN</h2>
                            <TextField
                                id="outlined-multiline-static"
                                label="STDIN"
                                multiline
                                minRows={2}
                                variant="outlined"
                                fullWidth={true}
                                value={stdin}
                                onChange={(e) => {
                                    setStdin(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <h2>STDOUT</h2>
                            <TextField
                                id="outlined-multiline-static"
                                label="STDOUT"
                                multiline
                                minRows={2}
                                variant="outlined"
                                fullWidth={true}
                                value={stdout}
                                onChange={(e) => {
                                    setStdout(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <Button disabled={onSubmit}
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                    backgroundColor: "rgb(250, 53, 88)",
                                }}
                                onClick={() => CreateQuestion()}
                            >
                                Create Question
							</Button>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        </AuthLayout>

    );
}
