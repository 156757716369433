let initialState = {
	globalRankings: [],
};

function globalRankingReducer(state = initialState, action) {
	if (action.type === "GET_ALL_GLOBALRANKING") {
		return {
			...state,
			globalRankings: action.payload,
		};
	}
	return state;
}

export default globalRankingReducer;
