import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API } from "../../../../backend";
import Loader from "../../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import EventLayout from "../../../../components/EventLayout";
import Drawer from "@material-ui/core/Drawer";
import { FormControl } from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import { useHistory, useLocation } from "react-router";
import Grid from "@material-ui/core/Grid";
import logoSm from "../../../../assets/images/logo-sm.png";
import { DropzoneArea } from "material-ui-dropzone";
import SessionSidebar from "./VirtualboothSidebar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";

const moment = require("moment");
const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Heading,
    Indent,
    Table,
    TableToolbar,
    Link,
    List,
    MediaEmbed,
    CodeBlock,
    ImageResize,
    ImageToolbar,
    ImageStyle,
  ],
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "mediaEmbed",
    "insertTable",
    "codeBlock",
  ],
  mediaEmbed: {
    previewsInData: true
  },
  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },

  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  codeBlock: {
    languages: [
      // Do not render the CSS class for the plain text code blocks.
      { language: "plaintext", label: "Plain text", class: "" },

      // Use the "php-code" class for PHP code blocks.
      { language: "php", label: "PHP", class: "php-code" },

      // Use the "js" class for JavaScript code blocks.
      // Note that only the first ("js") class will determine the language of the block when loading data.
      {
        language: "javascript",
        label: "JavaScript",
        class: "js javascript js-code",
      },

      // Python code blocks will have the default "language-python" CSS class.
      { language: "python", label: "Python" },
    ],
  },
};
const useStyles = makeStyles({
  root: {
    minHeight: 206,
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardbody: {
    padding: "0 10px 10px 10px",
    borderRadius: "8px",
  },
  eventbanner: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "100px",
    objectFit: "fill",
    maxWidth: "100%",
  },
  eventName: {
    fontWeight: 500,
    display: "-webkit-box",
    overflow: "hidden",
    "-mozBoxOrient": "vertical",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": 2,
  },
  moreOptions: {
    background: "#ffffff",
    position: "absolute",
    right: "10px",
    top: "6px",
    borderRadius: "50px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f44d5e",
    fontSize: "20px",
  },
  optionMenu: {
    boxShadow: "none",
    border: "1px solid #dbdbdb",
    "& div": {
      boxShadow: "none",
      border: "1px solid #dbdbdb",
    },
  },
  optionMenuItem: {
    boxShadow: "none",
  },
  createEventDrawer: {
    width: "40%",
  },
  creatEventHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    overflowX: "hidden",
  },
  spacingCreateEvent: {
    padding: "10px 30px 10px 30px",
    height: "calc(100vh - 142px)",
    overflow: "auto",
  },
  eventType: {
    width: "50%",
    textAlign: "center",
    color: "gray",
    marginBottom: 0,
    padding: "8px",
  },
  border: {
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
  },
  selectedEventType: {
    background: "#f44d5e",
    color: "#ffffff",
    width: "50%",
    textAlign: "center",
    marginBottom: 0,
    padding: "8px",
  },
  textField: {
    marginRight: 10,
    border: "1px solid rgba(53, 53, 53, 0.17)",
    borderRadius: "4px",
    color: "#353535",
    width: "inherit",
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function VirtualBoothDetails(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const eventId = props.match.params.eventId;
  const sessionId = props.match.params.sessionId;
  const boothId = props.match.params.boothId;
  const [drawer, setDrawer] = React.useState({
    speakers: false,
    sponsers: false,
    addSession: false,
  });
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [sessionDates, setSessionDates] = React.useState([]);
  const [datetabsValue, setdatetabsValue] = React.useState("");
  const [openPosterDel, setopenPosterDel] = React.useState({
    show: false,
    data: {},
    imagetype: "",
  });
  const [scroll, setScroll] = React.useState("paper");
  const [check, setCheck] = React.useState(false);
  // const [pageBannerImg, setpageBannerImg] = React.useState("");
  const [companyLogoImg, setCompanyLogoImg] = useState([]);
  const [comapanyTitle, setComapanyTitle] = useState("");
  const [contactUsEmail, setContactUsEmail] = useState("");
  // const [logo, setLogo] = useState([]);
  const [aboutUs, setAboutUs] = useState("");
  const [pageBanner, setPageBanner] = useState([]);
  const [smallBanner, setSmallBanner] = useState([]);
  const [spotLightBanner, setSpotLightBanner] = useState([]);
  const [sponseredProductLabel, setSponseredProductLabel] = useState("");
  const [files, setFiles] = useState([]);
  const [priority, setPriority] = useState(0);
  const [contactNumber, setContactNumber] = useState("");
  const [sponsershipCategoryList, setsponsershipCategoryList] = useState([]);
  const [sponsershipType, setSponsershipType] = useState({});
  const [address, setAddress] = useState("");
  const [isfeatured, setIsFeatured] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  const [createCategory, setCreateCategory] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [isEditCategory, setIsEditCategory] = React.useState("");
  const [boothData, setBoothData] = React.useState([]);
  const [boothSize, setBoothSize] = useState("large");
  const [currentSelectImage, setCurrentSelectImage] = useState("image");
  const [website, setWebsite] = React.useState("");
  const [linkedIn, setLinkedIn] = React.useState("");
  const [twitter, setTwitter] = React.useState("");
  const [fb, setfb] = React.useState("");
  const [insta, setInsta] = React.useState("");
  const [whatsApp, setWhatsApp] = React.useState("");
  let location = useLocation();
  var temp = location.pathname.split("/");
  var activeNav = temp[3];
  // const [smallBannerIMg, setSmallBannerImg] = React.useState("");
  // const [fileImg, setfileImg] = React.useState("");
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCategoryOptions(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setEnd(!end);
  };
  const deleteCategory = (category) => {
    axios
      .delete(
        `${BOOTCAMP_API}/events/deleteSponserCategoryData/${category._id}`
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setsponsershipCategoryList((prev) =>
            prev.filter((x) => {
              return x._id !== category._id;
            })
          );
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const addVirtualBooth = async () => {
    const formData = new FormData();
    comapanyTitle && formData.append("comapanyTitle", comapanyTitle);
    contactUsEmail && formData.append("contactUsEmail", contactUsEmail);
    companyLogoImg.length !== 0
      ? formData.append("logo", companyLogoImg[0])
      : boothData?.companyId?.logo &&
        formData.append("logo", boothData?.companyId?.logo);
    website && formData.append("website", website);
    aboutUs && formData.append("aboutUs", aboutUs);
    formData.append("publish", isPublish);
    pageBanner.length !== 0
      ? formData.append("pageBanner", pageBanner[0])
      : boothData?.companyId?.pageBanner &&
        formData.append("pageBanner", boothData?.companyId?.pageBanner);
    smallBanner.length !== 0
      ? formData.append("smallBanner", smallBanner[0])
      : boothData?.companyId?.smallBanner &&
        formData.append("smallBanner", boothData?.companyId?.smallBanner);
    spotLightBanner.length !== 0
      ? formData.append("spotLightBanner", spotLightBanner[0])
      : boothData?.companyId?.spotLightBanner &&
        formData.append(
          "spotLightBanner",
          boothData?.companyId?.spotLightBanner
        );
    boothSize && formData.append("boothSize", boothSize);
    sponseredProductLabel &&
      formData.append("sponseredProductLabel", sponseredProductLabel);
    formData.append("featured", isfeatured);
    files.length !== 0
      ? formData.append("files", files[0])
      : boothData?.companyId?.files &&
        formData.append("files", boothData?.companyId?.files);
    priority && formData.append("priority", priority);
    contactNumber && formData.append("contactNumber", contactNumber);
    formData.append("eventId", props.match.params.eventId);
    sponsershipType &&
      formData.append(
        "sponsershipType",
        sponsershipType._id || sponsershipType.value
      );
    fb && formData.append("fb", fb);
    twitter && formData.append("twitter", twitter);
    linkedIn && formData.append("linkedIn", linkedIn);
    insta && formData.append("Instagram", insta);
    whatsApp && formData.append("whatsApp", whatsApp);
    address && formData.append("address", address);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .put(
        `${BOOTCAMP_API}/events/updateCompanyExhibitors/${boothData.companyId._id}`,
        formData,
        config
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          console.log(response);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const deletePoster = () => {
    const paramData = {};
    paramData[openPosterDel.imagetype] = openPosterDel.data;
    axios
      .delete(
        `${BOOTCAMP_API}/events/deleteImageCompanyExhibitors/${boothData.companyId._id}/${eventId}`,
        { params: paramData }
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setBoothData((prev) => ({
            ...prev,
            [openPosterDel.imagetype]: "",
          }));
          setopenPosterDel({ ...openPosterDel, show: false });
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const resetAllData = () => {};
  const getBoothData = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificCompanyExhibitors/${props.match.params.boothId}`
      )
      .then((response) => {
        setBoothData(response.data.result.data);
        addDataToInputField(response.data.result.data);
      })
      .catch((err) => console.log(err));
  };
  const addDataToInputField = (data) => {
    setComapanyTitle(data.companyId?.comapanyTitle);
    setContactNumber(data.companyId?.contactNumber);
    setSponsershipType({
      label: data?.sponsershipType?.title,
      value: data?.sponsershipType?._id,
    });
    setBoothSize(data?.companyId?.boothSize);
    setSponseredProductLabel(data?.companyId?.sponseredProductLabel || "");
    setAboutUs(data?.companyId?.aboutUs || "");
    setContactUsEmail(data?.companyId?.contactUsEmail || "");
    setAddress(data?.companyId?.address || "");
    setfb(data?.companyId?.fb || "");
    setInsta(data?.companyId?.Instagram || "");
    setLinkedIn(data?.companyId?.linkedIn || "");
    setWebsite(data?.companyId?.website || "");
    setWhatsApp(data?.companyId?.whatsApp || "");
    setIsPublish(data?.companyId?.publish);
    setIsFeatured(data?.companyId?.featured);
    setTwitter(data?.companyId?.twitter || "");
    setBoothSize(data?.boothSize);
    setPriority(data?.priority);
    // setLogo(data.companyId.logo);
    // setpageBannerImg(data.companyId.pageBanner);
    // setSmallBannerImg(data.companyId.smallBanner);
    // setfileImg(data.companyId.files);
  };
  const getCategoryList = () => {
    axios
      .get(
        `${BOOTCAMP_API}/events/getSponserCategoryUnderEvent/${
          props.match.params.eventId
        }/${activeNav === "virtual-booth" ? "booth" : "sponsor"}`
      )
      .then((response) => {
        let categoryList = response.data.result.data;
        categoryList.filter((x) => {
          x.label = x.title;
          x.value = x._id;
        });
        setsponsershipCategoryList(categoryList);
      })
      .catch((err) => console.log(err));
  };
  const getEventData = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
        let eventResponse = response.data.result.data.dates;
        let sessionDates = [];
        eventResponse.filter((x, index) => {
          let data = {};
          data.value = x;
          data.label = `Day ${index + 1} - ${moment(x).format("Do MMM YYYY")}`;
          sessionDates.push(data);
        });
        setdatetabsValue(sessionDates[0].label);
        setSessionDates(sessionDates);
      })
      .catch((err) => console.log(err));
  };
  const createNewCategory = () => {
    if (isEditCategory === "") {
      const newTrack = {
        title: categoryTitle,
        eventId: props.match.params.eventId,
      };
      axios
        .post(`${BOOTCAMP_API}/events/createSponserCategory`, newTrack)
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setCreateCategory(false);
            let categoryResponse = response.data.result.data;
            categoryResponse.label = categoryResponse.title;
            categoryResponse.value = categoryResponse._id;
            setsponsershipCategoryList((prev) => [...prev, categoryResponse]);
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    } else {
      const newTrack = {
        title: categoryTitle,
      };
      axios
        .put(
          `${BOOTCAMP_API}/events/updateSponserCategory/${isEditCategory}`,
          newTrack
        )
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setCreateCategory(false);
            setsponsershipCategoryList((prev) =>
              prev.filter((x) => {
                if (x._id === isEditCategory) {
                  x.title = response.data.result.data.title;
                  x.label = response.data.result.data.title;
                  x.value = response.data.result.data._id;
                  setIsEditCategory("");
                  return x;
                }
                return prev;
              })
            );
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getEventData();
    getBoothData();
    getCategoryList();
  }, []);

  return (
    <EventLayout>
      {messageData && (
        <Snackbar
          open={successMessage}
          onClose={() => setSuccessMessage(false)}
        >
          <>
            <Alert onClose={() => setSuccessMessage(false)} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar open={errorStatus} onClose={() => setErrorStatus(false)}>
          <>
            <Alert onClose={() => setErrorStatus(false)} severity="error">
              {errorMessage}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        {Object.keys(eventData).length > 0 ? (
          <div className="w-100 event-overview">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <br />
                <ul className="eventsBreadcrumb p-0 d-flex align-items-center">
                  <li
                    className="cursor-pointer"
                    onClick={() =>
                      history.push(
                        `/event/${props.match.params.eventId}/${activeNav}`
                      )
                    }
                  >
                    {activeNav === "virtual-booth"
                      ? "Virtual Booths"
                      : "Sponsors"}
                  </li>
                  <li style={{ color: "#353535" }}>{eventData.title}</li>
                </ul>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SessionSidebar
                  eventId={eventId}
                  boothId={boothId}
                  boothType={activeNav}
                />
              </Grid>
              <Grid item xs={8}>
                <div className="event-cardHld">
                  <h3>Stream Options</h3>
                  <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
                  <div className="row">
                    <div className="col-4">
                      <div className="addSession mt-2">
                        {boothData?.companyId?.logo ? (
                          <div className="ImgHld">
                            <img
                              src={boothData.companyId.logo}
                              alt=""
                              className="img-fluid"
                            />
                            <div className="Imagesoverlay mx-2 d-flex">
                              {/* <span
                                className="float-right"
                                style={{
                                  fontSize: "24px",
                                  color: "#f44d5e",
                                  cursor: "pointer",
                                }}
                              >
                                <i className="mdi mdi-pencil-box-outline"></i>
                              </span> */}
                              <span
                                onClick={() =>
                                  setopenPosterDel({
                                    ...openPosterDel,
                                    show: true,
                                    imagetype: "logo",
                                    data: boothData?.companyId?.logo,
                                  })
                                }
                              >
                                <i
                                  className="mdi mdi-delete brand-color"
                                  style={{ fontSize: "30px" }}
                                ></i>
                              </span>
                            </div>
                          </div>
                        ) : (
                          <DropzoneArea
                            acceptedFiles={[
                              "image/jpeg",
                              "image/png",
                              "image/bmp",
                            ]}
                            onChange={(files) => setCompanyLogoImg(files)}
                            maxFileSize={9242880}
                            filesLimit={1}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-8">
                      <div>
                        <label htmlFor="title" className="event-label">
                          Booth Name
                          <span style={{ color: "red", fontSize: "10px" }}>
                            *
                          </span>
                        </label>
                        <input
                          id="title"
                          type="text"
                          className="form-control"
                          placeholder="Enter Booth Name"
                          value={comapanyTitle}
                          onChange={(e) => setComapanyTitle(e.target.value)}
                        />
                      </div>
                      <div className="mt-3">
                        <label htmlFor="title" className="event-label">
                          Category
                        </label>
                        <div className="position-relative">
                          <div
                            className="customSelectControls"
                            onClick={() =>
                              setShowCategoryOptions(!showCategoryOptions)
                            }
                          >
                            <span>
                              {Object.keys(sponsershipType).length > 0
                                ? sponsershipType.title || sponsershipType.label
                                : "Select Category"}
                            </span>
                          </div>
                          {showCategoryOptions && (
                            <div className="customDropDown" ref={wrapperRef}>
                              {!createCategory ? (
                                <button
                                  className="event-outlined-btn mb-2 ml-2"
                                  onClick={() => setCreateCategory(true)}
                                >
                                  ADD
                                  <i className="mdi mdi-plus-circle-outline ml-1"></i>
                                </button>
                              ) : (
                                <div className="d-flex px-2 createCategoryHld">
                                  <input
                                    type="text"
                                    placeholder="Enter category"
                                    className="form-control"
                                    value={categoryTitle}
                                    onChange={(e) =>
                                      setCategoryTitle(e.target.value)
                                    }
                                  />
                                  <div className="createcategoryinputOptions">
                                    {categoryTitle ? (
                                      <span
                                        onClick={createNewCategory}
                                        className="cursor-pointer"
                                      >
                                        <i
                                          className="mdi mdi-checkbox-marked brand-color"
                                          style={{ fontSize: "24px" }}
                                        ></i>
                                      </span>
                                    ) : (
                                      <span className="cursor-pointer">
                                        <i
                                          className="mdi mdi-checkbox-marked"
                                          style={{
                                            fontSize: "24px",
                                            color: "rgba(0, 0, 0, 0.26)",
                                          }}
                                        ></i>
                                      </span>
                                    )}
                                    <span
                                      className="cursor-pointer"
                                      onClick={() => setCreateCategory(false)}
                                    >
                                      <i
                                        className="mdi mdi-close-box-outline brand-color"
                                        style={{ fontSize: "24px" }}
                                      ></i>
                                    </span>
                                  </div>
                                </div>
                              )}

                              <hr />
                              <div className="optionsHld">
                                {sponsershipCategoryList.map((category) => (
                                  <p
                                    key={category._id}
                                    className="mb-0"
                                    onClick={() => (
                                      setSponsershipType(category),
                                      setShowCategoryOptions(false),
                                      setCreateCategory(false)
                                    )}
                                  >
                                    {category.title}
                                    <span
                                      style={{
                                        fontSize: "24px",
                                        float: "right",
                                      }}
                                      className="lightGrey-color"
                                      onClick={(e) => (
                                        e.stopPropagation(),
                                        deleteCategory(category)
                                      )}
                                    >
                                      <i className="mdi mdi-delete"></i>
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "24px",
                                        float: "right",
                                      }}
                                      className="lightGrey-color mr-2"
                                      onClick={(e) => (
                                        e.stopPropagation(),
                                        setCreateCategory(true),
                                        setIsEditCategory(category._id),
                                        setCategoryTitle(category.title)
                                      )}
                                    >
                                      <i className="mdi mdi-pencil-box-outline"></i>
                                    </span>
                                  </p>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-6">
                      <label htmlFor="title" className="event-label">
                        Booth Size
                      </label>
                      <div className="d-flex align-items-center justify-content-between virtualBoothSize">
                        <div
                          className="virtualBoothSizeSection"
                          onClick={() => setBoothSize("large")}
                          style={{
                            backgroundColor:
                              boothSize === "large" ? "#f44D54" : null,
                            borderRadius:
                              boothSize === "large" ? "5px 0px 0px 5px" : null,
                            color: boothSize === "large" ? "#ffffff" : null,
                          }}
                        >
                          <p>Large</p>
                        </div>
                        <div
                          className="virtualBoothSizeSection"
                          onClick={() => setBoothSize("medium")}
                          style={{
                            backgroundColor:
                              boothSize === "medium" ? "#f44D54" : null,
                            color: boothSize === "medium" ? "#ffffff" : null,
                          }}
                        >
                          <p>Medium</p>
                        </div>
                        <div
                          className="virtualBoothSizeSection"
                          onClick={() => setBoothSize("small")}
                          style={{
                            backgroundColor:
                              boothSize === "small" ? "#f44D54" : null,
                            borderRadius:
                              boothSize === "small" ? "0px 5px 5px 0px" : null,
                            color: boothSize === "small" ? "#ffffff" : null,
                          }}
                        >
                          <p>Small</p>
                        </div>
                      </div>
                    </div>

                    <div className="col-6">
                      <label htmlFor="title" className="event-label">
                        Priority
                      </label>
                      <input
                        id="priority"
                        type="number"
                        className="form-control"
                        placeholder="Enter Priority"
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-12">
                      <label htmlFor="title" className="event-label">
                        Sponsored Product/Service Label
                      </label>
                      <input
                        id="title"
                        type="text"
                        className="form-control"
                        placeholder="Enter Sponsored Product/Service Label"
                        value={sponseredProductLabel}
                        onChange={(e) =>
                          setSponseredProductLabel(e.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-12">
                      <div className="virtualBoothAddBannerButtonText">
                        <p>Listing Page Banner 385x250px | 5MB (Maximum)</p>
                        <p>
                          This banner is displayed on a booth card on the
                          exhibitor's list.
                        </p>
                      </div>

                      <div>
                        <div className="addSession mt-2">
                          {boothData?.companyId?.pageBanner ? (
                            <div className="ImgHld">
                              <img
                                src={boothData?.companyId?.pageBanner}
                                alt=""
                                className="img-fluid"
                              />
                              <div className="Imagesoverlay mx-2 d-flex">
                                <span
                                  onClick={() =>
                                    setopenPosterDel({
                                      ...openPosterDel,
                                      show: true,
                                      imagetype: "pageBanner",
                                      data: boothData?.companyId?.pageBanner,
                                    })
                                  }
                                >
                                  <i
                                    className="mdi mdi-delete brand-color"
                                    style={{ fontSize: "30px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <DropzoneArea
                              acceptedFiles={[
                                "image/jpeg",
                                "image/png",
                                "image/bmp",
                              ]}
                              onChange={(files) => setPageBanner(files)}
                              maxFileSize={9242880}
                              filesLimit={1}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-12">
                      <div className="virtualBoothAddBannerButtonText">
                        <p>Small Banner 385x250px | 5MB (Maximum)</p>
                        <p style={{ marginBottom: "0px" }}>
                          This banner is displayed on a booth card on the mobile
                          app,reception and sessions page.
                          <br />
                          <small>
                            A cropped version of the default image on mobile app
                            will be placed automatically if a separate imgae is
                            not added here
                          </small>
                        </p>
                      </div>
                      <div>
                        <div className="addSession mt-2">
                          {boothData?.companyId?.smallBanner ? (
                            <div className="ImgHld">
                              <img
                                src={boothData?.companyId?.smallBanner}
                                alt=""
                                className="img-fluid"
                              />
                              <div className="Imagesoverlay mx-2 d-flex">
                                <span
                                  onClick={() =>
                                    setopenPosterDel({
                                      ...openPosterDel,
                                      show: true,
                                      imagetype: "smallBanner",
                                      data: boothData?.companyId?.smallBanner,
                                    })
                                  }
                                >
                                  <i
                                    className="mdi mdi-delete brand-color"
                                    style={{ fontSize: "30px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <DropzoneArea
                              acceptedFiles={[
                                "image/jpeg",
                                "image/png",
                                "image/bmp",
                              ]}
                              onChange={(files) => setSmallBanner(files)}
                              maxFileSize={9242880}
                              filesLimit={1}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-12">
                      <div className="row mt-3 mb-3">
                        <div className="col-12">
                          <div className="virtualBoothAddBannerButtonText">
                            <p>Spotlight Banner</p>
                            <p>
                              This banner is displayed on a booth card on the
                              exhibitor's list.
                            </p>
                          </div>

                          <div className="mt-2">
                            <div
                              className="col-4 d-flex align-items-center justify-content-between virtualBoothSize"
                              style={{ padding: "0px" }}
                            >
                              <div
                                className="virtualBoothSizeSection"
                                onClick={() => setCurrentSelectImage("image")}
                                style={{
                                  backgroundColor:
                                    currentSelectImage === "image"
                                      ? "#f44D54"
                                      : null,
                                  borderRadius:
                                    currentSelectImage === "image"
                                      ? "5px 0px 0px 5px"
                                      : null,
                                  color:
                                    currentSelectImage === "image"
                                      ? "#ffffff"
                                      : null,
                                  width: "50%",
                                }}
                              >
                                <p>Image</p>
                              </div>
                              <div
                                className="virtualBoothSizeSection"
                                onClick={() => setCurrentSelectImage("video")}
                                style={{
                                  backgroundColor:
                                    currentSelectImage === "video"
                                      ? "#f44D54"
                                      : null,
                                  borderRadius:
                                    currentSelectImage === "video"
                                      ? "0px 5px 5px 0px"
                                      : null,
                                  color:
                                    currentSelectImage === "video"
                                      ? "#ffffff"
                                      : null,
                                  width: "50%",
                                }}
                              >
                                <p>Video</p>
                              </div>
                            </div>
                            <div>
                              <div className="addSession mt-2">
                                {boothData?.companyId?.spotLightBanner ? (
                                  <div className="ImgHld">
                                    <img
                                      src={
                                        boothData?.companyId?.spotLightBanner
                                      }
                                      alt=""
                                      className="img-fluid"
                                    />
                                    <div className="Imagesoverlay mx-2 d-flex">
                                      <span
                                        onClick={() =>
                                          setopenPosterDel({
                                            ...openPosterDel,
                                            show: true,
                                            imagetype: "spotLightBanner",
                                            data: boothData?.companyId
                                              ?.spotLightBanner,
                                          })
                                        }
                                      >
                                        <i
                                          className="mdi mdi-delete brand-color"
                                          style={{ fontSize: "30px" }}
                                        ></i>
                                      </span>
                                    </div>
                                  </div>
                                ) : (
                                  <DropzoneArea
                                    acceptedFiles={[
                                      "image/jpeg",
                                      "image/png",
                                      "image/bmp",
                                    ]}
                                    onChange={(files) =>
                                      setSpotLightBanner(files)
                                    }
                                    maxFileSize={9242880}
                                    filesLimit={1}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-3 mb-3">
                    <label htmlFor="title" className="event-label">
                      About
                    </label>
                    <div className={classes.editor}>
                      <CKEditor
                        editor={ClassicEditor}
                        config={editorConfiguration}
                        data={aboutUs}
                        onInit={(editor) => {
                          // You can store the "editor" and use when it is needed.
                          console.log("Editor is ready to use!", editor);
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setAboutUs(data);
                        }}
                      />
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-6">
                      <label htmlFor="title" className="event-label">
                        Mobile Number
                      </label>
                      <input
                        id="title"
                        type="text"
                        className="form-control"
                        placeholder="Enter Mobile Number"
                        value={contactNumber.phoneNumber}
                        onChange={(e) => setContactNumber(e.target.value)}
                      />
                    </div>

                    <div className="col-6">
                      <label htmlFor="title" className="event-label">
                        Contact Email ID
                      </label>
                      <input
                        id="title"
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact Email ID"
                        value={contactUsEmail}
                        onChange={(e) => setContactUsEmail(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-12">
                      <label htmlFor="title" className="event-label">
                        Address
                      </label>
                      <textarea
                        style={{ height: "150px" }}
                        id="title"
                        type="text"
                        className="form-control"
                        placeholder="Enter address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="row mt-3 mb-3">
                    <div className="col-12">
                      <div className="virtualBoothAddBannerButtonText">
                        <p>File</p>
                        <p>
                          .DOCX,.DOC,.PPT,.PPTX,.PDF,.XLS,.XLSX,|100MB(Maximum)
                        </p>
                      </div>
                      <div>
                        <div className="addSession mt-2">
                          {boothData?.companyId?.files ? (
                            <div className="">
                              {/* <img
                                src={boothData?.companyId?.files}
                                alt=""
                                className="img-fluid"
                              /> */}

                              <a href={boothData?.companyId?.files}>
                                Uploded File
                              </a>

                              <div className="Imagesoverlay mx-2 d-flex">
                                <span
                                  onClick={() =>
                                    setopenPosterDel({
                                      ...openPosterDel,
                                      show: true,
                                      imagetype: "files",
                                      data: boothData?.companyId?.files,
                                    })
                                  }
                                >
                                  <i
                                    className="mdi mdi-delete brand-color"
                                    style={{ fontSize: "30px" }}
                                  ></i>
                                </span>
                              </div>
                            </div>
                          ) : (
                            <DropzoneArea
                              // acceptedFiles={[
                              //   "image/jpeg",
                              //   "image/png",
                              //   "image/bmp",
                              //   ]}

                              acceptedFiles={[
                                "application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf",
                              ]}
                              onChange={(files) => setFiles(files)}
                              maxFileSize={9242880}
                              filesLimit={1}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 mb-3 pl-2 pr-2">
                    <FormControl className={classes.formControl}>
                      <label htmlFor="title">Social Links</label>
                      <input
                        id="title"
                        type="text"
                        className="form-control mb-2"
                        placeholder="https://website"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <input
                        id="title"
                        type="text"
                        className="form-control mb-2"
                        placeholder="https://linkedIn"
                        value={linkedIn}
                        onChange={(e) => setLinkedIn(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <input
                        id="title"
                        type="text"
                        className="form-control mb-2"
                        placeholder="https://twitter"
                        value={twitter}
                        onChange={(e) => setTwitter(e.target.value)}
                      />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                      <input
                        id="title"
                        type="text"
                        className="form-control mb-2"
                        placeholder="https://fb"
                        value={fb}
                        onChange={(e) => setfb(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <input
                        id="title"
                        type="text"
                        className="form-control mb-2"
                        placeholder="https://insta"
                        value={insta}
                        onChange={(e) => setInsta(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      <input
                        id="title"
                        type="text"
                        className="form-control mb-2"
                        placeholder="Enter WhatsApp Number"
                        value={whatsApp}
                        onChange={(e) => setWhatsApp(e.target.value)}
                      />
                    </FormControl>
                  </div>

                  {/* <div className="mt-3 mb-3">
                    <div className="mt-3">
                      <label htmlFor="title" className="event-label">
                        Custom Tags
                      </label>
                      <input
                        id="title"
                        type="text"
                        className="form-control"
                        placeholder="Enter tags"
                      />
                    </div>
                  </div> */}

                  {/* <div>
                    <input
                      className="virtualBoothCheckBox"
                      type="checkbox"
                      id="rateBooth"
                      name="rateBooth"
                      value="Bike"
                    />
                    <label htmlFor="vehicle1">
                      Let attendees rate this booth
                    </label>
                  </div> */}

                  {/* <div>
                    <input
                      className="virtualBoothCheckBox"
                      type="checkbox"
                      id="featuredBooth"
                      name="featuredBooth"
                      value={isfeatured}
                      onChange={() => setIsFeatured(!isfeatured)}
                    />
                    <label htmlFor="vehicle2"> Feature this Booth</label>
                  </div> */}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isfeatured}
                        onChange={() => {
                          setIsFeatured(!isfeatured);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Feature this Booth"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPublish}
                        onChange={() => {
                          setIsPublish(!isPublish);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Publish"
                  />

                  {/* <div>
                    <input
                      className="virtualBoothCheckBox"
                      type="checkbox"
                      id="publishBooth"
                      name="publishBooth"
                      value={isPublish}
                      onChange={() => setIsPublish(!isPublish)}
                    />
                    <label htmlFor="vehicle2">Publish</label>
                  </div> */}

                  <br />
                  <div className=" mr-auto d-flex justify-content-end">
                    <button
                      className="event-primary-btn"
                      onClick={addVirtualBooth}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
      </Row>
      <Drawer
        anchor="right"
        open={drawer.speakers}
        onClose={toggleDrawer("speakers", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Add Speaker</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("speakers", false)}
                onKeyDown={toggleDrawer("speakers", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <FormControl className={classes.formControl}>
              <input
                id="title"
                type="text"
                className="form-control mb-2"
                placeholder="Search"
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer">
                  <img
                    src={logoSm}
                    alt=""
                    className="img-fluid speakerImg mb-2"
                  />
                  <p className="mb-0">Name</p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer">
                  <img
                    src={logoSm}
                    alt=""
                    className="img-fluid speakerImg mb-2"
                  />
                  <p className="mb-0">Name</p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer">
                  <img
                    src={logoSm}
                    alt=""
                    className="img-fluid speakerImg mb-2"
                  />
                  <p className="mb-0">Name</p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer">
                  <img
                    src={logoSm}
                    alt=""
                    className="img-fluid speakerImg mb-2"
                  />
                  <p className="mb-0">Name</p>
                </div>
              </Grid>
            </Grid>
            <br />
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn}>ADD</button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("speakers", false)}
              onKeyDown={toggleDrawer("speakers", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={drawer.sponsers}
        onClose={toggleDrawer("sponsers", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Add Sponsor</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("sponsers", false)}
                onKeyDown={toggleDrawer("sponsers", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <FormControl className={classes.formControl}>
              <input
                id="title"
                type="text"
                className="form-control mb-2"
                placeholder="Search"
              />
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <div className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer">
                  <img
                    src={logoSm}
                    alt=""
                    className="img-fluid speakerImg mb-2"
                  />
                  <p className="mb-0">Name</p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer">
                  <img
                    src={logoSm}
                    alt=""
                    className="img-fluid speakerImg mb-2"
                  />
                  <p className="mb-0">Name</p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer">
                  <img
                    src={logoSm}
                    alt=""
                    className="img-fluid speakerImg mb-2"
                  />
                  <p className="mb-0">Name</p>
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer">
                  <img
                    src={logoSm}
                    alt=""
                    className="img-fluid speakerImg mb-2"
                  />
                  <p className="mb-0">Name</p>
                </div>
              </Grid>
            </Grid>
            <br />
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn}>Apply</button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("sponsers", false)}
              onKeyDown={toggleDrawer("sponsers", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>

      <Dialog
        open={openPosterDel.show}
        onClose={() => setopenPosterDel({ ...openPosterDel, show: false })}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong>Image deleted once can't be retrieved.</strong>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete this Image"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setopenPosterDel({ ...openPosterDel, show: false })}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deletePoster}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </EventLayout>
  );
}
