import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
// import DownloadLoader from "../../DownloadUserData/Loader";
import Header from "../Header";
import AuthLayout from "../../components/AuthLayout";
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import '../../App.css';
import axios from "axios"
import { API } from "../../backend"
const moment = require('moment');

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
	  '& > *': {
		marginTop: theme.spacing(2),
	  },
	},
  }));

export default function AllPracticeModules(props) {
	const [open, setOpen] = React.useState(false);
	const [end, setEnd] = useState(false);
	const [messageData, setMessage] = useState("");
	const [loaderAt, setLoaderAt] = useState(-1)
	const [errorMessage, setErrorMessage] = useState("");
	const [errorStatus, setErrorStatus] = useState(false);
	const [status, setStatus] = useState(false);
	const [statusLoader, setStatusLoader] = useState(false);
	const [practiceModules, setPracticeModules] = useState([]);
	const [totalModules, setTotalModules] = useState(0);
	const [selectedPage, setSelectedPage] = useState("1");

	const classes = useStyles();

	const handleClose = () => {
		setStatus(false);
	};

	const handleCloseEnd = () => {
		setEnd(!end);
	};

	const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };


	const FetchAllPracticeModules = (pageNumber) =>{

		axios.get(`${API}/cms/practice_modules/${pageNumber}`)
			.then((response) =>{
				setPracticeModules(response.data.modules);
				setTotalModules(response.data.total_modules);
			})
	}

	const updatePublish = (data,index) =>{

		setLoaderAt(index);

		axios.put(`${API}/update_practice_module/${data.slug}`,null,{
			params:{
				"title":data.title,
				"description":data.description,
				"short_description":data.short_description,
				"level":data.HLevel,
				"Publish":!data.Publish,
				"moduleno":data.module_number
			}
		}).then((response) =>{

			if(response.data.message){
				setLoaderAt(-1);
				setStatus(true);
				setMessage(!data.Publish ? `Published Successfully!` : 'Unpublished Successfully!');
				var practiceDup = practiceModules.slice();
				practiceDup[index].Publish = !data.Publish;
				setPracticeModules(practiceDup);

			}
			else {
				setErrorStatus(true);
				setErrorMessage(`No practice Module found to ${data.Publish ? 'Publish' : 'Unpublish'}`);
				setStatusLoader(false);
				setLoaderAt(-1);
			}
		})
		.catch((error) =>{
			setLoaderAt(-1);
		})
	}

	const nextPracticeModules = (e,value) =>{

		setSelectedPage(value);

		FetchAllPracticeModules(value);
	}

	useEffect(() => {
		FetchAllPracticeModules("1");
	}, []);


	return (
		<AuthLayout>
			<React.Fragment>
				<div className="">
					<Row>
						<Col>
							<Header name="All Practice Modules" />
						</Col>
					</Row>

					<Row>
						<Col lg={12}>
							<>
								{status && (
									<Snackbar
										open={status}
										autoHideDuration={5000}
										onClose={handleClose}
									>
										<>
											<Alert
												onClose={handleClose}
												severity="success"
											>
												{messageData}
											</Alert>
										</>
									</Snackbar>
								)}

								{errorStatus && (
									<Snackbar
										open={errorStatus}
										autoHideDuration={7000}
										onClose={handleCloseError}
									>
										<Alert
											onClose={handleCloseError}
											severity="error"
										>
											{errorMessage}
										</Alert>
									</Snackbar>
								)}


								{
									practiceModules.length > 0
										?
										(
											practiceModules.map((practiceModule, index) => (
												<Card key={practiceModule.slug}>
													<Row>

														<Col lg={7}>
															<CardBody>
																<h4>
																	{practiceModule.title}
																</h4>
																<span>
																	<b>Short Description: </b> {practiceModule.short_description ? practiceModule.short_description : ''}
																</span>
																<br />
																<span>
																	<b>Description: </b> {practiceModule.description}
																</span>
																<br />
																<span>
																	<b>Difficulty: </b> {practiceModule.HLevel}
																</span>
																<br />
																<span>
																	<b>Module No: </b> {practiceModule.module_number}
																</span>
																<br />
																<span>
																	<b>Created At: </b> {practiceModule.created}
																</span>
																<br />
															</CardBody>
														</Col>

														<Col
															lg={5}
															style={{
																textAlign: "center",
															}}
														>
															<CardBody
																style={{
																	paddingLeft: "0px",
																	paddingRight: "0px",
																}}
															>

																{
																	index === loaderAt
																		?
																		(
																			<div style={{ margin: "auto", textAlign: 'left' }}>
																				<Loader />
																			</div>
																		)
																		:
																		(
																			<>
																				<ButtonToggle
																					className={practiceModule.Publish ? 'unpublish' : 'publish'}
																					onClick={() => updatePublish(practiceModule,index)}
																					// onClick={() => updateFlags(index, practiceModule.Publish ? true : false)}
																				>
																					{practiceModule.Publish ? 'UNPUBLISH' : 'PUBLISH'}
																				</ButtonToggle>

																				<Link
																					to={`/updatePracticeModule/${practiceModule.slug}`}
																				>
																					<ButtonToggle
																						color="danger"
																						style={{
																							paddingLeft:
																								"30px",
																							paddingRight:
																								"30px",
																							margin: "15px"
																						}}
																					>
																						Edit
																					</ButtonToggle>
																				</Link>

																				<Link to={`/practiceModules/${practiceModule.slug}/${practiceModule._id}`}>
																					<ButtonToggle
																						color="dark"
																						style={{
																							paddingLeft:
																								"30px",
																							paddingRight:
																								"30px",
																							margin: "15px",
																						}}
																					>
																						View Practice
																					</ButtonToggle>
																				</Link>
																			</>
																		)
																}

															</CardBody>
														</Col>
													</Row>
												</Card>
											))
										)
										:
										(
											<>
												{
													practiceModules.length === 0
														?
														<h4 style={{ textAlign: "center" }}>No Practice Module found</h4>
														:
														<div style={{ textAlign: "center" }}>
															<Loader />
														</div>
												}
											</>
										)
								}
							</>
						</Col>
						<Col lg={12} >
							<Row style={{justifyContent:"center",paddingBottom:"20px"}}>
								<Pagination count={totalModules % 10 === 0 ? parseInt(totalModules / 10) : parseInt(totalModules / 10)+1 }
										defaultPage={1}
										page={Number(selectedPage)}
										variant="outlined" 
                                		shape="rounded"
										className={classes.root}
										onChange={nextPracticeModules} 
										showFirstButton showLastButton  />
							</Row>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		</AuthLayout>
	);
}
