import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import { BOOTCAMP_API } from "../../backend";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    minHeight: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },
  control: {
    padding: theme.spacing(2),
  },
  image: {
    minHeight: 150,
    objectFit: "cover",
  },
}));

function Coins() {
  const [fullscreen, setFullsreen] = useState(false);
  const [coins, setCoins] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  const getAllCoins = async () => {
    await axios
      .get(`${BOOTCAMP_API}/mhCoins/getAllEarnMhChallengeDataCms`)
      .then((response) => {
        setCoins(response.data.result.data);
      })
      .catch((err) => console.log(err));
  };

  const deleteCoins = async (data) => {
    await axios
      .delete(
        `${BOOTCAMP_API}/mhCoins/deleteSpecificEarnMhCoinChallengeCms/${data._id}`
      )
      .then((response) => {
        setCoins(
          coins.filter((x) => {
            if (x._id === data._id) {
              return x._id !== data._id;
            }
            return coins;
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const onPublishChange = async (data, publishStatus) => {
    const requestData = {
      publish: publishStatus,
    };
    await axios
      .put(
        `${BOOTCAMP_API}/mhCoins/updateMhCoinChallenge/${data._id}`,
        requestData
      )
      .then((response) => {
        setCoins(
          coins.filter((x) => {
            if (x._id === data._id) {
              x.publish = response.data.result.data.publish;
            }
            return coins;
          })
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllCoins();
  }, []);
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Coins" />
      <Grid container className="mt-4">
        <Grid container spacing={6}>
          {coins.map((data) => (
            <Grid key={data._id} item xs={4} className="">
              <Paper className={classes.paper + " " + "p-2"}>
                <h4>Category: {data.earnMhCoinCategoryId.title}</h4>
                <h5>Title: {data.title}</h5>
               {data.description && <p>Description: {data.description}</p>}
                <p>URL: {data.redirectionLink}</p>
                <p>Coins: {data.coins}</p>
                <div className="">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mr-1"
                    onClick={() => history.push(`/coins/update/${data._id}`)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="mr-1"
                    onClick={() => deleteCoins(data)}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => onPublishChange(data, data.publish ? false : true)}
                  >
                    {data.publish ? "Unpublish" : "Publish"}
                  </Button>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

export default Coins;
