import React, { useState } from "react";
import axios, { post } from "axios";
import { API, BOOTCAMP_API } from "../../backend";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";

import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
// import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
// import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { DropzoneArea } from "material-ui-dropzone";
import Loader from "../../components/Loader";
import { Row, Col, Card, CardBody } from "reactstrap";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import Header from "../Header";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';

import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { stringify } from "querystring";
const { inspect } = require("util");
const editorConfiguration = {
    plugins: [
        Essentials,
        Bold,
        Italic,
        Paragraph,
        Image,
        ImageUpload,
        Base64UploadAdapter,
        Heading,
        Indent,
        Table,
        TableToolbar,
        Link,
        List,
        MediaEmbed,
        CodeBlock,
        ImageResize,
        ImageToolbar,
        ImageStyle,
    ],
    toolbar: [
        "heading",
        "bold",
        "italic",
        "imageUpload",
        "link",
        "bulletedList",
        "numberedList",
        "indent",
        "outdent",
        "mediaEmbed",
        "insertTable",
        "codeBlock",
    ],
    mediaEmbed: {
        previewsInData: true
    },
    image: {
        resizeOptions: [
            {
                name: "imageResize:original",
                label: "Original",
                value: null,
            },
            {
                name: "imageResize:50",
                label: "50%",
                value: "50",
            },
            {
                name: "imageResize:75",
                label: "75%",
                value: "75",
            },
        ],
        toolbar: [
            "imageResize",
            "|",
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "imageTextAlternative",
        ],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },

    image: {
        resizeOptions: [
            {
                name: "imageResize:original",
                label: "Original",
                value: null,
            },
            {
                name: "imageResize:50",
                label: "50%",
                value: "50",
            },
            {
                name: "imageResize:75",
                label: "75%",
                value: "75",
            },
        ],
        toolbar: [
            "imageResize",
            "|",
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "imageTextAlternative",
        ],
    },

    link: {
        decorators: {
            openInNewTab: {
                mode: "manual",
                label: "Open in a new tab",
                attributes: {
                    target: "_blank",
                    rel: "noopener noreferrer",
                },
            },
        },
    },

    heading: {
        options: [
            {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph",
            },
            {
                model: "heading1",
                view: "h1",
                title: "Heading 1",
                class: "ck-heading_heading1",
            },
            {
                model: "heading2",
                view: "h2",
                title: "Heading 2",
                class: "ck-heading_heading2",
            },
            {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
            },
            {
                model: "heading4",
                view: "h4",
                title: "Heading 4",
                class: "ck-heading_heading4",
            },
            {
                model: "heading5",
                view: "h5",
                title: "Heading 5",
                class: "ck-heading_heading5",
            },
            {
                model: "heading6",
                view: "h6",
                title: "Heading 6",
                class: "ck-heading_heading6",
            },
        ],
    },
    codeBlock: {
        languages: [
            // Do not render the CSS class for the plain text code blocks.
            { language: "plaintext", label: "Plain text", class: "" },

            // Use the "php-code" class for PHP code blocks.
            { language: "php", label: "PHP", class: "php-code" },

            // Use the "js" class for JavaScript code blocks.
            // Note that only the first ("js") class will determine the language of the block when loading data.
            {
                language: "javascript",
                label: "JavaScript",
                class: "js javascript js-code",
            },

            // Python code blocks will have the default "language-python" CSS class.
            { language: "python", label: "Python" },
        ],
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        // textAlign: "center",
        color: theme.palette.text.secondary,
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 400,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 420,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateBootcamp() {
    const classes = useStyles();
    const history = useHistory();
    const token = useSelector((store) => store.auth.token);
    console.log("defult", axios.defaults.headers.common["Authorization"]);
    const dispatch = useDispatch();

    const [image_1, setSelectedFile_1] = useState("");

    const [messageData, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);
    const [bootcamp, setBootcamp] = useState({});
    const [videoURLs, setvideoURLs] = useState([""]);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState(1);
    const [offeredBy, setOfferedBy] = useState("");
    const [s3UrlUnMatchedAt, setS3UrlUnMatchedAt] = useState(-1);

    const handleClose = () => {
        setStatus(!status);
    };

    const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };
    const handleLoader = () => {
        setStatusLoader(!statusLoader);
    };


    const addIndex = () => {
        setvideoURLs([...videoURLs, ""]);
    }

    const CancelCurrentIndex = (position) => {
        // setvideoURLs(videoURLs.splice(position, 1));
        const new_videos = videoURLs.slice()
        new_videos.splice(position, 1)
        setvideoURLs(new_videos)
    }


    const CreateBootcamp = () => {
        const formData = new FormData();
        formData.append("files", image_1[0]);
        const token = JSON.parse(localStorage.getItem('jwt'));

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': token.token
            },
        };

        setStatusLoader(true);

        if (
            title === "" ||
            description === "" ||
            offeredBy === "" ||
            videoURLs === "" ||
            !(Array.isArray(image_1) && image_1.length)

        ) {

            setErrorStatus(true);
            setErrorMessage("Field Missing! All Feild Required !!");

        } else {

            axios
                .post(`${BOOTCAMP_API}/media/`, formData)
                .then((response) => {
                    const media_id = response.data.result.data[0]._id;

                    const new_bootcamp = {
                        title: title,
                        description: description,
                        thumbnail: media_id,
                        offered_by: offeredBy,
                        about: 'about',
                        type: 'data-science',
                        difficulty: 'medium',
                        course_info: {
                            videos: videoURLs,
                            reading_content: description
                        }
                    };

                    axios
                        .post(`${BOOTCAMP_API}/bootcamp_course/`, new_bootcamp, config)
                        .then((response) => {
                            if (response.data.error) {
                                // setStatus(true);
                                setErrorStatus(true);
                                setErrorMessage(response.data.error);
                                setStatusLoader(false);
                            } else {
                                setStatus(true);
                                setMessage(response.data.message);
                                setStatusLoader(false);
                                history.push("/bootcamps");
                            }
                        })
                        .catch((err) => console.log(err));
                })
                .catch((err) => console.log(err));
        }
    }

    const uploadFileToS3 = (acceptedFiles) => {
        const formData = new FormData();
        formData.append("files", acceptedFiles[0]);
        axios
            .post(`${BOOTCAMP_API}/media/`, formData)
            .then((response) => {
                setSelectedFile_1(response.data.result.data._id)
            })
            .catch((err) => console.log(err));
    }

    const updateEmbeddedVideo = (index, value) => {
        const s3_pattern = new RegExp(/(s3-|s3\.)?(.*)\.amazonaws\.com/g);
        if (s3_pattern.test(value)) {
            setS3UrlUnMatchedAt(-1);
            const new_videos = videoURLs.slice()
            new_videos[index] = value
            setvideoURLs(new_videos);
        } else {
            setS3UrlUnMatchedAt(index);
            const new_videos = videoURLs.slice()
            new_videos[index] = value
            setvideoURLs(new_videos);
        }
    }

    return (
        <AuthLayout>

            <Header name="Create Bootcamp" />

            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        {status && (
                            <Snackbar
                                open={status}
                                autoHideDuration={15000}
                                onClose={handleClose}
                            >
                                <Alert onClose={handleClose} severity="success">
                                    {messageData}
                                </Alert>
                            </Snackbar>
                        )}


                        {errorStatus && (
                            <Snackbar
                                open={errorStatus}
                                autoHideDuration={15000}
                                onClose={handleCloseError}
                            >
                                <Alert
                                    onClose={handleCloseError}
                                    severity="error"
                                >
                                    {errorMessage}
                                </Alert>
                            </Snackbar>
                        )}


                        {statusLoader && (
                            <Snackbar
                                open={statusLoader}
                                // autoHideDuration={95000}
                                onClose={handleLoader}
                            >
                                <Alert onClose={handleLoader} severity="info">
                                    <Loader />
                                </Alert>
                            </Snackbar>
                        )}


                        <Paper className={classes.paper}>
                            <h2>Bootcamp Title</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="title"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth={true}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />

                            <br />
                            <br />
                            <h2>Bootcamp Description</h2>

                            <CKEditor
                                editor={ClassicEditor}
                                config={editorConfiguration}
                                data="<p>Hello from CKEditor 5!</p>"
                                onInit={(editor) => {
                                    // You can store the "editor" and use when it is needed.
                                    console.log(
                                        "Editor is ready to use!",
                                        editor
                                    );
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setDescription(data);
                                    console.log("data composed");
                                    console.log({ event, editor, data });
                                }}
                                onBlur={(event, editor) => {
                                    console.log("Blur.", editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log("Focus.", editor);
                                }}
                            />

                            <br />
                            <br />

                            <h2>Offered by</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="title"
                                multiline
                                rows={1}
                                variant="outlined"
                                fullWidth={true}
                                value={offeredBy}
                                onChange={(e) => {
                                    setOfferedBy(e.target.value);
                                }}
                            />

                            <br />
                            <br />


                            <h2>Bootcamp Banner Image</h2>
                            <DropzoneArea
                                acceptedFiles={[
                                    "image/jpeg",
                                    "image/png",
                                    "image/bmp",
                                ]}
                                filesLimit={1}
                                onChange={(files) => setSelectedFile_1(files)}
                                maxFileSize={9242880}
                            // onDrop={(acceptedFiles) => uploadFileToS3(acceptedFiles)}
                            />

                            <br />
                            <br />

                            <h2>Bootcamp videos</h2>

                            {

                                videoURLs.map((videoURL, index) => {

                                    return (
                                        <>
                                            <Grid container direction="row" justify="center" alignContent="center" key={index}>
                                                <Grid item xs={10} md={10} sm={10} lg={10}>
                                                    <TextField
                                                        id="outlined-multiline-static"
                                                        label="Video URLs"
                                                        multiline
                                                        rows={1}
                                                        error={s3UrlUnMatchedAt === index ? true : false}
                                                        variant="outlined"
                                                        helperText={s3UrlUnMatchedAt === index ? 'Not macthed with S3 URL' : ''}
                                                        fullWidth={true}
                                                        value={videoURL}
                                                        onChange={(e) => {
                                                            updateEmbeddedVideo(index, e.target.value);
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} md={1} sm={1} lg={1} style={{ margin: 'auto', textAlign: 'center' }}>
                                                    <span style={{ height: '50px', width: '50px', cursor: 'pointer' }} onClick={addIndex}><b>ADD MORE</b></span>
                                                </Grid>
                                                <Grid item xs={1} md={1} sm={1} lg={1} style={{ margin: 'auto', textAlign: 'center' }}>
                                                    <ClearOutlinedIcon style={{ height: '50px', width: '50px', color: 'red', cursor: 'pointer' }} onClick={() => CancelCurrentIndex(index)} />
                                                </Grid>
                                            </Grid>
                                            <br />
                                        </>
                                    )

                                })
                            }

                            <br />
                            <br />
                            <br />
                            <br />

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={CreateBootcamp}
                                size="large"
                                style={{
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                    backgroundColor: "rgb(250, 53, 88)",
                                }}
                            >
                                Create Bootcamp
							</Button>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        </AuthLayout>
    );
}
