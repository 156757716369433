import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import { BOOTCAMP_API } from "../../backend";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import TablePagination from "@material-ui/core/TablePagination";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";

const options = ["ordered", "confirmed", "processing", "deliverd"];

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#fa3558",
    color: theme.palette.common.white,
    fontSize: 14,
    paddingTop: "12px",
    paddingBottom: "12px",
  },
  body: {
    fontSize: 12,
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "#e6e6e6",
    },
  },
}))(TableRow);

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 12,
    padding: "10px 26px 10px 10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: "none",
  },
  paper: {
    minHeight: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },
  control: {
    padding: theme.spacing(2),
  },
  image: {
    minHeight: 150,
    objectFit: "cover",
  },
  menuRoot: {
    boxShadow: "0px 2px 20px 5px rgb(0 0 0 / 5%)",
  },
}));

function OrderLists() {
  const [fullscreen, setFullsreen] = useState(false);
  const [orders, setOrders] = useState([]);
  const [total, setTotal] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const classes = useStyles();
  const handleOrderStatusChange = async (event, order) => {
    const requestBody = {
      orderStatus: event.target.value,
    };
    await axios
      .put(`${BOOTCAMP_API}/mhCoins/updateOrderStatus/${order._id}`,requestBody)
      .then((response) => {
        setOrders(
          orders.filter((x) => {
            if (x._id === order._id) {
              x.orderStatus = event.target.value;
            }
            return orders;
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const getAllCoins = async (pageNum) => {
    await axios
      .get(
        `${BOOTCAMP_API}/mhCoins/getAllMhOrdersHistory?current_page=${(pageNum + 1)}`
      )
      .then((response) => {
        setOrders(response.data.result.data);
        setTotal(response.data.result.totalOrders);
      })
      .catch((err) => console.log(err));
  };
  const handleChangePage = (event, newPage) => {
    getAllCoins(newPage);
    setPage(newPage);
  };

  useEffect(() => {
    getAllCoins();
  }, []);
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Orders" />
      <Grid
        style={{
          justifyContent: "center",
        }}
      >
        <Grid xs={12} sm={12} md={12} lg={12}>
          <Paper>
            <>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Product Name</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Mobile No.</StyledTableCell>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>Landmark</StyledTableCell>
                    <StyledTableCell>Coins</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                  </TableRow>
                </TableHead>
                {orders.length > 0 ? (
                  <TableBody>
                    {orders.map((order, index) => (
                      <StyledTableRow key={index}>
                        <StyledTableCell>
                          {order?.productId?.productName}
                        </StyledTableCell>
                        <StyledTableCell>
                          {order?.shippingDataId?.name}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {order?.shippingDataId?.email}
                        </StyledTableCell>
                        <StyledTableCell>
                          {order?.shippingDataId?.phoneNumber}
                        </StyledTableCell>
                        <StyledTableCell>
                          {order?.shippingDataId?.address[0]?.floorOrBuilding +
                            "," +
                            order.shippingDataId?.address[0]?.addressLine +
                            "," +
                            order.shippingDataId?.address[0]?.city +
                            "," +
                            order.shippingDataId?.address[0]?.pincode}
                        </StyledTableCell>
                        <StyledTableCell>
                          {order.shippingDataId?.address[0]?.landmark}
                        </StyledTableCell>
                        <StyledTableCell>
                          {order.productId?.coins}
                        </StyledTableCell>
                        <StyledTableCell>
                          <FormControl>
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              value={order.orderStatus}
                              onChange={(e) =>
                                handleOrderStatusChange(e, order)
                              }
                              input={<BootstrapInput />}
                            >
                              <MenuItem value="ordered">ordered</MenuItem>
                              <MenuItem value="confirmed">confirmed</MenuItem>
                              <MenuItem value="processing">processing</MenuItem>
                              <MenuItem value="deliverd">deliverd</MenuItem>
                            </Select>
                          </FormControl>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      marginLeft: "350px",
                    }}
                  >
                    <img
                      src="https://flevix.com/wp-content/uploads/2020/01/Bounce-Bar-Preloader-1.gif"
                      width="300"
                    />
                  </div>
                )}
              </Table>
              <TablePagination
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                rowsPerPageOptions={[10]}
              />

              {/* <Pagination count={2000} color="primary" /> */}
              <br />
            </>
          </Paper>
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

export default OrderLists;
