import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API } from "../../../../backend";
import Loader from "../../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import DownloadLoader from "../../../DownloadUserData/Loader";
import EventLayout from "../../../../components/EventLayout";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { FormControl } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import logoSm from "../../../../assets/images/logo-sm.png";
import Select from "react-select";
import { SketchPicker } from "react-color";
import { DropzoneArea } from "material-ui-dropzone";
import SessionSidebar from "./SessionSidebar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { isAuthenticated } from "../../../../auth/helper";

const moment = require("moment");
const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Heading,
    Indent,
    Table,
    TableToolbar,
    Link,
    List,
    MediaEmbed,
    CodeBlock,
    ImageResize,
    ImageToolbar,
    ImageStyle,
  ],
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "mediaEmbed",
    "insertTable",
    "codeBlock",
  ],
  mediaEmbed: {
    previewsInData: true
  },
  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },

  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  codeBlock: {
    languages: [
      // Do not render the CSS class for the plain text code blocks.
      { language: "plaintext", label: "Plain text", class: "" },

      // Use the "php-code" class for PHP code blocks.
      { language: "php", label: "PHP", class: "php-code" },

      // Use the "js" class for JavaScript code blocks.
      // Note that only the first ("js") class will determine the language of the block when loading data.
      {
        language: "javascript",
        label: "JavaScript",
        class: "js javascript js-code",
      },

      // Python code blocks will have the default "language-python" CSS class.
      { language: "python", label: "Python" },
    ],
  },
};
const useStyles = makeStyles({
  root: {
    minHeight: 206,
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardbody: {
    padding: "0 10px 10px 10px",
    borderRadius: "8px",
  },
  eventbanner: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "100px",
    objectFit: "fill",
    maxWidth: "100%",
  },
  eventName: {
    fontWeight: 500,
    display: "-webkit-box",
    overflow: "hidden",
    "-mozBoxOrient": "vertical",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": 2,
  },
  moreOptions: {
    background: "#ffffff",
    position: "absolute",
    right: "10px",
    top: "6px",
    borderRadius: "50px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f44d5e",
    fontSize: "20px",
  },
  optionMenu: {
    boxShadow: "none",
    border: "1px solid #dbdbdb",
    "& div": {
      boxShadow: "none",
      border: "1px solid #dbdbdb",
    },
  },
  optionMenuItem: {
    boxShadow: "none",
  },
  createEventDrawer: {
    width: "40%",
  },
  creatEventHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    overflowX: "hidden",
  },
  spacingCreateEvent: {
    padding: "10px 30px 10px 30px",
    height: "calc(100vh - 142px)",
    overflow: "auto",
  },
  eventType: {
    width: "50%",
    textAlign: "center",
    color: "gray",
    marginBottom: 0,
    padding: "8px",
  },
  border: {
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
  },
  selectedEventType: {
    background: "#f44d5e",
    color: "#ffffff",
    width: "50%",
    textAlign: "center",
    marginBottom: 0,
    padding: "8px",
  },
  textField: {
    marginRight: 10,
    border: "1px solid rgba(53, 53, 53, 0.17)",
    borderRadius: "4px",
    color: "#353535",
    width: "inherit",
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },

  peoplesImage: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    position: "relative",
    objectFit: "contain",
  },

  totalPeoplesImage: {
    marginLeft: "10px",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  peoplesImageSection: {
    position: "relative",
    marginRight: "15px",
  },
});
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
var FileSaver = require("file-saver");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SessionBasicDetails(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [displayDelete, setDisplayDelete] = useState("");
  const [getAddSpeakers, setGetAddSpeakers] = useState([]);
  const [getAddSponsers, setGetAddSponsers] = useState([]);
  const [getSpeakers, setGetSpeakers] = useState([]);
  const [getSponsers, setGetSponsers] = useState([]);
  const [selectedSponser, setSelectedSponser] = useState([]);
  const [boothData, setBoothData] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");
  const [searchSponserText, setSearchSponserText] = React.useState("");
  const [isSearchDrop, setIsSearchDrop] = React.useState(false);
  const [isSearchSponserDrop, setIsSearchSponserDrop] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [limit, setLimit] = useState("");
  const [sort, setSort] = useState("PublicScore");
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [HREFName, setHREFName] = useState("");
  const [eventType, setEventType] = React.useState("");
  const [eventTypeValue, setEventTypeValue] = React.useState("");
  const [isCreateTrack, setIsCreateTrack] = React.useState(false);
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bannerImg, setBannerImg] = useState([]);
  const eventId = props.match.params.eventId;
  const sessionId = props.match.params.sessionId;
  const [featuredCheck, setFeaturedCheck] = React.useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [drawer, setDrawer] = React.useState({
    speakers: false,
    sponsers: false,
    addSession: false,
  });
  const [background, setBgColor] = React.useState("#dbdbdb");
  const [selectedDate, setSelectedDate] = React.useState("");
  const [sessionTitle, setsessionTitle] = React.useState("");
  const [sessionDescription, setsessionDescription] = React.useState("");
  const [allTracks, setAllTracks] = React.useState([]);
  const [createTrackTitle, setCreateTrackTitle] = React.useState("");
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [isEditTrack, setIsEditTrack] = React.useState("");
  const [sessionDates, setSessionDates] = React.useState([]);
  const [sessionStartTime, setSessionStartTime] = React.useState("");
  const [sessionEndTime, setSessionEndTime] = React.useState("");
  const [assignedTrack, setAssignedTrack] = React.useState("");
  const [datetabsValue, setdatetabsValue] = React.useState("");
  const [Agenda, setAgenda] = React.useState([]);
  const [openPosterDel, setopenPosterDel] = React.useState({
    show: false,
    data: {},
  });
  const [scroll, setScroll] = React.useState("paper");
  const [check, setCheck] = React.useState(false);
  const [sessionbannerImg, setSessionbannerImg] = React.useState("");
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowColorPicker(false);
          setIsSearchDrop(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const onSelecthandleChange = (selectedOption) => {
    setAssignedTrack(selectedOption);
  };
  const handleChangeComplete = (color) => {
    setBgColor(color.hex);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setEnd(!end);
  };
  const createTrackLayout = () => {
    setIsCreateTrack(true);
  };
  const onSearchChange = (e) => {
    setIsSearchDrop(true);
    setGetAddSpeakers([]);
    setSearchText(e.target.value);
    e.target.value.length > 3 && getAllRegisteredPeople(e.target.value);
    e.target.value === "" && getTotalSpeakers();
  };

  const onSearchChangeSponser = (e) => {
    setIsSearchSponserDrop(true);
    setGetAddSponsers([]);
    setSearchSponserText(e.target.value);
    e.target.value.length > 3 && getAllRegisteredSponsersPeople(e.target.value);
    e.target.value === "" && getAllRegisteredSponsersTotalPeople();
  };

  const getAllRegisteredPeople = async (text) => {
    const paramData = {};
    if (isAuthenticated()) {
      paramData.user_id = isAuthenticated().user._id;
    }

    await axios
      .get(`${API}/events/people/search/${eventData.slug}/${text}`, {
        params: paramData,
      })
      .then((response) => {
        setGetAddSpeakers(response.data.data.speakers);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //python API call
  const getAllRegisteredSponsersPeople = async (text) => {
    const paramData = {};
    if (isAuthenticated()) {
      paramData.user_id = isAuthenticated().user._id;
    }
    await axios
      .get(`${API}/events/sponsers/search/${eventData.slug}/${text}`, {
        params: paramData,
      })
      .then((response) => {
        setGetAddSponsers(response?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // node API call
  const getAllRegisteredSponsersTotalPeople = async () => {
    const eventId = props.match.params.eventId;
    const paramData = {};
    paramData.noPagination = "on";

    await axios
      .get(
        `${BOOTCAMP_API}/events/getAllCompanyExhibitorsUnderEventId/${eventId}/sponsor`,
        {
          params: paramData,
        }
      )
      .then((response) => {
        let exhibitorsResponse = response.data.result.data.map((comapanies) => {
          return {
            ...{ exhibitorId: comapanies._id },
            ...comapanies.companyData,
          };
        });
        setGetAddSponsers(exhibitorsResponse);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTotalSpeakers = async () => {
    await axios
      .get(`${API}/events/speakers/${eventData.slug}`)
      .then((response) => {
        setGetAddSpeakers(response.data.message);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const AddTeamMember = (user) => {
    const newMember = {
      eventId: props.match.params.eventId,
      userId: user.userId,
    };
    axios
      .put(
        `${BOOTCAMP_API}/events/addSpeakerToagenda/${props.match.params.sessionId}`,
        newMember
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          getEventData();
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setDrawer({ ...drawer, speakers: false });
          setIsSearchDrop(false);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  const AddSponserMember = (exhibitorId) => {
    const newSponser = {
      eventId: props.match.params.eventId,
      exhibitorId: exhibitorId,
    };
    axios
      .put(
        `${BOOTCAMP_API}/events/addSponserToagenda/${props.match.params.sessionId}`,
        newSponser
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          getEventData();
          setMessageData(response.data.result.message);
          setDrawer({ ...drawer, sponsers: false });
          setIsSearchSponserDrop(false);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  const deleteSpeaker = (speaker) => {
    const deleteMember = {
      agendaId: Agenda._id,
    };
    axios
      .put(`${BOOTCAMP_API}/events/removeSpeaker/${speaker._id}`, deleteMember)
      .then((response) => {
        getEventData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteSponser = (sponser) => {
    const deleteMember = {
      agendaId: props.match.params.sessionId,
    };
    axios
      .put(`${BOOTCAMP_API}/events/removeSponser/${sponser._id}`, deleteMember)
      .then((response) => {
        getEventData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteTrack = (track) => {
    const trackdata = {
      title: track.title,
      colorCode: track.colorCode,
    };
    axios
      .delete(
        `${BOOTCAMP_API}/events/deleteEventTrackerData/${track._id}`,
        trackdata
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setAllTracks((prev) =>
            prev.filter((x) => {
              return x._id !== track._id;
            })
          );
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const deletePoster = () => {
    const updateSession = {
      poster: Agenda.poster,
    };
    axios
      .put(
        `${BOOTCAMP_API}/events/deleteAgendaBanner/${Agenda._id}`,
        updateSession
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setSessionbannerImg("");
          setopenPosterDel({ ...openPosterDel, show: false });
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const createNewTrack = () => {
    const newTrack = {
      title: createTrackTitle,
      colorCode: background,
    };
    if (isEditTrack === "") {
      axios
        .post(`${BOOTCAMP_API}/events/createEventTracker`, newTrack)
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setIsCreateTrack(false);
            setAllTracks((prev) => [...prev, response.data.result.data]);
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    } else {
      axios
        .put(
          `${BOOTCAMP_API}/events/updateEventsTrackerData/${isEditTrack}`,
          newTrack
        )
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setIsCreateTrack(false);
            setAllTracks((prev) =>
              prev.filter((x) => {
                if (x._id === isEditTrack) {
                  x.colorCode = response.data.result.data.colorCode;
                  x.slug = response.data.result.data.slug;
                  x.title = response.data.result.data.tilte;
                  x.label = response.data.result.data.title;
                  x.title = response.data.result.data.title;
                  setBgColor(response.data.result.data.colorCode);
                  return x;
                }
                return prev;
              })
            );
            setIsEditTrack("");
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    }
  };
  const addSession = async () => {
    if (bannerImg.length === 0) {
      const newSession = {
        title: sessionTitle,
        eventId: props.match.params.eventId,
        poster: sessionbannerImg,
        startTime: moment(
          new Date(selectedDate.value.split("T")[0] + "," + sessionStartTime)
        ).format(),
        endTime: moment(
          new Date(selectedDate.value.split("T")[0] + "," + sessionEndTime)
        ).format(),
        agendaType: assignedTrack.value,
        description: sessionDescription,
        featured: featuredCheck,
        publish: isPublish,
      };
      axios
        .put(
          `${BOOTCAMP_API}/events/updateAgendaData/${Agenda._id}`,
          newSession
        )
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setIsCreateTrack(false);
            setDrawer({ ...drawer, addSession: false });
            setAgenda(response.data.result.data);
            addAgendaValues(response.data.result.data, sessionDates);
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    } else {
      const formData = new FormData();
      formData.append("", bannerImg[0]);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(`${BOOTCAMP_API}/events/upload/${props.match.params.eventId}`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            const newSession = {
              title: sessionTitle,
              eventId: props.match.params.eventId,
              poster: response.data.result.data[0].s3_url,
              startTime: moment(
                new Date(
                  selectedDate?.value?.split("T")[0] + "," + sessionStartTime
                )
              ).format(),
              endTime: moment(
                new Date(
                  selectedDate?.value?.split("T")[0] + "," + sessionEndTime
                )
              ).format(),
              agendaType: assignedTrack.value,
              description: sessionDescription,
              featured: true,
              publish: true,
            };
            axios
              .put(
                `${BOOTCAMP_API}/events/updateAgendaData/${Agenda._id}`,
                newSession
              )
              .then((response) => {
                if (response.data.error) {
                  setErrorStatus(true);
                  setErrormesage(response.data.result.message);
                } else {
                  setSuccessMessage(true);
                  setMessageData(response.data.result.message);
                  setIsCreateTrack(false);
                  setDrawer({ ...drawer, addSession: false });
                  setAgenda(response.data.result.data);
                  addAgendaValues(response.data.result.data, sessionDates);
                }
              })
              .catch((error) => {
                setSuccessMessage(false);
                setErrorStatus(false);
                console.log(error);
              });
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    }
  };
  const resetAllData = () => {
    setsessionTitle("");
    setsessionDescription("");
    setAssignedTrack("");
  };
  const getEventData = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
        let eventResponse = response.data.result.data.dates;
        let sessionDates = [];
        eventResponse.filter((x, index) => {
          let data = {};
          data.value = x;
          data.label = `Day ${index + 1} - ${moment(x).format("Do MMM YYYY")}`;
          sessionDates.push(data);
        });
        setdatetabsValue(sessionDates[0].label);
        setSessionDates(sessionDates);
        getAgendaData(sessionDates);
      })
      .catch((err) => console.log(err));
  };
  const getAllTracks = async () => {
    await axios
      .get(`${BOOTCAMP_API}/events/getAllEventTrackers`)
      .then((response) => {
        let trackResponse = response.data.result.data;
        trackResponse.filter((x) => {
          x.label = x.title;
          x.value = x._id;
        });
        setAllTracks(trackResponse);
      })
      .catch((err) => console.log(err));
  };
  const getAgendaData = async (sessionDates) => {
    await axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificAgenda/${props.match.params.sessionId}/${props.match.params.eventId}`
      )
      .then((response) => {
        setGetSpeakers(response.data.result.data.speakers);
        setGetSponsers(response.data.result.data.sponsers);
        setAgenda(response.data.result.data);
        addAgendaValues(response.data.result.data, sessionDates);
      })
      .catch((err) => console.log(err));
  };
  const addAgendaValues = (responseAgenda, sessionDates) => {
    console.log(moment(responseAgenda.startTime).format("HH:mm"),moment(responseAgenda.endTime).format("hh:mm a"));
    // let showStartTime = moment(responseAgenda.startTime).format("hh:mm a");
    // console.log("hjbdvjkjdf",showStartTime.split[0,1]);
    setSessionStartTime(moment(responseAgenda.startTime).format("HH:mm"));
    setSessionEndTime(moment(responseAgenda.endTime).format("HH:mm"));
    setsessionTitle(responseAgenda.title);
    setsessionDescription(responseAgenda.description);
    setFeaturedCheck(responseAgenda.featured);
    setIsPublish(responseAgenda.publish);
    setAssignedTrack({
      label: responseAgenda.agendaType?.title,
      value: responseAgenda.agendaType?._id,
    });
    setSessionbannerImg(responseAgenda?.poster || "");
    const selecteddatebody = {};
    sessionDates.filter((x, index) => {
      if (
        moment(x.value).format("Do MMM YYYY") ===
        moment(responseAgenda.startTime).format("Do MMM YYYY")
      ) {
        selecteddatebody.label = `Day ${index + 1} - ${moment(
          responseAgenda.startTime
        ).format("Do MMM YYYY")}`;
        selecteddatebody.value = responseAgenda.startTime;
        setSelectedDate(selecteddatebody);
      }
    });
  };

  useEffect(() => {
    getEventData();
    getAllTracks();
    getAllRegisteredSponsersTotalPeople();
  }, []);

  useEffect(() => {
    if (eventData._id) {
      getTotalSpeakers();
    }
  }, [eventData]);

  return (
    <EventLayout>
      {messageData && (
        <Snackbar
          open={successMessage}
          onClose={() => setSuccessMessage(false)}
        >
          <>
            <Alert onClose={() => setSuccessMessage(false)} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar open={errorStatus} onClose={() => setErrorStatus(false)}>
          <>
            <Alert onClose={() => setErrorStatus(false)} severity="error">
              {errorMessage}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        {Object.keys(eventData).length > 0 ? (
          <div className="w-100 event-overview">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <br />
                <ul className="eventsBreadcrumb p-0 d-flex align-items-center">
                  <li
                    className="cursor-pointer"
                    onClick={() =>
                      history.push(
                        `/event/${props.match.params.eventId}/sessions`
                      )
                    }
                  >
                    Sessions
                  </li>
                  <li style={{ color: "#353535" }}>{eventData.title}</li>
                </ul>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SessionSidebar eventId={eventId} sessionId={sessionId} />
              </Grid>
              <Grid item xs={8}>
                <div className="event-cardHld">
                  <h3>Basic Details</h3>
                  <hr />
                  <div className="d-flex">
                    <div className="col-6 pl-0">
                      <label htmlFor="title" className="event-label">
                        Session Date
                        <span style={{ color: "red", verticalAlign: "" }}>
                          *
                        </span>
                      </label>
                      <Select
                        value={selectedDate}
                        onChange={(value) => setSelectedDate(value)}
                        options={sessionDates}
                        isSearchable={false}
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="title" className="event-label">
                        Start Time
                        <span style={{ color: "red", verticalAlign: "" }}>
                          *
                        </span>
                      </label>
                      <TextField
                        id="datetime-local"
                        type="time"
                        className={classes.textField}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            color: "#353535",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          input: "#353535",
                          label: "#353535",
                        }}
                        value={sessionStartTime}
                        onChange={(e) => setSessionStartTime(e.target.value)}
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="title" className="event-label">
                        End Time
                        <span style={{ color: "red", verticalAlign: "" }}>
                          *
                        </span>
                      </label>
                      <TextField
                        id="datetime-local"
                        type="time"
                        // defaultValue="07:30"
                        className={classes.textField}
                        InputProps={{
                          disableUnderline: true,
                          style: {
                            color: "#353535",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{
                          input: "#353535",
                          label: "#353535",
                        }}
                        value={sessionEndTime}
                        onChange={(e) => setSessionEndTime(e.target.value)}
                      />
                    </div>
                  </div>
                  <br />
                  <FormControl className={classes.formControl}>
                    <label htmlFor="title" className="event-label">
                      Session Title
                      <span style={{ color: "red", verticalAlign: "" }}>*</span>
                    </label>
                    <input
                      id="title"
                      type="text"
                      className="form-control"
                      placeholder="Example: AMA Session with panellist Name onML for Peace"
                      value={sessionTitle}
                      onChange={(e) => setsessionTitle(e.target.value)}
                    />
                  </FormControl>
                  <br />
                  <br />
                  <label htmlFor="title" className="event-label">
                    Assign Track
                  </label>
                  <Select
                    value={assignedTrack}
                    onChange={(value) => setAssignedTrack(value)}
                    options={allTracks}
                  />
                  <br />

                  <label htmlFor="title" className="event-label">
                    Session Description
                  </label>
                  <div className={classes.editor}>
                    <CKEditor
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      data={sessionDescription}
                      onInit={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setsessionDescription(data);
                      }}
                    />
                  </div>
                  <br />
                  <label className="event-label d-block mb-0">
                    Session Speakers
                  </label>
                  <span>Let your audience know more about your speakers.</span>
                  <div
                    className="d-flex align-items-center mt-2"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="addSpeakers mr-2"
                      onClick={toggleDrawer("speakers", true)}
                      onKeyDown={toggleDrawer("speakers", true)}
                    >
                      <i className="mdi mdi-plus-circle-outline"></i>
                    </div>
                    <div className={classes.totalPeoplesImage}>
                      {getSpeakers.map((eachSpeaker, index) => (
                        <Tooltip
                          title={eachSpeaker.Name}
                          placement="top"
                          key={index}
                        >
                          <div className={classes.peoplesImageSection}>
                            <img
                              src={eachSpeaker.Picture}
                              className={classes.peoplesImage}
                              onMouseEnter={() => {
                                setDisplayDelete(eachSpeaker._id);
                              }}
                              onMouseLeave={() => setDisplayDelete("")}
                            />
                            {displayDelete === eachSpeaker._id && (
                              <i
                                className="mdi mdi-delete"
                                onMouseEnter={() => {
                                  setDisplayDelete(eachSpeaker._id);
                                }}
                                onMouseLeave={() => setDisplayDelete("")}
                                onClick={() => {
                                  deleteSpeaker(eachSpeaker);
                                }}
                                style={{
                                  position: "absolute",
                                  left: "35px",
                                  top: "-3px",
                                  cursor: "pointer",
                                  fontSize: "20px",
                                }}
                              ></i>
                            )}
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                  <br />
                  <br />
                  <label className="event-label d-block mb-0">
                    Session Sponsors
                  </label>
                  <span>
                    Add your session sponsors and connect your brand to its
                    audience.
                  </span>

                  <div
                    className="d-flex align-items-center mt-2"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="addSpeakers mr-2"
                      onClick={toggleDrawer("sponsers", true)}
                      onKeyDown={toggleDrawer("sponsers", true)}
                    >
                      <i className="mdi mdi-plus-circle-outline"></i>
                    </div>
                    <div className={classes.totalPeoplesImage}>
                      {getSponsers?.map((eachSponser, index) => (
                        <Tooltip
                          title={eachSponser.companyId.comapanyTitle}
                          placement="top"
                          key={index}
                        >
                          <div className={classes.peoplesImageSection}>
                            <img
                              src={eachSponser.companyId.logo}
                              className={classes.peoplesImage}
                              onMouseEnter={() => {
                                setDisplayDelete(eachSponser.companyId._id);
                              }}
                              onMouseLeave={() => setDisplayDelete("")}
                            />
                            {displayDelete === eachSponser.companyId._id && (
                              <i
                                className="mdi mdi-delete"
                                onMouseEnter={() => {
                                  setDisplayDelete(eachSponser.companyId._id);
                                }}
                                onMouseLeave={() => setDisplayDelete("")}
                                onClick={() => {
                                  deleteSponser(eachSponser);
                                }}
                                style={{
                                  position: "absolute",
                                  left: "35px",
                                  top: "-3px",
                                  cursor: "pointer",
                                  fontSize: "20px",
                                }}
                              ></i>
                            )}
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>

                  {/* <div
                    className="addSpeakers"
                    onClick={toggleDrawer("sponsers", true)}
                    onKeyDown={toggleDrawer("sponsers", true)}
                  >
                    <i className="mdi mdi-plus-circle-outline"></i>
                  </div> */}
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={featuredCheck}
                        onChange={() => {
                          setFeaturedCheck(!featuredCheck);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Make this a featured session"
                  />
                  <br />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isPublish}
                        onChange={() => {
                          setIsPublish(!isPublish);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Publish"
                  />
                  <br />
                  <label htmlFor="title" className="event-label">
                    Banner Image
                  </label>
                  {sessionbannerImg === "" ? (
                    <div className="addSession">
                      <DropzoneArea
                        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                        onChange={(files) => setBannerImg(files)}
                        maxFileSize={9242880}
                      />
                    </div>
                  ) : (
                    <div className="ImgHld">
                      <img
                        src={sessionbannerImg}
                        alt=""
                        className="img-fluid"
                      />
                      <div className="Imagesoverlay mx-2 d-flex">
                        <span
                          onClick={() =>
                            setopenPosterDel({
                              ...openPosterDel,
                              show: true,
                            })
                          }
                        >
                          <i
                            className="mdi mdi-delete brand-color"
                            style={{ fontSize: "30px" }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  )}
                  <br />
                  <div className=" mr-auto d-flex justify-content-end">
                    <button className="event-primary-btn" onClick={addSession}>
                      SAVE
                    </button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
      </Row>
      <Drawer
        anchor="right"
        open={drawer.speakers}
        onClose={toggleDrawer("speakers", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Add Speaker</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("speakers", false)}
                onKeyDown={toggleDrawer("speakers", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <FormControl className={classes.formControl}>
              <input
                id="title"
                type="text"
                className="form-control mb-2"
                placeholder="Search"
                onChange={onSearchChange}
                onClick={() => setIsSearchDrop(true)}
                value={searchText}
              />
            </FormControl>
            <Grid container spacing={2}>
              {/* {isSearchDrop &&
                getAddSpeakers.map((speaker, index) => (
                  <Grid item xs={4} key={index}>
                    <div
                      className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer"
                      onClick={() => AddTeamMember(speaker)}
                    >
                      <img
                        src={speaker.Picture}
                        alt=""
                        className="img-fluid speakerImg mb-2"
                      />
                      <p className="mb-0">{speaker.Name}</p>
                    </div>
                  </Grid>
                ))} */}

              {searchText && searchText !== ""
                ? getAddSpeakers.map((speaker, index) => (
                    <Grid item xs={4} key={index}>
                      <div
                        className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer"
                        onClick={() => AddTeamMember(speaker)}
                      >
                        <img
                          src={speaker.Picture}
                          alt=""
                          className="img-fluid speakerImg mb-2"
                        />
                        <p className="mb-0">{speaker.Name}</p>
                      </div>
                    </Grid>
                  ))
                : getAddSpeakers.map((speaker, index) => (
                    <Grid item xs={4} key={index}>
                      <div
                        className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer"
                        onClick={() => AddTeamMember(speaker)}
                      >
                        <img
                          src={speaker.Picture}
                          alt=""
                          className="img-fluid speakerImg mb-2"
                        />
                        <p className="mb-0">{speaker.Name}</p>
                      </div>
                    </Grid>
                  ))}
            </Grid>
            <br />
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn} onClick={AddTeamMember}>
              ADD
            </button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("speakers", false)}
              onKeyDown={toggleDrawer("speakers", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={drawer.sponsers}
        onClose={toggleDrawer("sponsers", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Add Sponsor</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("sponsers", false)}
                onKeyDown={toggleDrawer("sponsers", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <FormControl className={classes.formControl}>
              <input
                id="title"
                type="text"
                className="form-control mb-2"
                placeholder="Search"
                onChange={onSearchChangeSponser}
                onClick={() => setIsSearchSponserDrop(true)}
                value={searchSponserText}
              />
            </FormControl>
            {searchSponserText && searchSponserText !== "" ? (
              <Grid container spacing={2}>
                {getAddSponsers?.map((sponser, index) => (
                  <Grid item xs={4} key={index}>
                    <div
                      className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer"
                      onClick={() => AddSponserMember(sponser.sponser_id)}
                    >
                      <img
                        src={sponser?.companyId?.logo}
                        alt=""
                        className="img-fluid speakerImg mb-2"
                      />
                      <p className="mb-0">
                        {sponser?.companyId?.comapanyTitle}
                      </p>
                    </div>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid container spacing={2}>
                {getAddSponsers?.map((sponser, index) => (
                  <Grid item xs={4} key={index}>
                    <div
                      className="event-cardHld text-center d-flex flex-column justify-content-center align-items-center cursor-pointer"
                      onClick={() => AddSponserMember(sponser.exhibitorId)}
                    >
                      <img
                        src={sponser.logo}
                        alt=""
                        className="img-fluid speakerImg mb-2"
                      />
                      <p className="mb-0">{sponser.comapanyTitle}</p>
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}

            <br />
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn}>Apply</button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("sponsers", false)}
              onKeyDown={toggleDrawer("sponsers", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={drawer.tracks}
        onClose={toggleDrawer("tracks", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Manage Tracks</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("tracks", false)}
                onKeyDown={toggleDrawer("tracks", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            {allTracks.map((track) => (
              <div
                className={` mb-2 ${
                  isEditTrack === track._id ? "createtrackhld" : "event-cardHld"
                }`}
                key={track._id}
              >
                <div
                  className={`d-flex align-items-center justify-content-between cursor-pointer ${
                    isEditTrack === track._id ? "" : "trackhld"
                  }`}
                >
                  {isEditTrack === track._id ? (
                    <>
                      <div
                        className="mr-2"
                        style={{
                          background: `${background}`,
                          width: "20px",
                          height: "20px",
                          borderRadius: "4px",
                        }}
                        onClick={() => setShowColorPicker(!showColorPicker)}
                      ></div>
                      <input
                        type="text"
                        placeholder=""
                        name="trackName"
                        className="mr-2"
                        placeholder="Enter Name"
                        value={createTrackTitle}
                        onChange={(e) => setCreateTrackTitle(e.target.value)}
                      />
                      {createTrackTitle ? (
                        <span
                          onClick={createNewTrack}
                          className="cursor-pointer"
                        >
                          <i
                            className="mdi mdi-checkbox-marked mr-2 brand-color"
                            style={{ fontSize: "24px" }}
                          ></i>
                        </span>
                      ) : (
                        <span className="cursor-pointer">
                          <i
                            className="mdi mdi-checkbox-marked"
                            style={{
                              fontSize: "24px",
                              color: "rgba(0, 0, 0, 0.26)",
                            }}
                          ></i>
                        </span>
                      )}
                      <span
                        className="cursor-pointer"
                        onClick={() => (
                          setIsEditTrack(""), setCreateTrackTitle("")
                        )}
                      >
                        <i
                          className="mdi mdi-close-box-outline brand-color"
                          style={{ fontSize: "24px" }}
                        ></i>
                      </span>
                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center">
                        <i
                          className="mdi mdi-drag-vertical mr-2"
                          style={{ fontSize: "20px" }}
                        ></i>
                        <div
                          className="colorIndicator"
                          style={{ backgroundColor: `${track.colorCode}` }}
                        ></div>
                        <div className="ml-2">
                          <p className="mb-0">{track.title}</p>
                          {/* <span>6 sessions</span> */}
                        </div>
                      </div>
                      <div className="iconsHld">
                        <span
                          style={{ fontSize: "24px" }}
                          onClick={() => (
                            setIsEditTrack(track._id),
                            setCreateTrackTitle(track.title),
                            setBgColor(track.colorCode)
                          )}
                        >
                          <i className="mdi mdi-pencil-box-outline brand-color"></i>
                        </span>
                        <span
                          style={{ fontSize: "24px" }}
                          onClick={() => deleteTrack(track)}
                        >
                          <i className="mdi mdi-delete brand-color"></i>
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
            {isCreateTrack && (
              <div className="d-flex align-items-center createtrackhld">
                <div
                  className="mr-2"
                  style={{
                    background: `${background}`,
                    width: "20px",
                    height: "20px",
                    borderRadius: "4px",
                  }}
                  onClick={() => setShowColorPicker(!showColorPicker)}
                ></div>
                <input
                  type="text"
                  placeholder=""
                  name="trackName"
                  className="mr-2"
                  placeholder="Enter Name"
                  value={createTrackTitle}
                  onChange={(e) => setCreateTrackTitle(e.target.value)}
                />
                {createTrackTitle ? (
                  <span onClick={createNewTrack} className="cursor-pointer">
                    <i
                      className="mdi mdi-checkbox-marked mr-2 brand-color"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </span>
                ) : (
                  <span className="cursor-pointer">
                    <i
                      className="mdi mdi-checkbox-marked"
                      style={{ fontSize: "24px", color: "rgba(0, 0, 0, 0.26)" }}
                    ></i>
                  </span>
                )}
                <span
                  className="cursor-pointer"
                  onClick={() => (
                    setIsCreateTrack(false), setCreateTrackTitle("")
                  )}
                >
                  <i
                    className="mdi mdi-close-box-outline brand-color"
                    style={{ fontSize: "24px" }}
                  ></i>
                </span>
              </div>
            )}
            {showColorPicker && (
              <div className="" ref={wrapperRef}>
                <SketchPicker
                  color={background}
                  onChangeComplete={handleChangeComplete}
                />
              </div>
            )}
            <br />
            <button className="event-outlined-btn" onClick={createTrackLayout}>
              <i className="mdi mdi-plus-circle-outline mr-1"></i> ADD TRACK
            </button>
            <br />
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn}>ADD</button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("tracks", false)}
              onKeyDown={toggleDrawer("tracks", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
      <Dialog
        open={openPosterDel.show}
        onClose={() => setopenPosterDel({ ...openPosterDel, show: false })}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong>Banner deleted once can't be retrieved.</strong>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete Banner"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setopenPosterDel({ ...openPosterDel, show: false })}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deletePoster}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </EventLayout>
  );
}
