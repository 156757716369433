import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../../components/AuthLayout";
import Loader from "../../../components/Loader";
import Header from "../../Header";
import { useHistory } from "react-router-dom";
import EasyMDE from 'easymde';
// import sectionSerice from '../../../services/mocks/sectionService';
import axios, { post } from "axios";
import { BOOTCAMP_API } from "../../../backend";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        // textAlign: "center",
        color: theme.palette.text.secondary,
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 400,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 420,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateSection(props) {

    const bootcamps = useSelector((store) => store.bootcamps.bootcamps);
    const dispatch = useDispatch();

    const otherModuleReferences = [
        {
            key: 'href1',
            value: ''
        },
        {
            key: 'href2',
            value: ''
        },
        {
            key: 'href3',
            value: ''
        },
    ];

    const classes = useStyles();
    const history = useHistory();
    const [fullscreen, setFullsreen] = useState(false);
    const [messageData, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [difficulty, setDifficulty] = useState("");
    const [qType, setQType] = useState("");
    const [duration, setDuration] = useState(0);
    const [onSubmit, setOnSubmit] = useState(false);
    const [shortDescription, setShortDescription] = useState('');
    const [hrefs, setHrefs] = useState(otherModuleReferences);
    const [order, setOrder] = useState('');

    let MDE;
    const handleClose = () => {
        setStatus(!status);
    };

    const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };
    const handleLoader = () => {
        setStatusLoader(!statusLoader);
    };

    useEffect(() => {
        MDE = new EasyMDE({
            element: document.getElementById('body'),
            autoDownloadFontAwesome: true,
            autofocus: true,
            status: false,
            singleLineBreaks: false,
            onToggleFullScreen: (opend) => setFullsreen(opend)
        });

        MDE.codemirror.on('change', () => {
            setDescription(MDE.value());
        });
    }, [MDE]);

    const token = JSON.parse(localStorage.getItem('jwt'));

    const CreateSection = async () => {
        try {
            setOnSubmit(true);
            if (title === "" || order === "" ) {
                setErrorStatus(true);
                setErrorMessage("Field Missing! All Feild Required !!");
            } else {
                    const section = {
                        sectionTitle: title,
                        description: description,
                        sectionOrder: order,
                        // duration: duration, //in seconds
                        bootCampCourseId: props.match.params.moduleObjectID,
                        timeStamp: new Date()
                    };
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': token.token
                        },
                    };
                    axios
                        .post(`${BOOTCAMP_API}/bootcamp/createBootCampSection`, section, config)
                        .then((response) => {
                            console.log(response)
                            setStatus(true);
                            setMessage('Successfully created Section');
                            setStatusLoader(false);
                            history.push(`/sections/${props.match.params.moduleID}/${props.match.params.moduleObjectID}`);
                        })
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <AuthLayout isMDEFullScreen={fullscreen && fullscreen === true ? true : false}>

            <Header name="Create Section" />
            Module: {props.match.params.moduleID}
            <br/>
            <br/>
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        {status && (
                            <Snackbar
                                open={status}
                                autoHideDuration={15000}
                                onClose={handleClose}
                            >
                                <Alert onClose={handleClose} severity="success">
                                    {messageData}
                                </Alert>
                            </Snackbar>
                        )}


                        {errorStatus && (
                            <Snackbar
                                open={errorStatus}
                                autoHideDuration={15000}
                                onClose={handleCloseError}
                            >
                                <Alert
                                    onClose={handleCloseError}
                                    severity="error"
                                >
                                    {errorMessage}
                                </Alert>
                            </Snackbar>
                        )}


                        {statusLoader && (
                            <Snackbar
                                open={statusLoader}
                                // autoHideDuration={95000}
                                onClose={handleLoader}
                            >
                                <Alert onClose={handleLoader} severity="info">
                                    <Loader />
                                </Alert>
                            </Snackbar>
                        )}


                        <Paper className={classes.paper}>
                            <h2>Title</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Title"
                                multiline
                                rows={3}
                                variant="outlined"
                                fullWidth={true}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />

                            <br />
                            <br />
                            <h2>Description</h2>
                            <p>Optional field: Leave Blank if not necessary</p>

                            <textarea id="body" name="body" value={MDE?.value()} />

                            <br />
                            <br />

                            <h2>Order</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Section Order"
                                type='number'
                                rows={1}
                                variant="outlined"
                                fullWidth={true}
                                value={order}
                                onChange={(e) => {
                                    setOrder(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <Button disabled={onSubmit}
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                    backgroundColor: "rgb(250, 53, 88)",
                                }}
                                onClick={() => CreateSection()}
                            >
                                Create Section
							</Button>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        </AuthLayout>
    );
}

