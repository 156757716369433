import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API } from "../../backend";
import Loader from "../../components/Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DownloadLoader from "../DownloadUserData/Loader";

var FileSaver = require("file-saver");

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function GetAllHackathon() {
	const hackathons = useSelector((store) => store.hackathons.hackathons);
	const dispatch = useDispatch();

	const [open, setOpen] = React.useState(false);
	const [limit, setLimit] = useState("");
	const [sort, setSort] = useState("PublicScore");
	const [start, setStart] = useState(false);
	const [end, setEnd] = useState(false);
	const [messageData, setMessageData] = useState("");
	const [HREFName, setHREFName] = useState("");

	const handleClickOpen = (HREFName) => {
		setHREFName(HREFName);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseEnd = () => {
		setEnd(!end);
	};

	const downloadFileHackathon = () => {
		setStart(true);
		setMessageData("File Download Started ...");
		var formData = new FormData();
		formData.append("limit", limit);
		formData.append("sortby", sort);

		axios({
			url: `${API}/lb/export/${HREFName}`,
			method: "POST",
			headers: {
				"content-type": "multipart/form-data",
			},
			data: formData,
			responseType: "blob",
		})
			.then((response) => {
				console.log(response);
				FileSaver.saveAs(response.data, "userdata.csv");
				setStart(false);
				setEnd(true);
				setMessageData("File Download Completed");
			})
			.catch((err) => console.log(err));
	};

	const getAllHackathons = () => {
		axios
			.get(`${API}/competitions`)
			.then((response) => {
				// console.log("respone hack", response.data);
				dispatch({
					type: "GET_ALL_HACKATHONS",
					payload: response.data,
				});
			})
			.catch((err) => console.log(err));
	};
	// console.log(hackathons);
	useEffect(() => {
		getAllHackathons();
	}, []);

	console.log("hackathon", Object.keys(hackathons).length);

	console.log("sort", sort);

	return (
		<>
			{start && (
				<Snackbar open={start} onClose={handleClose}>
					<>
						<Alert onClose={handleClose} severity="success">
							<DownloadLoader pexal="55px" />
							{messageData}
						</Alert>
					</>
				</Snackbar>
			)}
			{end && (
				<Snackbar open={end} onClose={handleCloseEnd}>
					<>
						<Alert onClose={handleCloseEnd} severity="success">
							{messageData}
						</Alert>
					</>
				</Snackbar>
			)}
			{Object.keys(hackathons).length > 0 ? (
				hackathons.map((hackathon) => (
					<Card key={hackathon._id}>
						<Row>
							<Col lg={7}>
								<CardBody>
									{" "}
									<h4>{hackathon.HName}</h4> <br />
									{hackathon.HStatus === "Active" ? (
										<Row>
											<Col
												md={2}
												lg={2}
												style={{ textAlign: "center" }}
											>
												{/* <p> */}
												<div
													style={{
														background: "#43BE31",
														borderRadius: "5px",
														color: "#fff",
														marginLeft: "10px",
														marginRight: "10px",
													}}
												>
													<p>{hackathon.HStatus} </p>
												</div>
												{/* </p> */}
											</Col>
										</Row>
									) : (
										<Row>
											<Col
												lg={2}
												style={{ textAlign: "center" }}
											>
												{/* <p> */}
												<div
													style={{
														background: "red",
														borderRadius: "5px",
														color: "#fff",
														marginLeft: "10px",
														marginRight: "10px",
													}}
												>
													<p>{hackathon.HStatus} </p>
												</div>
												{/* </p> */}
											</Col>
										</Row>
									)}
								</CardBody>
							</Col>
							<Col lg={2} style={{ textAlign: "right" }}>
								<CardBody
									style={{
										paddingLeft: "0px",
										paddingRight: "0px",
									}}
								>
									{" "}
									<Link
										to={`/updateHackathon/${hackathon.HREFName}`}
									>
										<ButtonToggle
											color="danger"
											style={{
												paddingLeft: "30px",
												paddingRight: "30px",
											}}
										>
											Edit
										</ButtonToggle>
									</Link>
								</CardBody>
							</Col>
							<Col lg={3} style={{ textAlign: "left" }}>
								<CardBody
									style={{
										paddingLeft: "0px",
										paddingRight: "0px",
									}}
								>
									{" "}
									<ButtonToggle
										color="success"
										style={{
											paddingLeft: "30px",
											paddingRight: "30px",
										}}
										onClick={() =>
											handleClickOpen(hackathon.HREFName)
										}
									>
										Export Leaderboard
									</ButtonToggle>
								</CardBody>
							</Col>
						</Row>
					</Card>
				))
			) : (
				<div style={{ textAlign: "center" }}>
					<Loader />
				</div>
			)}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth={true}
				maxWidth="md"
			>
				<DialogTitle id="alert-dialog-title">
					Downlaod Public/Private Leaderboard Data
				</DialogTitle>
				<DialogContent>
					{/* <DialogContentText id="alert-dialog-description">
						Let Google help apps determine location. This means
						sending anonymous location data to Google, even when no
						apps are running.
					</DialogContentText> */}
					<br />
					<div class="form-group row">
						<label
							class="col-sm-2  col-form-label"
							for="simpleinput"
						>
							Limit
						</label>
						<div class="col-sm-10">
							<input
								type="text"
								class="form-control"
								value={limit}
								onChange={(e) => {
									setLimit(e.target.value.trim());
								}}
							/>
						</div>
					</div>

					<div class="form-group row">
						<label for="sel1" class="col-sm-2  col-form-label">
							Sortby
						</label>
						<div class="col-sm-10">
							<select
								class="form-control"
								id="sel1"
								onChange={(e) => {
									setSort(e.target.value);
								}}
								value={sort}
							>
								<option value="PublicScore">PublicScore</option>
								<option value="PrivateScore">
									PrivateScore
								</option>
							</select>
						</div>
					</div>
					<br />
				</DialogContent>
				<DialogActions>
					<ButtonToggle
						color="danger"
						style={{
							paddingLeft: "30px",
							paddingRight: "30px",
						}}
						onClick={handleClose}
					>
						Cancel
					</ButtonToggle>
					<button
						class="btn btn-pink waves-effect waves-light"
						onClick={downloadFileHackathon}
					>
						{" "}
						<span>Download</span> &nbsp;
						<i class="fas fa-download"></i>{" "}
					</button>
				</DialogActions>
			</Dialog>
		</>
	);
}
