import React, { useEffect, useState } from "react";
import axios from "axios";
import { BOOTCAMP_API } from "../../backend";
import { makeStyles, withStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { green } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
import { DropzoneArea } from "material-ui-dropzone";
import { useHistory, useLocation } from "react-router";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "react-select";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 999,
  }),
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const GreenCheckbox = withStyles({
	root: {
		color: green[400],
		"&$checked": {
			color: green[600],
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function CreateRedeemCoin(props) {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [coin, setCoin] = useState(0);
  const [priority, setPriority] = useState(0);
  const [apperalSize, setApperalSize] = useState({});
  const [productSize, setProductSize] = useState("");
  const [subscriptions, setSubscriptions] = useState([]);
  const [selectedSubscriptions, setSelectedSubscriptions] = useState("");
  const [messageData, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [image, setImage] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [publish, setPublish] = useState(false);
  const [deliverableValue,setDeliverableValue] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname.split("/");
  const handleClose = () => {
    setStatus(!status);
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };
  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };
  const CreateRedeem = () => {
    if (
      name === "" ||
      image === "" ||
      coin === "" ||
      productCategory.value === "" ||
      priority === ""
    ) {
      setErrorStatus(true);
      setErrorMessage("Field Missing! All Feild Required !!");
    } else {
      if (productCategory.value === "subscription") {
        if (selectedSubscriptions === "") {
          setErrorStatus(true);
          setErrorMessage("Field Missing! All Feild Required !!");
          return;
        } else {
          createProduct();
        }
      } else {
        createProduct();
      }
    }
  };
  const createProduct = () => {
    const requestData = {
      productName: name,
      productImage: image,
      coins: coin,
      productCategory: productCategory.value,
      apperalSize: apperalSize.value,
      description: description,
      priority: priority,
      productSize: productSize,
      deliverable: deliverableValue,
    };
    if (apperalSize.length > 0) {
      const apperal_size = [];
      apperalSize.filter((x) => {
        apperal_size.push(x.value);
      });
      requestData.apperalSize = apperal_size;
    }
    if (productCategory.value === "subscription") {
      requestData.subscriptionPlanId = selectedSubscriptions.value;
    }
    setStatusLoader(true);
    axios
      .post(`${BOOTCAMP_API}/mhCoins/createMhCoinProduct`, requestData)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setStatus(true);
          setMessage(response.data.result.message);
          setStatusLoader(false);
          history.push("/redeem-coins");
        } else {
          setErrorStatus(true);
          setErrorMessage(response.data.result.message);
          setStatusLoader(false);
        }
      })
      .catch((error) => {
        setErrorStatus(true);
        setErrorMessage(error);
        setStatusLoader(false);
        console.log(error);
      });
  };
  const getAllSubscriptionPalns = async () => {
    await axios
      .get(`${BOOTCAMP_API}/subscriptions/getAllSubscriptionPlanCms`)
      .then((response) => {
        setSubscriptions(
          response.data.result.data.filter((x) => {
            if (x.publish) {
              x.label = x.name;
              x.value = x._id;
              return x;
            }
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const onSelecthandleChange = (selectedOption) => {
    setApperalSize(selectedOption);
  };

  const apperalSizeOptions = [
    {
      id: "1",
      name: "XS",
      label: "XS",
      value: "XS",
    },
    {
      id: "2",
      name: "S",
      label: "S",
      value: "S",
    },
    {
      id: "3",
      name: "M",
      label: "M",
      value: "M",
    },
    {
      id: "4",
      name: "L",
      label: "L",
      value: "L",
    },
    {
      id: "5",
      name: "XL",
      label: "XL",
      value: "XL",
    },
    {
      id: "6",
      name: "XXL",
      label: "XXL",
      value: "XXl",
    },
    {
      id: "7",
      name: "XXXL",
      label: "XXXL",
      value: "XXXL",
    },
  ];

  const productCategoryOptions = [
    {
      id: "1",
      name: "apperals",
      label: "Apperals",
      value: "apperals",
    },
    {
      id: "2",
      name: "accessories",
      label: "Accessories",
      value: "accessories",
    },
    {
      id: "3",
      name: "passes",
      label: "Passes",
      value: "passes",
    },
    {
      id: "4",
      name: "subscription",
      label: "Subscription",
      value: "subscription",
    },
  ];

  const UpdateProduct = () => {
    if (
      name === "" ||
      image === "" ||
      coin === "" ||
      productCategory.value === "" ||
      priority === ""
    ) {
      setErrorStatus(true);
      setErrorMessage("Field Missing! All Feild Required !!");
    } else {
      if (productCategory.value === "subscription") {
        if (selectedSubscriptions === "") {
          setErrorStatus(true);
          setErrorMessage("Field Missing! All Feild Required !!");
          return;
        } else {
          updateRedeemCoinProduct();
        }
      } else {
        updateRedeemCoinProduct();
      }
    }
  };

  const updateRedeemCoinProduct = () => {
    const requestData = {
      productName: name,
      productImage: image,
      coins: coin,
      productCategory: productCategory.value,
      apperalSize: apperalSize.value,
      priority: priority,
      publish: publish,
      deliverable: deliverableValue
    };
    description !== "" && (requestData.description = description);
    productSize !== "" && (requestData.productSize = productSize);
    if (apperalSize.length > 0) {
      const apperal_size = [];
      apperalSize.filter((x) => {
        apperal_size.push(x.value);
      });
      requestData.apperalSize = apperal_size;
    }
    if (productCategory.value === "subscription") {
      requestData.subscriptionPlanId = selectedSubscriptions.value;
    }
    setStatusLoader(true);
    axios
      .put(
        `${BOOTCAMP_API}/mhCoins/updateMhCoinProduct/${props.match.params.productId}`,
        requestData
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setStatus(true);
          setMessage(response.data.result.message);
          setStatusLoader(false);
          history.push("/redeem-coins");
        } else {
          setErrorStatus(true);
          setErrorMessage(response.data.result.message);
          setStatusLoader(false);
        }
      })
      .catch((error) => {
        setErrorStatus(true);
        setErrorMessage(error);
        setStatusLoader(false);
        console.log(error);
      });
  };

  const getProductData = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/mhCoins/getSpecificMhCoinProductCms/${props.match.params.productId}`
      )
      .then((response) => {
        setName(response.data.result.data.productName);
        setDescription(response.data.result.data.description);
        setCoin(response.data.result.data.coins);
        setPublish(response.data.result.data.publish);
        setDeliverableValue(response.data.result.data.deliverable)
        setPriority(response.data.result.data.priority);
        setImage(response.data.result.data.productImage);
        response.data.result.data.productCategory &&
          setProductCategory({
            label: response.data.result.data.productCategory,
            value: response.data.result.data.productCategory,
          });
        let array = []
        response.data.result.data.apperalSize.forEach(element => {
            array.push({label: element,value: element})
        });
        response.data.result.data.apperalSize.length > 0 &&
        setApperalSize(array);
        setProductSize(response.data.result.data.productSize);
        response.data.result.data.subscriptionPlanId &&
          setSelectedSubscriptions(
            subscriptions.filter((x) => {
              if (x._id === response.data.result.data.subscriptionPlanId) {
                x.label = x.name;
                x.value = x._id;
                return x;
              }
            })
          );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    url.includes("update") && getProductData();
  }, []);

  return (
    <AuthLayout>
      <Header
        name={
          url.includes("update") ? "Update Redeem Coin" : "Create Redeem Coin"
        }
      />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {status && (
              <Snackbar
                open={status}
                autoHideDuration={15000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </Snackbar>
            )}
            {errorStatus && (
              <Snackbar
                open={errorStatus}
                autoHideDuration={15000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            )}
            {statusLoader && (
              <Snackbar open={statusLoader} onClose={handleLoader}>
                <Alert onClose={handleLoader} severity="info">
                  <Loader />
                </Alert>
              </Snackbar>
            )}

            <Paper className={classes.paper}>
              <h2>Name</h2>
              <TextField
                id="outlined-multiline-static"
                label="Name"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth={true}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Description</h2>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth={true}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Image</h2>
              <TextField
                id="outlined-multiline-static"
                label="Image"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth={true}
                value={image}
                onChange={(e) => {
                  setImage(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Coin</h2>
              <TextField
                id="standard-number"
                label="Coin"
                type="number"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={coin}
                onChange={(e) => {
                  setCoin(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Priority</h2>

              <TextField
                id="standard-number"
                label="Priority"
                type="number"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={priority}
                onChange={(e) => {
                  setPriority(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Product category</h2>
              <Select
                styles={customStyles}
                onChange={(e) => {
                  setProductCategory(e);
                  if (e.value === "subscription") {
                    getAllSubscriptionPalns();
                  }
                }}
                value={productCategory}
                options={productCategoryOptions}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Group"
              />
              {productCategory.value === "apperals" && (
                <div className="w-100">
                  <br />
                  <br />
                  <h2>Apperal size</h2>
                  <Select
                    styles={customStyles}
                    onChange={onSelecthandleChange}
                    isMulti
                    options={apperalSizeOptions}
                    value={apperalSize}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Group"
                  />
                </div>
              )}

              <br />
              <br />

              <h2>Product Size</h2>
              <TextField
                id="outlined-multiline-static"
                label="Product Size"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth={true}
                value={productSize}
                onChange={(e) => {
                  setProductSize(e.target.value);
                }}
              />
              {productCategory.value === "subscription" && (
                <>
                  <br />
                  <br />
                  <h2>Subscriptions</h2>
                  <Select
                    styles={customStyles}
                    onChange={(e) => {
                      setSelectedSubscriptions(e);
                    }}
                    options={subscriptions}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Group"
                  />
                </>
              )}

              <br />
              <br />
              
              <h2>Deliverable</h2>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={deliverableValue}
                    onChange={() => {
                      setDeliverableValue(!deliverableValue);
                    }}
                    name="checkedG"
                  />
                }
                label="Deliverable"
              />
              <br />
              <br />
              {url.includes("update") ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={UpdateProduct}
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                >
                  Save changes
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={CreateRedeem}
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                >
                  Create Redeem Plan
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
