import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Loader from "../../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DownloadLoader from "../../../DownloadUserData/Loader";
import EventLayout from "../../../../components/EventLayout";
import Grid from "@material-ui/core/Grid";
import SessionSidebar from "./SessionSidebar";
import { BOOTCAMP_API } from "../../../../backend";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SessionsAdvanced(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const eventId = props.match.params.eventId;
  const sessionId = props.match.params.sessionId;
  const [streamOption, setstreamOption] = React.useState(true);
  const history = useHistory();
  const onStreamOptionhandleChange = (event) => {
    setstreamOption(!streamOption);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setEnd(!end);
  };

  const getEventData = () => {
    axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getEventData();
  }, []);

  return (
    <EventLayout>
      {start && (
        <Snackbar open={start} onClose={handleClose}>
          <>
            <Alert onClose={handleClose} severity="success">
              <DownloadLoader pexal="55px" />
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {end && (
        <Snackbar open={end} onClose={handleCloseEnd}>
          <>
            <Alert onClose={handleCloseEnd} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        {Object.keys(eventData).length > 0 ? (
          <div className="w-100 event-overview">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <br />
                <ul className="eventsBreadcrumb p-0 d-flex align-items-center">
                  <li className="cursor-pointer" onClick={()=>history.push(`/event/${props.match.params.eventId}/sessions`)}>Sessions</li>
                  <li style={{color:"#353535"}}>{eventData.title}
                  </li>
                </ul>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SessionSidebar eventId={eventId} sessionId={sessionId} />
              </Grid>
              <Grid item xs={8}>
                <div className="event-cardHld">
                  <h3>Advanced</h3>
                  <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="">
                      <h5 className="mb-1" style={{ fontWeight: "400" }}>Registrations</h5>
                      <h6 className="event-label">
                        Only Attendees who Register shall be able to watch this
                        session.
                      </h6>
                      <h4 style={{ fontWeight: "400" }} className="my-1">
                        Allow Attendees To Register For This Session
                      </h4>
                    </div>
                    <Switch
                      checked={streamOption}
                      onChange={onStreamOptionhandleChange}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <br />
                  <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
                  <br />
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="">
                      <h5 className="mb-1"  style={{ fontWeight: "400" }}>Session Visibility</h5>
                      <h6 className="event-label">
                        This session will be visible only to attendees of
                        certain groups or attendees who you manually
                        upload/choose. For others, it will be hidden.
                      </h6>
                      <h4 style={{ fontWeight: "400" }} className="my-1">
                        Hide This Session
                      </h4>
                    </div>
                    <Switch
                      checked={streamOption}
                      onChange={onStreamOptionhandleChange}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <br />
                  <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
                  <br />
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="">
                      <h5 className="mb-1"  style={{ fontWeight: "400" }}>Interpretation in other languages</h5>
                      <h6 className="event-label">
                        Enable real time interpretation in your session and let
                        your attendee watch the session in the language of their
                        choice.
                      </h6>
                    </div>
                    <Switch
                      checked={streamOption}
                      onChange={onStreamOptionhandleChange}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <br />
                  <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
                  <br />
                  <div className="d-flex justify-content-between align-items-end">
                    <div className="">
                      <h5 className="mb-1"  style={{ fontWeight: "400" }}>Custom iframe Integration</h5>
                      <h6 className="event-label">
                        Enable custom Integrations and let your attendees take
                        the most out of the sessions. It is commonly used for
                        closed captioning with services like streamtext,
                        syncwords etc
                      </h6>
                    </div>
                    <Switch
                      checked={streamOption}
                      onChange={onStreamOptionhandleChange}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <br />
                  <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
                  <br />
                  <div className=" mr-auto d-flex justify-content-end">
                    <button className="event-primary-btn">SAVE</button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
      </Row>
    </EventLayout>
  );
}
