import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import { BOOTCAMP_API } from "../../backend";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Pagination from "@material-ui/lab/Pagination";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles({
  creatCategorytHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
  disabledCreateBtn: {
    opacity: 0.3
  },
  createEventDrawer: {
    width: "40%",
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createCatagoryHld: {
    background: "#f6f6f6",
    borderRadius: "10px",
    padding: "16px",
  },
  categorycardhld: {
    border: "1px solid #dbdbdb",
    borderRadius: "10px",
    padding: "16px",
    minHeight: "75px",
    "&:hover": {
      "& $iconsHld": {
        display: "block",
      },
    },
  },
  iconsHld: {
    display: "none",
    "&:hover": {
      display: "block",
    },
  },
  questionshld: {
    background: "#ffffff",
    boxShadow: "7px 7px 20px 3px rgb(0 0 0 / 15%)",
    padding: "20px",
  },
  searchicon: {
    border: "1px solid #ced4da",
    borderTopRightRadius: "4px",
    paddingTop: "7px",
    paddingBottom: "7px",
    borderBottomRightRadius: "4px",
  },
});
export default function JobRole(props) {
  const [fullscreen, setFullsreen] = useState(false);
  const [tagStatus, setTagStatus] = React.useState(false);
  const [isEditSkill, setIsEditSkill] = React.useState("");
  const [skillTitle, setSkillTitle] = React.useState("");
  const [isToast, setIsToast] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [searchText, setSearchText] = React.useState("");
  const [totalSkills, setTotalSkills] = React.useState(0);
  const [selectedPage, setSelectedPage] = useState("1");
  const [skills, setSkills] = React.useState([]);
  const classes = useStyles();
  const createSkill = async () => {
    const body = {
      title: skillTitle,
    };
    await axios
      .post(`${BOOTCAMP_API}/enterpriseAssessment/createJobRole`, body)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setTagStatus(false);
          setSkills((prev) => [...prev, response.data.result.data]);
          setIsToast({
            show: true,
            message: "successfully created",
            variant: "success",
          });
        } else {
          setIsToast({
            show: true,
            message: response.data.result.title.message,
            variant: "error",
          });
        }
      })
      .catch((error) => {
        setIsToast({
          show: true,
          message: error.response.data.result.message,
          variant: "error",
        });
        console.log(error);
      });
  };
  const updateSkills = async () => {
    const body = {
      title: skillTitle,
    };
    await axios
      .put(`${BOOTCAMP_API}/enterpriseAssessment/updateJobRole/${isEditSkill}`, body)
      .then((response) => {
        setSkills(
          skills.filter((x) => {
            if (x._id === isEditSkill) {
              x.title = skillTitle;
            }
            return skills;
          })
        );
        setIsEditSkill("");
      })
      .catch((error) => {
        setIsToast({
          show: true,
          message: error.response.data.result.message,
          variant: "error",
        });
        console.log(error);
      });
  };

  const deleteSkill = (skill) => {
    axios
      .delete(`${BOOTCAMP_API}/skills/deleteSpecificSkill/${skill._id}`)
      .then((response) => {
        setSkills((prev) =>
          prev.filter((x) => {
            return x._id !== skill._id;
          })
        );
        setIsToast({
          show: true,
          message: "Deleted successfully",
          variant: "success",
        });
      })
      .catch((error) => {
        console.log(error);
        setIsToast({
          show: true,
          message: error.response.data.result.message,
          variant: "error",
        });
      });
  };
  const openNewTagForm = () => {
    setTagStatus(true);
  };
  const onSkillPaginationChange = (e, value) => {
    getAllSkills(value, searchText);
    setSelectedPage(value);
  };
  const searchQuestions = (e) => {
    setSearchText(e.target.value);
  };
  const onSearchClick = () => {
    if (searchText !== "") {
      setSearchText("");
      getAllSkills(selectedPage, "");
    } else {
      getAllSkills(selectedPage, searchText);
    }
  };
  const handleKeyDownSearch = (event) => {
    if (event.key === "Enter") {
      getAllSkills(selectedPage, searchText);
    }
  };
  const getAllSkills = async (page, searchText) => {
    const paramData = {
      current_page: page,
    };
    if (searchText !== "") {
      paramData.searchString = searchText;
    }
    await axios
      .get(`${BOOTCAMP_API}/enterpriseAssessment/getAllJobRole`, {
        params: paramData,
      })
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setSkills(response.data.result.data);
          setTotalSkills(response.data.result.totalCount);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getAllSkills(1, "");
  }, []);
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Job role" />
      <Snackbar
        open={isToast.show}
        onClose={() => setIsToast({ ...isToast, show: false })}
      >
        <>
          <Alert
            onClose={() => setIsToast({ ...isToast, show: false })}
            severity={isToast.variant}
          >
            {isToast.message}
          </Alert>
        </>
      </Snackbar>
      {/* <div className="col-6 pl-0 d-flex align-items-center ml-3">
        <input
          type="text"
          placeholder="Search Questions"
          className="form-control"
          value={searchText}
          onChange={searchQuestions}
          onKeyDown={handleKeyDownSearch}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        />
        <span className={`${classes.searchicon} px-2`} onClick={onSearchClick}>
          {searchText !== "" ? (
            <i class="mdi mdi-close"></i>
          ) : (
            <i class="fa fa-search"></i>
          )}
        </span>
      </div> */}
      <div className="px-3 py-3">
        {tagStatus && (
          <div className={`mb-2 col-6 ${classes.createCatagoryHld}`}>
            <input
              name="title"
              id="title"
              type="text"
              className="form-control mb-2"
              placeholder="Title"
              value={skillTitle}
              onChange={(e) => setSkillTitle(e.target.value)}
            />
            {skillTitle === "" ? (
              <button className={classes.createBtn + " " + classes.disabledCreateBtn}>
                ADD
              </button>
            ) : (
              <button className={classes.createBtn} onClick={createSkill}>
                ADD
              </button>
            )}
            <button
              className={`${classes.cancelBtn}`}
              onClick={() => {
                setTagStatus(false);
                setIsEditSkill("");
              }}
            >
              CANCEL
            </button>
          </div>
        )}
        <button className="event-outlined-btn mb-3" onClick={openNewTagForm}>
          <i className="mdi mdi-plus-circle-outline mr-1"></i> ADD Job role
        </button>
        <div className="row">
          {skills?.map((skill) => (
            <div className="col-4" key={skill._id}>
              {isEditSkill !== skill._id ? (
                <div
                  className={`mb-2 d-flex align-items-center justify-content-between ${classes.categorycardhld}`}
                  key={skill._id}
                >
                  <div>
                    <h5 className="mb-1 mt-0">Title: {skill?.title}</h5>
                  </div>
                  <div className={classes.iconsHld}>
                    <span
                      style={{ fontSize: "24px" }}
                      onClick={() => (
                        setIsEditSkill(skill?._id), setSkillTitle(skill?.title)
                      )}
                      className="cursor-pointer"
                    >
                      <i className="mdi mdi-pencil-box-outline brand-color"></i>
                    </span>
                    {/* <span
                      style={{ fontSize: "24px" }}
                      onClick={() => deleteSkill(skill)}
                      className="cursor-pointer"
                    >
                      <i className="mdi mdi-delete brand-color"></i>
                    </span> */}
                  </div>
                </div>
              ) : (
                <div className={`mb-2 ${classes.createCatagoryHld}`}>
                  <input
                    name="title"
                    id="title"
                    type="text"
                    className="form-control mb-2"
                    placeholder="Title"
                    value={skillTitle}
                    onChange={(e) => setSkillTitle(e.target.value)}
                  />
                  <br />
                  <button className={classes.createBtn} onClick={updateSkills}>
                    SAVE
                  </button>
                  <button
                    className={`${classes.cancelBtn}`}
                    onClick={() => {
                      setTagStatus(false);
                      setIsEditSkill("");
                    }}
                  >
                    CANCEL
                  </button>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-center my-4">
          <Pagination
            count={
              totalSkills % 10 === 0
                ? parseInt(totalSkills / 10)
                : parseInt(totalSkills / 10) + 1
            }
            defaultPage={1}
            page={Number(selectedPage)}
            variant="outlined"
            shape="rounded"
            className={classes.root}
            onChange={onSkillPaginationChange}
            showFirstButton
            showLastButton
          />
        </div>
      </div>
    </AuthLayout>
  );
}
