import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useHistory } from "react-router-dom";
import EasyMDE from 'easymde';
import axios from "axios"
import { BOOTCAMP_API } from "../../backend"
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        // textAlign: "center",
        color: theme.palette.text.secondary,
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 400,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 420,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateSubCategory(props) {
    const otherModuleReferences = [
        {
            key: 'href1',
            value: ''
        },
        {
            key: 'href2',
            value: ''
        },
        {
            key: 'href3',
            value: ''
        },
    ];

    const classes = useStyles();
    const history = useHistory();
    const [fullscreen, setFullsreen] = useState(false);
    const [messageData, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [assessmentCategory, setAssessmentCategory] = useState("");
    const [difficulty, setDifficulty] = useState("");
    const [onSubmit, setOnSubmit] = useState(false);
    const [badge, setBadge] = useState("");
    const [publish, setPublish] = useState(false);
    const [badgesNotAssigned, setBadgesNotAssigned] = useState([])
    const [premium, setPremium] = useState(false);
    const [isLms, setIsLms] = useState(false);

    const token = JSON.parse(localStorage.getItem('jwt'));

    let MDE;
    const handleClose = () => {
        setStatus(!status);
    };

    const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };
    const handleLoader = () => {
        setStatusLoader(!statusLoader);
    };

    const FetchAssessmentCategoryDetails = () =>{

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': token.token
            },
        };

        axios.get(`${BOOTCAMP_API}/assessmentMock/getSpecificAssessmentCategory/${props.match.params.assessmentCategoryId}`)
            .then((response) =>{
                if(response.data.result.category){
                    if(response.data.result.category.type === "ml_skills"){
                        axios.get(`${BOOTCAMP_API}/assessmentMock/getAllMajorBadgesWichAreNotAssigned`)
                            .then((response) =>{
                                setBadgesNotAssigned(response.data.result.minorBadges);
                            })
                            .catch((err)=>{
                                console.log(err);
                            })
                    }
                    setAssessmentCategory(response.data.result.category.type);
                }
            })
            .catch((error)=>{
                console.log(error);
            })
    }

    const FetchAssessmentSubCategoryDetails = () => {

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': token.token
            },
        };

        axios.get(`${BOOTCAMP_API}/assessmentMock/getSpecificAssessmentSubCategory/${props.match.params.subCategoryId}`)
            .then((response) =>{
                if(response.data.result.category){
                    setTitle(response.data.result.category.title);
                    setDescription(response.data.result.category.description);
                    MDE.value(response.data.result.category.description)
                    setDifficulty(response.data.result.category.difficulty);
                    setPublish(response.data.result.category.publish);
                    setPremium(response.data.result.category.premium);
                    setIsLms(response.data.result.category.lmsContent)
                    if(response.data.result.category.badge)
                        setBadge(response.data.result.category.badge);
                    
                }
            })
            .catch((error)=>{
                console.log(error);
            })
    }
    useEffect(() => {

        FetchAssessmentCategoryDetails();
        FetchAssessmentSubCategoryDetails();

        MDE = new EasyMDE({
            element: document.getElementById('body'),
            autoDownloadFontAwesome: true,
            autofocus: true,
            status: false,
            singleLineBreaks: false,
            onToggleFullScreen: (opend) => setFullsreen(opend)
        });
        MDE.codemirror.on('change', () => {
            setDescription(MDE.value());
        });
    }, [MDE]);

    const updateAssessmentSubCategory = () =>{

        setOnSubmit(true);
        setStatusLoader(true);
        // if (title === "" || shortDescription === "" || difficulty === "" || description === "") {
        if (title === "" || description === "" || (assessmentCategory === "ml_skills" && badge === "")) {
            setErrorStatus(true);
            setErrorMessage("Required Field Missing!!");
            setStatusLoader(false);
        }
        else{

            axios.put(`${BOOTCAMP_API}/assessmentMock/updateAssessmentSubcategory/${props.match.params.subCategoryId}`,null,{
                data:{
                    "title":title,
                    "description":description,
                    "publish":publish,
                    "difficulty":difficulty,
                    "badge":badge,
                    "premium":premium,
                    lmsContent: isLms
                }
            }).then((response) =>{

                if(response){
                        setStatus(true);
                        setMessage(response.data.result.message);
                        setStatusLoader(false);
                        setTimeout(() => {
                            history.push(`/subCategoryList/${props.match.params.assessmentCategoryId}`);
                        }, 2500);
                }
                else{
                        setErrorStatus(true);
                        setErrorMessage('Exception in creating new Practice Module!');
                        setStatusLoader(false);
                }


            })
            .catch((error) =>{
                    setErrorStatus(true);
                    setErrorMessage(error);
                    setStatusLoader(false);

                setStatusLoader(false);
            })
        }
        
    }

    return (
      <AuthLayout
        isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
      >
        <Header name="Update Assessment Sub Category" />

        <div className={classes.root}>
          <Grid container>
            <Grid item xs={12}>
              {status && (
                <Snackbar
                  open={status}
                  autoHideDuration={15000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity="success">
                    {messageData}
                  </Alert>
                </Snackbar>
              )}

              {errorStatus && (
                <Snackbar
                  open={errorStatus}
                  autoHideDuration={15000}
                  onClose={handleCloseError}
                >
                  <Alert onClose={handleCloseError} severity="error">
                    {errorMessage}
                  </Alert>
                </Snackbar>
              )}

              {statusLoader && (
                <Snackbar
                  open={statusLoader}
                  // autoHideDuration={95000}
                  onClose={handleLoader}
                >
                  <Alert onClose={handleLoader} severity="info">
                    <Loader />
                  </Alert>
                </Snackbar>
              )}

              <Paper className={classes.paper}>
                <h2>Title</h2>

                <TextField
                  id="outlined-multiline-static"
                  label="Title"
                  multiline
                  rows={3}
                  variant="outlined"
                  fullWidth={true}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />

                <br />
                <br />

                <h2>Description</h2>

                {/* <TextField
                                id="outlined-multiline-static"
                                label="description"
                                multiline
                                rows={3}
                                variant="outlined"
                                fullWidth={true}
                                value={description}
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                    }}
                            /> */}

                <textarea id="body" name="body" value={MDE?.value()} />

                <br />
                <br />

                <h2>Difficulty</h2>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Difficulty
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={difficulty}
                    onChange={(e) => {
                      setDifficulty(e.target.value);
                    }}
                    label="Difficulty"
                  >
                    <MenuItem value="BEGINNER">Beginner</MenuItem>
                    <MenuItem value="INTERMEDIATE">Intermediate</MenuItem>
                    <MenuItem value="ADVANCED">Advance</MenuItem>
                    {/* <MenuItem value={30}>Thirty</MenuItem> */}
                  </Select>
                </FormControl>

                <br />
                <br />

                {assessmentCategory === "ml_skills" && (
                  <>
                    <h2>Badge</h2>

                    {/* <FormControl
								variant="outlined"
								className={classes.formControl}
							>
								<InputLabel id="demo-simple-select-outlined-label">
									Badge
								</InputLabel>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={badge}
									onChange={(e) => {
										setBadge(e.target.value);
									}}
									label="Badge"
								>
                                    {
                                        badgesNotAssigned.map((badgeNotAssigned,index)=>(
                                            
                                                <MenuItem value={badgeNotAssigned._id}>
										            {badgeNotAssigned.badgeTitle}
									            </MenuItem>
                                           
                                        ))
                                    }
								</Select>
							</FormControl> */}

                    {/* <TextField
                                id="outlined-multiline-static"
                                label="Title"
                                multiline
                                rows={3}
                                variant="outlined"
                                fullWidth={true}
                                value={badge}
                                onChange={(e) => {
                                    setBadge(e.target.value);
                                }}
                            /> */}

                    <br />
                    <br />
                  </>
                )}

                <h2>Premium</h2>
                <Switch
                  checked={premium}
                  onChange={()=>setPremium(!premium)}
                  name="premium"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                <br />
                <br />

                <h2>LMS</h2>
                <Switch checked={isLms} onChange={(event) => setIsLms(event.target.checked)} name="isLMS" />
                <br />
                <br />

                <Button
                  disabled={onSubmit}
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                  onClick={(e) => updateAssessmentSubCategory()}
                >
                  Update Assessment Sub Category
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </AuthLayout>
    );
}

