import React, { Component } from "react";
import { connect } from "react-redux";
import Header from "./Header";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Card, CardBody } from "reactstrap";
import { getLoggedInUser } from "../helpers/authUtils";
import Loader from "../components/Loader";
import AuthLayout from "../components/AuthLayout";
import GetAllHackathon from "./Hackathon/GetAllHackathon";

class DefaultDashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: getLoggedInUser(),
		};
	}

	render() {
		return (
			// <>
			<AuthLayout>
				<React.Fragment>
					<div className="">
						{/* preloader */}
						{this.props.loading && <Loader />}
						<Header name="All Hackathon" />

						<Row>
							<Col lg={12}>
								<GetAllHackathon />
							</Col>
						</Row>
					</div>
				</React.Fragment>
			</AuthLayout>
			// </>
		);
	}
}

export default connect()(DefaultDashboard);
