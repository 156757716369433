import React, { useState } from "react";
import { Container } from "reactstrap";

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
import EventSidebar from "./EventSidebar";
import RightSidebar from "./RightSidebar";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import { isAuthenticated } from "../auth/helper";
import setAuthToken from "../auth/helper/setToken";
import { getUserInfo } from "../user/helper/userapicall";
import { store } from "../store/store";
import EventTopbar from "./EventTopbar";

if (localStorage.getItem("jwt")) {
  let jwt = JSON.parse(localStorage.getItem("jwt"));
  setAuthToken(jwt.token);
  let userId = isAuthenticated().user._id;
  getUserInfo(userId)
    .then((response) => {
      store.dispatch({
        type: "GET_USER_INFO",
        payload: response.data.user,
      });
    })
    .catch((err) => console.log(err));
}

const RightSidebarContent = () => {
  const user = useSelector((store) => store.user.user);

  return (
    <div className="user-box">
      <div className="user-img">
        <img
          src={user.Picture}
          alt="user-img"
          title="Nik Patel"
          className="rounded-circle img-fluid"
        />
        <a href="/" className="user-edit">
          <i className="mdi mdi-pencil"></i>
        </a>
      </div>

      <h5>{<a href="/">{user.UserName}</a>}</h5>
      <p className="text-muted mb-0">
        <small>{user.Role}</small>
      </p>
    </div>
  );
};

function EventLayout(props) {
  const [isCondensed, setIsCondensed] = useState(false);
  const toggleMenu = (e) => {
    e.preventDefault();
    setIsCondensed(!isCondensed);
  };

  /**
   * Toggle right side bar
   */
  const toggleRightSidebar = () => {
    document.body.classList.toggle("right-bar-enabled");
  };

  return (
    <div className="app" style={{ backgroundColor: "#F9FBFD" }}>
      <div id="wrapper">
        {props && !props.isMDEFullScreen && (
          <EventTopbar
            rightSidebarToggle={toggleRightSidebar}
            menuToggle={toggleMenu}
          />
        )}
        {props && !props.isMDEFullScreen && (
          <EventSidebar isCondensed={isCondensed} />
        )}
        {/* </Suspense> */}
        <div className="content-page event-content-page ">
          <div className="content">
            <Container>{props.children}</Container>
          </div>
          <Footer />
        </div>
      </div>

      <RightSidebar title={"Settings"}>
        <RightSidebarContent />
      </RightSidebar>
    </div>
  );
}

export default EventLayout;
