import React, { useState } from "react";
import { Container } from "reactstrap";


// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import RightSidebar from "./RightSidebar";
import Footer from "./Footer";
// const loading = () => <div className="text-center"></div>;
// import { Link, useHistory, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthenticated } from "../auth/helper";
import setAuthToken from "../auth/helper/setToken";
import { getUserInfo } from "../user/helper/userapicall";
import { store } from "../store/store";

if (localStorage.getItem("jwt")) {
	let jwt = JSON.parse(localStorage.getItem("jwt"));
	// console.log("jwt", jwt);
	setAuthToken(jwt.token);
	let userId = isAuthenticated().user._id;
	getUserInfo(userId)
		.then((response) => {
			// console.log("user", response.data.user);
			store.dispatch({
				type: "GET_USER_INFO",
				payload: response.data.user,
			});
		})
		.catch((err) => console.log(err));
}

const RightSidebarContent = () => {
	const user = useSelector((store) => store.user.user);

	return (
		<div className="user-box">
			<div className="user-img">
				<img
					src={user.Picture}
					alt="user-img"
					title="Nik Patel"
					className="rounded-circle img-fluid"
				/>
				<a href="/" className="user-edit">
					<i className="mdi mdi-pencil"></i>
				</a>
			</div>

			<h5>{<a href="/">{user.UserName}</a>}</h5>
			<p className="text-muted mb-0">
				<small>{user.Role}</small>
			</p>
		</div>
	);
};

function AuthLayout(props) {
	const [isCondensed, setIsCondensed] = useState(false);
	// constructor(props) {
	// 	super(props);

	// 	this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
	// 	this.toggleMenu = this.toggleMenu.bind(this);
	// 	this.state = {
	// 		isCondensed: false,
	// 	};
	// }

	// signOut(e) {
	// 	e.preventDefault();
	// 	this.props.history.push("/login");
	// }

	/**
	 * toggle Menu
	 */
	const toggleMenu = (e) => {
		e.preventDefault();
		// this.setState({ isCondensed: !this.state.isCondensed });
		setIsCondensed(!isCondensed);
	};

	/**
	 * Toggle right side bar
	 */
	const toggleRightSidebar = () => {
		document.body.classList.toggle("right-bar-enabled");
	};

	// render() {
	// get the child view which we would like to render
	// const children = this.props.children || null;
	return (
		<div className="app" style={{backgroundColor:'#F9FBFD'}}>
			<div id="wrapper">
				{/* <Suspense fallback={loading()}> */}
				{
					props && !props.isMDEFullScreen 
					&& 
					<Topbar
					rightSidebarToggle={toggleRightSidebar}
					menuToggle={toggleMenu}
					// {...this.props}
				/>
				}
				{
					props && !props.isMDEFullScreen
					&&
					<Sidebar
					isCondensed={isCondensed}
					// {...this.props}
					/>
				}
				{/* </Suspense> */}
				<div className="content-page">
					<div className="content">
						<Container fluid>
							
							{props.children}
							
						</Container>
					</div>
					<Footer />
				</div>
			</div>

			<RightSidebar title={"Settings"}>
				<RightSidebarContent />
			</RightSidebar>
		</div>
	);
	// }
}

export default AuthLayout;
