import React, { useState } from "react";
import axios from "axios";
import { BOOTCAMP_API } from "../../backend";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateSubscriptionPlan() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const [messageData, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [period, setPeriod] = useState("");
  const [currencyType, setCurrencyType] = useState("");
  const [actualPrice, setActualPrice] = useState("");
  const history = useHistory();
  const handleClose = () => {
    setStatus(!status);
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };
  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };
  const CreateSubscription = () => {
    if (
      name === "" ||
      description === "" ||
      shortDescription === "" ||
      amount === "" ||
      period === "" ||
      currencyType === "" ||
      actualPrice === ""
    ) {
      setErrorStatus(true);
      setErrorMessage("Field Missing! All Feild Required !!");
    } else {
      const requestData = {
        name: name,
        description: description,
        shortDescription: shortDescription,
        amount: parseFloat(amount),
        period: period,
        currencyType: currencyType,
        actualPrice: parseFloat(actualPrice)
      };
      setStatusLoader(true);
      axios
        .post(
          `${BOOTCAMP_API}/subscriptions/createSubscriptionPlan`,
          requestData
        )
        .then((response) => {
          console.log(response);
          if (response.status === 201) {
            setStatus(true);
            setMessage(response.data.result.message);
            setStatusLoader(false);
            history.push("/subscriptions");
          } else {
            setErrorStatus(true);
            setErrorMessage(response.data.result.message);
            setStatusLoader(false);
          }
        })
        .catch((error) => {
          setErrorStatus(true);
          setErrorMessage(error);
          setStatusLoader(false);
          console.log(error);
        });
    }
  };

  return (
    <AuthLayout>
      <Header name="Create Subscription Paln" />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {status && (
              <Snackbar
                open={status}
                autoHideDuration={15000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </Snackbar>
            )}
            {errorStatus && (
              <Snackbar
                open={errorStatus}
                autoHideDuration={15000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            )}
            {statusLoader && (
              <Snackbar open={statusLoader} onClose={handleLoader}>
                <Alert onClose={handleLoader} severity="info">
                  <Loader />
                </Alert>
              </Snackbar>
            )}

            <Paper className={classes.paper}>
              <h2>Name</h2>
              <TextField
                id="outlined-multiline-static"
                label="Name"
                multiline
                rows={4}
                variant="outlined"
                fullWidth={true}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Description</h2>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                variant="outlined"
                fullWidth={true}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Short Description</h2>
              <TextField
                id="outlined-multiline-static"
                label="Short Description"
                multiline
                variant="outlined"
                fullWidth={true}
                value={shortDescription}
                onChange={(e) => {
                  setShortDescription(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Actual Price</h2>

              <TextField
                id="standard-number"
                label="Actual Price"
                type="number"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={actualPrice}
                onChange={(e) => {
                  setActualPrice(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Discount Price</h2>

              <TextField
                id="standard-number"
                label="Discount Price"
                type="number"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={amount}
                onChange={(e) => {
                  setAmount(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Period</h2>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Period
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={period}
                  onChange={(e) => {
                    setPeriod(e.target.value);
                  }}
                  label="Period"
                >
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="yearly">Yearly</MenuItem>
                </Select>
              </FormControl>
              <br />
              <br />

              <h2>Currency Type</h2>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Currency Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={currencyType}
                  onChange={(e) => {
                    setCurrencyType(e.target.value);
                  }}
                  label="Currency Type"
                >
                  <MenuItem value="INR">INR</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </FormControl>

              <br />
              <br />

              <Button
                variant="contained"
                color="primary"
                onClick={CreateSubscription}
                size="large"
                style={{
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  backgroundColor: "rgb(250, 53, 88)",
                }}
              >
                Create Subscription Plan
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
