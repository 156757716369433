import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		"& > * + *": {
			marginLeft: theme.spacing(2),
		},
	},
}));

export default function Loader(props) {
	const classes = useStyles();

	return (
		<>
			<div className={classes.root}>
				{/* <CircularProgress /> */}
				<CircularProgress
					color="inherit"
					style={{ marginLeft: props.pexal, marginBottom: "5px" }}
				/>
			</div>
		</>
	);
}
