import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API } from "../../backend";
import Loader from "../../components/Loader";
// import online from ""
import online from "../../assets/images/online.png";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DownloadLoader from "../DownloadUserData/Loader";
import Header from "../Header";
import { getLoggedInUser } from "../../helpers/authUtils";
import AuthLayout from "../../components/AuthLayout";
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

var FileSaver = require("file-saver");

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
	  '& > *': {
		marginTop: theme.spacing(2),
	  },
	},
  }));

export default function GetAllHackathon(props) {
	const practices = useSelector((store) => store.practices.practices);
	const dispatch = useDispatch();

	const [open, setOpen] = React.useState(false);
	const [limit, setLimit] = useState("");
	const [sort, setSort] = useState("PublicScore");
	const [start, setStart] = useState(false);
	const [end, setEnd] = useState(false);
	const [messageData, setMessageData] = useState("");
	const [HREFName, setHREFName] = useState("");
	const [practiceList,setPracticeList] = useState([]);
	const [isEmptyList,setIsEmptyList] = useState(false);
	const [totalPractice, setTotalPractice] = useState(0);
	const [selectedPage, setSelectedPage] = useState("1");

	const classes = useStyles();

	const handleClickOpen = (HREFName) => {
		setHREFName(HREFName);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseEnd = () => {
		setEnd(!end);
	};

	const downloadFileHackathon = () => {
		setStart(true);
		setMessageData("File Download Started ...");
		var formData = new FormData();
		formData.append("limit", limit);
		formData.append("sortby", sort);

		axios({
			url: `${API}/lb/export/${HREFName}`,
			method: "POST",
			headers: {
				"content-type": "multipart/form-data",
			},
			data: formData,
			responseType: "blob",
		})
			.then((response) => {
				console.log(response);
				FileSaver.saveAs(response.data, "userdata.csv");
				// FileDownload(response.data, "report.csv");
				// setSubmissions(response.data.submissions);
				// console.log("here");
				setStart(false);
				setEnd(true);
				setMessageData("File Download Completed");
			})
			.catch((err) => console.log(err));
	};

	const deletePractice = (practiceHREFName) =>{

		var r = window.confirm("Are you sure want to delete the practice");

		if(r === true){
			axios.delete(`${API}/delete_practice/${practiceHREFName}`)
				.then((response) =>{
					if(response)
						getAllPractice(selectedPage);
				})
		}

	}

	const getAllPractice = (pageNumber) => {
		console.log('PRACTICE PROPS', props)
		axios
			.get(`${API}/cms/practice_module/${props.match.params.moduleId}/${pageNumber}`)
			.then((response) => {
				
				if(response.data.practices){
					
					if(response.data.practices.length > 0){
						setPracticeList(response.data.practices);
						setTotalPractice(response.data.total_practices)
					}
					else
						setIsEmptyList(true);
				}
			})
			.catch((err) => console.log(err));
	};

	const nextPracticeSet = (e,value) =>{

		setSelectedPage(value);

		getAllPractice(value);
	}

	// console.log("hackthon", hackathons);
	useEffect(() => {
		getAllPractice("1");
	}, []);

	// console.log("hackathon", Object.keys(hackathons).length);

	return (
		<AuthLayout>
			<React.Fragment>
				<div className="">
					{/* preloader */}
					{/* {this.props.loading && <Loader />} */}
					<Header name="All Practice" />
					<Row>
					<Col>

						<Link
							to={`/createPractice/${props.match.params.moduleId}/${props.match.params.moduleObjectId}`}
						>
							<ButtonToggle
								color="dark"
								style={{
									paddingLeft:
										"30px",
									paddingRight:
										"30px",
									margin: "15px",
									float: 'right'
								}}
							>
								Add Practice
							</ButtonToggle>
						</Link>
					</Col>
				</Row>

					<Row>
						<Col lg={12}>
							<>
								{start && (
									<Snackbar
										open={start}
										onClose={handleClose}
									>
										<>
											<Alert
												onClose={handleClose}
												severity="success"
											>
												<DownloadLoader pexal="55px" />
												{messageData}
											</Alert>
										</>
									</Snackbar>
								)}
								{end && (
									<Snackbar
										open={end}
										onClose={handleCloseEnd}
									>
										<>
											<Alert
												onClose={handleCloseEnd}
												severity="success"
											>
												{messageData}
											</Alert>
										</>
									</Snackbar>
								)}

								{practiceList.length > 0 ? (
									practiceList.map((practice) => (
										<Card key={practice._id}>
											<Row>
												<Col lg={8}>
													<CardBody>
														{" "}
														<h4>
															{practice.HName}
														</h4>{" "}
													</CardBody>
												</Col>
												<Col
													lg={2}
													style={{
														textAlign: "right",
													}}
												>
													<CardBody
														style={{
															paddingLeft: "0px",
															paddingRight: "0px",
														}}
													>
														{" "}
														<Link
															to={`/updatePractice/${practice.HREFName}/${practice.module_id}`}
														>
															<ButtonToggle
																color="danger"
																style={{
																	paddingLeft:
																		"30px",
																	paddingRight:
																		"30px",
																}}
															>
																Edit
															</ButtonToggle>
														</Link>
													</CardBody>
												</Col>
												<Col 
													lg={2}
													>
														
														<CardBody
														style={{
															paddingLeft: "0px",
															paddingRight: "0px",
														}}
													>
														
														<ButtonToggle
																color="danger"
																style={{
																	paddingLeft:
																		"30px",
																	paddingRight:
																		"30px",
																}}
																onClick={(e) => deletePractice(practice.HREFName)}
															>
																Delete
														</ButtonToggle>
													</CardBody>
												</Col>
											</Row>
										</Card>
									))
								) : (
									<>
									{
										isEmptyList ? (
											<div style={{ textAlign: "center" }}>
												No Practice Available
											</div>
										):(
											<div style={{ textAlign: "center" }}>
												<Loader />
											</div>
										)
									}
									
									</>
								)}

								<Card>
									<Col lg={12}>
										<Row style={{justifyContent:"center",paddingBottom:"20px"}}>
											<Pagination count={totalPractice % 10 === 0 ? parseInt(totalPractice / 10) : parseInt(totalPractice / 10)+1 }
														defaultPage={1}
														page={Number(selectedPage)}
														variant="outlined" 
                                						shape="rounded"
														className={classes.root}
														onChange={nextPracticeSet} 
														showFirstButton showLastButton  />
										</Row>
									</Col>
								</Card>

								<Dialog
									open={open}
									onClose={handleClose}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									fullWidth={true}
									maxWidth="md"
								>
									<DialogTitle id="alert-dialog-title">
										Downlaod Public/Private Leaderboard Data
									</DialogTitle>
									<DialogContent>
										{/* <DialogContentText id="alert-dialog-description">
						Let Google help apps determine location. This means
						sending anonymous location data to Google, even when no
						apps are running.
					</DialogContentText> */}
										<br />
										<div class="form-group row">
											<label
												class="col-sm-2  col-form-label"
												for="simpleinput"
											>
												Limit
											</label>
											<div class="col-sm-10">
												<input
													type="text"
													class="form-control"
													value={limit}
													onChange={(e) => {
														setLimit(
															e.target.value.trim()
														);
													}}
												/>
											</div>
										</div>

										<div class="form-group row">
											<label
												for="sel1"
												class="col-sm-2  col-form-label"
											>
												Sortby
											</label>
											<div class="col-sm-10">
												<select
													class="form-control"
													id="sel1"
													onChange={(e) => {
														setSort(e.target.value);
													}}
													value={sort}
												>
													<option value="PublicScore">
														PublicScore
													</option>
													<option value="PrivateScore">
														PrivateScore
													</option>
												</select>
											</div>
										</div>
										<br />
									</DialogContent>
									<DialogActions>
										<ButtonToggle
											color="danger"
											style={{
												paddingLeft: "30px",
												paddingRight: "30px",
											}}
											onClick={handleClose}
										>
											Cancel
										</ButtonToggle>
										<button
											class="btn btn-pink waves-effect waves-light"
											onClick={downloadFileHackathon}
										>
											{" "}
											<span>Download</span> &nbsp;
											<i class="fas fa-download"></i>{" "}
										</button>
									</DialogActions>
								</Dialog>
							</>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		</AuthLayout>
	);
}
