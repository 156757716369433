import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API } from "../../../../backend";
import Loader from "../../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import DownloadLoader from "../../../DownloadUserData/Loader";
import EventLayout from "../../../../components/EventLayout";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { FormControl } from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import logoSm from "../../../../assets/images/logo-sm.png";
import Select from "react-select";
import { SketchPicker } from "react-color";
import { DropzoneArea } from "material-ui-dropzone";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";

const moment = require("moment");
const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Heading,
    Indent,
    Table,
    TableToolbar,
    Link,
    List,
    MediaEmbed,
    CodeBlock,
    ImageResize,
    ImageToolbar,
    ImageStyle,
  ],
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "mediaEmbed",
    "insertTable",
    "codeBlock",
  ],
  mediaEmbed: {
    previewsInData: true
  },
  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },

  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  codeBlock: {
    languages: [
      // Do not render the CSS class for the plain text code blocks.
      { language: "plaintext", label: "Plain text", class: "" },

      // Use the "php-code" class for PHP code blocks.
      { language: "php", label: "PHP", class: "php-code" },

      // Use the "js" class for JavaScript code blocks.
      // Note that only the first ("js") class will determine the language of the block when loading data.
      {
        language: "javascript",
        label: "JavaScript",
        class: "js javascript js-code",
      },

      // Python code blocks will have the default "language-python" CSS class.
      { language: "python", label: "Python" },
    ],
  },
};
const useStyles = makeStyles({
  root: {
    minHeight: 206,
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardbody: {
    padding: "0 10px 10px 10px",
    borderRadius: "8px",
  },
  eventbanner: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "100px",
    objectFit: "fill",
    maxWidth: "100%",
  },
  eventName: {
    fontWeight: 500,
    display: "-webkit-box",
    overflow: "hidden",
    "-mozBoxOrient": "vertical",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": 2,
  },
  moreOptions: {
    background: "#ffffff",
    position: "absolute",
    right: "10px",
    top: "6px",
    borderRadius: "50px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f44d5e",
    fontSize: "20px",
  },
  optionMenu: {
    boxShadow: "none",
    border: "1px solid #dbdbdb",
    "& div": {
      boxShadow: "none",
      border: "1px solid #dbdbdb",
    },
  },
  optionMenuItem: {
    boxShadow: "none",
  },
  createEventDrawer: {
    width: "40%",
  },
  creatEventHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    overflowX: "hidden",
  },
  spacingCreateEvent: {
    padding: "10px 30px 10px 30px",
    height: "calc(100vh - 142px)",
    overflow: "auto",
  },
  eventType: {
    width: "50%",
    textAlign: "center",
    color: "gray",
    marginBottom: 0,
    padding: "8px",
  },
  border: {
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
  },
  selectedEventType: {
    background: "#f44d5e",
    color: "#ffffff",
    width: "50%",
    textAlign: "center",
    marginBottom: 0,
    padding: "8px",
  },
  textField: {
    marginRight: 10,
    border: "1px solid rgba(53, 53, 53, 0.17)",
    borderRadius: "4px",
    color: "#353535",
    width: "inherit",
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
  createBtnDisabled:{
    background: "#c4c4c4",
    color: "#000000",
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    fontSize: "12px",
    marginRight: "10px",
  }
});
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
var FileSaver = require("file-saver");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Sessions(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [isCreateTrack, setIsCreateTrack] = React.useState(false);
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bannerImg, setBannerImg] = useState([]);
  const [drawer, setDrawer] = React.useState({
    filter: false,
    tracks: false,
    addSession: false,
  });
  const [background, setBgColor] = React.useState("#dbdbdb");
  const [selectedDate, setSelectedDate] = React.useState("");
  const [sessionTitle, setsessionTitle] = React.useState("");
  const [sessionDescription, setsessionDescription] = React.useState("");
  const [allTracks, setAllTracks] = React.useState([]);
  const [createTrackTitle, setCreateTrackTitle] = React.useState("");
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [isEditTrack, setIsEditTrack] = React.useState("");
  const [sessionDates, setSessionDates] = React.useState([]);
  const [sessionStartTime, setSessionStartTime] = React.useState("");
  const [sessionEndTime, setSessionEndTime] = React.useState("");
  const [assignedTrack, setAssignedTrack] = React.useState("");
  const [datetabsValue, setdatetabsValue] = React.useState("");
  const [allAgenda, setallAgenda] = React.useState([]);
  const [openSessionDel, setopenSessionDel] = React.useState({
    show: false,
    data: {},
  });
  const [scroll, setScroll] = React.useState("paper");
  const [check, setCheck] = React.useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowColorPicker(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const onSelecthandleChange = (selectedOption) => {
    setAssignedTrack(selectedOption);
  };
  const handleChangeComplete = (color) => {
    setBgColor(color.hex);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setEnd(!end);
  };
  const createTrackLayout = () => {
    setIsCreateTrack(true);
  };
  const deleteTrack = (track) => {
    const trackdata = {
      title: track.title,
      colorCode: track.colorCode,
    };
    axios
      .delete(
        `${BOOTCAMP_API}/events/deleteEventTrackerData/${track._id}`,
        trackdata
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setAllTracks((prev) =>
            prev.filter((x) => {
              return x._id !== track._id;
            })
          );
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const createNewTrack = () => {
    const newTrack = {
      title: createTrackTitle,
      colorCode: background,
      eventId: props.match.params.eventId,
    };
    if (isEditTrack === "") {
      axios
        .post(`${BOOTCAMP_API}/events/createEventTracker`, newTrack)
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setIsCreateTrack(false);
            let trackResponse = response.data.result.data;
            trackResponse.label = response.data.result.data.title;
            trackResponse.value = response.data.result.data._id;
            setAllTracks((prev) => [...prev, trackResponse]);
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    } else {
      axios
        .put(
          `${BOOTCAMP_API}/events/updateEventsTrackerData/${isEditTrack}`,
          newTrack
        )
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setIsCreateTrack(false);
            setAllTracks((prev) =>
              prev.filter((x) => {
                if (x._id === isEditTrack) {
                  x.colorCode = response.data.result.data.colorCode;
                  x.slug = response.data.result.data.slug;
                  x.title = response.data.result.data.tilte;
                  x.label = response.data.result.data.title;
                  x.title = response.data.result.data.title;
                  setBgColor(response.data.result.data.colorCode);
                  return x;
                }
                return prev;
              })
            );
            setIsEditTrack("");
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    }
  };
  const addSession = async () => {
    if (bannerImg.length === 0) {
      const newSession = {
        title: sessionTitle,
        eventId: props.match.params.eventId,
        poster: "",
        startTime: moment(
          new Date(selectedDate.value.split("T")[0] + "," + sessionStartTime)
        ).format(),
        endTime: moment(
          new Date(selectedDate.value.split("T")[0] + "," + sessionEndTime)
        ).format(),
        agendaType: assignedTrack.value,
        description: sessionDescription,
        featured: false,
        publish: true,
      };
      axios
        .post(`${BOOTCAMP_API}/events/createAgenda`, newSession)
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setIsCreateTrack(false);
            setDrawer({ ...drawer, addSession: false });
            getAllAgendas(selectedDate);
            resetAllData();
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    } else {
      const formData = new FormData();
      formData.append("", bannerImg[0]);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(`${BOOTCAMP_API}/events/upload/${props.match.params.eventId}`, formData, config)
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            const newSession = {
              title: sessionTitle,
              eventId: props.match.params.eventId,
              poster: response.data.result.data[0].s3_url,
              startTime: moment(
                new Date(
                  selectedDate.value.split("T")[0] + "," + sessionStartTime
                )
              ).format(),
              endTime: moment(
                new Date(
                  selectedDate.value.split("T")[0] + "," + sessionEndTime
                )
              ).format(),
              agendaType: assignedTrack.value,
              description: sessionDescription,
              featured: false,
              publish: true,
            };
            axios
              .post(`${BOOTCAMP_API}/events/createAgenda`, newSession)
              .then((response) => {
                if (response.data.error) {
                  setErrorStatus(true);
                  setErrormesage(response.data.result.message);
                } else {
                  setSuccessMessage(true);
                  setMessageData(response.data.result.message);
                  setIsCreateTrack(false);
                  setDrawer({ ...drawer, addSession: false });
                  getAllAgendas(selectedDate);
                  resetAllData();
                }
              })
              .catch((error) => {
                setSuccessMessage(false);
                setErrorStatus(false);
                console.log(error);
              });
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    }
  };
  const resetAllData = () => {
    setsessionTitle("");
    setsessionDescription("");
    setAssignedTrack("");
  };
  const deleteSession = () => {
    axios
      .delete(
        `${BOOTCAMP_API}/events/deleteSpecificAgenda/${openSessionDel.data._id}/${props.match.params.eventId}`
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setopenSessionDel({ show: false, data: "" });
          console.log(datetabsValue);
          getAllAgendas(datetabsValue);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const onSessionTabChange = (date) => {
    setdatetabsValue(date);
    getAllAgendas(date);
  };
  const getEventData = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
        let eventResponse = response.data.result.data.dates;
        let sessionDates = [];
        eventResponse.filter((x, index) => {
          let data = {};
          data.value = x;
          data.label = `Day ${index + 1} - ${moment(x).format("Do MMM YYYY")}`;
          sessionDates.push(data);
        });
        setdatetabsValue(sessionDates[0]);
        setSessionDates(sessionDates);
        getAllAgendas(sessionDates[0]);
      })
      .catch((err) => console.log(err));
  };
  const getAllTracks = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/events/getEventTrackersUnderEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        let trackResponse = response.data.result.data;
        trackResponse.filter((x) => {
          x.label = x.title;
          x.value = x._id;
        });
        setAllTracks(trackResponse);
      })
      .catch((err) => console.log(err));
  };
  const getAllAgendas = async (sessionDates) => {
    const body = {
      date: sessionDates.value.split("T")[0],
    };
    await axios
      .get(
        `${BOOTCAMP_API}/events/getAllAgendaUnderEvent/${props.match.params.eventId}`,
        {
          params: body,
        }
      )
      .then((response) => {
        setallAgenda(response.data.result.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getEventData();
    getAllTracks();
  }, []);

  return (
    <EventLayout>
      {start && (
        <Snackbar open={start} onClose={handleClose}>
          <>
            <Alert onClose={handleClose} severity="success">
              <DownloadLoader pexal="55px" />
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {end && (
        <Snackbar open={end} onClose={handleCloseEnd}>
          <>
            <Alert onClose={handleCloseEnd} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        {Object.keys(eventData).length > 0 ? (
          <div className="w-100 event-overview">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <br />
                <h4 className="mb-0">Sessions</h4>
                <p>
                  Adding sessions allows you to configure streaming,
                  registrations, visibility, and more.
                </p>
              </div>
            </div>
            <div className="event-cardHld">
              <div className="d-flex align-items-center justify-content-between">
                <form className="app-search">
                  <div className="app-search-box">
                    <div className="input-group pl-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                      <div className="input-group-append">
                        <button className="btn" type="submit">
                          <i className="fe-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="d-flex align-items-center">
                  {/* <button
                    className="mr-2 event-outlined-btn"
                    onClick={toggleDrawer("filter", true)}
                  >
                    <i className="mdi mdi-filter-outline"></i>
                  </button> */}
                  {/* <button className="mr-2 event-outlined-btn" disabled>
                    <i className="mdi mdi-calendar-blank"></i>
                  </button> */}
                  {/* <button className="mr-2 event-outlined-btn">
                    <i className="mdi mdi-delete"></i>
                  </button> */}
                  <button
                    className="mr-2 event-outlined-btn"
                    onClick={toggleDrawer("tracks", true)}
                  >
                    Manage Tracks
                  </button>
                  {/* <button className="mr-2 event-outlined-btn" disabled>
                    Add Day
                  </button> */}
                  <button
                    className="mr-2 event-primary-btn"
                    onClick={toggleDrawer("addSession", true)}
                  >
                    Add Session <i className="mdi mdi-menu-down ml-1"></i>
                  </button>
                </div>
              </div>
              <hr />
              <div className="sessionDayTab d-flex">
                {sessionDates.map((date, index) => (
                  <p
                    className={`mb-0 ${
                      datetabsValue.label === date.label ? "selected" : ""
                    }`}
                    style={{
                      borderTopLeftRadius: `${index === 0 ? "4px" : ""}`,
                      borderBottomLeftRadius: `${index === 0 ? "4px" : ""}`,
                      borderTopRightRadius: `${
                        index === sessionDates.length - 1 ? "4px" : ""
                      }`,
                      borderBottomRightRadius: `${
                        index === sessionDates.length - 1 ? "4px" : ""
                      }`,
                    }}
                    key={index}
                    onClick={() => onSessionTabChange(date)}
                  >{`${
                    datetabsValue.label === date.label
                      ? date.label
                      : "Day" + (index + 1)
                  }`}</p>
                ))}
              </div>
              <br />
              <div className="event-cardHld p-0">
                {Object.entries(allAgenda)?.map(([key, value], index) => (
                  <div
                    className="d-flex sessionHld"
                    key={index}
                    style={{
                      borderBottom: `${
                        index === Object.keys(allAgenda)?.length - 1
                          ? ""
                          : "1px solid #dbdbdb"
                      }`,
                    }}
                  >
                    <div
                      className="time"
                      style={{
                        borderTopLeftRadius: `${index === 0 ? "10px" : ""}`,
                        borderBottomLeftRadius: `${
                          index === Object.keys(allAgenda)?.length - 1
                            ? "10px"
                            : ""
                        }`,
                      }}
                    >
                      <span>{key}</span>
                    </div>
                    {value.map((agenda) => (
                      <div
                        className="event-cardHld m-2 cursor-pointer"
                        style={{ minWidth: "215px" }}
                        onClick={() =>
                          history.push(
                            `/event/${props.match.params.eventId}/sessions/${agenda._id}/basic-detail`
                          )
                        }
                        key={agenda._id}
                      >
                        <p className="mb-0 agendaTitle">{agenda.title}</p>
                        <span className="d-block mb-2">
                          {moment(agenda?.startTime).format("hh:mm A")} -{" "}
                          {moment(agenda?.endTime).format("hh:mm A")}
                        </span>
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item xs={6}>
                            <div className="speakershld">
                              {agenda?.speakers
                                .slice(0, 4)
                                .map((speaker, index) => (
                                  <img
                                    key={speaker._id}
                                    src={speaker.Picture}
                                    alt=""
                                    className="img-fluid"
                                    style={{ marginLeft: "-5px" }}
                                  />
                                ))}
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="text-right">
                              <span className="meetIcon mr-1 cursor-pointer">
                                <i className="mdi mdi-video"></i>
                              </span>
                              <span
                                className="meetIcon brand-color cursor-pointer"
                                onClick={(e) => (
                                  e.stopPropagation(),
                                  setopenSessionDel({
                                    show: true,
                                    data: agenda,
                                  })
                                )}
                              >
                                <i className="mdi mdi-delete"></i>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
      </Row>
      <Drawer
        anchor="right"
        open={drawer.addSession}
        onClose={toggleDrawer("addSession", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Add Session</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("addSession", false)}
                onKeyDown={toggleDrawer("addSession", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <div className="d-flex">
              <div className="col-6 pl-0">
                <label htmlFor="title" className="event-label">
                  Session Date
                  <span style={{ color: "red", verticalAlign: "" }}>*</span>
                </label>
                <Select
                  value={selectedDate}
                  onChange={(value) => setSelectedDate(value)}
                  options={sessionDates}
                  isSearchable={false}
                />
              </div>
              <div className="col-3">
                <label htmlFor="title" className="event-label">
                  Start Time
                  <span style={{ color: "red", verticalAlign: "" }}>*</span>
                </label>
                <TextField
                  id="datetime-local"
                  type="time"
                  // defaultValue="07:30"
                  className={classes.textField}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      color: "#353535",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    input: "#353535",
                    label: "#353535",
                  }}
                  value={sessionStartTime}
                  onChange={(e) => setSessionStartTime(e.target.value)}
                />
              </div>
              <div className="col-3">
                <label htmlFor="title" className="event-label">
                  End Time
                  <span style={{ color: "red", verticalAlign: "" }}>*</span>
                </label>
                <TextField
                  id="datetime-local"
                  type="time"
                  // defaultValue="07:30"
                  className={classes.textField}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      color: "#353535",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    input: "#353535",
                    label: "#353535",
                  }}
                  value={sessionEndTime}
                  onChange={(e) => setSessionEndTime(e.target.value)}
                />
              </div>
            </div>
            <br />
            <FormControl className={classes.formControl}>
              <label htmlFor="title" className="event-label">
                Session Title
                <span style={{ color: "red", verticalAlign: "" }}>*</span>
              </label>
              <input
                id="title"
                type="text"
                className="form-control"
                placeholder="Example: AMA Session with panellist Name onML for Peace"
                value={sessionTitle}
                onChange={(e) => setsessionTitle(e.target.value)}
              />
            </FormControl>
            <br />
            <br />
            <label htmlFor="title" className="event-label">
              Assign Track
            </label>
            <span
              className="brand-color float-right cursor-pointer"
              onClick={toggleDrawer("tracks", true)}
            >
              Manage Tracks
            </span>
            <Select
              value={assignedTrack}
              onChange={(value) => setAssignedTrack(value)}
              options={allTracks}
            />
            <br />
            <label htmlFor="title" className="event-label">
              Banner Image
            </label>
            <div className="addSession">
              <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                onChange={(files) => setBannerImg(files)}
                maxFileSize={9242880}
              />
            </div>
            <br />
            <label htmlFor="title" className="event-label">
              Session Description
            </label>
            <div className={classes.editor}>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={sessionDescription}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setsessionDescription(data);
                }}
              />
            </div>
            <br />
          </Col>
          <div className="pt-3 px-3">
            {sessionTitle &&
            sessionStartTime &&
            sessionEndTime &&
            assignedTrack &&
            selectedDate.value ? (
              <button className={classes.createBtn} onClick={addSession}>
                ADD
              </button>
            ) : (
              <button className={classes.createBtnDisabled} disabled>
                ADD
              </button>
            )}
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("addSession", false)}
              onKeyDown={toggleDrawer("addSession", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={drawer.filter}
        onClose={toggleDrawer("filter", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Filter By</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("filter", false)}
                onKeyDown={toggleDrawer("filter", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <label htmlFor="title">Track</label>
            <Select
              // value={selectedOption}
              // defaultValue={[options[2], options[3]]}
              onChange={onSelecthandleChange}
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Click here to select custom track"
            />
            <br />
            <label htmlFor="title">Speakers</label>
            <Select
              // value={selectedOption}
              // defaultValue={[options[2], options[3]]}
              onChange={onSelecthandleChange}
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Click here to select custom speakers"
            />
            <br />
            <label htmlFor="title">Custom Tags</label>
            <Select
              onChange={onSelecthandleChange}
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Click here to select custom tags"
            />
            <br />
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn}>Apply</button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("filter", false)}
              onKeyDown={toggleDrawer("filter", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={drawer.tracks}
        onClose={toggleDrawer("tracks", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Manage Tracks</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("tracks", false)}
                onKeyDown={toggleDrawer("tracks", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            {allTracks.map((track) => (
              <div
                className={` mb-2 ${
                  isEditTrack === track._id ? "createtrackhld" : "event-cardHld"
                }`}
                key={track._id}
              >
                <div
                  className={`d-flex align-items-center justify-content-between cursor-pointer ${
                    isEditTrack === track._id ? "" : "trackhld"
                  }`}
                >
                  {isEditTrack === track._id ? (
                    <>
                      <div
                        className="mr-2"
                        style={{
                          background: `${background}`,
                          width: "20px",
                          height: "20px",
                          borderRadius: "4px",
                        }}
                        onClick={() => setShowColorPicker(!showColorPicker)}
                      ></div>
                      <input
                        type="text"
                        placeholder=""
                        name="trackName"
                        className="mr-2"
                        placeholder="Enter Name"
                        value={createTrackTitle}
                        onChange={(e) => setCreateTrackTitle(e.target.value)}
                      />
                      {createTrackTitle ? (
                        <span
                          onClick={createNewTrack}
                          className="cursor-pointer"
                        >
                          <i
                            className="mdi mdi-checkbox-marked mr-2 brand-color"
                            style={{ fontSize: "24px" }}
                          ></i>
                        </span>
                      ) : (
                        <span className="cursor-pointer">
                          <i
                            className="mdi mdi-checkbox-marked"
                            style={{
                              fontSize: "24px",
                              color: "rgba(0, 0, 0, 0.26)",
                            }}
                          ></i>
                        </span>
                      )}
                      <span
                        className="cursor-pointer"
                        onClick={() => (
                          setIsEditTrack(""), setCreateTrackTitle("")
                        )}
                      >
                        <i
                          className="mdi mdi-close-box-outline brand-color"
                          style={{ fontSize: "24px" }}
                        ></i>
                      </span>
                    </>
                  ) : (
                    <>
                      <div className="d-flex align-items-center">
                        <i
                          className="mdi mdi-drag-vertical mr-2"
                          style={{ fontSize: "20px" }}
                        ></i>
                        <div
                          className="colorIndicator"
                          style={{ backgroundColor: `${track.colorCode}` }}
                        ></div>
                        <div className="ml-2">
                          <p className="mb-0">{track.title}</p>
                          {/* <span>6 sessions</span> */}
                        </div>
                      </div>
                      <div className="iconsHld">
                        <span
                          style={{ fontSize: "24px" }}
                          onClick={() => (
                            setIsEditTrack(track._id),
                            setCreateTrackTitle(track.title),
                            setBgColor(track.colorCode)
                          )}
                        >
                          <i className="mdi mdi-pencil-box-outline brand-color"></i>
                        </span>
                        <span
                          style={{ fontSize: "24px" }}
                          onClick={() => deleteTrack(track)}
                        >
                          <i className="mdi mdi-delete brand-color"></i>
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            ))}
            {isCreateTrack && (
              <div className="d-flex align-items-center createtrackhld">
                <div
                  className="mr-2"
                  style={{
                    background: `${background}`,
                    width: "20px",
                    height: "20px",
                    borderRadius: "4px",
                  }}
                  onClick={() => setShowColorPicker(!showColorPicker)}
                ></div>
                <input
                  type="text"
                  placeholder=""
                  name="trackName"
                  className="mr-2"
                  placeholder="Enter Name"
                  value={createTrackTitle}
                  onChange={(e) => setCreateTrackTitle(e.target.value)}
                />
                {createTrackTitle ? (
                  <span onClick={createNewTrack} className="cursor-pointer">
                    <i
                      className="mdi mdi-checkbox-marked mr-2 brand-color"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </span>
                ) : (
                  <span className="cursor-pointer">
                    <i
                      className="mdi mdi-checkbox-marked"
                      style={{ fontSize: "24px", color: "rgba(0, 0, 0, 0.26)" }}
                    ></i>
                  </span>
                )}
                <span
                  className="cursor-pointer"
                  onClick={() => (
                    setIsCreateTrack(false), setCreateTrackTitle("")
                  )}
                >
                  <i
                    className="mdi mdi-close-box-outline brand-color"
                    style={{ fontSize: "24px" }}
                  ></i>
                </span>
              </div>
            )}
            {showColorPicker && (
              <div className="" ref={wrapperRef}>
                <SketchPicker
                  color={background}
                  onChangeComplete={handleChangeComplete}
                />
              </div>
            )}
            <br />
            <button className="event-outlined-btn" onClick={createTrackLayout}>
              <i className="mdi mdi-plus-circle-outline mr-1"></i> ADD TRACK
            </button>
            <br />
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn}>ADD</button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("tracks", false)}
              onKeyDown={toggleDrawer("tracks", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
      <Dialog
        open={openSessionDel.show}
        onClose={() => setopenSessionDel({ ...openSessionDel, show: false })}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong>Events deleted once can't be retrieved.</strong>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete Events"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setopenSessionDel({ ...openSessionDel, show: false })
            }
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deleteSession}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </EventLayout>
  );
}
