import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import MetisMenu from "metismenujs/dist/metismenujs";
import { useSelector } from "react-redux";
import logo from "../assets/images/1.png";
import { useHistory, useLocation } from "react-router";

const SideNavContent = ({ eventId }) => {
  const history = useHistory();
  let location = useLocation();
  var temp = location.pathname.split("/");
  var activeNav = temp[temp.length - 1];
  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu" id="side-menu">
          <li className="logo-lg m-2" onClick={()=>history.push("/")}>
              <img src={logo} alt="" className="img-fluid" />
          </li>

          <li>
            <NavLink
              to={`/event/${eventId}`}
              className="waves-effect side-nav-link-ref"
              exact
              activeStyle={{
                color: "#71b6f9",
              }}
              style={{color:`${activeNav === eventId ? "#71b6f9" : ""}`}}
            >
              <i className="mdi mdi-home"></i>
              <span> Overview </span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={`/event/${eventId}/sessions`}
              className="waves-effect side-nav-link-ref"
              exact
              activeStyle={{
                color: "#71b6f9",
              }}
              style={{color:`${temp.includes("sessions") ? "#71b6f9" : ""}`}}
            >
              <i className="mdi mdi-pen"></i>
              <span>Session</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/event/${eventId}/people`}
              className="waves-effect side-nav-link-ref"
              exact
              activeStyle={{
                color: "#71b6f9",
              }}
            >
              <i className="mdi mdi-bell"></i>
              <span>People</span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={`/event/${eventId}/virtual-booth`}
              className="waves-effect side-nav-link-ref"
              exact
              activeStyle={{
                color: "#71b6f9",
              }}
              style={{color:`${temp.includes("virtual-booth") ? "#71b6f9" : ""}`}}
            >
              <i className="mdi mdi-monitor-multiple"></i>
              <span> Virtual Booths </span>
            </NavLink>
          </li>

          <li>
            <NavLink
              to={`/event/${eventId}/sponsors`}
              className="waves-effect side-nav-link-ref"
              exact
              activeStyle={{
                color: "#71b6f9",
              }}
              style={{color:`${temp.includes("sponsors") ? "#71b6f9" : ""}`}}
            >
              <i className="mdi mdi-monitor-multiple"></i>
              <span> Sponsors </span>
            </NavLink>
          </li>

          <li>
            <NavLink
              className="waves-effect side-nav-link-ref"
              to={`/event/${eventId}/branding`}
              exact
              activeStyle={{
                color: "#71b6f9",
              }}
            >
              <i className="mdi mdi-pen"></i>
              Branding
            </NavLink>
          </li>

          <li>
            <NavLink
              className="waves-effect side-nav-link-ref"
              to={`/event/${eventId}/form-popup`}
              exact
              activeStyle={{
                color: "#71b6f9",
              }}
            >
              <i className="mdi mdi-pen"></i>
              Form popup
            </NavLink>
          </li>

          {/* <li>
            <NavLink
              to={`/event/${eventId}/meeting`}
              className="waves-effect side-nav-link-ref"
              exact
              activeStyle={{
                color: "#71b6f9",
              }}
            >
              <i className="mdi mdi-monitor-multiple"></i>
              <span> Meetings </span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/event/${eventId}/engagement`}
              className="waves-effect side-nav-link-ref"
              exact
              activeStyle={{
                color: "#71b6f9",
              }}
            >
              <i className="mdi mdi-monitor-multiple"></i>
              <span> Engagement </span>
            </NavLink>
          </li> */}
        </ul>
      </div>
      <div className="clearfix"></div>
    </React.Fragment>
  );
};

const UserProfile = () => {
  const user = useSelector((store) => store.user.user);

  return (
    <React.Fragment>
      <div className="user-box text-center">
        <img
          src={user.Picture}
          alt="user-img"
          title="Nik Patel"
          className="rounded-circle img-thumbnail avatar-lg"
        />
        <UncontrolledDropdown>
          <DropdownToggle
            caret
            tag="a"
            className="text-dark dropdown-toggle h5 mt-2 mb-1 d-block"
          >
            {user.FName}&nbsp;{user.LName}
          </DropdownToggle>
          <DropdownMenu className="user-pro-dropdown">
            <DropdownItem>
              <i className="fe-user mr-1"></i>
              <span>My Account</span>
            </DropdownItem>
            <DropdownItem>
              <i className="fe-settings mr-1"></i>
              <span>Settings</span>
            </DropdownItem>
            <DropdownItem>
              <i className="fe-lock mr-1"></i>
              <span>Lock Screen</span>
            </DropdownItem>
            <DropdownItem>
              <i className="fe-log-out mr-1"></i>
              <span>Logout</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        <p className="text-muted">{user.Role}</p>
      </div>
    </React.Fragment>
  );
};

class EventSidebar extends Component {
  constructor(props) {
    super(props);
    this.handleOtherClick = this.handleOtherClick.bind(this);
    this.initMenu = this.initMenu.bind(this);
  }

  /**
   * Bind event
   */
  componentWillMount = () => {
    document.addEventListener("mousedown", this.handleOtherClick, false);
  };

  /**
   *
   */
  componentDidMount = () => {
    this.initMenu();
  };

  /**
   * Component did update
   */
  componentDidUpdate = (prevProps) => {
    if (this.props.isCondensed !== prevProps.isCondensed) {
      if (prevProps.isCondensed) {
        document.body.classList.remove("sidebar-enable");
        document.body.classList.remove("enlarged");
      } else {
        document.body.classList.add("sidebar-enable");
        const isSmallScreen = window.innerWidth < 768;
        if (!isSmallScreen) {
          document.body.classList.add("enlarged");
        }
      }

      this.initMenu();
    }
  };

  /**
   * Bind event
   */
  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleOtherClick, false);
  };

  /**
   * Handle the click anywhere in doc
   */
  handleOtherClick = (e) => {
    if (this.rightBarNodeRef && this.rightBarNodeRef.contains(e.target)) return;
    // else hide the menubar
    document.body.classList.remove("sidebar-enable");
  };

  /**
   * Init the menu
   */
  initMenu = () => {
    // render menu
    new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      // console.log("path", this.props.location.pathname);
      // if (this.props.location.pathname === links[i].pathname) {
      // 	matchingMenuItem = links[i];
      // 	break;
      // }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("in");
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3.parentElement;
        if (parent4) parent4.classList.add("in");
        const parent5 = parent4.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }
  };

  render() {
    const isCondensed = this.props.isCondensed || false;
    const eventData = this.props.eventData;
    return (
      <React.Fragment>
        <div
          className="left-side-menu event-left-side-menu"
          ref={(node) => (this.menuNodeRef = node)}
        >
          {!isCondensed && (
            <PerfectScrollbar>
              <SideNavContent eventId={eventData._id} />
            </PerfectScrollbar>
          )}
          {isCondensed && <UserProfile /> && <SideNavContent />}
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  eventData: state.events.event,
});
export default connect(mapStateToProps)(EventSidebar);
