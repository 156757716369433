import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EasyMDE from 'easymde';
import QuestionSetService from "../../services/mocks/QuestionSetService";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        // textAlign: "center",
        color: theme.palette.text.secondary,
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 400,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 420,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UpdateQuestionSetNew(props) {
    const otherModuleReferences = [
        {
            key: 'href1',
            value: ''
        },
        {
            key: 'href2',
            value: ''
        },
        {
            key: 'href3',
            value: ''
        },
    ];

    const classes = useStyles();
    const history = useHistory();
    let MDE;
    const [fullscreen, setFullsreen] = useState(false);
    const [messageData, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);

    const [questionSet, setQuestionSet] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState('');
    const [duration, setDuration] = useState(0);
    const [difficulty, setDifficulty] = useState("");
    const [qType, setQType] = useState("");
    const [onSubmit, setOnSubmit] = useState(false);
    const [shortDescription, setShortDescription] = useState('');
    const [hrefs, setHrefs] = useState(otherModuleReferences);
    const [premium, setPremium] = useState(false);

    const getQuestionSet = async () => {
        try {
            const response = await QuestionSetService.FetchQuestionSetBySlug(props.match.params.questionSetSlug)
            if (response) {
                setQuestionSet(response);
                setTitle(response.title);
                setDescription(response.description);
                setShortDescription(response.short_description ? response.short_description : '');
                setDuration(response.duration);
                setDifficulty(response.difficulty);
                setQType(response.questionSetType)
                setPremium(response.premium)
                // setHrefs(response.referenced_hrefs.map((href, index) => {
                //     return {
                //         key: `href${index + 1}`,
                //         value: href
                //     }
                // }));
                MDE.value(response.description);
            } else {
                setErrorStatus(true);
                setErrorMessage('Exception in creating new Question Set!');
                setStatusLoader(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getQuestionSet();
        MDE = new EasyMDE({
            element: document.getElementById('body'),
            autoDownloadFontAwesome: true,
            autofocus: true,
            status: false,
            singleLineBreaks: false,
            onToggleFullScreen: (opend) => setFullsreen(opend),
            initialValue: description
        });
        MDE.codemirror.on('change', () => {
            setDescription(MDE.value())
        });
    }, []);

    const handleClose = () => {
        setStatus(!status);
    };

    const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };
    const handleLoader = () => {
        setStatusLoader(!statusLoader);
    };

    const UpdateQuestionSet = async () => {
        try {
            setOnSubmit(true);
            if (title === "" || shortDescription === "" || parseInt(duration) === 0 || difficulty === "" || qType === "") {
                setErrorStatus(true);
                setErrorMessage("Field Missing! All Feild Required !!");
            } else {
                try {
                    // const referenced_hrefs = hrefs.filter((href) => href.value === "");
                    // if(referenced_hrefs.length >= 1){
                    //     setErrorStatus(true);
                    //     setErrorMessage("Field Missing! All Feild Required !!");
                    //     return;
                    // }
                    const updatingQuestionSet = {
                        title: title,
                        description: description,
                        short_description: shortDescription,
                        duration: duration, //in seconds
                        difficulty: difficulty,
                        questionSetType: qType,
                        premium: premium
                        // referenced_hrefs: hrefs.map((href) => href.value)
                    };
                    const response = await QuestionSetService.UpdateQuestionSet(questionSet._id, updatingQuestionSet);
                    if (response) {
                        setStatus(true);
                        setMessage('Updated Successfully!');
                        setStatusLoader(false);
                        setTimeout(() => {
                            history.push("/getAllQuestionSets");
                        }, 2500);
                    } else {
                        setErrorStatus(true);
                        setErrorMessage('Exception in creating new Question Set!');
                        setStatusLoader(false);
                    }
                } catch (error) {
                    setErrorStatus(true);
                    setErrorMessage(error);
                    setStatusLoader(false);
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <AuthLayout isMDEFullScreen={fullscreen && fullscreen === true ? true : false}>

            <Header name="Update Question Set" />

            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        {status && (
                            <Snackbar
                                open={status}
                                autoHideDuration={15000}
                                onClose={handleClose}
                            >
                                <Alert onClose={handleClose} severity="success">
                                    {messageData}
                                </Alert>
                            </Snackbar>
                        )}


                        {errorStatus && (
                            <Snackbar
                                open={errorStatus}
                                autoHideDuration={15000}
                                onClose={handleCloseError}
                            >
                                <Alert
                                    onClose={handleCloseError}
                                    severity="error"
                                >
                                    {errorMessage}
                                </Alert>
                            </Snackbar>
                        )}


                        {statusLoader && (
                            <Snackbar
                                open={statusLoader}
                                // autoHideDuration={95000}
                                onClose={handleLoader}
                            >
                                <Alert onClose={handleLoader} severity="info">
                                    <Loader />
                                </Alert>
                            </Snackbar>
                        )}


                        <Paper className={classes.paper}>
                            <h2>Title</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="title"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth={true}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />

                            <br />
                            <br />
                            <h2>Description</h2>

                            <textarea id="body" name="body" value={MDE?.value()} />

                            <br />
                            <br />

                            <h2>Short Description</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Short Description"
                                multiline
                                rows={2}
                                variant="outlined"
                                fullWidth={true}
                                value={shortDescription}
                                onChange={(e) => {
                                    setShortDescription(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <h2>Difficulty</h2>

                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Difficulty
                            </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={difficulty}
                                    onChange={(e) => {
                                        setDifficulty(e.target.value);
                                    }}
                                    label="Difficulty"
                                >
                                    <MenuItem value="BEGINNER">
                                        Beginner
                                </MenuItem>
                                    <MenuItem value="INTERMEDIATE">
                                        Intermediate
                                </MenuItem>
                                    <MenuItem value="ADVANCED">
                                        Advanced
                                </MenuItem>
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Type
								</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={qType}
                                    onChange={(e) => {
                                        setQType(e.target.value);
                                    }}
                                    label="QType"
                                >
                                    <MenuItem value="cms">
                                        CMS
                                    </MenuItem>
                                    <MenuItem value="bootcamp">
                                        Bootcamp
                                </MenuItem>
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            <h2>Duration (in seconds)</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="duration (in seconds)"
                                type='number'
                                rows={1}
                                variant="outlined"
                                fullWidth={true}
                                value={duration}
                                onChange={(e) => {
                                    setDuration(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            {/* <h2>HREFs (References of Other Module's)</h2>

                            {
                                hrefs.map((href, index) => {
                                   return(
                                        <TextField
                                        style={{
                                            marginTop: '15px'
                                        }}
                                        id="outlined-multiline-static"
                                        label={href.key}
                                        multiline
                                        rows={1}
                                        variant="outlined"
                                        fullWidth={true}
                                        value={href.value}
                                        onChange={(e) => {
                                            let newHrefs = hrefs.slice();
                                            let newHref = {
                                                key: href.key,
                                                value: e.target.value
                                            };
                                            newHrefs[index] = newHref;
                                            setHrefs(newHrefs);
                                        }}
                                    />
                                   )
                                })
                            }

                            <br />
                            <br /> */}
                            <h2>Premium</h2>
                            <Switch
                                checked={premium}
                                onChange={()=>setPremium(!premium)}
                                name="checkedA"
                                inputProps={{ "aria-label": "secondary checkbox" }}
                            />

                            <br />
                            <br />

                            <Button disabled={onSubmit}
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                    backgroundColor: "rgb(250, 53, 88)",
                                }}
                                onClick={() => UpdateQuestionSet()}
                            >
                                Update Question Set
                        </Button>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        </AuthLayout>
    );
}
