import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API } from "../../backend";
import Loader from "../../components/Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import DownloadLoader from "../DownloadUserData/Loader";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { FormControl, InputLabel } from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router";
import DialogContentText from "@material-ui/core/DialogContentText";
import Checkbox from "@material-ui/core/Checkbox";

const moment = require("moment");
const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Heading,
    Indent,
    Table,
    TableToolbar,
    Link,
    List,
    MediaEmbed,
    CodeBlock,
    ImageResize,
    ImageToolbar,
    ImageStyle,
  ],
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "mediaEmbed",
    "insertTable",
    "codeBlock",
  ],
  mediaEmbed: {
    previewsInData: true
  },
  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },

  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  codeBlock: {
    languages: [
      // Do not render the CSS class for the plain text code blocks.
      { language: "plaintext", label: "Plain text", class: "" },

      // Use the "php-code" class for PHP code blocks.
      { language: "php", label: "PHP", class: "php-code" },

      // Use the "js" class for JavaScript code blocks.
      // Note that only the first ("js") class will determine the language of the block when loading data.
      {
        language: "javascript",
        label: "JavaScript",
        class: "js javascript js-code",
      },

      // Python code blocks will have the default "language-python" CSS class.
      { language: "python", label: "Python" },
    ],
  },
};
const useStyles = makeStyles({
  root: {
    minHeight: 206,
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardbody: {
    padding: "0 10px 10px 10px",
    borderRadius: "8px",
  },
  eventbanner: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "100px",
    objectFit: "cover",
    maxWidth: "100%",
  },
  eventName: {
    fontWeight: 500,
    display: "-webkit-box",
    overflow: "hidden",
    "-mozBoxOrient": "vertical",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": 2,
  },
  moreOptions: {
    background: "#ffffff",
    position: "absolute",
    right: "10px",
    top: "6px",
    borderRadius: "50px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f44d5e",
    fontSize: "20px",
  },
  optionMenu: {
    boxShadow: "none",
    border: "1px solid #dbdbdb",
    "& div": {
      boxShadow: "none",
      border: "1px solid #dbdbdb",
    },
  },
  optionMenuItem: {
    boxShadow: "none",
  },
  createEventDrawer: {
    width: "40%",
  },
  creatEventHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    overflowX: "hidden",
  },
  spacingCreateEvent: {
    padding: "10px 30px 10px 30px",
    height: "calc(100vh - 142px)",
    overflow: "auto",
  },
  eventType: {
    width: "50%",
    textAlign: "center",
    color: "gray",
    marginBottom: 0,
    padding: "8px",
  },
  border: {
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
  },
  selectedEventType: {
    background: "#f44d5e",
    color: "#ffffff",
    width: "50%",
    textAlign: "center",
    marginBottom: 0,
    padding: "8px",
  },
  textField: {
    marginRight: 10,
    border: "1px solid rgba(53, 53, 53, 0.17)",
    borderRadius: "4px",
    color: "#353535",
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
});
var FileSaver = require("file-saver");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AllEvents() {
  const events = useSelector((store) => store.events.events);
  const dispatch = useDispatch();
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [eventType, setEventType] = React.useState("");
  const [eventTypeValue, setEventTypeValue] = React.useState("");
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [eventTiltle, setEventTitle] = React.useState("");
  const [eventDescription, setEventDescription] = React.useState("");
  const [eventStartDate, setEventStartDate] = React.useState("");
  const [eventEndDate, setEventEndDate] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [linkedIn, setLinkedIn] = React.useState("");
  const [twitter, setTwitter] = React.useState("");
  const [fb, setfb] = React.useState("");
  const [shortDescription, setEventShortDescription] = React.useState("");
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [instagram, setInstagram] = React.useState("");
  const [eventOptions, setEventOptions] = React.useState("");
  const [IsEdit, setIsEdit] = React.useState("");
  const [scroll, setScroll] = React.useState("paper");
  const [openEventDel, setOpenEventDel] = React.useState({
    show: false,
    eventData: {},
  });
  const [check, setCheck] = React.useState(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setEventOptions("");
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const handleClick = (e, event) => {
    e.stopPropagation();
    setEventOptions(event._id);
  };
  const toggleDrawer = (anchor, open, modalType) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (modalType === "new") {
      setIsEdit("");
      resetAllData();
    }
    setState({ ...state, [anchor]: open });
  };

  const handleChange = (event) => {
    setEventTypeValue(event.target.value);
    event.target.value !== "Other"
      ? setEventShortDescription(event.target.value)
      : setEventShortDescription("");
  };

  const createEvent = () => {
    const createEventBody = {
      title: eventTiltle,
      description: eventDescription,
      featured: true,
      publish: true,
      eventStartDate: eventStartDate,
      eventEndDate: eventEndDate,
      shortDescription: shortDescription,
      typeOfEvent: eventType,
    };
    if (website) {
      createEventBody.website = website;
    }
    if (fb) {
      createEventBody.fb = fb;
    }
    if (website) {
      createEventBody.twitter = twitter;
    }
    if (website) {
      createEventBody.Instagram = instagram;
    }
    if (website) {
      createEventBody.linkedIn = linkedIn;
    }
    if (IsEdit === "") {
      axios
        .post(`${BOOTCAMP_API}/events/createEvents`, createEventBody)
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setState({ ...state, ["right"]: false });
            setMessageData(response.data.result.message);
            resetAllData();
            getAllEvents();
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    } else {
      axios
        .put(`${BOOTCAMP_API}/events/updateEvents/${IsEdit}`, createEventBody)
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setState({ ...state, ["right"]: false });
            setMessageData(response.data.result.message);
            resetAllData();
            getAllEvents();
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    }
  };

  const onEditEvent = (e, event) => {
    e.stopPropagation();
    setEventOptions("");
    setIsEdit(event._id);
    setState({ ...state, ["right"]: true });
    setEventTitle(event.title);
    setEventType(event.typeOfEvent);
    setEventTypeValue(event.shortDescription);
    setEventDescription(event.description);
    setEventShortDescription(event.shortDescription);
    setEventStartDate(event.eventStartDate);
    setEventEndDate(event.eventEndDate);
    setWebsite(event.website);
    setfb(event.fb);
    setInstagram(event.instagram);
    setLinkedIn(event.linkedIn);
    setTwitter(event.twitter);
  };
  const resetAllData = () => {
    setEventTitle("");
    setEventType("");
    setEventTypeValue("");
    setEventShortDescription("");
    setEventStartDate("");
    setEventEndDate("");
    setWebsite("");
    setfb("");
    setInstagram("");
    setLinkedIn("");
    setTwitter("");
    setEventDescription("");
  };

  const onDeleteEvent = (e, event) => {
    e.stopPropagation();
    setEventOptions("");
    setOpenEventDel({ show: true, eventData: event });
    // Add Confirmation Modal to delete
  };
  const deleteEvent = () => {
    axios
      .delete(
        `${BOOTCAMP_API}/events/deleteSpecificEvent/${openEventDel.eventData._id}`
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          getAllEvents();
          setOpenEventDel({ ...openEventDel, show: false });
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const list = (anchor) => (
    <div role="presentation">
      <div className={classes.creatEventHeader}>
        <Col lg={9}>
          {IsEdit === "" ? <h4>Create Event</h4> : <h4>Edit the Event</h4>}
        </Col>
        <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
          <i
            className="mdi mdi-close"
            onClick={toggleDrawer(
              anchor,
              false,
              IsEdit === "" ? "new" : "edit"
            )}
            onKeyDown={toggleDrawer(
              anchor,
              false,
              IsEdit === "" ? "new" : "edit"
            )}
          ></i>
        </Col>
      </div>
      <Col lg={12} className={classes.spacingCreateEvent}>
        <FormControl className={classes.formControl}>
          <label htmlFor="title">
            Event Title
            <span style={{ color: "red", verticalAlign: "" }}>*</span>
          </label>
          <input
            id="title"
            type="text"
            className="form-control"
            placeholder="Example: Fireside chat with Panellist Name on AI"
            value={eventTiltle}
            onChange={(e) => setEventTitle(e.target.value)}
          />
        </FormControl>
        <br />
        <br />
        <label htmlFor="title">Event Description</label>
        <div className={classes.editor}>
          <CKEditor
            editor={ClassicEditor}
            config={editorConfiguration}
            data={eventDescription}
            onInit={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEventDescription(data);
              console.log("data composed");
              console.log({ event, editor, data });
            }}
            onBlur={(event, editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              console.log("Focus.", editor);
            }}
          />
        </div>
        <br />
        <Divider />
        <br />
        <h4>
          Event Type <span style={{ color: "red", verticalAlign: "" }}>*</span>
        </h4>
        <p>Choose the event type that you are interested in hosting.</p>
        <div
          className="d-flex align-items-center"
          style={{
            background: "#f6f6f6",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <p
            className={`${
              eventType === "internal"
                ? classes.selectedEventType
                : classes.eventType
            }`}
            onClick={() => setEventType("internal")}
          >
            Internal
          </p>
          <p
            className={`${
              eventType === "external"
                ? classes.selectedEventType
                : classes.eventType
            }`}
            onClick={() => setEventType("external")}
          >
            External
          </p>
        </div>
        {eventType && (
          <>
            <h4>Select the Event</h4>
            <div className={classes.border}>
              {eventType === "internal" ? (
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    className="p-2"
                    value={eventTypeValue}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Corporate All-Hands"
                      control={<Radio />}
                      label="Corporate All-Hands"
                    />
                    <FormControlLabel
                      value="Employee Onboarding"
                      control={<Radio />}
                      label="Employee Onboarding"
                    />
                    <FormControlLabel
                      value="Employee Training"
                      control={<Radio />}
                      label="Employee Training"
                    />
                    <FormControlLabel
                      value="Hackathon"
                      control={<Radio />}
                      label="Hackathon"
                    />
                    <FormControlLabel
                      value="Leadership Meeting"
                      control={<Radio />}
                      label="Leadership Meeting"
                    />
                    <FormControlLabel
                      value="Office Party"
                      control={<Radio />}
                      label="Office Party"
                    />
                    <FormControlLabel
                      value="Sales Kickoff"
                      control={<Radio />}
                      label="Sales Kickoff"
                    />
                    <FormControlLabel
                      value="Team Building"
                      control={<Radio />}
                      label="Team Building"
                    />
                    <FormControlLabel
                      value="Virtual Town Hall"
                      control={<Radio />}
                      label="Virtual Town Hall"
                    />
                    <FormControlLabel
                      value="Workshop"
                      control={<Radio />}
                      label="Workshop"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    className="p-2"
                    value={eventTypeValue}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Conferences"
                      control={<Radio />}
                      label="Conferences"
                    />
                    <FormControlLabel
                      value="Customer Advisory Boardg"
                      control={<Radio />}
                      label="Customer Advisory Board"
                    />
                    <FormControlLabel
                      value="Exhibitions"
                      control={<Radio />}
                      label="Exhibitions"
                    />
                    <FormControlLabel
                      value="External Job Fair "
                      control={<Radio />}
                      label="External Job Fair "
                    />
                    <FormControlLabel
                      value="Lead Generation Activities"
                      control={<Radio />}
                      label="Lead Generation Activities"
                    />
                    <FormControlLabel
                      value="Marketing Event"
                      control={<Radio />}
                      label="Marketing Event"
                    />
                    <FormControlLabel
                      value="Product Launch"
                      control={<Radio />}
                      label="Product Launch"
                    />
                    <FormControlLabel
                      value="Roadshow"
                      control={<Radio />}
                      label="Team Building"
                    />
                    <FormControlLabel
                      value="User Group Meetings"
                      control={<Radio />}
                      label="User Group Meetings"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </div>
          </>
        )}
        <br />
        {eventTypeValue === "Other" && (
          <>
            <FormControl className={classes.formControl}>
              <label htmlFor="title">
                What You Would Like To Accomplish Through This Event?
                <span style={{ color: "red", verticalAlign: "" }}>*</span>
              </label>
              <input
                id="title"
                type="text"
                className="form-control"
                placeholder="Example: Annual Appreciation"
                value={shortDescription}
                onChange={(e) => setEventShortDescription(e.target.value)}
              />
            </FormControl>
            <br />
          </>
        )}
        <br />
        <Divider />
        <br />
        <div className="d-flex">
          <div className="col-6 pl-0">
            <label htmlFor="title">
              Event Start Date
              <span style={{ color: "red", verticalAlign: "" }}>*</span>
            </label>
            <TextField
              id="datetime-local"
              type="datetime-local"
              className={classes.textField}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: "#353535",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                input: "#353535",
                label: "#353535",
              }}
              value={eventStartDate}
              onChange={(e) => setEventStartDate(e.target.value)}
              disabled={IsEdit === "" ? false : true}
            />
          </div>
          <div className="col-6">
            <label htmlFor="title">
              Event End Date
              <span style={{ color: "red", verticalAlign: "" }}>*</span>
            </label>
            <TextField
              id="datetime-local"
              type="datetime-local"
              className={classes.textField}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: "#353535",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                input: "#353535",
                label: "#353535",
              }}
              value={eventEndDate}
              onChange={(e) => setEventEndDate(e.target.value)}
              disabled={IsEdit === "" ? false : true}
            />
          </div>
        </div>

        <br />
        <br />
        <FormControl className={classes.formControl}>
          <label htmlFor="title">Social Engagement Links</label>
          <input
            id="title"
            type="text"
            className="form-control mb-2"
            placeholder="https://website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <input
            id="title"
            type="text"
            className="form-control mb-2"
            placeholder="https://linkedIn"
            value={linkedIn}
            onChange={(e) => setLinkedIn(e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <input
            id="title"
            type="text"
            className="form-control mb-2"
            placeholder="https://twitter"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <input
            id="title"
            type="text"
            className="form-control mb-2"
            placeholder="https://fb"
            value={fb}
            onChange={(e) => setfb(e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <input
            id="title"
            type="text"
            className="form-control mb-2"
            placeholder="https://insta"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
          />
        </FormControl>
      </Col>
      <div className="pt-3 px-3">
        {eventTiltle &&
        eventDescription &&
        eventStartDate &&
        eventEndDate &&
        eventType &&
        shortDescription ? (
          <>
            <button className={classes.createBtn} onClick={createEvent}>
              {IsEdit === "" ? "CREATE" : "SAVE"}
            </button>
          </>
        ) : (
          <button
            className={classes.createBtn}
            disabled
            style={{
              background: "rgba(0, 0, 0, 0.12)",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              color: "rgba(0, 0, 0, 0.26)",
            }}
          >
            CREATE
          </button>
        )}
        <button
          className={classes.cancelBtn}
          onClick={toggleDrawer(anchor, false, IsEdit === "" ? "new" : "edit")}
          onKeyDown={toggleDrawer(
            anchor,
            false,
            IsEdit === "" ? "new" : "edit"
          )}
        >
          CANCEL
        </button>
      </div>
    </div>
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onEditClick = (e) => {
    e.stopPropagation();
    handleClose();
  };

  const handleCloseEnd = () => {
    setEnd(!end);
  };
  const getAllEvents = () => {
    axios
      .get(`${BOOTCAMP_API}/events/getAllEvents`)
      .then((response) => {
        dispatch({
          type: "GET_ALL_EVENTS",
          payload: response.data.result.data,
        });
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllEvents();
  }, []);

  return (
    <AuthLayout>
      <Header name="All Events" />
      {messageData && (
        <Snackbar
          open={successMessage}
          onClose={() => setSuccessMessage(false)}
        >
          <>
            <Alert onClose={() => setSuccessMessage(false)} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar open={errorStatus} onClose={() => setErrorStatus(false)}>
          <>
            <Alert onClose={() => setErrorStatus(false)} severity="error">
              {errorMessage}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        <Col lg={8}>
          <form className="app-search">
            <div className="app-search-box">
              <div className="input-group col-5 pl-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search By Event name"
                />
                <div className="input-group-append">
                  <button className="btn" type="submit">
                    <i className="fe-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Col>
        <Col lg={4} className="text-right">
          <Button
            variant="contained"
            color="primary"
            onClick={toggleDrawer("right", true, "new")}
          >
            Create New Event
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        {Object.keys(events).length > 0 ? (
          events.map((event) => (
            <Col lg={4} key={event._id}>
              <Card
                className={classes.root}
                onClick={() => history.push(`/event/${event._id}`)}
              >
                <img
                  src={event?.carouselImages[0]?.imageLink}
                  alt=""
                  className={classes.eventbanner}
                />
                <CardContent className={classes.cardbody}>
                  <h4 className={classes.eventName}>{event.title}</h4>
                  <p className="mb-0">
                    <i className="mdi mdi-calendar-blank mr-1"></i>
                    {moment(event.eventStartDate).format("D MMM, YYYY")}
                  </p>
                </CardContent>
                <span
                  className={classes.moreOptions}
                  onClick={(e) => handleClick(e, event)}
                >
                  <i className="mdi mdi-dots-vertical"></i>
                </span>
                {eventOptions === event._id && (
                  <div className="eventOptionHld py-1" ref={wrapperRef}>
                    <p className="mb-0" onClick={(e) => onEditEvent(e, event)}>
                      <i className="mdi mdi-pencil mr-2"></i>Edit Event
                    </p>
                    <p
                      className="mb-0"
                      onClick={(e) => onDeleteEvent(e, event)}
                    >
                      <i className="mdi mdi-delete mr-1"></i>Delete Event
                    </p>
                  </div>
                )}
              </Card>
            </Col>
          ))
        ) : (
          <div>
            {/* <Loader /> */}
            No Events
          </div>
        )}
      </Row>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false, "new")}
        classes={{ paper: classes.createEventDrawer }}
      >
        {list("right")}
      </Drawer>
      <Dialog
        open={openEventDel.show}
        onClose={() => setOpenEventDel({ ...openEventDel, show: false })}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong>Events deleted once can't be retrieved.</strong>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete Events"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenEventDel({ ...openEventDel, show: false })}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deleteEvent}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </AuthLayout>
  );
}
