import React, { useState, useEffect } from "react";
import axios, { post } from "axios";
import { API } from "../../backend";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
// import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
// import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { DropzoneArea } from "material-ui-dropzone";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useSelector, useDispatch } from "react-redux";

const { inspect } = require("util");

const editorConfiguration = {
	plugins: [
		Essentials,
		Bold,
		Italic,
		Paragraph,
		Image,
		ImageUpload,
		Base64UploadAdapter,
		Heading,
		Indent,
		Table,
		TableToolbar,
		Link,
		List,
		MediaEmbed,
		CodeBlock,
		ImageResize,
		ImageToolbar,
		ImageStyle,
	],
	toolbar: [
		"heading",
		"bold",
		"italic",
		"imageUpload",
		"link",
		"bulletedList",
		"numberedList",
		"indent",
		"outdent",
		"mediaEmbed",
		"insertTable",
		"codeBlock",
	],
	mediaEmbed: {
        previewsInData: true
    },
	table: {
		contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
	},

	image: {
		resizeOptions: [
			{
				name: "imageResize:original",
				label: "Original",
				value: null,
			},
			{
				name: "imageResize:50",
				label: "50%",
				value: "50",
			},
			{
				name: "imageResize:75",
				label: "75%",
				value: "75",
			},
		],
		toolbar: [
			"imageResize",
			"|",
			"imageStyle:full",
			"imageStyle:side",
			"|",
			"imageTextAlternative",
		],
	},

	link: {
		decorators: {
			openInNewTab: {
				mode: "manual",
				label: "Open in a new tab",
				attributes: {
					target: "_blank",
					rel: "noopener noreferrer",
				},
			},
		},
	},

	heading: {
		options: [
			{
				model: "paragraph",
				title: "Paragraph",
				class: "ck-heading_paragraph",
			},
			{
				model: "heading1",
				view: "h1",
				title: "Heading 1",
				class: "ck-heading_heading1",
			},
			{
				model: "heading2",
				view: "h2",
				title: "Heading 2",
				class: "ck-heading_heading2",
			},
			{
				model: "heading3",
				view: "h3",
				title: "Heading 3",
				class: "ck-heading_heading3",
			},
			{
				model: "heading4",
				view: "h4",
				title: "Heading 4",
				class: "ck-heading_heading4",
			},
			{
				model: "heading5",
				view: "h5",
				title: "Heading 5",
				class: "ck-heading_heading5",
			},
			{
				model: "heading6",
				view: "h6",
				title: "Heading 6",
				class: "ck-heading_heading6",
			},
		],
	},
	codeBlock: {
		languages: [
			// Do not render the CSS class for the plain text code blocks.
			{ language: "plaintext", label: "Plain text", class: "" },

			// Use the "php-code" class for PHP code blocks.
			{ language: "php", label: "PHP", class: "php-code" },

			// Use the "js" class for JavaScript code blocks.
			// Note that only the first ("js") class will determine the language of the block when loading data.
			{
				language: "javascript",
				label: "JavaScript",
				class: "js javascript js-code",
			},

			// Python code blocks will have the default "language-python" CSS class.
			{ language: "python", label: "Python" },
		],
	},
};

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	paper: {
		padding: theme.spacing(2),
		// textAlign: "center",
		color: theme.palette.text.secondary,
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 400,
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 420,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}));

const GreenCheckbox = withStyles({
	root: {
		color: green[400],
		"&$checked": {
			color: green[600],
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UpdatePractice(props) {
	const classes = useStyles();

	// const token = useSelector((store) => store.auth.token);

	// console.log("defult", axios.defaults.headers.common["Authorization"]);

	const [pname, setPname] = useState("");
	// const [hstart, setHstart] = useState("");
	// const [hend, setHend] = useState("");
	const [category, setPcategory] = useState("");
	// const [hsort, setHsort] = useState("");
	const [level, setPlevel] = useState("");
	let [poverview, setPoverview] = useState("");
	let [notebook_url, setNotebook_url] = useState("");
	// let [heval, setHeval] = useState("");
	// let [hprize, setHprize] = useState("");
	const [pb_image, setSelectedFile_1] = useState([]);
	const [ps_image, setSelectedFile_2] = useState([]);
	const [datasets, setSelectedDatasets] = useState([]);
	// const [metric_type, setMetric_type] = useState("");
	// const [require_pr_pu_lb, setRequire_pr_pu_lb] = useState(false);
	// const [glb_calculation, setGlb_calculation] = useState(false);
	// const [yruth_file, setSelectedYruth_file] = useState([]);
	// const [submission_threshold, setSubmission_threshold] = useState(0);
	// const [target_column, setTarget_column] = useState("");
	const [publish, setPublish] = useState(false);
	const [coming_soon, setComing_soon] = useState(false);
	// const [Sponsored, setSponsored] = useState(false);
	// const [sponsor_terms, setSponsor_terms] = useState("sponsor_terms");
	// const [sub_closed, setSub_closed] = useState(true);

	const [messageData, setMessage] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const [errorStatus, setErrorStatus] = useState(false);
	const [status, setStatus] = useState(false);
	const [statusLoader, setStatusLoader] = useState(false);
	const [practiceno, setPracticeNo] = useState("");

	const [hackathon, setHackathon] = useState({});
	// const [HREFName, SetHREFName] = useState({});

	const getAllPractice = () => {
		axios
			.get(`${API}/practice/${props.match.params.practiceId}`)
			.then((response) => {
				console.log(response.data.practice[0]);
				setPname(response.data.practice[0].HName);
				setPcategory(response.data.practice[0].HCategory);
				setPoverview(response.data.practice[0].HOverview);
				setPublish(response.data.practice[0].Publish);
				setSelectedFile_1(response.data.practice[0].HImage);
				console.log(response.data.practice[0].HImage);
				setSelectedFile_2(response.data.practice[0].SImage);
				setSelectedDatasets(response.data.practice[0].Dataset);
				setNotebook_url(response.data.practice[0].Notebook_url);
				setPlevel(response.data.practice[0].HLevel);
				setPracticeNo(response.data.practice[0].practice_number);
			})
			.catch((err) => console.log(err));
	};
	// console.log("hackthon", hackathons);
	useEffect(() => {
		getAllPractice();
	}, []);

	const handleClose = () => {
		setStatus(!status);
	};

	const handleCloseError = () => {
		setErrorStatus(!errorStatus);
	};
	const handleLoader = () => {
		setStatusLoader(!statusLoader);
	};

	const UpdateHackathon = () => {

		const formData = new FormData();

		if (
			pname === "" ||
			category === "" ||
			poverview === "" ||
			level === "" ||
			notebook_url === "" //||
			// !(Array.isArray(datasets) && datasets.length)
		) {
			setErrorStatus(true);
			setErrorMessage("Field Missing! All Feild Required !!");
		} else {
			// console.log("file in fileUpload", file);
			formData.append("pname", pname);
			formData.append("category", category);
			formData.append("notebook_url", notebook_url);
			formData.append("poverview", poverview);
			// formData.append("pb_image", pb_image[0]);
			// formData.append("ps_image", ps_image[0]);
			// formData.append("dataset", datasets[0]);
			// formData.append("coming_soon", coming_soon);
			formData.append("publish", publish);
			formData.append("level", level);
			const config = {
				headers: {
					"content-type": "multipart/form-data",
				},
			};
			console.log("formData");
			// inspect
			console.log(inspect(formData));

			setStatusLoader(true);
			console.log(
				"API request :",
				`${API}/update_practice/${props.match.params.practiceId}`
			);
			axios
				.put(
					`${API}/update_practice/${props.match.params.practiceId}`,
					null,
					{
						params:{
							"pname":pname,
							"category":category,
							"level":level,
							"poverview":poverview,
							"notebook_url":notebook_url,
							"publish":publish,
							"module_id":props.match.params.moduleId,
							"practiceno":practiceno
						}
					}
				)
				.then((response) => {
					console.log(response);

					if (response.data.error) {
						// setStatus(true);
						setErrorStatus(true);
						setErrorMessage(response.data.error);
						setStatusLoader(false);
					} else {
						setStatus(true);
						setMessage(response.data.message);
						setStatusLoader(false);
					}
				})
				.catch((error) => {
					// setStatus(true);
					setErrorStatus(true);
					setErrorMessage(error);
					setStatusLoader(false);
					console.log(error);
				});
		}
	};

	return (
		// <>
		<AuthLayout>
			<Header name="Update Practice" />
			<div className={classes.root}>
				<Grid container>
					{/* <Grid item xs={1}>
						<Paper
							className={classes.paper}
							style={{ display: "none" }}
						></Paper>
					</Grid> */}
					<Grid item xs={12}>
						{status && (
							<Snackbar
								open={status}
								autoHideDuration={15000}
								onClose={handleClose}
							>
								<Alert onClose={handleClose} severity="success">
									{messageData}
								</Alert>
							</Snackbar>
						)}
						{errorStatus && (
							<Snackbar
								open={errorStatus}
								autoHideDuration={15000}
								onClose={handleCloseError}
							>
								<Alert
									onClose={handleCloseError}
									severity="error"
								>
									{errorMessage}
								</Alert>
							</Snackbar>
						)}
						{statusLoader && (
							<Snackbar
								open={statusLoader}
								autoHideDuration={15000}
								onClose={handleLoader}
							>
								<Alert onClose={handleLoader} severity="info">
									<Loader />
								</Alert>
							</Snackbar>
						)}

						<Paper className={classes.paper}>
							<h2>Practice Name</h2>
							<TextField
								id="outlined-multiline-static"
								label="PName"
								multiline
								rows={4}
								variant="outlined"
								fullWidth={true}
								value={pname}
								onChange={(e) => {
									setPname(e.target.value);
								}}
							/>

							<br />
							<br />
							
							<h2>Practice Number</h2>
							<TextField
								id="outlined-multiline-static"
								label="PName"
								multiline
								rows={4}
								variant="outlined"
								fullWidth={true}
								value={practiceno}
								onChange={(e) => {
									setPracticeNo(e.target.value);
								}}
							/>

							<br />
							<br />

							<h2>Notebook Url</h2>
							<TextField
								id="outlined-multiline-static"
								label="Notebook Url"
								multiline
								rows={3}
								variant="outlined"
								fullWidth={true}
								value={notebook_url}
								onChange={(e) => {
									setNotebook_url(e.target.value);
								}}
							/>

							<br />
							<br />

							<h2>Practice Category</h2>
							<FormControl
								variant="outlined"
								className={classes.formControl}
							>
								<InputLabel id="demo-simple-select-outlined-label">
									Pcategory
								</InputLabel>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={category}
									onChange={(e) => {
										setPcategory(e.target.value);
									}}
									label="pcategory"
								>
									<MenuItem value="Hackathon">
										Hackathon
									</MenuItem>
									<MenuItem value="Practice">
										Practice
									</MenuItem>
									{/* <MenuItem value={30}>Thirty</MenuItem> */}
								</Select>
							</FormControl>

							<br />
							<br />

							<h2>Practice Level</h2>
							<FormControl
								variant="outlined"
								className={classes.formControl}
							>
								<InputLabel id="demo-simple-select-outlined-label">
									level
								</InputLabel>
								<Select
									labelId="demo-simple-select-outlined-label"
									id="demo-simple-select-outlined"
									value={level}
									onChange={(e) => {
										setPlevel(e.target.value);
									}}
									label="hcategory"
								>
									{/* <MenuItem value="All Levels">
										All Levels
									</MenuItem>
									<MenuItem value="All">All</MenuItem> */}
									<MenuItem value="Beginner">
										Beginner
									</MenuItem>
									<MenuItem value="Intermediate">
										Intermediate
									</MenuItem>
									<MenuItem value="Advanced">
										Advanced
									</MenuItem>
									{/* <MenuItem value={30}>Thirty</MenuItem> */}
								</Select>
							</FormControl>

							<br />
							<br />

							<h2>Practice Overview</h2>
							<CKEditor
								editor={ClassicEditor}
								config={editorConfiguration}
								data={poverview}
								onInit={(editor) => {
									// You can store the "editor" and use when it is needed.
									console.log(
										"Editor is ready to use!",
										editor
									);
								}}
								onChange={(event, editor) => {
									const data = editor.getData();
									setPoverview(data);
									console.log({ data });
								}}
								onBlur={(event, editor) => {
									console.log("Blur.", editor);
								}}
								onFocus={(event, editor) => {
									console.log("Focus.", editor);
								}}
							/>
							<br />
							<br />

							{/* <h2>Practice Dataset</h2>
							<DropzoneArea
								acceptedFiles={["application/*"]}
								onChange={(files) => setSelectedDatasets(files)}
								maxFileSize={300242880}
							/>

							<br />
							<h4>
								Dataset Link : <a href={datasets}>Datasets</a>{" "}
							</h4>

							<br />
							<br /> */}

							{/* <h2>Coming Soon</h2>
							<FormControlLabel
								control={
									<GreenCheckbox
										checked={coming_soon}
										onChange={() => {
											setComing_soon(!coming_soon);
										}}
										name="checkedG"
									/>
								}
								label="Coming Soon"
							/>

							<br />
							<br /> */}

							<h2>Publish</h2>
							<FormControlLabel
								control={
									<GreenCheckbox
										checked={publish}
										onChange={() => {
											setPublish(!publish);
										}}
										name="checkedG"
									/>
								}
								label="Publish"
							/>

							<br />
							<br />
							<br />
							<br />
							<br />
							<br />

							<Button
								variant="contained"
								color="primary"
								onClick={UpdateHackathon}
								size="large"
								style={{
									paddingLeft: "50px",
									paddingRight: "50px",
									paddingTop: "15px",
									paddingBottom: "15px",
									backgroundColor: "rgb(250, 53, 88)",
								}}
							>
								Update Practice
							</Button>
						</Paper>
					</Grid>
					{/* <Grid item xs={1}>
						<Paper
							className={classes.paper}
							style={{ display: "none" }}
						></Paper>
					</Grid> */}
				</Grid>
			</div>
		</AuthLayout>
		// </>
	);
}
