import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API } from "../../../../backend";
import Loader from "../../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import DownloadLoader from "../../../DownloadUserData/Loader";
import EventLayout from "../../../../components/EventLayout";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { FormControl } from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import logoSm from "../../../../assets/images/logo-sm.png";
import Select from "react-select";
import { SketchPicker } from "react-color";
import { DropzoneArea } from "material-ui-dropzone";
import SessionSidebar from "./SessionSidebar";

const moment = require("moment");
const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Heading,
    Indent,
    Table,
    TableToolbar,
    Link,
    List,
    MediaEmbed,
    CodeBlock,
    ImageResize,
    ImageToolbar,
    ImageStyle,
  ],
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "mediaEmbed",
    "insertTable",
    "codeBlock",
  ],
  mediaEmbed: {
    previewsInData: true
  },
  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },

  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  codeBlock: {
    languages: [
      // Do not render the CSS class for the plain text code blocks.
      { language: "plaintext", label: "Plain text", class: "" },

      // Use the "php-code" class for PHP code blocks.
      { language: "php", label: "PHP", class: "php-code" },

      // Use the "js" class for JavaScript code blocks.
      // Note that only the first ("js") class will determine the language of the block when loading data.
      {
        language: "javascript",
        label: "JavaScript",
        class: "js javascript js-code",
      },

      // Python code blocks will have the default "language-python" CSS class.
      { language: "python", label: "Python" },
    ],
  },
};
const useStyles = makeStyles({
  root: {
    minHeight: 206,
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardbody: {
    padding: "0 10px 10px 10px",
    borderRadius: "8px",
  },
  eventbanner: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "100px",
    objectFit: "fill",
    maxWidth: "100%",
  },
  eventName: {
    fontWeight: 500,
    display: "-webkit-box",
    overflow: "hidden",
    "-mozBoxOrient": "vertical",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": 2,
  },
  moreOptions: {
    background: "#ffffff",
    position: "absolute",
    right: "10px",
    top: "6px",
    borderRadius: "50px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f44d5e",
    fontSize: "20px",
  },
  optionMenu: {
    boxShadow: "none",
    border: "1px solid #dbdbdb",
    "& div": {
      boxShadow: "none",
      border: "1px solid #dbdbdb",
    },
  },
  optionMenuItem: {
    boxShadow: "none",
  },
  createEventDrawer: {
    width: "40%",
  },
  creatEventHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    overflowX: "hidden",
  },
  spacingCreateEvent: {
    padding: "10px 30px 10px 30px",
    height: "calc(100vh - 142px)",
    overflow: "auto",
  },
  eventType: {
    width: "50%",
    textAlign: "center",
    color: "gray",
    marginBottom: 0,
    padding: "8px",
  },
  border: {
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
  },
  selectedEventType: {
    background: "#f44d5e",
    color: "#ffffff",
    width: "50%",
    textAlign: "center",
    marginBottom: 0,
    padding: "8px",
  },
  textField: {
    marginRight: 10,
    border: "1px solid rgba(53, 53, 53, 0.17)",
    borderRadius: "4px",
    color: "#353535",
    width: "inherit",
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
});
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
var FileSaver = require("file-saver");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Engagement(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [limit, setLimit] = useState("");
  const [sort, setSort] = useState("PublicScore");
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [HREFName, setHREFName] = useState("");
  const [eventType, setEventType] = React.useState("");
  const [eventTypeValue, setEventTypeValue] = React.useState("");
  const [isCreateTrack, setIsCreateTrack] = React.useState(false);
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [bannerImg, setBannerImg] = useState([]);
  const eventId = props.match.params.eventId;
  const sessionId = props.match.params.sessionId;
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [drawer, setDrawer] = React.useState({
    filter: false,
    tracks: false,
    addSession: false,
  });
  const [background, setBgColor] = React.useState("#dbdbdb");
  const [selectedOption, setSelectedOption] = React.useState([]);
  const onSelecthandleChange = (selectedOption) => {
    console.log(`Option selected:`, selectedOption);
  };
  const handleChangeComplete = (color) => {
    setBgColor(color.hex);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    // setState({ ...state, [anchor]: open });
    setDrawer({ ...drawer, [anchor]: open });
  };

  const handleChange = (event) => {
    setEventTypeValue(event.target.value);
  };

  const handleClickOpen = (HREFName) => {
    setHREFName(HREFName);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setEnd(!end);
  };
  const createTrackLayout = () => {
    setIsCreateTrack(true);
  };

  const downloadFileHackathon = () => {
    setStart(true);
    setMessageData("File Download Started ...");
    var formData = new FormData();
    formData.append("limit", limit);
    formData.append("sortby", sort);

    axios({
      url: `${API}/lb/export/${HREFName}`,
      method: "POST",
      headers: {
        "content-type": "multipart/form-data",
      },
      data: formData,
      responseType: "blob",
    })
      .then((response) => {
        FileSaver.saveAs(response.data, "userdata.csv");
        setStart(false);
        setEnd(true);
        setMessageData("File Download Completed");
      })
      .catch((err) => console.log(err));
  };

  const getEventData = () => {
    axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getEventData();
  }, []);

  return (
    <EventLayout>
      {console.log(drawer)}
      {start && (
        <Snackbar open={start} onClose={handleClose}>
          <>
            <Alert onClose={handleClose} severity="success">
              <DownloadLoader pexal="55px" />
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {end && (
        <Snackbar open={end} onClose={handleCloseEnd}>
          <>
            <Alert onClose={handleCloseEnd} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        {Object.keys(eventData).length > 0 ? (
          <div className="w-100 event-overview">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
              <ul className="eventsBreadcrumb p-0 d-flex align-items-center">
                  <li className="cursor-pointer" onClick={()=>history.push(`/event/${props.match.params.eventId}/sessions`)}>Sessions</li>
                  <li style={{color:"#353535"}}>{eventData.title}
                  </li>
                </ul>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SessionSidebar eventId={eventId} sessionId={sessionId} />
              </Grid>
              <Grid item xs={8}></Grid>
            </Grid>
            {/* <div className="event-cardHld">
              <div className="d-flex align-items-center justify-content-between">
                <form className="app-search">
                  <div className="app-search-box">
                    <div className="input-group pl-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                      <div className="input-group-append">
                        <button className="btn" type="submit">
                          <i className="fe-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="d-flex align-items-center">
                  <button
                    className="mr-2 event-outlined-btn"
                    onClick={toggleDrawer("filter", true)}
                  >
                    <i className="mdi mdi-filter-outline"></i>
                  </button>
                  <button className="mr-2 event-outlined-btn" disabled>
                    <i className="mdi mdi-calendar-blank"></i>
                  </button>
                  <button className="mr-2 event-outlined-btn">
                    <i className="mdi mdi-delete"></i>
                  </button>
                  <button
                    className="mr-2 event-outlined-btn"
                    onClick={toggleDrawer("tracks", true)}
                  >
                    Manage Tracks
                  </button>
                  <button className="mr-2 event-outlined-btn" disabled>
                    Add Day
                  </button>
                  <button
                    className="mr-2 event-primary-btn"
                    onClick={toggleDrawer("addSession", true)}
                  >
                    Add Session <i className="mdi mdi-menu-down ml-1"></i>
                  </button>
                </div>
              </div>
              <hr />
              <div className="sessionDayTab d-flex">
                <p className="mb-0 selected">Day 1 19th Jan, 2022</p>
                <p className="mb-0">Day 2</p>
              </div>
              <br />
              <div className="event-cardHld p-0">
                <div className="d-flex sessionHld">
                  <div className="time">
                    <span>09:15 AM</span>
                  </div>
                  <div className="event-cardHld m-2">
                    <p className="mb-0">Morning Session - Tech talks</p>
                    <span>09:15 AM - 01:30 PM</span>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={6}>
                        <div className="speakershld mt-1">
                          <img src={logoSm} alt="" className="img-fluid" />
                          <img
                            src={logoSm}
                            alt=""
                            className="img-fluid"
                            style={{ marginLeft: "-5px" }}
                          />
                          <img
                            src={logoSm}
                            alt=""
                            className="img-fluid"
                            style={{ marginLeft: "-5px" }}
                          />
                          <img
                            src={logoSm}
                            alt=""
                            className="img-fluid"
                            style={{ marginLeft: "-5px" }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="text-right">
                          <span className="meetIcon mr-2">
                            <i className="mdi mdi-video"></i>
                          </span>
                          <span className="meetIcon">
                            <i className="mdi mdi-delete"></i>
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className="d-flex sessionHld">
                  <div className="time">
                    <span>09:15 AM</span>
                  </div>
                  <div className="event-cardHld m-2">
                    <p className="mb-0">Morning Session - Tech talks</p>
                    <span>09:15 AM - 01:30 PM</span>
                    <Grid container alignItems="center" spacing={2}>
                      <Grid item xs={6}>
                        <div className="speakershld mt-1">
                          <img src={logoSm} alt="" className="img-fluid" />
                          <img
                            src={logoSm}
                            alt=""
                            className="img-fluid"
                            style={{ marginLeft: "-5px" }}
                          />
                          <img
                            src={logoSm}
                            alt=""
                            className="img-fluid"
                            style={{ marginLeft: "-5px" }}
                          />
                          <img
                            src={logoSm}
                            alt=""
                            className="img-fluid"
                            style={{ marginLeft: "-5px" }}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="text-right">
                          <span className="meetIcon mr-2">
                            <i className="mdi mdi-video"></i>
                          </span>
                          <span className="meetIcon">
                            <i className="mdi mdi-delete"></i>
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
      </Row>
      <Drawer
        anchor="right"
        open={drawer.addSession}
        onClose={toggleDrawer("addSession", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Add Session</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("addSession", false)}
                onKeyDown={toggleDrawer("addSession", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <div className="d-flex">
              <div className="col-6 pl-0">
                <label htmlFor="title" className="event-label">
                  Session Date
                  <span style={{ color: "red", verticalAlign: "" }}>*</span>
                </label>
                <TextField
                  id="datetime-local"
                  type="datetime-local"
                  defaultValue="2017-05-24T10:30"
                  className={classes.textField}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      color: "#353535",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    input: "#353535",
                    label: "#353535",
                  }}
                />
              </div>
              <div className="col-3">
                <label htmlFor="title" className="event-label">
                  Start Time
                  <span style={{ color: "red", verticalAlign: "" }}>*</span>
                </label>
                <TextField
                  id="datetime-local"
                  type="time"
                  defaultValue="07:30"
                  className={classes.textField}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      color: "#353535",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    input: "#353535",
                    label: "#353535",
                  }}
                />
              </div>
              <div className="col-3">
                <label htmlFor="title" className="event-label">
                  End Time
                  <span style={{ color: "red", verticalAlign: "" }}>*</span>
                </label>
                <TextField
                  id="datetime-local"
                  type="time"
                  defaultValue="07:30"
                  className={classes.textField}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      color: "#353535",
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    input: "#353535",
                    label: "#353535",
                  }}
                />
              </div>
            </div>
            <br />
            <FormControl className={classes.formControl}>
              <label htmlFor="title" className="event-label">
                Session Title
                <span style={{ color: "red", verticalAlign: "" }}>*</span>
              </label>
              <input
                id="title"
                type="text"
                className="form-control"
                placeholder="Example: AMA Session with panellist Name onML for Peace"
              />
            </FormControl>
            <br />
            <br />
            <label htmlFor="title" className="event-label">
              Assign Track
            </label>
            <Select
              value={selectedOption}
              onChange={onSelecthandleChange}
              options={options}
            />
            <br />
            <label htmlFor="title" className="event-label">
              Banner Image
            </label>
            <div className="addSession">
              <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                onChange={(files) => setBannerImg(files)}
                maxFileSize={9242880}
              />
            </div>
            <br />
            <label htmlFor="title" className="event-label">
              Session Description
            </label>
            <div className={classes.editor}>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data=""
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  console.log("data composed");
                  console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
            </div>
            <br />
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn}>ADD</button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("addSession", false)}
              onKeyDown={toggleDrawer("addSession", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={drawer.filter}
        onClose={toggleDrawer("filter", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Filter By</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("filter", false)}
                onKeyDown={toggleDrawer("filter", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <label htmlFor="title">Track</label>
            <Select
              // value={selectedOption}
              // defaultValue={[options[2], options[3]]}
              onChange={onSelecthandleChange}
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Click here to select custom track"
            />
            <br />
            <label htmlFor="title">Speakers</label>
            <Select
              // value={selectedOption}
              // defaultValue={[options[2], options[3]]}
              onChange={onSelecthandleChange}
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Click here to select custom speakers"
            />
            <br />
            <label htmlFor="title">Custom Tags</label>
            <Select
              onChange={onSelecthandleChange}
              isMulti
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Click here to select custom tags"
            />
            <br />
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn}>Apply</button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("filter", false)}
              onKeyDown={toggleDrawer("filter", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={drawer.tracks}
        onClose={toggleDrawer("tracks", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Manage Tracks</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("tracks", false)}
                onKeyDown={toggleDrawer("tracks", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <div className="event-cardHld mb-2">
              <div className="d-flex align-items-center justify-content-between cursor-pointer trackhld">
                <div className="d-flex align-items-center">
                  <i
                    className="mdi mdi-drag-vertical mr-2"
                    style={{ fontSize: "20px" }}
                  ></i>
                  <div className="colorIndicator"></div>
                  <div className="ml-2">
                    <p className="mb-0">tech talks</p>
                    <span>6 sessions</span>
                  </div>
                </div>

                <div className="iconsHld">
                  <span style={{ fontSize: "20px" }}>
                    <i className="mdi mdi-pencil-box-outline"></i>
                  </span>
                  <span style={{ fontSize: "20px" }}>
                    <i className="mdi mdi-delete"></i>
                  </span>
                </div>
              </div>
            </div>
            {isCreateTrack && (
              <div className="d-flex align-items-center createtrackhld">
                <div
                  className=""
                  style={{
                    background: `${background}`,
                    width: "20px",
                    height: "20px",
                    borderRadius: "4px",
                  }}
                  onClick={() => setShowColorPicker(!showColorPicker)}
                ></div>
                <input
                  type="text"
                  placeholder=""
                  name="trackName"
                  className="mr-2"
                  placeholder="Enter Name"
                />
                <span>
                  <i class="mdi mdi-checkbox-marked"></i>
                </span>
                <span>
                  <i class="mdi mdi-close-box-outline"></i>
                </span>
              </div>
            )}
            {showColorPicker && (
              <SketchPicker
                color={background}
                onChangeComplete={handleChangeComplete}
              />
            )}
            <br />
            <button className="event-outlined-btn" onClick={createTrackLayout}>
              <i class="mdi mdi-plus-circle-outline mr-1"></i> ADD TRACK
            </button>
            <br />
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn}>Apply</button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("tracks", false)}
              onKeyDown={toggleDrawer("tracks", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
    </EventLayout>
  );
}


