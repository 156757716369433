import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/AuthLayout";
import { Row, Col, Card, CardBody } from "reactstrap";
import Header from "../Header";
import axios from "axios";
import { API } from "../../backend";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Loader from "./Loader";
import { useSelector, useDispatch } from "react-redux";

var FileSaver = require("file-saver");
const FileDownload = require("js-file-download");

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DownlaodUsers() {
	const hackathons = useSelector((store) => store.hackathons.hackathons);
	const dispatch = useDispatch();

	console.log("hackathons", hackathons);

	const [Name, setName] = useState(false);
	const [Email, setEmail] = useState(false);
	const [Location, setLocation] = useState(false);
	const [Organization, setOrganization] = useState(false);
	const [Experience, setExperience] = useState(false);
	const [LatestDesignation, setLatestDesignation] = useState(false);
	const [Mobile, setMobile] = useState(false);
	const [LinkedIn, setLinkedIn] = useState(false);
	const [GitHub, setGitHub] = useState(false);
	const [Twitter, setTwitter] = useState(false);
	const [created, setCreated] = useState(false);
	const [ranks, setRanks] = useState(false);
	const [_id, set_id] = useState(false);
	const [start, setStart] = useState(false);
	const [end, setEnd] = useState(false);
	const [messageData, setMessageData] = useState("");
	const [tab1, setTab1] = useState(true);
	const [tab2, setTab2] = useState(false);
	const [HREFName, setHREFName] = useState("");
	const [limit, setLimit] = useState("");
	const [sort, setSort] = useState("");
	const [filter, setFilter] = useState("None");

	const getAllHackathons = () => {
		axios
			.get(`${API}/competitions`)
			.then((response) => {
				// console.log("respone hack", response.data);
				dispatch({
					type: "GET_ALL_HACKATHONS",
					payload: response.data,
				});
			})
			.catch((err) => console.log(err));
	};
	// console.log(hackathons);
	useEffect(() => {
		if (hackathons.length === 0) {
			getAllHackathons();
		}
	}, []);

	const handleClose = () => {
		setStart(!start);
	};
	const handleCloseEnd = () => {
		setEnd(!end);
	};

	const showTab1 = () => {
		setTab1(true);
		setTab2(false);
	};
	const showTab2 = () => {
		setTab2(true);
		setTab1(false);
	};

	const downloadFile = () => {
		setStart(true);
		setMessageData("File Download Started ...");
		var formData = new FormData();
		formData.append("Name", Name === true ? "t" : "f");
		formData.append("Email", Email === true ? "t" : "f");
		formData.append("Location", Location === true ? "t" : "f");
		formData.append("Organization", Organization === true ? "t" : "f");
		formData.append("Experience", Experience === true ? "t" : "f");
		formData.append(
			"LatestDesignation",
			LatestDesignation === true ? "t" : "f"
		);
		formData.append("Mobile", Mobile === true ? "t" : "f");
		formData.append("LinkedIn", LinkedIn === true ? "t" : "f");
		formData.append("GitHub", GitHub === true ? "t" : "f");
		formData.append("Twitter", Twitter === true ? "t" : "f");
		formData.append("created", created === true ? "t" : "f");
		formData.append("ranks", ranks === true ? "t" : "f");
		formData.append("_id", _id === true ? "t" : "f");
		console.log("filter", filter);
		if (filter !== "None") {
			formData.append("filter_by", filter);
		}
		const config = {
			headers: {
				"content-type": "multipart/form-data",
			},
		};
		axios({
			url: `${API}/allusers/export`,
			method: "POST",
			data: formData,
			headers: {
				"content-type": "multipart/form-data",
			},
			responseType: "blob",
		})
			.then((response) => {
				console.log(response);
				FileSaver.saveAs(response.data, "userdata.csv");
				// FileDownload(response.data, "report.csv");
				// setSubmissions(response.data.submissions);
				// console.log("here");
				setStart(false);
				setEnd(true);
				setMessageData("File Download Completed");
			})
			.catch((err) => console.log(err));
	};
	const downloadFileHackathon = () => {
		setStart(true);
		setMessageData("File Download Started ...");
		var formData = new FormData();
		formData.append("limit", limit);
		formData.append("sortby", sort);
		axios({
			url: `${API}/lb/export/${HREFName}`,
			method: "POST",
			headers: {
				"content-type": "multipart/form-data",
			},
			data: formData,
			responseType: "blob",
		})
			.then((response) => {
				console.log(response);
				FileSaver.saveAs(response.data, "userdata.csv");
				setStart(false);
				setEnd(true);
				setMessageData("File Download Completed");
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		// downloadFile();
	}, []);

	// console.log("Name", Name);
	return (
		<AuthLayout>
			{start && (
				<Snackbar open={start} onClose={handleClose}>
					<>
						<Alert onClose={handleClose} severity="success">
							<Loader pexal="55px" />
							{messageData}
						</Alert>
					</>
				</Snackbar>
			)}
			{end && (
				<Snackbar open={end} onClose={handleCloseEnd}>
					<>
						<Alert onClose={handleCloseEnd} severity="success">
							{messageData}
						</Alert>
					</>
				</Snackbar>
			)}
			<Header name="Downlaod Users Data" />

			<div className="row">
				<div class="col-xl-12">
					<div class="card-box">
						<h5 class="mb-3">Tabs Justified</h5>
						<ul class="nav nav-tabs nav-justified">
							<li class="nav-item" onClick={showTab1}>
								<a
									href="#home2"
									data-toggle="tab"
									aria-expanded="false"
									class={`nav-link ${
										tab1 === true ? "active" : ""
									}`}
								>
									<span class="d-block d-sm-none">
										<i class="fas fa-home"></i>
									</span>
									<span class="d-none d-sm-block">
										Downlaod All Users Data
									</span>
								</a>
							</li>
							<li class="nav-item" onClick={showTab2}>
								<a
									href="#profile2"
									data-toggle="tab"
									aria-expanded="true"
									class={`nav-link ${
										tab2 === true ? "active" : ""
									}`}
								>
									<span class="d-block d-sm-none">
										<i class="far fa-user"></i>
									</span>
									<span class="d-none d-sm-block">
										Downlaod Public/Private Leaderboard Data
									</span>
								</a>
							</li>
						</ul>
						<div class="tab-content">
							{tab1 === true ? (
								<div
									role="tabpanel"
									class={`tab-pane fade ${
										tab1 === true ? "show active" : ""
									}`}
									id="home2"
								>
									<div class="row">
										<div class="cl-12 col-lg-12">
											{/* <div class="card-box"> */}
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="1"
													value={Name}
													onChange={(e) => {
														setName(!Name);
													}}
												/>
												<label for="1"> Name </label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="2"
													value={Email}
													onChange={(e) => {
														setEmail(!Email);
													}}
												/>
												<label for="2"> Email </label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="3"
													value={Location}
													onChange={(e) => {
														setLocation(!Location);
													}}
												/>
												<label for="3">
													{" "}
													Location{" "}
												</label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="4"
													value={Organization}
													onChange={(e) => {
														setOrganization(
															!Organization
														);
													}}
												/>
												<label for="4">
													{" "}
													Organization{" "}
												</label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="5"
													value={Experience}
													onChange={(e) => {
														setExperience(
															!Experience
														);
													}}
												/>
												<label for="5">
													{" "}
													Experience{" "}
												</label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="6"
													value={LatestDesignation}
													onChange={(e) => {
														setLatestDesignation(
															!LatestDesignation
														);
													}}
												/>
												<label for="6">
													{" "}
													LatestDesignation{" "}
												</label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="7"
													value={Mobile}
													onChange={(e) => {
														setMobile(!Mobile);
													}}
												/>
												<label for="7"> Mobile </label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="8"
													value={LinkedIn}
													onChange={(e) => {
														setLinkedIn(!LinkedIn);
													}}
												/>
												<label for="8">
													{" "}
													LinkedIn{" "}
												</label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="9"
													value={GitHub}
													onChange={(e) => {
														setGitHub(!GitHub);
													}}
												/>
												<label for="9"> GitHub </label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="10"
													value={Twitter}
													onChange={(e) => {
														setTwitter(!Twitter);
													}}
												/>
												<label for="10">
													{" "}
													Twitter{" "}
												</label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="11"
													value={created}
													onChange={(e) => {
														setCreated(!created);
													}}
												/>
												<label for="11">
													{" "}
													created{" "}
												</label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="12"
													value={ranks}
													onChange={(e) => {
														setRanks(!ranks);
													}}
												/>
												<label for="12"> ranks </label>
											</div>
											<div class="checkbox checkbox-pink ">
												<input
													style={{
														height: "20px",
														width: "20px",
													}}
													type="checkbox"
													id="13"
													value={_id}
													onChange={(e) => {
														set_id(!_id);
													}}
												/>
												<label for="13"> _id </label>
											</div>

											<div class="form-group">
												<label for="sel1">
													Filter By
												</label>
												<select
													class="form-control"
													id="sel1"
													onChange={(e) => {
														setFilter(
															e.target.value
														);
													}}
													value={filter}
												>
													<option value="None">
														None
													</option>
													{hackathons.length > 0
														? hackathons.map(
																(hackathon) => (
																	<option
																		value={
																			hackathon.HREFName
																		}
																		key={
																			hackathon.HREFName
																		}
																	>
																		{
																			hackathon.HName
																		}
																	</option>
																)
														  )
														: ""}

													{/* <option value="PublicScore">
														PublicScore
													</option>
													<option value="PrivateScore">
														PrivateScore
													</option> */}
												</select>
											</div>

											<br />
											<br />
											<button
												class="btn btn-pink waves-effect waves-light"
												onClick={downloadFile}
											>
												{" "}
												<span>Download</span> &nbsp;
												<i class="fas fa-download"></i>{" "}
											</button>
											{/* </div> */}
										</div>
									</div>
								</div>
							) : (
								<div
									role="tabpanel"
									class={`tab-pane fade ${
										tab2 === true ? "show active" : ""
									}`}
									id="profile2"
								>
									<div class="row">
										<div class="cl-12 col-lg-12">
											{/* <div class="card-box"> */}

											<div class="form-group row">
												<label
													class="col-sm-2  col-form-label"
													for="simpleinput"
												>
													Hackthon HREFName
												</label>
												<div class="col-sm-10">
													<input
														type="text"
														class="form-control"
														value={HREFName}
														onChange={(e) => {
															setHREFName(
																e.target.value.trim()
															);
														}}
													/>
												</div>
											</div>
											<div class="form-group row">
												<label
													class="col-sm-2  col-form-label"
													for="simpleinput"
												>
													Limit
												</label>
												<div class="col-sm-10">
													<input
														type="text"
														class="form-control"
														value={limit}
														onChange={(e) => {
															setLimit(
																e.target.value.trim()
															);
														}}
													/>
												</div>
											</div>

											<div class="form-group">
												<label for="sel1">Sortby</label>
												<select
													class="form-control"
													id="sel1"
													onChange={(e) => {
														setSort(e.target.value);
													}}
													value={sort}
												>
													<option value="PublicScore">
														PublicScore
													</option>
													<option value="PrivateScore">
														PrivateScore
													</option>
												</select>
											</div>

											<br />
											<br />
											<button
												class="btn btn-pink waves-effect waves-light"
												onClick={downloadFileHackathon}
											>
												{" "}
												<span>Download</span> &nbsp;
												<i class="fas fa-download"></i>{" "}
											</button>
											{/* </div> */}
										</div>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}
