import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Switch from '@material-ui/core/Switch';
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useHistory } from "react-router-dom";
import EasyMDE from "easymde";
import axios from "axios";
import { BOOTCAMP_API } from "../../backend";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateBootcampModule() {
  const classes = useStyles();
  const history = useHistory();
  const [fullscreen, setFullsreen] = useState(false);
  const [messageData, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [priority, setPriority] = useState(0);
  const [onSubmit, setOnSubmit] = useState(false);
  const [isLms, setIsLms] = useState(false);

  let MDE;
  const handleClose = () => {
    setStatus(!status);
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };
  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };

  useEffect(() => {
    MDE = new EasyMDE({
      element: document.getElementById("body"),
      autoDownloadFontAwesome: true,
      autofocus: true,
      status: false,
      singleLineBreaks: false,
      onToggleFullScreen: (opend) => setFullsreen(opend),
    });

    // MDE.codemirror.on('change', () => {
    //     setDescription(MDE.value());
    // });
  }, [MDE]);

  const newBootcampModule = () => {
    setOnSubmit(true);
    setStatusLoader(true);
    // if (title === "" || shortDescription === "" || difficulty === "" || description === "") {
    if (title === "") {
      setErrorStatus(true);
      setErrorMessage("Required Field Missing!!");
      setStatusLoader(false);
    } else {
      axios
        .post(`${BOOTCAMP_API}/bootcamp/createmodule`, null, {
          data: {
            module_name: title,
            moduleImage: url,
            priority: priority,
            lmsContent: isLms
          },
        })
        .then((response) => {
          if (response) {
            setStatus(true);
            setMessage(response.data.message);
            setStatusLoader(false);
            setTimeout(() => {
              history.push("/bootcampModules");
            }, 2500);
          } else {
            setErrorStatus(true);
            setErrorMessage("Exception in creating new Practice Module!");
            setStatusLoader(false);
          }
        })
        .catch((error) => {
          setErrorStatus(true);
          setErrorMessage(error);
          setStatusLoader(false);

          setStatusLoader(false);
        });
    }
  };

  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Create Bootcamp Module" />

      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {status && (
              <Snackbar
                open={status}
                autoHideDuration={15000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </Snackbar>
            )}

            {errorStatus && (
              <Snackbar
                open={errorStatus}
                autoHideDuration={15000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            )}

            {statusLoader && (
              <Snackbar
                open={statusLoader}
                // autoHideDuration={95000}
                onClose={handleLoader}
              >
                <Alert onClose={handleLoader} severity="info">
                  <Loader />
                </Alert>
              </Snackbar>
            )}

            <Paper className={classes.paper}>
              <h2>Title</h2>

              <TextField
                id="outlined-multiline-static"
                label="Title"
                multiline
                rows={3}
                variant="outlined"
                fullWidth={true}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <br />
              <br />

              <h2>Image URL</h2>

              <TextField
                id="outlined-multiline-static"
                label="url"
                multiline
                rows={3}
                variant="outlined"
                fullWidth={true}
                value={url}
                onChange={(e) => {
                  setUrl(e.target.value);
                }}
              />
              <br />
              <br />

              <h2>Priority</h2>

              <TextField
                id="outlined-multiline-static"
                label="Priority"
                multiline
                rows={3}
                variant="outlined"
                fullWidth={true}
                value={priority}
                onChange={(e) => {
                  setPriority(e.target.value);
                }}
              />
              <br />
              <br />

              <h2>LMS</h2>
              <Switch checked={isLms} onChange={(event) => setIsLms(event.target.checked)} name="isLMS" />
              <br />
              <br />

              {/* <br />
                            <br />
                            <h2>Description</h2>

                            <textarea id="body" name="body" value={MDE?.value()} />

                            <br />
                            <br />

                            <h2>Short Description</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Short Description"
                                multiline
                                rows={2}
                                variant="outlined"
                                fullWidth={true}
                                value={shortDescription}
                                onChange={(e) => {
                                    setShortDescription(e.target.value);
                                }}
                            />
                            <br />
                            <br />

                            <h2>Difficulty</h2>

                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Difficulty
								</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={difficulty}
                                    onChange={(e) => {
                                        setDifficulty(e.target.value);
                                    }}
                                    label="Difficulty"
                                >
                                    <MenuItem value="Beginner">
                                        Beginner
                                </MenuItem>
                                    <MenuItem value="Intermediate">
                                        Intermediate
                                </MenuItem>
                                    <MenuItem value="Advanced">
                                        Advanced
                                </MenuItem>
                                </Select>
                            </FormControl>

                            <br />
                            <br /> */}

              {/* <h2>Duration (in seconds)</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Duration (in seconds)"
                                type='number'
                                rows={1}
                                variant="outlined"
                                fullWidth={true}
                                value={duration}
                                onChange={(e) => {
                                    setDuration(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <h2>HREFs (References of Other Module's)</h2>

                            {
                                hrefs.map((href, index) => {
                                    return (
                                        <TextField
                                            style={{
                                                marginTop: '15px'
                                            }}
                                            id="outlined-multiline-static"
                                            label={href.key}
                                            multiline
                                            rows={1}
                                            variant="outlined"
                                            fullWidth={true}
                                            value={href.value}
                                            onChange={(e) => {
                                                let newHrefs = hrefs.slice();
                                                let newHref = {
                                                    key: href.key,
                                                    value: e.target.value
                                                };
                                                newHrefs[index] = newHref;
                                                setHrefs(newHrefs);
                                            }}
                                        />
                                    )
                                })
                            }

                            <br />
                            <br /> */}
              <br />
              <br />

              <Button
                disabled={onSubmit}
                variant="contained"
                color="primary"
                size="large"
                style={{
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  backgroundColor: "rgb(250, 53, 88)",
                }}
                onClick={(e) => newBootcampModule()}
              >
                Create Bootcamp Module
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
