import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
	UncontrolledDropdown,
	DropdownMenu,
	DropdownItem,
	DropdownToggle,
} from "reactstrap";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import MetisMenu from "metismenujs/dist/metismenujs";
import profilePic from "../assets/images/users/user-1.jpg";
import { useSelector } from "react-redux";
import { isAuthenticated } from "../auth/helper";
import setAuthToken from "../auth/helper/setToken";
import { getUserInfo } from "../user/helper/userapicall";
import { store } from "../store/store";

// if (localStorage.getItem("jwt")) {
// 	let jwt = JSON.parse(localStorage.getItem("jwt"));
// 	// console.log("jwt", jwt);
// 	setAuthToken(jwt.token);
// 	let userId = isAuthenticated().user._id;
// 	getUserInfo(userId)
// 		.then((response) => {
// 			// console.log("user", response.data.user);
// 			store.dispatch({
// 				type: "GET_USER_INFO",
// 				payload: response.data.user,
// 			});
// 		})
// 		.catch((err) => console.log(err));
// }

const SideNavContent = () => {
	return (
		<React.Fragment>
			<div id="sidebar-menu">
				<ul className="metismenu" id="side-menu">
					<li className="menu-title">Navigation</li>

					<li>
						<NavLink
							to="/"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-home"></i>
							<span> Home </span>
						</NavLink>
					</li>

					<li>
						<NavLink
							to="/store"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-home"></i>
							<span> Store </span>
						</NavLink>
					</li>

					<li>
						<NavLink
							to="/createnotification"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-pen"></i>
							<span>Create Notification </span>
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/notifications"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-bell"></i>
							<span>Notifications</span>
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/create-events-notification"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-pen"></i>
							<span>Create Event Notification </span>
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/event-notifications"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-bell"></i>
							<span>Event Notifications</span>
						</NavLink>
					</li>

					<li>
						<NavLink
							to="/dashboard"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-monitor-multiple"></i>
							<span> Hackathons </span>
						</NavLink>
					</li>

					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/createHackathon"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							{/* <i className="mdi mdi-view-dashboard"></i> */}
							<i className="mdi mdi-pen"></i>
							Create Hackathon
						</NavLink>
					</li>

					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/hackathon-solutions"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-pen"></i>
							Hackathon solutions
						</NavLink>
					</li>

					<li>
						<NavLink
							to="/blogathons"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-monitor-multiple"></i>
							<span> Categories </span>
						</NavLink>
					</li>
					<li>
						<NavLink
							to="/reviewers"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-monitor-multiple"></i>
							<span> Reviewers </span>
						</NavLink>
					</li>

					<li>
						<NavLink
							to="/events"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-library-books"></i>
							<span> Events </span>
						</NavLink>
					</li>

					<li>
						<NavLink
							to="/practices"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="fas fa-code"></i>
							<span> Practices </span>
						</NavLink>
					</li>

					<li>
						<NavLink
							to="/practiceModules"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="fas fa-code"></i>
							<span> Practice Modules </span>
						</NavLink>
					</li>										

					<li>
						<NavLink
							to="/createPracticeModule"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="fas fa-laptop-code"></i>
							<span> Create Practice Module </span>
						</NavLink>
					</li>

					{/* <li>
						<NavLink
							to="/createPractice"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="fas fa-laptop-code"></i>
							<span> Create Practice </span>
						</NavLink>
					</li> */}

					{/* <li>
						<NavLink
							to="/bootcamps"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-book-open"></i> 
							<span> Bootcamps </span>
						</NavLink>
					</li>

					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/createBootcamp"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-database-plus"></i>
							Create Bootcamp
						</NavLink>
					</li> */}

					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/createBootcampModule"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-database-plus"></i>
							Add Bootcamp Module
						</NavLink>
					</li>

					<li>
						<NavLink
							to="/bootcampModules"
							className="waves-effect side-nav-link-ref"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-book-open"></i> 
							<span>View Bootcamp Modules </span>
						</NavLink>
					</li>
					
					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/alluser"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							{/* <i className="mdi mdi-view-dashboard"></i> */}
							<i className="fas fa-users"></i>
							All Users
						</NavLink>
					</li>
					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/downloadUsers"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							{/* <i className="mdi mdi-view-dashboard"></i> */}
							<i className="fas fa-download"></i>
							Download Users Data
						</NavLink>
					</li>
					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/profile"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							{/* <i className="mdi mdi-view-dashboard"></i> */}
							<i className="mdi mdi-account"></i>
							Profile
						</NavLink>
					</li>
					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/getAllQuestionSets"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="fas fa-code"></i>
							Question Sets
						</NavLink>
					</li>
					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/createQuestionSet"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-pen"></i>
							Create Question Set
						</NavLink>
					</li>
					{/* <li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/createMajorBadge"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-pen"></i>
							Create Major Badge
						</NavLink>
					</li> */}
					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/majorBadgeList"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-pen"></i>
							Major Badge List
						</NavLink>
					</li>
					{/* <li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/createAssessmentCategory"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-pen"></i>
							Create Assessment Category
						</NavLink>
					</li> */}
					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/assessmentCategoryList"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-pen"></i>
							Assessment Category List
						</NavLink>
					</li>
					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/skills"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-database-plus"></i>
							Skills
						</NavLink>
					</li>
					<li>
						<NavLink
							className="waves-effect side-nav-link-ref"
							to="/job-role"
							exact
							activeStyle={{
								color: "#71b6f9",
							}}
						>
							<i className="mdi mdi-database-plus"></i>
							Job Role
						</NavLink>
					</li>
				</ul>
			</div>
			<div className="clearfix"></div>
		</React.Fragment>
	);
};

const UserProfile = () => {
	const user = useSelector((store) => store.user.user);

	return (
		<React.Fragment>
			<div className="user-box text-center">
				<img
					src={user.Picture}
					alt="user-img"
					title="Nik Patel"
					className="rounded-circle img-thumbnail avatar-lg"
				/>
				<UncontrolledDropdown>
					<DropdownToggle
						caret
						tag="a"
						className="text-dark dropdown-toggle h5 mt-2 mb-1 d-block"
					>
						{user.FName}&nbsp;{user.LName}
					</DropdownToggle>
					<DropdownMenu className="user-pro-dropdown">
						<DropdownItem>
							<i className="fe-user mr-1"></i>
							<span>My Account</span>
						</DropdownItem>
						<DropdownItem>
							<i className="fe-settings mr-1"></i>
							<span>Settings</span>
						</DropdownItem>
						<DropdownItem>
							<i className="fe-lock mr-1"></i>
							<span>Lock Screen</span>
						</DropdownItem>
						<DropdownItem>
							<i className="fe-log-out mr-1"></i>
							<span>Logout</span>
						</DropdownItem>
					</DropdownMenu>
				</UncontrolledDropdown>

				<p className="text-muted">{user.Role}</p>
				{/* <ul className="list-inline">
					<li className="list-inline-item">
						<Link to="/" className="text-muted">
							<i className="mdi mdi-settings"></i>
						</Link>
					</li>

					<li className="list-inline-item">
						<Link to="/" className="text-custom">
							<i className="mdi mdi-power"></i>
						</Link>
					</li>
				</ul> */}
			</div>
		</React.Fragment>
	);
};

class Sidebar extends Component {
	constructor(props) {
		super(props);
		this.handleOtherClick = this.handleOtherClick.bind(this);
		this.initMenu = this.initMenu.bind(this);
	}

	/**
	 * Bind event
	 */
	componentWillMount = () => {
		document.addEventListener("mousedown", this.handleOtherClick, false);
	};

	/**
	 *
	 */
	componentDidMount = () => {
		this.initMenu();
	};

	/**
	 * Component did update
	 */
	componentDidUpdate = (prevProps) => {
		if (this.props.isCondensed !== prevProps.isCondensed) {
			if (prevProps.isCondensed) {
				document.body.classList.remove("sidebar-enable");
				document.body.classList.remove("enlarged");
			} else {
				document.body.classList.add("sidebar-enable");
				const isSmallScreen = window.innerWidth < 768;
				if (!isSmallScreen) {
					document.body.classList.add("enlarged");
				}
			}

			this.initMenu();
		}
	};

	/**
	 * Bind event
	 */
	componentWillUnmount = () => {
		document.removeEventListener("mousedown", this.handleOtherClick, false);
	};

	/**
	 * Handle the click anywhere in doc
	 */
	handleOtherClick = (e) => {
		if (this.rightBarNodeRef && this.rightBarNodeRef.contains(e.target)) return;
		// else hide the menubar
		document.body.classList.remove("sidebar-enable");
	};

	/**
	 * Init the menu
	 */
	initMenu = () => {
		// render menu
		new MetisMenu("#side-menu");
		var links = document.getElementsByClassName("side-nav-link-ref");
		var matchingMenuItem = null;
		for (var i = 0; i < links.length; i++) {
			// console.log("path", this.props.location.pathname);
			// if (this.props.location.pathname === links[i].pathname) {
			// 	matchingMenuItem = links[i];
			// 	break;
			// }
		}

		if (matchingMenuItem) {
			matchingMenuItem.classList.add("active");
			var parent = matchingMenuItem.parentElement;

			/**
			 * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
			 * We should come up with non hard coded approach
			 */
			if (parent) {
				parent.classList.add("active");
				const parent2 = parent.parentElement;
				if (parent2) {
					parent2.classList.add("in");
				}
				const parent3 = parent2.parentElement;
				if (parent3) {
					parent3.classList.add("active");
					var childAnchor = parent3.querySelector(".has-dropdown");
					if (childAnchor) childAnchor.classList.add("active");
				}

				const parent4 = parent3.parentElement;
				if (parent4) parent4.classList.add("in");
				const parent5 = parent4.parentElement;
				if (parent5) parent5.classList.add("active");
			}
		}
	};

	render() {
		const isCondensed = this.props.isCondensed || false;

		return (
			<React.Fragment>
				<div
					className="left-side-menu"
					ref={(node) => (this.menuNodeRef = node)}
				>
					{!isCondensed && (
						<PerfectScrollbar>
							{/* <UserProfile /> */}
							<SideNavContent />
						</PerfectScrollbar>
					)}
					{isCondensed && <UserProfile /> && <SideNavContent />}
				</div>
			</React.Fragment>
		);
	}
}

export default connect()(Sidebar);
