import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
// import DownloadLoader from "../../DownloadUserData/Loader";
import Header from "../Header";
import AuthLayout from "../../components/AuthLayout";
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import '../../App.css';
import axios from "axios"
import { API, BOOTCAMP_API } from "../../backend"
const moment = require('moment');

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function MinorBadgesList(props) {
    // const [open, setOpen] = React.useState(false);
    const [end, setEnd] = useState(false);
    const [messageData, setMessage] = useState("");
    const [loaderAt, setLoaderAt] = useState(-1)
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);
    const [bootcampModules, setBootcampModules] = useState([]);
    const [reloadPage, setReloadPage] = useState(false);
    const [minorBadgesList, setMinorBadgesList] = useState([]);
    // const [totalModules, setTotalModules] = useState(0);
    // const [selectedPage, setSelectedPage] = useState("1");

    const classes = useStyles();

    const handleClose = () => {
        setStatus(false);
    };

    const handleCloseEnd = () => {
        setEnd(!end);
    };

    const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };

    const token = JSON.parse(localStorage.getItem('jwt'));


    const FetchMinorBadgesList = () => {

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': token.token
            },
        };

        axios
            .get(`${BOOTCAMP_API}/assessmentMock/getAllMinorBadges?majorBadgeId=${props.match.params.majorBadgeId}`, config)
            .then((response) => {
                response.data && response.data.result &&
                setMinorBadgesList(response.data.result.category)
                setReloadPage(false)
                // setTotalModules(response.data.length);
            })
    }

    const updatePublish = (data, index) => {

        setLoaderAt(index);

        axios.put(`${BOOTCAMP_API}/assessmentMock/updateMinorBadge/${data._id}`, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': token.token
            },
            data: {
                "badgeTitle": data.badgeTitle,
                "badgeImage": data.badgeImage,
                "description": data.description,
                "publish":!data.publish
            }
        }).then((response) => {
            if (response.data.result.message) {
                setStatus(true);
                setLoaderAt(-1);
                setMessage(response.data.result.category.publish ? `Published Successfully!` : 'Unpublished Successfully!');
                var minorBadge = minorBadgesList.slice();
                minorBadge[index].publish = !data.Publish;
                setMinorBadgesList(minorBadge);
                setReloadPage(true);
            }
            else {
                setErrorStatus(true);
                setErrorMessage(`No practice Module found to ${data.publish ? 'Publish' : 'Unpublish'}`);
                setStatusLoader(false);
                setLoaderAt(-1);
            }
        })
            .catch((error) => {
                setLoaderAt(-1);
            })
    }

    // const nextPracticeModules = (e,value) =>{

    // 	setSelectedPage(value);

    // 	FetchAllPracticeModules(value);
    // }

    useEffect(() => {
        FetchMinorBadgesList();
    }, [reloadPage]);


    return (
        <AuthLayout>
            <React.Fragment>
                <div className="">
                    <Row>
                        <Col>
                            <Header name="Minor Badges" />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Link
							    to={`/createMinorBadge/${props.match.params.majorBadgeId}`}
						    >
							    <ButtonToggle
								    color="dark"
								    style={{
									    paddingLeft:
										    "30px",
									    paddingRight:
										    "30px",
									    margin: "15px",
									    float: 'right'
								    }}
							    >
								Add Minor Badge
							</ButtonToggle>
						</Link>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <>
                                {status && (
                                    <Snackbar
                                        open={status}
                                        autoHideDuration={5000}
                                        onClose={handleClose}
                                    >
                                        <>
                                            <Alert
                                                onClose={handleClose}
                                                severity="success"
                                            >
                                                {messageData}
                                            </Alert>
                                        </>
                                    </Snackbar>
                                )}

                                {errorStatus && (
                                    <Snackbar
                                        open={errorStatus}
                                        autoHideDuration={7000}
                                        onClose={handleCloseError}
                                    >
                                        <Alert
                                            onClose={handleCloseError}
                                            severity="error"
                                        >
                                            {errorMessage}
                                        </Alert>
                                    </Snackbar>
                                )}

                                {
                                    minorBadgesList.length !== 0
                                        ?
                                        (
                                            minorBadgesList.map((minorBadge, index) => (
                                                <Card>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <CardBody>
                                                                <h4>
                                                                    {minorBadge.badgeTitle}
                                                                </h4>
                                                                <img src={minorBadge.badgeImage} style={{height:"70px"}} />
                                                            </CardBody>
                                                        </Col>

                                                        <Col
                                                            lg={5}
                                                            style={{
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <CardBody
                                                                style={{
                                                                    paddingLeft: "0px",
                                                                    paddingRight: "0px",
                                                                }}
                                                            >

                                                                {
                                                                    index === loaderAt
                                                                        ?
                                                                        (
                                                                            <div style={{ margin: "auto", textAlign: 'left' }}>
                                                                                <Loader />
                                                                            </div>
                                                                        )
                                                                        :
                                                                        (
                                                                            <>
                                                                                <ButtonToggle
                                                                                    className={minorBadge.publish ? 'unpublish' : 'publish'}
                                                                                    onClick={() => updatePublish(minorBadge, index)}
                                                                                // onClick={() => updateFlags(index, bootcampModule.Publish ? true : false)}
                                                                                >
                                                                                    {minorBadge.publish ? 'UNPUBLISH' : 'PUBLISH'}
                                                                                </ButtonToggle>

                                                                                <Link
                                                                                    to={`/updateMinorBadge/${minorBadge._id}`}
                                                                                >
                                                                                    <ButtonToggle
                                                                                        color="danger"
                                                                                        style={{
                                                                                            paddingLeft:
                                                                                                "30px",
                                                                                            paddingRight:
                                                                                                "30px",
                                                                                            margin: "15px"
                                                                                        }}
                                                                                    >
                                                                                        Edit
                                                                                    </ButtonToggle>
                                                                                </Link>
                                                                            </>
                                                                        )
                                                                }

                                                            </CardBody>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            ))
                                        )
                                        :
                                        <h4 style={{ textAlign: "center" }}>No Minor Badges found</h4>

                                }
                            </>
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        </AuthLayout>
    );
}
