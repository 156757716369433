import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Header from "../Header";
import AuthLayout from "../../components/AuthLayout";
import { makeStyles } from '@material-ui/core/styles';
import '../../App.css';
import axios from "axios"
import { BOOTCAMP_API } from "../../backend"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function GetAllBootcampModules(props) {
    // const [open, setOpen] = React.useState(false);
    const [end, setEnd] = useState(false);
    const [messageData, setMessage] = useState("");
    const [loaderAt, setLoaderAt] = useState(-1)
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);
    const [bootcampModules, setBootcampModules] = useState([]);
    const [reloadPage, setReloadPage] = useState(false);

    const classes = useStyles();

    const handleClose = () => {
        setStatus(false);
    };

    const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };

    const token = JSON.parse(localStorage.getItem('jwt'));


    const FetchAllPracticeModules = () => {

        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': token.token
            },
        };

        axios
            .get(`${BOOTCAMP_API}/bootcamp/moduleslist`, config)
            .then((response) => {
                response.data && response.data.result &&
                    setBootcampModules(response.data.result.data)
                setReloadPage(false)
                // setTotalModules(response.data.length);
            })
    }

    const updatePublish = (publishtype, lmsContentType, data, index) => {

        setLoaderAt(index);

        axios.put(`${BOOTCAMP_API}/bootcamp/updatemodule/${data._id}`, null, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': token.token
            },
            data: {
                "module_name": data.ModuleName,
                "publish": publishtype,
                "lmsContent": lmsContentType,
            }
        }).then((response) => {
            if (response.data.result.message) {
                setLoaderAt(-1);
                setStatus(true);
                // setMessage(response.data.result.data.publish ? `Published Successfully!` : 'Unpublished Successfully!');
                var practiceDup = bootcampModules.slice();
                practiceDup[index].publish = publishtype;
                practiceDup[index].lmsContent = lmsContentType;
                setBootcampModules(practiceDup);
                // setReloadPage(true);
            }
            else {
                setErrorStatus(true);
                setErrorMessage(`No practice Module found to ${publishtype ? 'Publish' : 'Unpublish'}`);
                setStatusLoader(false);
                setLoaderAt(-1);
            }
        })
            .catch((error) => {
                setLoaderAt(-1);
            })
    }

    // const nextPracticeModules = (e,value) =>{

    // 	setSelectedPage(value);

    // 	FetchAllPracticeModules(value);
    // }

    useEffect(() => {
        FetchAllPracticeModules();
    }, [reloadPage]);


    return (
        <AuthLayout>
            <React.Fragment>
                <div className="">
                    <Row>
                        <Col>
                            <Header name="All Bootcamp Modules" />
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={12}>
                            <>
                                {status && (
                                    <Snackbar
                                        open={status}
                                        autoHideDuration={5000}
                                        onClose={handleClose}
                                    >
                                        <>
                                            <Alert
                                                onClose={handleClose}
                                                severity="success"
                                            >
                                                {messageData}
                                            </Alert>
                                        </>
                                    </Snackbar>
                                )}

                                {errorStatus && (
                                    <Snackbar
                                        open={errorStatus}
                                        autoHideDuration={7000}
                                        onClose={handleCloseError}
                                    >
                                        <Alert
                                            onClose={handleCloseError}
                                            severity="error"
                                        >
                                            {errorMessage}
                                        </Alert>
                                    </Snackbar>
                                )}

                                {
                                    bootcampModules.length !== 0
                                        ?
                                        (
                                            bootcampModules.map((bootcampModule, index) => (
                                                <Card key={index}>
                                                    <Row>
                                                        <Col lg={7}>
                                                            <CardBody>
                                                                <h4>
                                                                    {bootcampModule.ModuleName}
                                                                    <FormControlLabel
                                                                        control={<Switch checked={bootcampModule.lmsContent} onChange={(event) => updatePublish(bootcampModule.publish, event.target.checked, bootcampModule, index)} name="checkedA" />}
                                                                        label="LMS"
                                                                        className="ml-2 mb-0"
                                                                    />
                                                                </h4>

                                                            </CardBody>
                                                        </Col>

                                                        <Col
                                                            lg={5}
                                                            style={{
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            <CardBody
                                                                style={{
                                                                    paddingLeft: "0px",
                                                                    paddingRight: "0px",
                                                                }}
                                                            >

                                                                {
                                                                    index === loaderAt
                                                                        ?
                                                                        (
                                                                            <div style={{ margin: "auto", textAlign: 'left' }}>
                                                                                <Loader />
                                                                            </div>
                                                                        )
                                                                        :
                                                                        (
                                                                            <>
                                                                                <ButtonToggle
                                                                                    className={bootcampModule.publish ? 'unpublish' : 'publish'}
                                                                                    onClick={() => updatePublish(!bootcampModule.publish, bootcampModule.lmsContent, bootcampModule, index)}
                                                                                // onClick={() => updateFlags(index, bootcampModule.Publish ? true : false)}
                                                                                >
                                                                                    {bootcampModule.publish ? 'UNPUBLISH' : 'PUBLISH'}
                                                                                </ButtonToggle>

                                                                                <Link
                                                                                    to={`/updateBootcampModule/${bootcampModule._id}`}
                                                                                >
                                                                                    <ButtonToggle
                                                                                        color="danger"
                                                                                        style={{
                                                                                            paddingLeft:
                                                                                                "30px",
                                                                                            paddingRight:
                                                                                                "30px",
                                                                                            margin: "15px"
                                                                                        }}
                                                                                    >
                                                                                        Edit
                                                                                    </ButtonToggle>
                                                                                </Link>
                                                                                <Link to={`bootcampModules/${(bootcampModule.ModuleName).replace(/\?/g, '').trim()}/${bootcampModule._id}`}>
                                                                                    <ButtonToggle
                                                                                        color="dark"
                                                                                        style={{
                                                                                            paddingLeft:
                                                                                                "30px",
                                                                                            paddingRight:
                                                                                                "30px",
                                                                                            margin: "15px",
                                                                                        }}
                                                                                    >
                                                                                        View Courses
                                                                                    </ButtonToggle>
                                                                                </Link>
                                                                            </>
                                                                        )
                                                                }

                                                            </CardBody>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            ))
                                        )
                                        :
                                        <h4 style={{ textAlign: "center" }}>No Practice Module found</h4>

                                }
                            </>
                        </Col>
                        {/* <Col lg={12} >
							<Row style={{justifyContent:"center",paddingBottom:"20px"}}>
								<Pagination count={totalModules % 10 === 0 ? parseInt(totalModules / 10) : parseInt(totalModules / 10)+1 }
										defaultPage={1}
										page={Number(selectedPage)}
										variant="outlined" 
                                		shape="rounded"
										className={classes.root}
										// onChange={nextPracticeModules} 
										showFirstButton showLastButton  />
							</Row>
						</Col> */}
                    </Row>
                </div>
            </React.Fragment>
        </AuthLayout>
    );
}
