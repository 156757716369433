import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { BOOTCAMP_API, EVENT_URL } from "../../../../backend";
import Loader from "../../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import DownloadLoader from "../../../DownloadUserData/Loader";
import EventLayout from "../../../../components/EventLayout";
import { FormControl } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Select from "react-select";
import SessionSidebar from "./SessionSidebar";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router";
const useStyles = makeStyles({
  formControl: {
    width: "100%",
  },
});
const StreamSourceOptions = [
  { value: "zoom", label: "Zoom Webinar" },
  { value: "youtube", label: "YouTube" },
  { value: "vimeo", label: "Vimeo" },
  { value: "others", label: "others" },
];

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SessionStreamOption(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [recordingLink, setRecordingLink] = React.useState("");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const eventId = props.match.params.eventId;
  const sessionId = props.match.params.sessionId;
  const [streamOption, setstreamOption] = React.useState(true);
  const [IsCopy, setIsCopy] = React.useState(false);
  const [Agenda, setAgenda] = React.useState([]);
  const [selectedStreamSource, setselectedStreamSource] = React.useState("");
  const [streamLink, setStreamLink] = React.useState("");
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const history = useHistory();
  const onStreamOptionhandleChange = (event) => {
    setstreamOption(!streamOption);
  };
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setEnd(!end);
  };

  const copyURL = () => {
    navigator.clipboard.writeText(`${EVENT_URL}/events/${eventData.slug}/home`);
    setIsCopy(true);
  };

  const saveStreaOptions = () => {
    const streamOptionsBody = {
      streamSource: selectedStreamSource.value,
      streamLink: streamLink,
      onDemandRecordingLink: recordingLink,
      host: [],
      featured: Agenda.featured,
      publish: Agenda.publish,
    };
    axios
      .put(
        `${BOOTCAMP_API}/events/updateAgendaData/${Agenda._id}`,
        streamOptionsBody
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setAgenda(response.data.result.data);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  const getEventData = () => {
    axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
      })
      .catch((err) => console.log(err));
  };
  const getAgendaData = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificAgenda/${props.match.params.sessionId}/${props.match.params.eventId}`
      )
      .then((response) => {
        setAgenda(response.data.result.data);
        setStreamLink(response.data.result.data?.streamSession?.streamLink);
        setRecordingLink(
          response.data.result.data?.streamSession?.onDemandRecordingLink
        );
        setselectedStreamSource({
          label: response.data.result.data.streamSession?.streamSource || "",
          value: response.data.result.data.streamSession?.streamSource || "",
        });
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getEventData();
    getAgendaData();
  }, []);

  return (
    <EventLayout>
      {messageData && (
        <Snackbar open={successMessage} onClose={() => setSuccessMessage(false)}>
          <>
            <Alert onClose={() => setSuccessMessage(false)} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar open={errorStatus} onClose={() => setErrorStatus(false)}>
          <>
            <Alert onClose={() => setErrorStatus(false)} severity="error">
              {errorMessage}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        {Object.keys(eventData).length > 0 ? (
          <div className="w-100 event-overview">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <br />
                <ul className="eventsBreadcrumb p-0 d-flex align-items-center">
                  <li className="cursor-pointer" onClick={()=>history.push(`/event/${props.match.params.eventId}/sessions`)}>Sessions</li>
                  <li style={{color:"#353535"}}>{eventData.title}
                  </li>
                </ul>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SessionSidebar eventId={eventId} sessionId={sessionId} />
              </Grid>
              <Grid item xs={8}>
                <div className="event-cardHld">
                  <h3>Stream Options</h3>
                  <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="event-label">Stream This Session</label>
                    <Switch
                      checked={streamOption}
                      onChange={onStreamOptionhandleChange}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <FormControl className={classes.formControl}>
                    <label className="event-label">
                      Stream Source
                      <span style={{ color: "red", verticalAlign: "" }}>*</span>
                    </label>
                    <Select
                      options={StreamSourceOptions || ""}
                      isSearchable={false}
                      onChange={(value) => setselectedStreamSource(value)}
                      value={selectedStreamSource}
                    />
                    <p className="inputInfo mt-1">
                      The speakers and attendees will join a zoom webinar
                    </p>
                  </FormControl>
                  <br />
                  {selectedStreamSource.value !== "" && (
                    <>
                      {selectedStreamSource.value === "zoom" ? (
                        <>
                          <FormControl className={classes.formControl}>
                            <label className="event-label">Choose Host</label>
                            <Select
                              options={StreamSourceOptions || ""}
                              isSearchable={false}
                            />
                            <p className="inputInfo mt-1">Stream Links</p>
                          </FormControl>
                          <br />
                          <div
                            className="p-2"
                            style={{
                              background: "#f6f6f6",
                              borderRadius: "4px",
                            }}
                          >
                            <p>Zoom Webinar Start Link</p>
                            <span>
                              The host can start the Webinar from the link below
                              or by clicking the
                              <br />
                              <b className="brand-color">START SESSION</b>{" "}
                              button against the session after logging in to the
                              event
                            </span>
                            <div className="d-flex align-items-center">
                              <p className="event-link-generated mb-0">
                                {`${EVENT_URL}/events/${eventData.slug}/home`}
                              </p>{" "}
                              <span
                                style={{ color: "#f44d5e", cursor: "pointer" }}
                                className="ml-2"
                                onClick={copyURL}
                              >
                                <i className="mdi mdi-content-copy"></i>
                                {IsCopy ? "copied" : "copy"}
                              </span>
                            </div>
                          </div>
                          <br />
                        </>
                      ) : (
                        <>
                          {console.log(
                            selectedStreamSource,
                            selectedStreamSource !== "",
                            Object.keys(selectedStreamSource).length
                          )}
                          <FormControl className={classes.formControl}>
                            <label htmlFor="title" className="event-label">
                              Stream Link
                              <span style={{ color: "red", verticalAlign: "" }}>
                                *
                              </span>
                            </label>
                            <input
                              id="title"
                              type="text"
                              className="form-control mb-3"
                              placeholder="Example: Fireside chat with Panellist Name on AI"
                              value={streamLink}
                              onChange={(e) => setStreamLink(e.target.value)}
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <label htmlFor="title" className="event-label">
                              On Demand Recording Link
                            </label>
                            <input
                              id="title"
                              type="text"
                              className="form-control"
                              placeholder="Example: Fireside chat with Panellist Name on AI"
                              value={recordingLink}
                              onChange={(e) => setRecordingLink(e.target.value)}
                            />
                          </FormControl>
                        </>
                      )}
                    </>
                  )}

                  <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
                  <br />
                  <div className=" mr-auto d-flex justify-content-end">
                    {(selectedStreamSource &&
                      selectedStreamSource.value === "zoom") ||
                    (selectedStreamSource &&
                      selectedStreamSource.value !== "zoom" &&
                      streamLink !== "" &&
                      recordingLink !== "") ? (
                      <button
                        className="event-primary-btn"
                        onClick={saveStreaOptions}
                      >
                        SAVE
                      </button>
                    ) : (
                      <button className="event-primary-btn" disabled>
                        SAVE
                      </button>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
      </Row>
    </EventLayout>
  );
}
