import React, { useState, useEffect, useRef } from "react";
import QuestionService from "../../../../services/mocks/QuestionService";

export default function JobRole({ jobRole, setJobRole }) {
  const [skillslist, setSkillslist] = useState([]);
  const [showSkillsDrop, setShowSkillsDrop] = useState(false);
  const [skills, setSkills] = React.useState("");
  const [currentSkillsPage, setCurrentSkillsPage] = useState(1);
  const inputEl = useRef(null);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSkillsDrop(false);
          setSkills("");
          setCurrentSkillsPage(1);
          getAllJobRole(currentSkillsPage, "");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    const searchData = setTimeout(()=>{
      getAllJobRole(currentSkillsPage, "");
    },1000)
    return () => clearTimeout(searchData);
  }, [skills]);

  const onClickDivFocusInput = () => {
    inputEl.current.focus();
  };

  const getAllJobRole = async (page, type) => {
    try {
      const paramData = {
        current_page: page,
      };
      skills !== "" && (paramData.searchString = skills);
      const response = await QuestionService.FetchAllJobRole(paramData);
      if (response) {
        type === "scroll"
          ? setSkillslist((prev) => [...prev, ...response])
          : setSkillslist(response);

        setCurrentSkillsPage(page);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSkillChange = (e) => {
    setSkills(e.target.value);
    setShowSkillsDrop(true);
    setCurrentSkillsPage(1);
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      if (skillslist.length > 0) {
        addSkills(skillslist[0]?.name);
      } else {
        setSkills("");
      }
    }
  };
  const addSkills = (skills) => {
    if (skills && !isInList(skills)) {
      setJobRole([...jobRole, skills]);
      setSkills("");
      setShowSkillsDrop(false);
    }
  };
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      getAllJobRole(currentSkillsPage + 1, "scroll");
    }
  };
  const isInList = (value) => {
    return jobRole?.some((i) => i._id === value._id);
  };

  const handleDelete = (item) => {
    setJobRole(jobRole.filter((i) => i._id !== item._id));
  };

  return (
    <>
      <h2>JOb Role</h2>
      <div className="skillSethld" onClick={onClickDivFocusInput}>
        {jobRole?.length > 0 &&
          jobRole?.map((item,index) => (
            <div className="tag-item" key={index}>
              {item.title}
              <button
                type="button"
                className="button"
                onClick={() => handleDelete(item)}
              >
                &times;
              </button>
            </div>
          ))}
        <input
          placeholder={`${jobRole?.length > 0 ? "" : "Eg Data Scientist"}`}
          ref={inputEl}
          value={skills}
          onChange={handleSkillChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      {showSkillsDrop && (
        <div
          className="skillDropdown mt-2"
          ref={wrapperRef}
          onScroll={handleScroll}
        >
          {skillslist.length > 0 &&
            skillslist?.map((skill) => (
              <p
                key={skill._id}
                onClick={() => {
                  addSkills(skill);
                  onClickDivFocusInput();
                }}
                className="mb-0"
              >
                {skill?.title}
              </p>
            ))}
        </div>
      )}
    </>
  );
}
