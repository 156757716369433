import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { BOOTCAMP_API } from "../../../backend";
import Loader from "../../../components/Loader";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DownloadLoader from "../../DownloadUserData/Loader";
import Header from "../../Header";
import AuthLayout from "../../../components/AuthLayout";
import { makeStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
const moment = require('moment');

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			marginTop: theme.spacing(2),
		},
	},
}));

export default function GetAllCourses(props) {

	const [open, setOpen] = React.useState(false);
	const [limit, setLimit] = useState("");
	const [sort, setSort] = useState("PublicScore");
	const [start, setStart] = useState(false);
	const [end, setEnd] = useState(false);
	const [messageData, setMessageData] = useState("");
	const [HREFName, setHREFName] = useState("");
	const [courseList, setcourseList] = useState([]);
	const [isEmptyList, setIsEmptyList] = useState(false);
	const [reloadPage, setReloadPage] = useState(false);
	const [state, setState] = useState({
		checkedA: true,
	});

	const handleChange = (event) => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseEnd = () => {
		setEnd(!end);
	};

	// const downloadFileHackathon = () => {
	// 	setStart(true);
	// 	setMessageData("File Download Started ...");
	// 	var formData = new FormData();
	// 	formData.append("limit", limit);
	// 	formData.append("sortby", sort);

	// 	axios({
	// 		url: `${API}/lb/export/${HREFName}`,
	// 		method: "POST",
	// 		headers: {
	// 			"content-type": "multipart/form-data",
	// 		},
	// 		data: formData,
	// 		responseType: "blob",
	// 	})
	// 		.then((response) => {
	// 			console.log(response);
	// 			FileSaver.saveAs(response.data, "userdata.csv");
	// 			// FileDownload(response.data, "report.csv");
	// 			// setSubmissions(response.data.submissions);
	// 			// console.log("here");
	// 			setStart(false);
	// 			setEnd(true);
	// 			setMessageData("File Download Completed");
	// 		})
	// 		.catch((err) => console.log(err));
	// };

	// const deletebootcamp = (bootcampHREFName) =>{

	// 	var r = window.confirm("Are you sure want to delete the bootcamp");

	// 	if(r === true){
	// 		axios.delete(`${API}/delete_bootcamp/${bootcampHREFName}`)
	// 			.then((response) =>{
	// 				if(response)
	// 					getAllCourses(selectedPage);
	// 			})
	// 	}

	// }

	const getAllCourses = () => {
		// console.log('BOOTCAMP PROPS', props)
		setReloadPage(false)
		axios
			.get(`${BOOTCAMP_API}/bootcamp/getAllBootCampCourses/${props.match.params.moduleObjectID}`)
			.then((response) => {
				if (response.data.result) {

					if (response.data.result.data.length > 0) {
						setcourseList(response.data.result.data);
						// settotalCourses(response.data.total_bootcamps)
					}
					else
						setIsEmptyList(true);
				}
			})
			.catch((err) => console.log(err));
	};


	const token = JSON.parse(localStorage.getItem('jwt'));

	const updatePublish = (publishType, lmsType, data) => {

		axios.put(`${BOOTCAMP_API}/bootcamp/updateBootCampCourse/${data._id}`, null, {
			headers: {
				"Content-Type": "application/json",
				'Authorization': token.token
			},
			data: {
				"title": data.title,
				"description": data.description,
				"instructor": data.instructor,
				"FAQ": data.FAQ,
				"timeStamp": new Date(),
				"mockReference": data.mockReference ? data.mockReference : '',
				"syllabus": data.syllabus,
				"publish": publishType,
				"premium": data.premium,
				lmsContent: lmsType
			}
		}).then((response) => {
			if (response.data.result.message) {
				setcourseList(prev => prev.filter(x => {
					if (x._id === data._id) {
						Object.assign(x, response.data.result.data);
					}
					return prev
				}))
			}
			else {
				alert(`No Course found to ${data.publish ? 'Publish' : 'Unpublish'}`);
			}
		})
			.catch((error) => {
				alert('error')
			})
	}

	// const nextbootcampSet = (e,value) =>{

	// 	setSelectedPage(value);

	// 	getAllCourses(value);
	// }

	// console.log("hackthon", hackathons);
	useEffect(() => {
		getAllCourses();
	}, [reloadPage]);

	// console.log("hackathon", Object.keys(hackathons).length);

	return (
		<AuthLayout>
			<React.Fragment>
				<div className="">
					{/* preloader */}
					{/* {this.props.loading && <Loader />} */}
					<Header name={props.match.params.moduleID} />
					<Row>
						<Col>

							<Link
								to={`/createCourse/${props.match.params.moduleID}/${props.match.params.moduleObjectID}`}
							>
								<ButtonToggle
									color="dark"
									style={{
										paddingLeft:
											"30px",
										paddingRight:
											"30px",
										margin: "15px",
										float: 'right'
									}}
								>
									Add Course
								</ButtonToggle>
							</Link>
						</Col>
					</Row>

					<Row>
						<Col lg={12}>
							<>
								{start && (
									<Snackbar
										open={start}
										onClose={handleClose}
									>
										<>
											<Alert
												onClose={handleClose}
												severity="success"
											>
												<DownloadLoader pexal="55px" />
												{messageData}
											</Alert>
										</>
									</Snackbar>
								)}
								{end && (
									<Snackbar
										open={end}
										onClose={handleCloseEnd}
									>
										<>
											<Alert
												onClose={handleCloseEnd}
												severity="success"
											>
												{messageData}
											</Alert>
										</>
									</Snackbar>
								)}

								{courseList.length > 0 ? (
									courseList.map((bootcamp, index) => (
										<Card key={index}>
											<Row>
												<Col lg={8}>
													<CardBody>
														{" "}
														<h4>
															{bootcamp.title}
															<FormControlLabel
																control={<Switch checked={bootcamp.lmsContent} onChange={(event) => updatePublish(bootcamp.publish, event.target.checked, bootcamp, index)} name="checkedA" />}
																label="LMS"
																className="ml-2 mb-0"
															/>
														</h4>
														<p>
															<span className="font-weight-bold">Users Participated:</span> {bootcamp.userParticipated} <br />
															<span className="font-weight-bold">Created At:</span> {moment(bootcamp.updated_at).format('MMMM Do, YYYY  h:mma')}
														</p>{" "}
													</CardBody>
												</Col>
												<Col
													lg={2}
													style={{
														textAlign: "right",
													}}
												>
													<CardBody
														style={{
															paddingLeft: "0px",
															paddingRight: "0px",
														}}
													>

														<ButtonToggle
															className={bootcamp.publish ? 'unpublish' : 'publish'}
															onClick={() => updatePublish(!bootcamp.publish, bootcamp.lmsContent, bootcamp, index)}
														// onClick={() => updateFlags(index, bootcampModule.Publish ? true : false)}
														>
															{bootcamp.publish ? 'UNPUBLISH' : 'PUBLISH'}
														</ButtonToggle>

														{" "}
														<Link
															to={`/updateCourse/${props.match.params.moduleID}/${props.match.params.moduleObjectID}/${bootcamp._id}`}
														>
															<ButtonToggle
																color="danger"
																style={{
																	paddingLeft:
																		"30px",
																	paddingRight:
																		"30px",
																}}
															>
																Edit
															</ButtonToggle>
														</Link>
														<br /><br />
														{" "}
														<Link
															to={`/sections/${(bootcamp.title).replace(/\?/g, '').trim()}/${bootcamp._id}`}
														>
															<ButtonToggle
																color="dark"
																style={{
																	paddingLeft:
																		"30px",
																	paddingRight:
																		"30px",
																}}
															>
																View Sections
															</ButtonToggle>
														</Link>
													</CardBody>
												</Col>
												{/* <Col 
													lg={2}
													>
														
														<CardBody
														style={{
															paddingLeft: "0px",
															paddingRight: "0px",
														}}
													>
														
														<ButtonToggle
																color="danger"
																style={{
																	paddingLeft:
																		"30px",
																	paddingRight:
																		"30px",
																}}
																onClick={(e) => deletebootcamp(bootcamp.HREFName)}
															>
																Delete
														</ButtonToggle>
													</CardBody>
												</Col> */}
											</Row>
										</Card>
									))
								) : (
									<>
										{
											isEmptyList ? (
												<div style={{ textAlign: "center" }}>
													No Courses Available
												</div>
											) : (
												<div style={{ textAlign: "center" }}>
													<Loader />
												</div>
											)
										}

									</>
								)}

								{/* <Card>
									<Col lg={12}>
										<Row style={{justifyContent:"center",paddingBottom:"20px"}}>
											<Pagination count={totalCourses % 10 === 0 ? parseInt(totalCourses / 10) : parseInt(totalCourses / 10)+1 }
														defaultPage={1}
														page={Number(selectedPage)}
														variant="outlined" 
                                						shape="rounded"
														className={classes.root}
														onChange={nextbootcampSet} 
														showFirstButton showLastButton  />
										</Row>
									</Col>
								</Card> */}

								<Dialog
									open={open}
									onClose={handleClose}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									fullWidth={true}
									maxWidth="md"
								>
									<DialogTitle id="alert-dialog-title">
										Downlaod Public/Private Leaderboard Data
									</DialogTitle>
									<DialogContent>
										{/* <DialogContentText id="alert-dialog-description">
						Let Google help apps determine location. This means
						sending anonymous location data to Google, even when no
						apps are running.
					</DialogContentText> */}
										<br />
										<div class="form-group row">
											<label
												class="col-sm-2  col-form-label"
												for="simpleinput"
											>
												Limit
											</label>
											<div class="col-sm-10">
												<input
													type="text"
													class="form-control"
													value={limit}
													onChange={(e) => {
														setLimit(
															e.target.value.trim()
														);
													}}
												/>
											</div>
										</div>

										<div class="form-group row">
											<label
												for="sel1"
												class="col-sm-2  col-form-label"
											>
												Sortby
											</label>
											<div class="col-sm-10">
												<select
													class="form-control"
													id="sel1"
													onChange={(e) => {
														setSort(e.target.value);
													}}
													value={sort}
												>
													<option value="PublicScore">
														PublicScore
													</option>
													<option value="PrivateScore">
														PrivateScore
													</option>
												</select>
											</div>
										</div>
										<br />
									</DialogContent>
									<DialogActions>
										<ButtonToggle
											color="danger"
											style={{
												paddingLeft: "30px",
												paddingRight: "30px",
											}}
											onClick={handleClose}
										>
											Cancel
										</ButtonToggle>
										{/* <button
											class="btn btn-pink waves-effect waves-light"
											onClick={downloadFileHackathon}
										>
											{" "}
											<span>Download</span> &nbsp;
											<i class="fas fa-download"></i>{" "}
										</button> */}
									</DialogActions>
								</Dialog>
							</>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		</AuthLayout>
	);
}
