import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { BOOTCAMP_API } from "../../../backend";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import EventLayout from "../../../components/EventLayout";

import Switch from "@material-ui/core/Switch";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function FormPopup(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();
  const [messageData, setMessageData] = useState("");
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [isformPopup, setIsformPopup] = useState(false);
  const [mandatoryFields, setMandatoryFields] = useState({
    Name: false,
    Designation: false,
    Experience: false,
    Location: false,
    MobileNumber: false,
    Resume: false,
    Organization: false,
  });
  const [visibleFields, setVisibleFields] = useState({
    Name: false,
    Designation: false,
    Experience: false,
    Location: false,
    MobileNumber: false,
    Resume: false,
    Organization: false,
  });

  const saveEventFormData = () => {
    const mandateField = [];
    const visibleField = [];
    Object.entries(mandatoryFields).map(([key, value]) => {
      if (value) {
        mandateField.push(key);
      }
    });
    Object.entries(visibleFields).map(([key, value]) => {
      if (value) {
        visibleField.push(key);
      }
    });
    const createEventBody = {
      featured: eventData.featured,
      publish: eventData.publish,
      formExists: isformPopup,
      formFieldsMandatory: mandateField,
      fieldsVisible: visibleField,
    };
    axios
      .put(
        `${BOOTCAMP_API}/events/updateEvents/${eventData._id}`,
        createEventBody
      )
      .then((response) => {
        console.log(response);
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          getEventData();
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  const getEventData = () => {
    axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        addDataToRedux(response.data.result.data);
      })
      .catch((err) => console.log(err));
  };
  const addDataToRedux = (eventResponseData) => {
    dispatch({
      type: "EVENT_DATA",
      payload: eventResponseData,
    });
    setIsformPopup(eventResponseData.formExists);
    eventResponseData.formFieldsMandatory.filter((x) => {
      setMandatoryFields((prev) => ({ ...prev, [x]: true }));
    });
    eventResponseData.fieldsVisible.filter((x) => {
      setVisibleFields((prev) => ({ ...prev, [x]: true }));
    });
  };
  useEffect(() => {
    getEventData();
  }, []);

  return (
    <EventLayout>
      {messageData && (
        <Snackbar
          open={successMessage}
          onClose={() => setSuccessMessage(false)}
        >
          <>
            <Alert onClose={() => setSuccessMessage(false)} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar open={errorStatus} onClose={() => setErrorStatus(false)}>
          <>
            <Alert onClose={() => setErrorStatus(false)} severity="error">
              {errorMessage}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        <div className="w-100 event-overview">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <br />
              <h4 className="mb-0">Form Popup</h4>
              <p>
                Whether you want to show this popup once the user land to event
                page
              </p>
            </div>
          </div>
          <div className="event-cardHld">
            <h4 className="mt-3 mb-0">Event Landing popup</h4>
            <div className="d-flex justify-content-between align-items-center">
              <label className="event-label">
                Enabling this option would allow you to see the event landing
                popup.
              </label>
              <Switch
                checked={isformPopup}
                onChange={() => setIsformPopup(!isformPopup)}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
            {isformPopup && (
              <div className="row">
                <div className="col-lg-8">
                  <br />
                  <h4 className="mt-3 mb-0">Name Field</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="event-label">
                      Enabling this option would allow you to make Name field
                      mandatory.
                    </label>
                  </div>
                  <h4 className="mt-3 mb-0">MobileNumber Field</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="event-label">
                      Enabling this option would allow you to make MobileNumber
                      field mandatory.
                    </label>
                  </div>
                  <h4 className="mt-3 mb-0">Organization Field</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="event-label">
                      Enabling this option would allow you to make Organization
                      field mandatory.
                    </label>
                  </div>
                  <h4 className="mt-3 mb-0">Designation Field</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="event-label">
                      Enabling this option would allow you to make Designation
                      field mandatory.
                    </label>
                  </div>
                  <h4 className="mt-3 mb-0">Location Field</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="event-label">
                      Enabling this option would allow you to make Location
                      field mandatory.
                    </label>
                  </div>
                  <h4 className="mt-3 mb-0">Experience Field</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="event-label">
                      Enabling this option would allow you to make Experience
                      field mandatory.
                    </label>
                  </div>
                  <h4 className="mt-3 mb-0">Resume Field</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <label className="event-label">
                      Enabling this option would allow you to make Resume field
                      mandatory.
                    </label>
                  </div>
                  
                </div>
                <div className="col-lg-2 text-center">
                  <h5>Visible Fields</h5>
                  <div className="mt-4">
                    <Switch
                      checked={visibleFields.Name}
                      onChange={(e) => {
                        setVisibleFields({
                          ...visibleFields,
                          Name: !visibleFields.Name,
                        });
                        if (!e.target.checked) {
                          setMandatoryFields({
                            ...mandatoryFields,
                            Name: !mandatoryFields.Name,
                          });
                        }
                      }}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={visibleFields.MobileNumber}
                      onChange={() =>
                        setVisibleFields({
                          ...visibleFields,
                          MobileNumber: !visibleFields.MobileNumber,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={visibleFields.Organization}
                      onChange={() =>
                        setVisibleFields({
                          ...visibleFields,
                          Organization: !visibleFields.Organization,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={visibleFields.Designation}
                      onChange={() =>
                        setVisibleFields({
                          ...visibleFields,
                          Designation: !visibleFields.Designation,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={visibleFields.Location}
                      onChange={() =>
                        setVisibleFields({
                          ...visibleFields,
                          Location: !visibleFields.Location,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={visibleFields.Experience}
                      onChange={() =>
                        setVisibleFields({
                          ...visibleFields,
                          Experience: !visibleFields.Experience,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={visibleFields.Resume}
                      onChange={() =>
                        setVisibleFields({
                          ...visibleFields,
                          Resume: !visibleFields.Resume,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  
                </div>
                <div className="col-lg-2 text-center">
                  <h5>Mandatory Fields</h5>
                  <div className="mt-4">
                    <Switch
                      checked={mandatoryFields.Name}
                      onChange={() =>
                        setMandatoryFields({
                          ...mandatoryFields,
                          Name: !mandatoryFields.Name,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={mandatoryFields.MobileNumber}
                      onChange={() =>
                        setMandatoryFields({
                          ...mandatoryFields,
                          MobileNumber: !mandatoryFields.MobileNumber,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={mandatoryFields.Organization}
                      onChange={() =>
                        setMandatoryFields({
                          ...mandatoryFields,
                          Organization: !mandatoryFields.Organization,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={mandatoryFields.Designation}
                      onChange={() =>
                        setMandatoryFields({
                          ...mandatoryFields,
                          Designation: !mandatoryFields.Designation,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={mandatoryFields.Location}
                      onChange={() =>
                        setMandatoryFields({
                          ...mandatoryFields,
                          Location: !mandatoryFields.Location,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={mandatoryFields.Experience}
                      onChange={() =>
                        setMandatoryFields({
                          ...mandatoryFields,
                          Experience: !mandatoryFields.Experience,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                  <div className="mt-4">
                    <Switch
                      checked={mandatoryFields.Resume}
                      onChange={() =>
                        setMandatoryFields({
                          ...mandatoryFields,
                          Resume: !mandatoryFields.Resume,
                        })
                      }
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                  </div>
                </div>
              </div>
            )}
            <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
            <div className="mr-auto d-flex justify-content-end">
              <button className="event-outlined-btn">CANCEL</button>
              <button
                className="event-primary-btn ml-2"
                onClick={saveEventFormData}
              >
                SAVE
              </button>
            </div>
          </div>
          <br />
        </div>
      </Row>
    </EventLayout>
  );
}
