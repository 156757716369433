import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import Loader from "../../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import DownloadLoader from "../../../DownloadUserData/Loader";
import EventLayout from "../../../../components/EventLayout";
import Grid from "@material-ui/core/Grid";
import SessionSidebar from "./SessionSidebar";
import { BOOTCAMP_API } from "../../../../backend";
import { DataGrid } from "@material-ui/data-grid";
import logoSm from "../../../../assets/images/logo-sm.png";
import { useHistory } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const moment = require("moment");
const useStyles = makeStyles({});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const columns = [
  {
    field: "id",
    renderCell: (cellValues) => {
      return (
        <div className="d-flex align-items-center">
          <img
            src={logoSm}
            alt="event-logo"
            className="img-fluid mr-2"
            style={{ width: "30px", height: "30px" }}
          />
          <p className="mt-2">Abhi Ram</p>
        </div>
      );
    },
    headerName: "Name",
    width: 200,
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
    // editable: true,
  },
  {
    field: "age",
    renderCell: (cellValues) => {
      return (
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-center mr-2">
            <p className="tableCellConTent mb-0">Attendee</p>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <p className="tableCellConTent mb-0">Speaker</p>
          </div>
        </div>
      );
    },
    headerName: "Group",
    width: 200,
    // editable: true,
  },
  {
    field: "designation",
    headerName: "Designation",
    width: 200,
    // editable: true,
  },
  {
    field: "organisaton",
    headerName: "Organisation",
    width: 200,
    // editable: true,
  },
];
const rows = [
  {
    id: 1,
    email: "abc@gmail.com",
    age: 35,
    designation: "Manager",
    organisaton: "My Company",
  },
  {
    id: 2,
    email: "abc@gmail.com",
    age: 35,
    designation: "Manager",
    organisaton: "My Company",
  },
  {
    id: 3,
    email: "abc@gmail.com",
    age: 35,
    designation: "Manager",
    organisaton: "My Company",
  },
  {
    id: 4,
    email: "abc@gmail.com",
    age: 35,
    designation: "Manager",
    organisaton: "My Company",
  },
  {
    id: 5,
    email: "abc@gmail.com",
    age: 35,
    designation: "Manager",
    organisaton: "My Company",
  },
  {
    id: 6,
    email: "abc@gmail.com",
    age: 35,
    designation: "Manager",
    organisaton: "My Company",
  },
  {
    id: 7,
    email: "abc@gmail.com",
    age: 35,
    designation: "Manager",
    organisaton: "My Company",
  },
];
export default function Attendees(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [currentNavigation, setCurrentNavigation] = useState("RegisteredList");
  const [SelectedList, setSelectedList] = React.useState([]);
  const [registeredTableData, setRegisteredTableData] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [MasterChecked, setMasterChecked] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const [SelectedListAttendes, setSelectedListAttendes] = React.useState([]);
  const [attendeesData, setAttendesData] = useState([]);
  const [openDeleteAttendeDialog, setOpenDeleteAttendeDialog] =
    React.useState(false);
  const [MasterCheckedAttendes, setMasterCheckedAttendes] =
    React.useState(false);

  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const eventId = props.match.params.eventId;
  const sessionId = props.match.params.sessionId;
  const history = useHistory();
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setEnd(!end);
  };

  const getEventData = () => {
    axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
      })
      .catch((err) => console.log(err));
  };

  const getAllRegistersData = async () => {
    const eventId = props.match.params.eventId;

    await axios
      .get(`${BOOTCAMP_API}/events/getAllTheRegisteredUsers/${eventId}`)
      .then((response) => {
        setRegisteredTableData(response.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const opendeletePeople = () => {
    setSelectedList(registeredTableData.filter((e) => e.selected));
    setOpenDeleteDialog(true);
    // setOpenGroupDialog(false);
  };

  const opendeleteAttendePeople = () => {
    setSelectedList(attendeesData.filter((e) => e.selected));
    setOpenDeleteAttendeDialog(true);
    // setOpenGroupDialog(false);
  };

  const onMasterCheck = (e) => {
    let tempList = registeredTableData;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));

    //Update State
    // setList(tempList);
    setRegisteredTableData(tempList);
    setMasterChecked(e.target.checked);
    setSelectedList(registeredTableData.filter((e) => e.selected));
  };

  // Update List Item's state and Master Checkbox State
  const onItemCheck = (e, item) => {
    let tempList = registeredTableData;
    tempList.map((user) => {
      if (user._id === item._id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = registeredTableData.length;
    const totalCheckedItems = registeredTableData.filter(
      (e) => e.selected
    ).length;

    // Update State
    // setList(tempList);
    setRegisteredTableData(tempList);
    setMasterChecked(totalItems === totalCheckedItems);
    setSelectedList(registeredTableData.filter((e) => e.selected));
  };

  const deletePeople = () => {
    const deleteUsersId = [];
    SelectedList.map((x) => {
      deleteUsersId.push(x._id);
    });
    axios
      .delete(`${BOOTCAMP_API}/events/deleteMultipleRegisteredUserData`, {
        data: {
          regIdArray: deleteUsersId,
        },
      })
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setOpenDeleteDialog(false);
          getAllRegistersData();
          setSelectedList([]);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  const getAllAttendesData = async () => {
    const eventId = props.match.params.eventId;

    await axios
      .get(
        `${BOOTCAMP_API}/events/getAllTheAtendeeUnderSessionUsers/${props.match.params.sessionId}`
      )
      .then((response) => {
        setAttendesData(response.data.result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onMasterCheckAttendes = (e) => {
    let tempList = attendeesData;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));

    //Update State
    // setList(tempList);
    setAttendesData(tempList);
    setMasterCheckedAttendes(e.target.checked);
    setSelectedListAttendes(attendeesData.filter((e) => e.selected));
  };

  // Update List Item's state and Master Checkbox State
  const onItemCheckAttendes = (e, item) => {
    let tempList = attendeesData;
    tempList.map((user) => {
      if (user._id === item._id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = attendeesData.length;
    const totalCheckedItems = attendeesData.filter((e) => e.selected).length;

    // Update State
    // setList(tempList);
    setAttendesData(tempList);
    setMasterCheckedAttendes(totalItems === totalCheckedItems);
    setSelectedListAttendes(attendeesData.filter((e) => e.selected));
  };

  const deleteAttendePeople = () => {
    const deleteUsersId = [];
    SelectedListAttendes.map((x) => {
      deleteUsersId.push(x.userId._id);
    });

    const deletedAttendesIds = {
      attendeeList: deleteUsersId,
    };

    axios
      .put(
        `${BOOTCAMP_API}/events/deleteAttendeUnderSession/${props.match.params.sessionId}`,
        deletedAttendesIds
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setOpenDeleteAttendeDialog(false);
          getAllAttendesData();
          setSelectedListAttendes([]);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getEventData();
    getAllRegistersData();
    getAllAttendesData();
  }, []);

  return (
    <EventLayout>
      {start && (
        <Snackbar open={start} onClose={handleClose}>
          <>
            <Alert onClose={handleClose} severity="success">
              <DownloadLoader pexal="55px" />
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {end && (
        <Snackbar open={end} onClose={handleCloseEnd}>
          <>
            <Alert onClose={handleCloseEnd} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        {Object.keys(eventData).length > 0 ? (
          <div className="w-100 event-overview">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <br />
                <ul className="eventsBreadcrumb p-0 d-flex align-items-center">
                  <li
                    className="cursor-pointer"
                    onClick={() =>
                      history.push(
                        `/event/${props.match.params.eventId}/sessions`
                      )
                    }
                  >
                    Sessions
                  </li>
                  <li style={{ color: "#353535" }}>{eventData.title}</li>
                </ul>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SessionSidebar eventId={eventId} sessionId={sessionId} />
              </Grid>
              <Grid item xs={9}>
                <div className="event-cardHld">
                  <h3>Attendees</h3>
                  <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
                  <div className="d-flex">
                    <h5
                      className="mb-1 mr-2 cursor-pointer"
                      style={{
                        fontWeight: "400",
                        borderBottom:
                          currentNavigation === "RegisteredList"
                            ? "3px solid #f44d5e"
                            : null,
                        paddingBottom:
                          currentNavigation === "RegisteredList"
                            ? "10px"
                            : null,
                        color:
                          currentNavigation === "RegisteredList"
                            ? "#f44d5e"
                            : null,
                      }}
                      onClick={() => setCurrentNavigation("RegisteredList")}
                    >
                      Registered List
                    </h5>
                    <h5
                      className="mb-1 cursor-pointer"
                      style={{
                        fontWeight: "400",
                        borderBottom:
                          currentNavigation === "AttendedList"
                            ? "3px solid #f44d5e"
                            : null,
                        paddingBottom:
                          currentNavigation === "AttendedList" ? "10px" : null,
                        color:
                          currentNavigation === "AttendedList"
                            ? "#f44d5e"
                            : null,
                      }}
                      onClick={() => setCurrentNavigation("AttendedList")}
                    >
                      Attended List
                    </h5>
                  </div>
                  <br />
                  <form className="app-search">
                    <div className="app-search-box">
                      <div className="input-group col-5 pl-0">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search By Name"
                        />
                        <div className="input-group-append">
                          <button className="btn" type="submit">
                            <i className="fe-search"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <br />
                  {currentNavigation === "RegisteredList" ? (
                    registeredTableData.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            {SelectedList.length === 0 && (
                              <tr>
                                <th scope="col">
                                  <input
                                    type="checkbox"
                                    className=""
                                    checked={MasterChecked}
                                    id="mastercheck"
                                    onChange={(e) => onMasterCheck(e)}
                                  />
                                </th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Group</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Organisation</th>
                              </tr>
                            )}
                            {SelectedList.length > 0 && (
                              <tr>
                                <th scope="col">
                                  <input
                                    type="checkbox"
                                    className=""
                                    checked={MasterChecked}
                                    id="mastercheck"
                                    onChange={(e) => onMasterCheck(e)}
                                  />
                                </th>
                                <th scope="col">
                                  {SelectedList.length}&nbsp;Selected
                                </th>
                                <th scope="col">
                                  <div
                                    className="cursor-pointer"
                                    onClick={opendeletePeople}
                                  >
                                    <span className="mr-1">
                                      <i className="mdi mdi-delete"></i>
                                    </span>
                                    <span className="brand-color">Delete</span>
                                  </div>
                                </th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                              </tr>
                            )}
                          </thead>
                          <tbody>
                            {registeredTableData.map((user) => (
                              <tr
                                key={user._id}
                                className={user.selected ? "selected" : ""}
                              >
                                <th scope="row">
                                  <input
                                    type="checkbox"
                                    checked={user.selected}
                                    className=""
                                    id="rowcheck{user.id}"
                                    onChange={(e) => onItemCheck(e, user)}
                                  />
                                </th>
                                <td>{user?.userId?.Name || "-"}</td>
                                <td>{user?.userId?.Email || "-"}</td>
                                <td className="peopleGroup">
                                  <span className="count">
                                    {user.userGroup.length}
                                  </span>
                                  {user.userGroup.map((group, index) => (
                                    <span key={index} className="groupName">
                                      {group}
                                    </span>
                                  ))}
                                </td>
                                <td>{user?.userId?.LatestDesignation || "-"}</td>
                                <td>{user?.userId?.Organization || "-"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="d-flex align-items-center justify-content-center">
                        No Users Found
                      </p>
                    )
                  ) : null}

                  {currentNavigation === "AttendedList" ? (
                    attendeesData.length > 0 ? (
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            {SelectedListAttendes.length === 0 && (
                              <tr>
                                <th scope="col">
                                  <input
                                    type="checkbox"
                                    className=""
                                    checked={MasterCheckedAttendes}
                                    id="mastercheck"
                                    onChange={(e) => onMasterCheckAttendes(e)}
                                  />
                                </th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Group</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Organisation</th>
                              </tr>
                            )}
                            {SelectedListAttendes.length > 0 && (
                              <tr>
                                <th scope="col">
                                  <input
                                    type="checkbox"
                                    className=""
                                    checked={MasterCheckedAttendes}
                                    id="mastercheck"
                                    onChange={(e) => onMasterCheckAttendes(e)}
                                  />
                                </th>
                                <th scope="col">
                                  {SelectedListAttendes.length}&nbsp;Selected
                                </th>
                                <th scope="col">
                                  <div
                                    className="cursor-pointer"
                                    onClick={opendeleteAttendePeople}
                                  >
                                    <span className="mr-1">
                                      <i className="mdi mdi-delete"></i>
                                    </span>
                                    <span className="brand-color">Delete</span>
                                  </div>
                                </th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                                <th scope="col"></th>
                              </tr>
                            )}
                          </thead>
                          <tbody>
                            {attendeesData.map((user) => (
                              <tr
                                key={user._id}
                                className={user.selected ? "selected" : ""}
                              >
                                <th scope="row">
                                  <input
                                    type="checkbox"
                                    checked={user.selected}
                                    className=""
                                    id="rowcheck{user.id}"
                                    onChange={(e) =>
                                      onItemCheckAttendes(e, user)
                                    }
                                  />
                                </th>
                                <td>{user.userId.Name || "-"}</td>
                                <td>{user.userId.Email || "-"}</td>
                                <td className="peopleGroup">
                                  <span className="count">
                                    {user.userGroup.length}
                                  </span>
                                  {user.userGroup.map((group, index) => (
                                    <span key={index} className="groupName">
                                      {group}
                                    </span>
                                  ))}
                                </td>
                                <td>{user.userId.LatestDesignation || "-"}</td>
                                <td>{user.userId.Organization || "-"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="d-flex align-items-center justify-content-center">
                        No Users Found
                      </p>
                    )
                  ) : null}
                </div>
                <br />
              </Grid>
            </Grid>

            <Dialog
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogContent dividers={scroll === "paper"}>
                <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                  <strong>People deleted once can't be retrieved.</strong>
                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={check}
                        onChange={() => {
                          setCheck(!check);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Are you sure you want to delete this user"
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDeleteDialog(false)}
                  style={{
                    backgroundColor: "rgb(250, 53, 88)",
                    color: "#fff",
                  }}
                >
                  No
                </Button>
                {check ? (
                  <Button
                    onClick={deletePeople}
                    style={{
                      backgroundColor: "#019031",
                      color: "#fff",
                    }}
                  >
                    Yes
                  </Button>
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#dbdbdb",
                      color: "#fff",
                    }}
                  >
                    Yes
                  </Button>
                )}
              </DialogActions>
            </Dialog>

            <Dialog
              open={openDeleteAttendeDialog}
              onClose={() => setOpenDeleteAttendeDialog(false)}
              scroll={scroll}
              aria-labelledby="scroll-dialog-title"
              aria-describedby="scroll-dialog-description"
            >
              <DialogContent dividers={scroll === "paper"}>
                <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                  <strong>People deleted once can't be retrieved.</strong>
                  <br />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={check}
                        onChange={() => {
                          setCheck(!check);
                        }}
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label="Are you sure you want to delete this user"
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpenDeleteAttendeDialog(false)}
                  style={{
                    backgroundColor: "rgb(250, 53, 88)",
                    color: "#fff",
                  }}
                >
                  No
                </Button>
                {check ? (
                  <Button
                    onClick={deleteAttendePeople}
                    style={{
                      backgroundColor: "#019031",
                      color: "#fff",
                    }}
                  >
                    Yes
                  </Button>
                ) : (
                  <Button
                    style={{
                      backgroundColor: "#dbdbdb",
                      color: "#fff",
                    }}
                  >
                    Yes
                  </Button>
                )}
              </DialogActions>
            </Dialog>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
      </Row>
    </EventLayout>
  );
}
