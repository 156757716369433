let initialState = {
	notifications: [],
	event_notifications: []
};

function notificationsReducer(state = initialState, action) {
	if (action.type === "GET_ALL_NOTIFICATIONS") {
		return {
			...state,
			notifications: action.payload,
		};
	}
	if (action.type === "GET_ALL_EVENT_NOTIFICATIONS") {
        return {
          ...state,
          event_notifications: action.payload
        };
      }
	return state;
}

export default notificationsReducer;
