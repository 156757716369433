import { combineReducers, createStore } from "redux";
import authReducer from "./reducers/authReducer";
import userReducer from "./reducers/userReducer";
import hackathonsReducer from "./reducers/hackathonsReducer";
import globalRankingReducer from "./reducers/globalRankingReducer";
import practicesReducer from "./reducers/practicesReducer";
import bootcampsReducer from "./reducers/bootcampsReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import blogathonReducer from "./reducers/blogathonReducer";
import eventsReducer from "./reducers/eventsReducer";

let reducer = combineReducers({
	auth: authReducer,
	user: userReducer,
	hackathons: hackathonsReducer,
	globalRankings: globalRankingReducer,
	practices: practicesReducer,
	bootcamps: bootcampsReducer,
	notifications: notificationsReducer,
	blogathons: blogathonReducer,
	events: eventsReducer
});

let store = createStore(
	reducer
	// window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => {
	// console.log("dispatched: ", store.getState());
});

function stateMapper(state) {
	return state;
}

export { store, stateMapper };
