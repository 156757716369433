import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API } from "../../../backend";
import Loader from "../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import EventLayout from "../../../components/EventLayout";
import Drawer from "@material-ui/core/Drawer";
import Select from "react-select";
import { DropzoneArea } from "material-ui-dropzone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import { FormControl } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
const useStyles = makeStyles({
  root: {
    minHeight: 206,
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardbody: {
    padding: "0 10px 10px 10px",
    borderRadius: "8px",
  },
  eventbanner: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "100px",
    objectFit: "fill",
    maxWidth: "100%",
  },
  eventName: {
    fontWeight: 500,
    display: "-webkit-box",
    overflow: "hidden",
    "-mozBoxOrient": "vertical",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": 2,
  },
  moreOptions: {
    background: "#ffffff",
    position: "absolute",
    right: "10px",
    top: "6px",
    borderRadius: "50px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f44d5e",
    fontSize: "20px",
  },
  optionMenu: {
    boxShadow: "none",
    border: "1px solid #dbdbdb",
    "& div": {
      boxShadow: "none",
      border: "1px solid #dbdbdb",
    },
  },
  optionMenuItem: {
    boxShadow: "none",
  },
  createEventDrawer: {
    width: "40%",
  },
  creatEventHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    overflowX: "hidden",
  },
  spacingCreateEvent: {
    padding: "10px 30px 10px 30px",
    height: "calc(100vh - 142px)",
    overflow: "auto",
  },
  eventType: {
    width: "50%",
    textAlign: "center",
    color: "gray",
    marginBottom: 0,
    padding: "8px",
  },
  border: {
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
  },
  selectedEventType: {
    background: "#f44d5e",
    color: "#ffffff",
    width: "50%",
    textAlign: "center",
    marginBottom: 0,
    padding: "8px",
  },
  textField: {
    marginRight: 10,
    border: "1px solid rgba(53, 53, 53, 0.17)",
    borderRadius: "4px",
    color: "#353535",
    width: "inherit",
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
  createBtnDisabled: {
    background: "#c4c4c4",
    color: "#000000",
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    fontSize: "12px",
    marginRight: "10px",
  },
});
const options = [
  { value: "ATTENDEE", label: "Attendee" },
  { value: "BOOTH MEMBER", label: "Booth Member" },
  { value: "SPEAKER", label: "Speaker" },
];
const Users = [
  {
    id: 1,
    selected: false,
    name: "Leanne Graham",
    email: "Sincere@april.biz",
    phone: "1-770-736-8031 x56442",
    website: "hildegard.org",
  },
  {
    id: 2,
    selected: false,
    name: "Ervin Howell",
    email: "Shanna@melissa.tv",
    phone: "010-692-6593 x09125",
    website: "anastasia.net",
  },
  {
    id: 3,
    selected: false,
    name: "Clementine Bauch",
    email: "Nathan@yesenia.net",
    phone: "1-463-123-4447",
    website: "ramiro.info",
  },
  {
    id: 4,
    selected: false,
    name: "Patricia Lebsack",
    email: "Julianne.OConner@kory.org",
    phone: "493-170-9623 x156",
    website: "kale.biz",
  },
  {
    id: 5,
    selected: false,
    name: "Chelsey Dietrich",
    email: "Lucio_Hettinger@annie.ca",
    phone: "(254)954-1289",
    website: "demarco.info",
  },
];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function People(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();
  const [messageData, setMessageData] = useState("");
  const classes = useStyles();
  const [bannerImg, setBannerImg] = useState([]);
  const [drawer, setDrawer] = React.useState({
    filter: false,
    addPeople: false,
  });

  const [openPosterDel, setopenPosterDel] = React.useState({
    show: false,
    data: {},
    imagetype: "",
  });
  const [peopleData, setPeopleData] = useState([]);

  const [picture, setPicture] = useState("");
  const [check, setCheck] = React.useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [experience, setExperience] = useState("");
  const [location, setLocation] = useState("");
  const [designation, setdesignation] = useState("");
  const [organization, setOrganization] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [github, setGithub] = useState("");
  const [twitter, setTwitter] = useState("");
  const [userGroup, setUserGroup] = useState([]);
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);

  const [List, setList] = React.useState(Users);
  const [MasterChecked, setMasterChecked] = React.useState(false);
  const [SelectedList, setSelectedList] = React.useState([]);
  const [isEditPeople, setIsEditPeople] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openGroupDialog, setOpenGroupDialog] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [limit, setLimit] = React.useState(5);
  const [peopleCount, setPeopleCount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [preview, setPreview] = useState({});
  const [bio, setBio] = useState("");
  const onSelecthandleChange = (selectedOption, option) => {
    if (option.removedValue && option.removedValue.isFixed) return;
    setUserGroup(selectedOption);
  };

  const onImagesVideosClick = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setBannerImg(undefined);
      return;
    }

    if (
      e.target.files[0].type != "image/png" &&
      e.target.files[0].type != "image/jpeg"
    ) {
      setBannerImg(undefined);
      return;
    }
    // I've kept this example simple by using the first image instead of multiple
    setBannerImg(e.target.files);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setPreview(objectUrl);
  };
  const fileInput = useRef(null);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  const addPeople = () => {
    const formData = new FormData();
    bannerImg.length > 0 && formData.append("Picture", bannerImg[0]);
    firstName && formData.append("FirstName", firstName);
    lastName && formData.append("LastName", lastName);
    email && formData.append("Email", email);
    location && formData.append("Location", location);
    organization && formData.append("Organization", organization);
    designation && formData.append("LatestDesignation", designation);
    experience && formData.append("Experience", experience);
    mobile && formData.append("Mobile", mobile);
    linkedIn && formData.append("LinkedIn", linkedIn);
    github && formData.append("GitHub", github);
    twitter && formData.append("Twitter", twitter);
    bio && formData.append("Bio", bio);
    formData.append("EventId", props.match.params.eventId);
    axios
      .post(`${API}/events/register/user`, formData)
      .then((response) => {
        console.log(response);
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.error);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.message);
          const formData = new FormData();
          // formData.append("userId", userID);
          formData.append("eventId", props.match.params.eventId);
          let groupData = [];
          userGroup.filter((x) => {
            groupData.push(x.value);
          });
          formData.append("userGroup", groupData);
          setDrawer({ ...drawer, addPeople: false });
          getPeoplesData();
          // axios
          //   .post(`${BOOTCAMP_API}/events/registerForEvent`, formData)
          //   .then((response) => {
          //     if (response.data.error) {
          //       setErrorStatus(true);
          //       setErrormesage(response.data.result.message);
          //     } else {
          //       setSuccessMessage(true);
          //       setDrawer({ ...drawer, ["addPeople"]: false });
          //       setMessageData(response.data.result.message);
          //       resetAllData();
          //     }
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const UpdatePeople = () => {
    const formData = new FormData();
    bannerImg.length > 0 && formData.append("Picture", bannerImg[0]);
    formData.append(
      "Name",
      (firstName !== undefined ? firstName : "") +
        " " +
        (lastName !== undefined ? lastName : "")
    );
    location && formData.append("Location", location);
    organization && formData.append("Organization", organization);
    designation && formData.append("LatestDesignation", designation);
    experience && formData.append("Experience", experience);
    mobile && formData.append("Mobile", mobile);
    linkedIn && formData.append("LinkedIn", linkedIn);
    github && formData.append("GitHub", github);
    twitter && formData.append("Twitter", twitter);
    bio && formData.append("Bio", bio);
    axios
      .put(`${API}/user/update/${SelectedList[0].userId._id}`, formData)
      .then((response) => {
        if (response.data) {
          setSuccessMessage(true);
          setMessageData(response.data?.message);
          editUserGroups();
          getPeoplesData();
          setSelectedList([]);
          setMasterChecked(false);
        } else {
          setErrorStatus(true);
          setErrormesage(response.data.message);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const editUserGroups = () => {
    let groupData = [];
    userGroup.filter((x) => {
      groupData.push(x.value);
    });
    const peopleGroup = {
      userGroup: groupData,
    };
    axios
      .put(
        `${BOOTCAMP_API}/events/updatePeopleWhoRegistered/${SelectedList[0]._id}`,
        peopleGroup
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setDrawer({ ...drawer, ["addPeople"]: false });
          setMessageData(response.data.result.message);
          setOpenGroupDialog(false);
          setPeopleData((prev) =>
            prev.filter((x) => {
              if (x._id === SelectedList[0]._id) {
                x.userGroup = groupData;
              }
              return prev;
            })
          );
          resetAllData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const resetAllData = () => {
    setfirstName("");
    setlastName("");
    setMobile("");
    setExperience("");
    setGithub("");
    setLinkedIn("");
    setLocation("");
    setOrganization("");
    setEmail("");
    setdesignation("");
    setTwitter("");
  };

  const getEventData = () => {
    axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        console.log("event Id", props.match.params.eventId);
        console.log(response);
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
      })
      .catch((err) => console.log(err));
  };
  // Select/ UnSelect Table rows
  const onMasterCheck = (e) => {
    let tempList = peopleData;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));

    //Update State
    setList(tempList);
    setPeopleData(tempList);
    setMasterChecked(e.target.checked);
    setSelectedList(peopleData.filter((e) => e.selected));
  };

  // Update List Item's state and Master Checkbox State
  const onItemCheck = (e, item) => {
    let tempList = peopleData;
    tempList.map((user) => {
      if (user._id === item._id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = peopleData.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    // Update State
    setList(tempList);
    setPeopleData(tempList);
    setMasterChecked(totalItems === totalCheckedItems);
    setSelectedList(peopleData.filter((e) => e.selected));
  };

  const deletePeople = () => {
    if (SelectedList.length === 1) {
      axios
        .delete(
          `${BOOTCAMP_API}/events/deleteSpecificRegisteredData/${SelectedList[0]._id}`
        )
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setOpenDeleteDialog(false);
            setPeopleData((prev) =>
              prev.filter((x) => {
                return x._id !== SelectedList[0]._id;
              })
            );
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    } else {
      const deleteUsersId = [];
      SelectedList.map((x) => {
        deleteUsersId.push(x._id);
      });
      const deleteUsers = {
        regIdArray: deleteUsersId,
      };
      axios
        .delete(`${BOOTCAMP_API}/events/deleteMultipleRegisteredUserData`, {
          data: {
            regIdArray: deleteUsersId,
          },
        })
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setOpenDeleteDialog(false);
            getPeoplesData();
            setSelectedList([]);
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    }
  };
  const editPeople = () => {
    setIsEditPeople(true);
    setSelectedList(peopleData.filter((e) => e.selected));
    setDrawer({ ...drawer, addPeople: true });
    const selectedPeople = peopleData.filter((e) => e.selected);
    const particularUserId = selectedPeople[0]?.userId?._id;
    axios
      .get(`${API}/user/newprofile/${particularUserId}`)
      .then((response) => {
        setEmail(response.data.user.Email);
        setPicture(response.data.user?.Picture);
        setExperience(response.data.user?.Experience);
        setMobile(response.data.user?.Mobile);
        setLocation(response.data.user?.Location);
        setdesignation(response.data.user?.LatestDesignation);
        setOrganization(response.data.user?.Organization);
        setLinkedIn(response.data.user?.LinkedIn);
        setGithub(response.data.user?.GitHub);
        setTwitter(response.data.user?.Twitter);
        setBio(response.data.user?.Bio);
      })
      .catch((err) => console.log(err));
    setfirstName(selectedPeople[0].userId?.Name?.split(" ")[0]);
    setlastName(selectedPeople[0].userId?.Name?.split(" ")[1]);
    // setEmail(selectedPeople[0].userId?.Email);
    // setExperience(selectedPeople[0].userId?.Experience);
    // setMobile(selectedPeople[0].userId?.Mobile);
    // setLocation(selectedPeople[0].userId?.Location);
    // setdesignation(selectedPeople[0].userId?.LatestDesignation);
    // setOrganization(selectedPeople[0].userId?.Organization);
    // setLinkedIn(selectedPeople[0].userId?.LinkedIn);
    // setGithub(selectedPeople[0].userId?.GitHub);
    // setTwitter(selectedPeople[0].userId?.Twitter);
    console.log(selectedPeople);
    const peoplegroupArray = [];
    selectedPeople[0].userGroup.map((x) => {
      const peoplegroup = {};
      peoplegroup.label = x;
      peoplegroup.value = x;
      peoplegroupArray.push(peoplegroup);
    });
    setUserGroup(peoplegroupArray);
  };
  const opendeletePeople = () => {
    setSelectedList(peopleData.filter((e) => e.selected));
    setOpenDeleteDialog(true);
    setOpenGroupDialog(false);
  };
  const openAssignGroupsModal = () => {
    setSelectedList(peopleData.filter((e) => e.selected));
    const selectedPeople = peopleData.filter((e) => e.selected);
    const peoplegroupArray = [];
    selectedPeople[0].userGroup.map((x) => {
      const peoplegroup = {};
      peoplegroup.label = x;
      peoplegroup.value = x;
      peoplegroupArray.push(peoplegroup);
      if (x === "ATTENDEE") {
        peoplegroup.visited = true;
        peoplegroup.isFixed = true;
      } else {
        peoplegroup.visited = false;
        peoplegroup.isFixed = false;
      }
    });
    setUserGroup(peoplegroupArray);
    setOpenGroupDialog(true);
    setOpenDeleteDialog(false);
  };
  const addPeopleOpen = () => {
    setIsEditPeople("");
    setDrawer({ ...drawer, addPeople: true });
  };
  const getPeoplesData = () => {
    const paramData = {
      limit: limit,
      current_page: selectedPage,
    };
    axios
      .get(
        `${BOOTCAMP_API}/events/getAllTheRegisteredUsers/${props.match.params.eventId}`,
        {
          params: paramData,
        }
      )
      .then((response) => {
        let peopleResponse = response.data.result.data.filter((x, index) => {
          x.selected = false;
          return x;
        });
        setPeopleData(peopleResponse);
        setPeopleCount(response.data.result.totalPeople);
      })
      .catch((err) => console.log(err));
  };

  const handlePaginationChange = (e, value) => {
    setSelectedPage(value);
  };

  useEffect(() => {
    getPeoplesData();
  }, [limit, selectedPage]);

  useEffect(() => {
    getEventData();
  }, []);

  return (
    <EventLayout>
      {messageData && (
        <Snackbar
          open={successMessage}
          onClose={() => setSuccessMessage(false)}
        >
          <>
            <Alert onClose={() => setSuccessMessage(false)} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar open={errorStatus} onClose={() => setErrorStatus(false)}>
          <>
            <Alert onClose={() => setErrorStatus(false)} severity="error">
              {errorMessage}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        {Object.keys(eventData).length > 0 ? (
          <div className="w-100 event-overview">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <br />
                <h4 className="mb-0">People</h4>
                <p>
                  Adding sessions allows you to configure streaming,
                  registrations, visibility, and more.
                </p>
              </div>
            </div>
            <div className="event-cardHld">
              <div className="d-flex align-items-center justify-content-between">
                <form className="app-search">
                  <div className="app-search-box">
                    <div className="input-group pl-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                      <div className="input-group-append">
                        <button className="btn" type="submit">
                          <i className="fe-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="d-flex align-items-center">
                  <button
                    className="mr-2 event-outlined-btn"
                    onClick={toggleDrawer("filter", true)}
                  >
                    <i className="mdi mdi-filter-outline"></i>
                  </button>
                  {/* <button className="mr-2 event-outlined-btn">
                    <i className="mdi mdi-delete"></i>
                  </button> */}
                  <button
                    className="mr-2 event-primary-btn"
                    onClick={addPeopleOpen}
                  >
                    Add People <i className="mdi mdi-menu-down ml-1"></i>
                  </button>
                </div>
              </div>
              <hr />
              <div style={{ width: "100%" }}>
                {/* <DataGrid
                  rows={peopleData}
                  columns={columns}
                  pageSize={5}
                  checkboxSelection
                  disableSelectionOnClick
                  onSelectionModelChange={(_id) => setPeopleIds(_id)}
                  disableColumnMenu
                  disableColumnFilter
                  autoHeight
                /> */}
              </div>
              <table className="table">
                <thead>
                  {SelectedList.length === 0 && (
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          className=""
                          checked={MasterChecked}
                          id="mastercheck"
                          onChange={(e) => onMasterCheck(e)}
                        />
                      </th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Group</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Organisation</th>
                    </tr>
                  )}
                  {SelectedList.length === 1 && (
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          className=""
                          checked={MasterChecked}
                          id="mastercheck"
                          onChange={(e) => onMasterCheck(e)}
                        />
                      </th>
                      <th scope="col">{SelectedList.length} Selected</th>
                      <th scope="col">
                        <div className="cursor-pointer" onClick={editPeople}>
                          <span className="mr-1">
                            <i className="mdi mdi-pencil"></i>
                          </span>
                          <span className="brand-color">Edit</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          className="cursor-pointer"
                          onClick={opendeletePeople}
                        >
                          <span className="mr-1">
                            <i className="mdi mdi-delete"></i>
                          </span>
                          <span className="brand-color">Delete</span>
                        </div>
                      </th>
                      {/* <th scope="col">
                        <span className="mr-1 cursor-pointer">
                          <i className="mdi mdi-send"></i>
                        </span>
                        <span className="brand-color cursor-pointer">
                          send Invite Emailer
                        </span>
                      </th> */}
                      <th scope="col">
                        <div
                          className="cursor-pointer"
                          onClick={openAssignGroupsModal}
                        >
                          <span className="mr-1">
                            <i className="mdi mdi-tag"></i>
                          </span>
                          <span className="brand-color">Assign Groups</span>
                        </div>
                      </th>
                      <th scope="col"></th>
                    </tr>
                  )}
                  {SelectedList.length > 1 && (
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          className=""
                          checked={MasterChecked}
                          id="mastercheck"
                          onChange={(e) => onMasterCheck(e)}
                        />
                      </th>
                      <th scope="col">{SelectedList.length} Selected</th>
                      <th scope="col">
                        <div
                          className="cursor-pointer"
                          onClick={opendeletePeople}
                        >
                          <span className="mr-1">
                            <i className="mdi mdi-delete"></i>
                          </span>
                          <span className="brand-color">Delete</span>
                        </div>
                      </th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {peopleData.map((user) => (
                    <tr
                      key={user._id}
                      className={user.selected ? "selected" : ""}
                    >
                      <th scope="row">
                        <input
                          type="checkbox"
                          checked={user.selected}
                          className=""
                          id="rowcheck{user.id}"
                          onChange={(e) => onItemCheck(e, user)}
                        />
                      </th>
                      <td>{user?.userId?.Name || "-"}</td>
                      <td>{user?.userId?.Email || "-"}</td>
                      <td className="peopleGroup">
                        <span className="count">{user?.userGroup?.length}</span>
                        {user?.userGroup.map((group, index) => (
                          <span key={index} className="groupName">
                            {group}
                          </span>
                        ))}
                      </td>
                      <td>{user?.userId?.LatestDesignation || "-"}</td>
                      <td>{user?.userId?.Organization || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex align-items-center justify-content-between">
                <select
                  name="page"
                  id="page"
                  value={limit}
                  onChange={(e) => {
                    setLimit(e.target.value);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                </select>
                <Pagination
                  count={
                    peopleCount % limit === 0
                      ? parseInt(peopleCount / limit)
                      : parseInt(peopleCount / limit) + 1
                  }
                  defaultPage={1}
                  page={Number(selectedPage)}
                  variant="outlined"
                  shape="rounded"
                  // className={classes.root}
                  onChange={handlePaginationChange}
                  showFirstButton
                  showLastButton
                  color="secondary"
                />
              </div>
              {/* <button
                className="btn btn-primary"
                onClick={() => getSelectedRows()}
              >
                Get Selected Items {SelectedList.length}
              </button> */}

              <br />
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
      </Row>
      <Drawer
        anchor="right"
        open={drawer.addPeople}
        onClose={toggleDrawer("addPeople", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              {isEditPeople ? <h4>Edit People</h4> : <h4>Add People</h4>}
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("addPeople", false)}
                onKeyDown={toggleDrawer("addPeople", false)}
              ></i>
            </Col>
          </div>

          <Col lg={12} className={classes.spacingCreateEvent}>
            <div className="row">
              {/* <div className="col-4">
                <div className="addSession mt-2">
                  <DropzoneArea
                    acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                    onChange={(files) => setBannerImg(files)}
                    maxFileSize={9242880}
                  />
                </div>
              </div> */}

              <div className="col-4">
                <div className="addSession mt-2">{console.log(bannerImg)}
                  {Object.keys(preview)?.length > 0 || picture !== "" ? (
                    <div className="ImgHld">
                      <img src={Object.keys(preview)?.length > 0 ? preview : picture} alt="" className="img-fluid" />
                      <div className="Imagesoverlay d-flex">
                        <span
                          onClick={() => fileInput.current && fileInput.current.click()}
                        >
                          <i
                            className="mdi mdi-account-edit brand-color"
                            style={{ fontSize: "30px" }}
                          ></i>
                        </span>
                        <input
                          ref={fileInput}
                          id="filePicker"
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={onImagesVideosClick}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="mt-2">
                      <DropzoneArea
                        acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                        onChange={(files) => setBannerImg(files)}
                        maxFileSize={9242880}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="col-8 mt-2">
                <div>
                  <label htmlFor="title" className="event-label">
                    First Name
                  </label>
                  <input
                    id="title"
                    type="text"
                    className="form-control"
                    placeholder="Enter First Name"
                    value={firstName || ""}
                    onChange={(e) => setfirstName(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <label htmlFor="title" className="event-label">
                    Last Name
                  </label>
                  <input
                    id="title"
                    type="text"
                    className="form-control"
                    placeholder="Enter Last Name"
                    value={lastName || ""}
                    onChange={(e) => setlastName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* <div className="mt-2">
              <label htmlFor="title">Group</label>
              <Select
                onChange={onSelecthandleChange}
                isMulti
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Group"
                value={userGroup}
              />
            </div> */}

            <div className="mt-3 mb-3">
              <label htmlFor="title" className="event-label">
                EmailID
              </label>
              <input
                id="email"
                type="text"
                className="form-control"
                placeholder="Enter Email ID"
                value={email || ""}
                onChange={(e) => setEmail(e.target.value)}
                disabled={isEditPeople ? true : false}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="title" className="event-label">
                Mobile No
              </label>
              <input
                id="mobile"
                type="text"
                className="form-control"
                placeholder="Enter Mobile Number"
                value={mobile || ""}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>

            <div className="mt-3 mb-3">
              <label htmlFor="title" className="event-label">
                Total Experience
              </label>
              <input
                id="experience"
                type="text"
                className="form-control"
                placeholder="Enter Total Experience"
                value={experience || ""}
                onChange={(e) => setExperience(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="title" className="event-label">
                Location
              </label>
              <input
                id="location"
                type="text"
                className="form-control"
                placeholder="Enter Current Location"
                value={location || ""}
                onChange={(e) => setLocation(e.target.value)}
              />
            </div>

            <div className="mt-3 mb-3">
              <label htmlFor="title" className="event-label">
                Designation
              </label>
              <input
                id="designation"
                type="text"
                className="form-control"
                placeholder="Enter Current Designation"
                value={designation || ""}
                onChange={(e) => setdesignation(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="title" className="event-label">
                Organization
              </label>
              <input
                id="organization"
                type="text"
                className="form-control"
                placeholder="Enter Current Organization"
                value={organization || ""}
                onChange={(e) => setOrganization(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="title" className="event-label">
                Bio
              </label>
              <input
                id="bio"
                type="text"
                className="form-control"
                placeholder="Enter Bio"
                value={bio || ""}
                onChange={(e) => setBio(e.target.value)}
              />
            </div>

            <div className="">
              <label htmlFor="title" className="event-label">
                LinkedIn
              </label>
              <input
                id="linkedIn"
                type="text"
                className="form-control"
                placeholder="https://linkedin.com/in/username"
                value={linkedIn || ""}
                onChange={(e) => setLinkedIn(e.target.value)}
              />
            </div>

            <div className="mt-3">
              <label htmlFor="title" className="event-label">
                Github
              </label>
              <input
                id="github"
                type="text"
                className="form-control"
                placeholder="https://gitgub.com/in/username"
                value={github || ""}
                onChange={(e) => setGithub(e.target.value)}
              />
            </div>

            <div className="mt-3">
              <label htmlFor="title" className="event-label">
                Twitter
              </label>
              <input
                id="twitter"
                type="text"
                className="form-control"
                placeholder="https://twitter.com/in/username"
                value={twitter || ""}
                onChange={(e) => setTwitter(e.target.value)}
              />
            </div>
            <div className="px-2">
              <br />
              {/* <FormControlLabel
                control={
                  <Checkbox
                    checked={check}
                    onChange={() => {
                      setCheck(!check);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Let attendees rate this booth"
              /> */}
            </div>

            <br />
          </Col>
          <div className="pt-3 px-3">
            {isEditPeople ? (
              <button className={classes.createBtn} onClick={UpdatePeople}>
                Save
              </button>
            ) : (
              <>
                {email !== "" && firstName !== "" && lastName !== "" ? (
                  <button className={classes.createBtn} onClick={addPeople}>
                    ADD
                  </button>
                ) : (
                  <button className={classes.createBtnDisabled} disabled>
                    ADD
                  </button>
                )}
              </>
            )}
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("addPeople", false)}
              onKeyDown={toggleDrawer("addPeople", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={drawer.filter}
        onClose={toggleDrawer("filter", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Filter By</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("filter", false)}
                onKeyDown={toggleDrawer("filter", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <div>
              <label htmlFor="title">Group</label>
              <Select
                onChange={onSelecthandleChange}
                isMulti
                options={options}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Group"
              />
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-6">
                <label htmlFor="title" className="event-label">
                  Designation
                </label>
                <input
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder="Enter Designation"
                />
              </div>

              <div className="col-6">
                <label htmlFor="title" className="event-label">
                  Organization
                </label>
                <input
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder="Enter Organization"
                />
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-6">
                <label htmlFor="title" className="event-label">
                  Location
                </label>
                <input
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder="Enter Location"
                />
              </div>
            </div>
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn}>Apply</button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("filter", false)}
              onKeyDown={toggleDrawer("filter", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>

      <Dialog
        open={openPosterDel.show}
        onClose={() => setopenPosterDel({ ...openPosterDel, show: false })}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong>Image deleted once can't be retrieved.</strong>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete this Image"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setopenPosterDel({ ...openPosterDel, show: false })}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              // onClick={deletePoster}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong>People deleted once can't be retrieved.</strong>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete this user"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deletePeople}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={openGroupDialog}
        onClose={() => setOpenGroupDialog(false)}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong style={{ fontSize: "18px" }}> Group Assignment</strong>
            <br />
            <br />
            <FormControl style={{ minWidth: "500px" }}>
              <label htmlFor="title">Group</label>
              <Select
                onChange={onSelecthandleChange}
                isMulti
                options={options}
                isClearable={!userGroup.some((type) => type.visited)}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Group"
                value={userGroup}
              />
            </FormControl>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenGroupDialog(false)}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {SelectedList.length > 0 ? (
            <Button
              onClick={editUserGroups}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </EventLayout>
  );
}
