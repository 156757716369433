import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/AuthLayout";
import { Row, Col, Card, CardBody } from "reactstrap";
import Header from "../Header";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API } from "../../backend";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TablePagination from "@material-ui/core/TablePagination";
import { Link } from "react-router-dom";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MuiAlert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";

import { getUserInfo } from "../../user/helper/userapicall";
import Pagination from "@material-ui/lab/Pagination";

function AlertComponent(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: "#fa3558",
		color: theme.palette.common.white,
		fontSize: 14,
		paddingTop: "12px",
		paddingBottom: "12px",
	},
	body: {
		fontSize: 12,
		paddingTop: "5px",
		paddingBottom: "5px",
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:hover": {
			backgroundColor: "#e6e6e6",
		},
	},
}))(TableRow);

// const useStyle = makeStyles({
// 	table: {
// 		minWidth: 700,
// 	},
// });

const useStyle = makeStyles((theme) => ({
	appBar: {
		position: "relative",
		backgroundColor: "#fa3558",
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
	table: {
		minWidth: 700,
	},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AllUsers() {
	const classes = useStyle();
	const leaderBoard = useSelector(
		(store) => store.globalRankings.globalRankings
	);
	// console.log(leaderBoard);
	const dispatch = useDispatch();
	// const [leaderBoard, setLeaderBoard] = useState([]);

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [openTerms, setOpenTerms] = React.useState(false);
	const [check, setCheck] = React.useState(false);
	const [error, setError] = useState("");
	const [status, setStatus] = useState(false);
	const [message, setMessage] = useState("");
	const [scroll, setScroll] = React.useState("paper");
	const [currentUserid, setCurrentUserid] = useState("");
	const [user, setUser] = useState([]);
	const [total, setTotal] = useState(0);
	const [open, setOpen] = React.useState(false);
	const [singleUser, setSingleUser] = useState({});

	const handleClickOpenProfile = (userData) => {
		setSingleUser(userData);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseTerms = () => {
		setOpenTerms(!openTerms);
	};

	const handleClickOpen = (userID) => {
		setCurrentUserid(userID);
		setOpenTerms(true);
	};

	const handleChangePage = (event, newPage) => {
		setUser([]);
		console.log("newPage", newPage, rowsPerPage);
		axios
			.post(
				`${API}/registered/users/?per_page=${rowsPerPage}&page=${
					newPage + 1
				}`
			)
			.then((response) => {
				console.log("response", response);
				setUser(response.data.users);
				setTotal(response.data.total * rowsPerPage);
				// dispatch({
				// 	type: "GET_ALL_GLOBALRANKING",
				// 	payload: response.data.global_lb,
				// });
			})
			.catch((err) => console.log(err));
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
		setUser([]);
		console.log("newPage", event.target.value);
		axios
			.post(
				`${API}/registered/users/?per_page=${
					event.target.value
				}&page=${1}`
			)
			.then((response) => {
				console.log("response", response);
				setUser(response.data.users);
				setTotal(response.data.total * event.target.value);
				// dispatch({
				// 	type: "GET_ALL_GLOBALRANKING",
				// 	payload: response.data.global_lb,
				// });
			})
			.catch((err) => console.log(err));
	};

	const handleCloseMsg = () => {
		setStatus(!status);
	};

	// const dispatch = useDispatch();

	const getGlobalRanking = () => {
		console.log("here did mount");
		axios
			.post(`${API}/registered/users/?per_page=10&page=1`)
			.then((response) => {
				console.log("response", response);
				setUser(response.data.users);
				setTotal(response.data.total * rowsPerPage);
				// dispatch({
				// 	type: "GET_ALL_GLOBALRANKING",
				// 	payload: response.data.global_lb,
				// });
			})
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		getGlobalRanking();
	}, []);

	const getJoinDate = (date) => {
		var tempDate = date.split(" ")[0].split("/");
		var day = tempDate[0];
		var month = tempDate[1];
		var year = tempDate[2].split(" ")[0];
		const d = new Date(`${year}-${month}-${day}`);
		var month = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		var finalMonth = month[d.getMonth()];

		return `${finalMonth} ${year}`;
	};

	const deleteProfile = () => {
		getUserInfo(currentUserid)
			.then((response) => {
				console.log("user", response.data.user);
				const formData = new FormData();
				formData.append("email", response.data.user.Email);
				axios
					.delete(`${API}/user/delete`, {
						headers: {
							"content-type": "multipart/form-data",
						},
						data: formData,
					})
					.then((response) => {
						console.log(response);

						if (response.data.error) {
							setError(response.data.error);
						} else {
							setStatus(true);
							setMessage(response.data.message);
							setOpenTerms(false);
						}
					})
					.catch((error) => {
						// setStatus(true);

						console.log(error);
					});
			})
			.catch((err) => console.log(err));
	};

	return (
		<AuthLayout>
			<Header name="All Users" />
			{status && (
				<Snackbar
					open={status}
					autoHideDuration={15000}
					onClose={handleCloseMsg}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
				>
					<AlertComponent onClose={handleCloseMsg} severity="success">
						{message}
					</AlertComponent>
				</Snackbar>
			)}
			<Grid
				style={{
					justifyContent: "center",
				}}
			>
				<Grid xs={12} sm={12} md={12} lg={12}>
					<Paper>
						<>
							<Table
								className={classes.table}
								aria-label="customized table"
							>
								<TableHead>
									<TableRow>
										<StyledTableCell>Rank</StyledTableCell>
										<StyledTableCell align="right"></StyledTableCell>
										<StyledTableCell>Name</StyledTableCell>
										<StyledTableCell></StyledTableCell>
										<StyledTableCell align="center">
											Email
										</StyledTableCell>
										<StyledTableCell align="center">
											Mobile No.
										</StyledTableCell>
										<StyledTableCell align="right"></StyledTableCell>
										<StyledTableCell align="right"></StyledTableCell>
									</TableRow>
								</TableHead>
								{user.length > 0 ? (
									<TableBody>
										{user.map((row, index) => (
											<StyledTableRow key={index}>
												<StyledTableCell>
													<strong>
														{index +
															1 +
															page * rowsPerPage}
														.
													</strong>
												</StyledTableCell>
												<StyledTableCell align="right">
													<img
														src={row.Picture}
														width="35"
														height="35"
														alt={row.Name}
														style={{
															borderRadius: "50%",
														}}
													/>
												</StyledTableCell>
												<StyledTableCell
													component="th"
													scope="row"
												>
													{row.Name}
												</StyledTableCell>
												<StyledTableCell align="left">
													MachineHacker since{" "}
													{getJoinDate(row.created)}
												</StyledTableCell>
												<StyledTableCell align="right">
													{row.Email}
												</StyledTableCell>
												<StyledTableCell align="right">
													{row.Mobile}
												</StyledTableCell>
												<StyledTableCell align="right">
													<Button
														// color="primary"
														onClick={() => {
															handleClickOpenProfile(
																row
															);
														}}
														style={{
															backgroundColor:
																"#43BE31",
															color: "#fff",
															fontSize: "10px",
														}}
													>
														View
													</Button>
												</StyledTableCell>
												<StyledTableCell align="right">
													<Button
														// color="primary"
														onClick={() => {
															handleClickOpen(
																row._id
															);
														}}
														style={{
															backgroundColor:
																"rgb(250, 53, 88)",
															color: "#fff",
															fontSize: "10px",
														}}
													>
														Delete
													</Button>
												</StyledTableCell>
											</StyledTableRow>
										))}
									</TableBody>
								) : (
									<div
										style={{
											textAlign: "center",
											marginLeft: "350px",
										}}
									>
										<img
											src="https://flevix.com/wp-content/uploads/2020/01/Bounce-Bar-Preloader-1.gif"
											width="300"
										/>
									</div>
								)}
							</Table>
							<TablePagination
								rowsPerPageOptions={[10, 20, 50, 100]}
								component="div"
								count={total}
								rowsPerPage={rowsPerPage}
								page={page}
								onChangePage={handleChangePage}
								onChangeRowsPerPage={handleChangeRowsPerPage}
							/>

							{/* <Pagination count={2000} color="primary" /> */}
							<br />
						</>
					</Paper>
				</Grid>
			</Grid>
			<Dialog
				open={openTerms}
				onClose={handleCloseTerms}
				scroll={scroll}
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogContent dividers={scroll === "paper"}>
					<DialogContentText
						id="scroll-dialog-description"
						tabIndex={-1}
					>
						<p>
							<strong>
								Account deleted once can't be retrieved.
							</strong>
						</p>

						<FormControlLabel
							control={
								<Checkbox
									checked={check}
									onChange={() => {
										setCheck(!check);
									}}
									name="checkedB"
									color="primary"
								/>
							}
							label="Are you sure you want to delete account"
						/>
						{/* <p>
							I'd like Analytics India to provide my contact
							information to &nbsp;
							<a href="https://aws.amazon.com/legal/marketingentities/">
								Amazon Web Services
							</a>{" "}
							&nbsp; so AWS can share the latest AWS news and
							offers with me by email, post or telephone.
						</p> */}
						{/* <p>By clicking "I Agree"</p> */}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleCloseTerms}
						style={{
							backgroundColor: "rgb(250, 53, 88)",
							color: "#fff",
						}}
					>
						No
					</Button>
					{check ? (
						<Button
							onClick={deleteProfile}
							style={{
								backgroundColor: "#019031",
								color: "#fff",
							}}
						>
							Yes
						</Button>
					) : (
						<Button
							style={{
								backgroundColor: "#dbdbdb",
								color: "#fff",
							}}
						>
							Yes
						</Button>
					)}
				</DialogActions>
			</Dialog>

			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography
							variant="h6"
							className={classes.title}
							style={{ color: "#fff" }}
						>
							{singleUser.Name}
						</Typography>
						<Button autoFocus color="inherit" onClick={handleClose}>
							Done
						</Button>
					</Toolbar>
				</AppBar>
				<List>
					<ListItem button>
						<ListItemText
							primary="Email"
							secondary={singleUser.Email}
						/>
					</ListItem>
					<Divider />
					<ListItem button>
						<ListItemText
							primary="Experience"
							secondary={singleUser.Experience}
						/>
					</ListItem>

					<ListItem button>
						<ListItemText
							primary="Location"
							secondary={singleUser.Location}
						/>
					</ListItem>
					<ListItem button>
						<ListItemText
							primary="Mobile"
							secondary={singleUser.Mobile}
						/>
					</ListItem>
					<ListItem button>
						<ListItemText
							primary="Organization"
							secondary={singleUser.Organization}
						/>
					</ListItem>
					<ListItem button>
						<ListItemText
							primary="Role"
							secondary={singleUser.Role}
						/>
					</ListItem>
					<ListItem button>
						<ListItemText
							primary="LatestDesignation"
							secondary={singleUser.LatestDesignation}
						/>
					</ListItem>
					<ListItem button>
						<ListItemText
							primary="LinkedIn"
							secondary={singleUser.LinkedIn}
						/>
					</ListItem>
					<ListItem button>
						<ListItemText
							primary="GitHub"
							secondary={singleUser.GitHub}
						/>
					</ListItem>
					<ListItem button>
						<ListItemText
							primary="Twitter"
							secondary={singleUser.Twitter}
						/>
					</ListItem>
					<ListItem button>
						<ListItemText
							primary="Created"
							secondary={singleUser.created}
						/>
					</ListItem>
					<div className="row">
						<div className="col-md-12 pl-3">
							<h3>Rank</h3>
							{singleUser.ranks !== undefined &&
							singleUser.ranks !== null ? (
								<div className="pl-3">
									{Object.keys(singleUser.ranks).map(
										(hackathon) => (
											<li>
												{hackathon} :{" "}
												{singleUser.ranks[hackathon]}
											</li>
										)
									)}
								</div>
							) : (
								""
							)}
						</div>
					</div>
					<ListItem button>
						<ListItemText primary="practices" secondary="Tethys" />
					</ListItem>
				</List>
			</Dialog>
		</AuthLayout>
	);
}
