import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API } from "../../backend";
import Loader from "../../components/Loader";
// import online from ""
import online from "../../assets/images/online.png";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DownloadLoader from "../DownloadUserData/Loader";
import Header from "../Header";
import { getLoggedInUser } from "../../helpers/authUtils";
import AuthLayout from "../../components/AuthLayout";
import CircularProgress from '@material-ui/core/CircularProgress';


// var FileSaver = require("file-saver");

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function GetAllBootcamps() {
	// const pracctices = useSelector((store) => store.bootcamps.bootcamps);
	const dispatch = useDispatch();

	const [open, setOpen] = React.useState(false);
	const [limit, setLimit] = useState("");
	const [sort, setSort] = useState("PublicScore");
	const [start, setStart] = useState(false);
	const [end, setEnd] = useState(false);
	const [messageData, setMessageData] = useState("");
	const [HREFName, setHREFName] = useState("")
	const [bootcamps, setBootcamps] = useState([])
	const [loader, setLoader] = useState(false)
	const [loaderAt, setLoaderAt] = useState(-1)


	// const handleClickOpen = (HREFName) => {
	// 	setHREFName(HREFName);
	// 	setOpen(true);
	// };

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseEnd = () => {
		setEnd(!end);
	};

	const GetAllBootcamps = () => {
		const token = JSON.parse(localStorage.getItem('jwt'));

		const config = {
			headers: {
				"Content-Type": "application/json",
				'Authorization': token.token
			},
		};

		axios
			.get(`${BOOTCAMP_API}/bootcamp_course/`, config)
			.then((response) => {
				setBootcamps(response.data.result.data);
				setLoaderAt(-1)
			})
			.catch((err) => console.log(err));
	};


	const PublishOrUnPublish = (index, bootcamp_course, draft) => {
		setLoaderAt(index)
		const token = JSON.parse(localStorage.getItem('jwt'));

		const config = {
			headers: {
				"Content-Type": "application/json",
				'Authorization': token.token
			},
		};

		axios
			.put(`${BOOTCAMP_API}/bootcamp_course/${bootcamp_course}/${draft}`, {}, config)
			.then((response) => {
				if (response.data.error) {
					console.log('error ', JSON.stringify(response.data.error));
				} else {
					GetAllBootcamps();
				}
			})
			.catch((err) => console.log(err));
	}



	useEffect(() => {
		GetAllBootcamps();
	}, []);


	return (
		<AuthLayout>
			<React.Fragment>
				<div className="">
					<Header name="All Bootcamps" />

					<Row>
						<Col lg={12}>
							<>
								{start && (
									<Snackbar
										open={start}
										onClose={handleClose}
									>
										<>
											<Alert
												onClose={handleClose}
												severity="success"
											>
												<DownloadLoader pexal="55px" />
												{messageData}
											</Alert>
										</>
									</Snackbar>
								)}

								{end && (
									<Snackbar
										open={end}
										onClose={handleCloseEnd}
									>
										<>
											<Alert
												onClose={handleCloseEnd}
												severity="success"
											>
												{messageData}
											</Alert>
										</>
									</Snackbar>
								)}

								{
									bootcamps.length > 0
										?
										(
											bootcamps.map((bootcamp, index) => (
												<Card key={bootcamp._id}>
													<Row>
														<Col lg={9}>
															<CardBody>
																{" "}
																<h4>
																	{bootcamp.title}
																</h4>{" "}
															</CardBody>
														</Col>
														<Col
															lg={3}
															style={{
																textAlign: "center",
															}}
														>
															<CardBody
																style={{
																	paddingLeft: "0px",
																	paddingRight: "0px",
																}}
															>
																{" "}

																{
																	index === loaderAt
																		?
																		(
																			<div style={{ margin: "auto", textAlign: 'left' }}>
																				<Loader />
																			</div>
																		)
																		:
																		(
																			<>
																				<ButtonToggle
																					color="primary"
																					style={{
																						paddingLeft:
																							"30px",
																						paddingRight:
																							"30px",
																						margin: "15px"
																					}}
																					onClick={() => PublishOrUnPublish(index, bootcamp._id, bootcamp.flags.draft ? 'publish' : 'unpublish')}
																				>
																					{bootcamp.flags.draft ? 'PUBLISH' : 'UNPUBLISH'}
																				</ButtonToggle>
																				
																				<Link
																					to={`/updateBootcamp/${bootcamp.slug}`}
																				>
																					<ButtonToggle
																						color="danger"
																						style={{
																							paddingLeft:
																								"30px",
																							paddingRight:
																								"30px",
																							margin: "15px"
																						}}
																					>
																						Edit
																					</ButtonToggle>
																				</Link>
																			</>
																		)
																}

															</CardBody>
														</Col>
													</Row>
												</Card>
											))
										)
										:
										(
											<div style={{ textAlign: "center" }}>
												<Loader />
											</div>
										)
								}
							</>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		</AuthLayout>
	);
}
