import React, { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { API } from "../../backend";
import { DropzoneArea } from "material-ui-dropzone";
import "./createblogathon.css";
import { FormControlLabel } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import CKEditor from "@ckeditor/ckeditor5-react";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
const { inspect } = require("util");
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default function CreateCategory(props) {
  const otherModuleReferences = [
    {
      key: "href1",
      value: "",
    },
    {
      key: "href2",
      value: "",
    },
    {
      key: "href3",
      value: "",
    },
  ];

  const classes = useStyles();
  const history = useHistory();
  const [fullscreen, setFullsreen] = useState(false);
  const [messageData, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [onSubmit, setOnSubmit] = useState(false);
  const [slug, setSlug] = useState("");
  const [image_2, setSelectedFile_2] = useState([]);
  const [samples, setSamples] = useState([]);
  const [publish, setPublish] = useState(false);
  const [href, sethref] = useState("");
  const [blogathons, setBlogathons] = useState([]);
  const inputEl = useRef(null);
  const token = JSON.parse(localStorage.getItem("jwt"));
  const [samplesList, setSamplesList] = useState("");
  const [resources, setResources] = useState("");
  let location = useLocation();
  var BlogathonUrl = location.pathname.split("/")[1];
  const handleClose = () => {
    setStatus(!status);
  };
  const editorConfiguration = {
    plugins: [
      Essentials,
      Bold,
      Italic,
      Paragraph,
      Image,
      ImageUpload,
      Base64UploadAdapter,
      Heading,
      Indent,
      Table,
      TableToolbar,
      Link,
      List,
      MediaEmbed,
      CodeBlock,
      ImageResize,
      ImageToolbar,
      ImageStyle,
    ],
    toolbar: [
      "heading",
      "bold",
      "italic",
      "imageUpload",
      "link",
      "bulletedList",
      "numberedList",
      "indent",
      "outdent",
      "mediaEmbed",
      "insertTable",
      "codeBlock",
    ],
    mediaEmbed: {
      previewsInData: true
    },
    image: {
      resizeOptions: [
        {
          name: "imageResize:original",
          label: "Original",
          value: null,
        },
        {
          name: "imageResize:50",
          label: "50%",
          value: "50",
        },
        {
          name: "imageResize:75",
          label: "75%",
          value: "75",
        },
      ],
      toolbar: [
        "imageResize",
        "|",
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
  
    image: {
      resizeOptions: [
        {
          name: "imageResize:original",
          label: "Original",
          value: null,
        },
        {
          name: "imageResize:50",
          label: "50%",
          value: "50",
        },
        {
          name: "imageResize:75",
          label: "75%",
          value: "75",
        },
      ],
      toolbar: [
        "imageResize",
        "|",
        "imageStyle:full",
        "imageStyle:side",
        "|",
        "imageTextAlternative",
      ],
    },
  
    link: {
      addTargetToExternalLinks: true,
    },
  
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    codeBlock: {
      languages: [
        // Do not render the CSS class for the plain text code blocks.
        { language: "plaintext", label: "Plain text", class: "" },
  
        // Use the "php-code" class for PHP code blocks.
        { language: "php", label: "PHP", class: "php-code" },
  
        // Use the "js" class for JavaScript code blocks.
        // Note that only the first ("js") class will determine the language of the block when loading data.
        {
          language: "javascript",
          label: "JavaScript",
          class: "js javascript js-code",
        },
  
        // Python code blocks will have the default "language-python" CSS class.
        { language: "python", label: "Python" },
      ],
    },
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };
  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };

  const getBlogathondata = () => {
    axios
      .get(`${API}/blogathon/category/${props.match.params.blogathonSlug}`)
      .then((response) => {
        console.log(response);
        setTitle(response.data.message.title);
        setDescription(response.data.message.description);
        setSelectedFile_2(response.data.message.logo);
        setSlug(response.data.message.skills);
        setSamples(response.data.message.samples);
        sethref(response.data.message.href);
        setResources(response.data.message.samples[0])
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllBlogathons = () => {
    axios
      .get(`${API}/blogathons`)
      .then((response) => {
        setBlogathons(response.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllBlogathons();
    BlogathonUrl.includes("update") && getBlogathondata();
  }, []);

  const CreateNewCategory = () => {
    try {
      // const exploreSamples = [...samples, samplesList];
      // const exploreSampleslist = exploreSamples
      //   .map((x) => x.trim())
      //   .filter((y) => y);
      setOnSubmit(true);
      if (
        title === "" ||
        description === "" ||
        image_2 === "" ||
        slug === "" ||
        resources === ""
      ) {
        setErrorStatus(true);
        setErrorMessage("Field Missing! All Feild Required !!");
      } else {
        let headers = {
          "Content-Type": "application/json",
          Authorization: token.token,
        };

        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        formData.append("logo", image_2[0]);
        formData.append("skills", slug);
        formData.append("samples",resources);
        // exploreSampleslist.filter((x) => {
        //   formData.append("samples", x);
        // });

        axios
          .post(`${API}/blogathon/category/create/${href}`, formData, headers)
          .then((response) => {
            if (response.data) {
              setStatus(true);
              setMessage("Successfully created Category");
              setStatusLoader(false);
              setTimeout(() => {
                history.push(`/blogathons`);
              }, 2500);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const UpdateCategory = () => {
    try {
      // const exploreSamples = [...samples, samplesList];
      // const exploreSampleslist = exploreSamples
      //   .map((x) => x.trim())
      //   .filter((y) => y);
      setOnSubmit(true);
      if (
        title === "" ||
        description === "" ||
        slug === "" ||
        resources === ""
      ) {
        setErrorStatus(true);
        setErrorMessage("Field Missing! All Feild Required !!");
      } else {
        let headers = {
          "Content-Type": "application/json",
          Authorization: token.token,
        };

        const formData = new FormData();
        formData.append("title", title);
        formData.append("description", description);
        image_2.length > 0 && formData.append("logo", image_2[0]);
        formData.append("skills", slug);
        formData.append("samples",resources);
        // exploreSampleslist.filter((x) => {
        //   formData.append("samples", x);
        // });
        formData.append("publish", publish);
        axios
          .put(
            `${API}/blogathon/category/update/${props.match.params.blogathonSlug}`,
            formData,
            headers
          )
          .then((response) => {
            if (response.data) {
              setStatus(true);
              setMessage("Successfully updated Category");
              setStatusLoader(false);
              setTimeout(() => {
                history.push(`/blogathons`);
              }, 2500);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onClickDivFocusInput = () => {
    inputEl.current.focus();
  };
  const handleDelete = (item) => {
    setSamples(samples.filter((i) => i !== item));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();
    var paste = evt.clipboardData.getData("text");
    //eslint-disable-next-line
    var emails = paste.match(/\r\n|\r|\n/g);
    // var emails = paste;
    if (emails) {
      var toBeAdded = paste.split("\n");
      setSamples([...samples, ...toBeAdded]);
    } else {
      var toBeAdded = [paste];
      setSamples([...samples, ...toBeAdded]);
    }
  };
  const isInList = (email) => {
    return samples.includes(email);
  };
  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();
      var value = samplesList.trim();

      if (value) {
        setSamples([...samples, samplesList]);
        setSamplesList("");
      }
    }
  };
  const handleEmailChange = (e) => {
    setSamplesList(e.target.value);
  };
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name={BlogathonUrl.includes("update") ? "Update Category":"Create Category"} />

      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {status && (
              <Snackbar
                open={status}
                autoHideDuration={15000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </Snackbar>
            )}

            {errorStatus && (
              <Snackbar
                open={errorStatus}
                autoHideDuration={15000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            )}

            {statusLoader && (
              <Snackbar open={statusLoader} onClose={handleLoader}>
                <Alert onClose={handleLoader} severity="info">
                  <Loader />
                </Alert>
              </Snackbar>
            )}

            <Paper className={classes.paper}>
              <h2>Title</h2>

              <TextField
                id="outlined-multiline-static"
                label="Title"
                multiline
                rows={3}
                variant="outlined"
                fullWidth={true}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Description</h2>

              <TextField
                id="outlined-multiline-static"
                label="Short Description"
                multiline
                rows={2}
                variant="outlined"
                fullWidth={true}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
              <br />
              <br />
              <h2>Image</h2>
              <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                onChange={(files) => setSelectedFile_2(files)}
                maxFileSize={9242880}
              />
              <br />
              {BlogathonUrl.includes("update") && (
                <img src={image_2} alt="" hight="100" width="150" />
              )}

              <br />
              <br />

              <h2>Skills</h2>

              <TextField
                id="outlined-multiline-static"
                label="Slug"
                multiline
                rows={2}
                variant="outlined"
                fullWidth={true}
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                }}
              />
              <br />
              <br />

              <h2>Resources</h2>
              <div className="">
                {/* {samples.map((item, index) => (
                  <div className="tag-item" key={index}>
                    {item}
                    <button
                      type="button"
                      className="button"
                      onClick={() => handleDelete(item)}
                    >
                      &times;
                    </button>
                  </div>
                ))}
                <input
                  placeholder="samples"
                  ref={inputEl}
                  value={samplesList}
                  onChange={handleEmailChange}
                  onKeyDown={handleKeyDown}
                  onPaste={handlePaste}
                /> */}
                <CKEditor
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={resources ? resources : "<p>Hello from CKEditor 5!</p>"}
                    onInit={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setResources(data);
                      console.log({ data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                  <br />
                  <br />
              </div>

              <br />
              <br />
             {BlogathonUrl.includes("update") && <>
              <h2>Publish</h2>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={publish}
                    onChange={() => {
                      setPublish(!publish);
                    }}
                    name="checkedG"
                  />
                }
                label="Publish"
              />
              </>}
              {!BlogathonUrl.includes("update") && (
                <>
                  <h2>Select Blogathon</h2>
                  <select
                    className="form-control"
                    id="sel1"
                    onChange={(e) => {
                      sethref(e.target.value);
                    }}
                    value={href}
                  >
                    <option value="None">None</option>
                    {blogathons.length > 0
                      ? blogathons.map((hackathon) => (
                          <option
                            value={hackathon.HREFName}
                            key={hackathon.HREFName}
                          >
                            {hackathon.HName}
                          </option>
                        ))
                      : ""}
                  </select>
                </>
              )}

              <br />
              <br />
              {BlogathonUrl.includes("update") ? (
                <Button
                  disabled={onSubmit}
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                  onClick={() => UpdateCategory()}
                >
                  Update Category
                </Button>
              ) : (
                <Button
                  disabled={onSubmit}
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                  onClick={() => CreateNewCategory()}
                >
                  Create Category
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
