import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import axios from "axios";
import { BOOTCAMP_API } from "../../backend";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    minHeight: 280,
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  },
  control: {
    padding: theme.spacing(2),
  },
  image: {
    minHeight: 150,
    objectFit: "cover",
  },
}));
function SubscriptionsPlan() {
  const [fullscreen, setFullsreen] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  const getAllSubscriptionPalns = async () => {
    await axios
      .get(`${BOOTCAMP_API}/subscriptions/getAllSubscriptionPlanCms`)
      .then((response) => {
        setSubscriptions(response.data.result.data);
      })
      .catch((err) => console.log(err));
  };

  const onPublishChange = async (data, publishStatus) => {
    const requestData = {
      publish: publishStatus,
    };
    await axios
      .put(
        `${BOOTCAMP_API}/subscriptions/subscriptionPlanUpdate/${data._id}`,
        requestData
      )
      .then((response) => {
        setSubscriptions(
          subscriptions.filter((x) => {
            if (x._id === data._id) {
              x.publish = response.data.result.data.publish;
            }
            return subscriptions;
          })
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllSubscriptionPalns();
  }, []);
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Subscription plan" />
      <Button
        className="float-right"
        variant="outlined"
        color="primary"
        onClick={() => history.push(`subscriptions/create`)}
      >
        Create Plan
      </Button>
      <Grid container className="mt-4">
        <Grid container spacing={6}>
          {subscriptions.map((data) => (
            <Grid key={data.id} item xs={4} className="">
              <Paper className={classes.paper + " " + "p-2"}>
                <h4>
                  <span>Name: </span>
                  {data.name}
                </h4>
                <p>
                  <span>Short Description: </span>
                  {data.shortDescription}
                </p>
                <p>
                  <span>Description: </span>
                  {data.description}
                </p>
                <p>
                  <span>Actual price: </span>
                  {data.actualPrice}
                </p>
                <p>
                  <span>Discount price: </span>
                  {data.amount}
                </p>
                <p>
                  <span>Paln: </span>
                  {data.amount}/{data.period === "monthly" ? "mo" : "yr"}
                </p>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() =>
                    onPublishChange(data, data.publish ? false : true)
                  }
                >
                  {data.publish ? "Unpublish" : "Publish"}
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

export default SubscriptionsPlan;
