import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import Button from "@material-ui/core/Button";
import { BOOTCAMP_API } from "../../backend";
import Switch from "@material-ui/core/Switch";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import EasyMDE from "easymde";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import { useHistory, useLocation } from "react-router";

import CKEditor from "@ckeditor/ckeditor5-react";
// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";

import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import JobRole from "../Compiler/Questions/JobRole/JobRole";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const options = [
  {
    id: "1",
    label: "Beginner",
    value: "Beginner",
  },
  {
    id: "2",
    label: "Intermediate",
    value: "Intermediate",
  },
  {
    id: "3",
    label: "Advanced",
    value: "Advanced",
  },
];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Heading,
    Indent,
    Table,
    TableToolbar,
    Link,
    List,
    MediaEmbed,
    CodeBlock,
    ImageResize,
    ImageToolbar,
    ImageStyle,
  ],
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "mediaEmbed",
    "insertTable",
    "codeBlock",
  ],
  mediaEmbed: {
    previewsInData: true,
  },
  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },

  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  codeBlock: {
    languages: [
      // Do not render the CSS class for the plain text code blocks.
      { language: "plaintext", label: "Plain text", class: "" },

      // Use the "php-code" class for PHP code blocks.
      { language: "php", label: "PHP", class: "php-code" },

      // Use the "js" class for JavaScript code blocks.
      // Note that only the first ("js") class will determine the language of the block when loading data.
      {
        language: "javascript",
        label: "JavaScript",
        class: "js javascript js-code",
      },

      // Python code blocks will have the default "language-python" CSS class.
      { language: "python", label: "Python" },
    ],
  },
};
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 999,
  }),
};
export default function CreatePractices(props) {
  const [fullscreen, setFullsreen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [listOfCategories, setListOfCategories] = React.useState([]);
  const [listOftags, setListOftags] = React.useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [title, setTitle] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [description, setDescription] = useState("");
  const [preCode, setPreCode] = useState("");
  const [priority, setPriority] = useState("");
  const [solutionDescription, setSolutionDescription] = useState("");
  const [solutionCode, setSolutionCode] = useState("");
  const [solutionVideoLink, setSolutionVideoLink] = useState("");
  const [constraints, setConstraints] = useState("");
  const [isToast, setIsToast] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [testCases, setTestCases] = useState([
    { input: "", expectedOutput: "" },
  ]);
  const [defaultTestCases, setDefaultTestCases] = useState({
    input: "",
    expectedOutput: "",
  });
  const [hints, setHints] = useState([""]);
  const [publish, setPublish] = useState(false);
  const [premium, setPremium] = useState(false);
  const [featured, setFeatured] = useState(false);
  const [previewVideoLink, setPreviewVideoLink] = useState("");
  const [jobRole, setJobRole] = useState([]);
  const [isLms, setIsLms] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname.split("/");
  let MDE;

  const handleFormChange = (event, index) => {
    let data = [...testCases];
    data[index][event.target.name] = event.target.value;
    setTestCases(data);
  };
  const handleDefaultTestCasesFormChange = (event) => {
    let data = { ...defaultTestCases };
    data[event.target.name] = event.target.value;
    setDefaultTestCases(data);
  };
  const handleHintsFormChange = (event, index) => {
    let data = [...hints];
    data[index] = event.target.value;
    setHints(data);
  };

  const submit = (e) => {
    e.preventDefault();
    console.log(testCases);
  };

  const addFields = () => {
    let object = {
      input: "",
      expectedOutput: "",
    };

    setTestCases([...testCases, object]);
  };

  const addHintFields = () => {
    let string = "";
    setHints([...hints, string]);
  };

  const removeFields = (index) => {
    let data = [...testCases];
    data.splice(index, 1);
    setTestCases(data);
  };

  const removeHintFields = (index) => {
    let data = [...hints];
    data.splice(index, 1);
    setHints(data);
  };

  const CreateQuestion = () => {
    let tags = [];
    selectedTags.filter((x) => tags.push(x._id));
    try {
      if (
        title === "" ||
        difficulty.value === "" ||
        description === "" ||
        preCode === "" ||
        priority === "" ||
        solutionDescription === "" ||
        solutionCode === "" ||
        selectedCategory.value === "" ||
        selectedTags.length === 0 ||
        testCases[0].expectedOutput === "" ||
        jobRole?.length === 0
      ) {
        setIsToast({
          show: true,
          message: "Field Missing! All Feild Required !!",
          variant: "error",
        });
      } else {
        let questionData = {};
        const jobroleIds = [];
        jobRole.filter((x) => {
          jobroleIds.push(x._id);
        });
        questionData = {
          categoryId: selectedCategory.value,
          tags: tags,
          questionTitle: title,
          difficulty: difficulty.value,
          description: description,
          preCode: preCode,
          testCases: testCases,
          priority: priority,
          solutionDescription: solutionDescription,
          solutionCode: solutionCode,
          defaultTestCases: defaultTestCases,
          hints: hints,
          constraints: constraints,
          previewVideo: previewVideoLink,
          solutionVideoLink: solutionVideoLink,
          jobRole: jobroleIds,
          lmsContent: isLms
        };
        axios
          .post(
            `${BOOTCAMP_API}/practices/createPracticeQuestion`,
            questionData
          )
          .then((response) => {
            setIsToast({
              show: true,
              message: "Successfully created question",
              variant: "success",
            });
            localStorage.removeItem("practicesQuesLength");
            setTimeout(() => {
              history.push(`/practices`);
            }, 2500);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const UpdateQuestion = () => {
    let tags = [];
    selectedTags.filter((x) => tags.push(x._id));
    try {
      if (
        title === "" ||
        difficulty.value === "" ||
        description === "" ||
        preCode === "" ||
        priority === "" ||
        solutionDescription === "" ||
        solutionCode === "" ||
        selectedCategory.value === "" ||
        selectedTags.length === 0 ||
        testCases[0].expectedOutput === "" ||
        jobRole?.length === 0
      ) {
        setIsToast({
          show: true,
          message: "Field Missing! All Feild Required !!",
          variant: "error",
        });
      } else {
        let questionData = {};
        const jobroleIds = [];
        jobRole.filter((x) => {
          jobroleIds.push(x._id);
        });
        questionData = {
          categoryId: selectedCategory.value,
          tags: tags,
          questionTitle: title,
          difficulty: difficulty.value,
          description: description,
          preCode: preCode,
          testCases: testCases,
          priority: priority,
          solutionDescription: solutionDescription,
          solutionCode: solutionCode,
          defaultTestCases: defaultTestCases,
          hints: hints,
          constraints: constraints,
          publish: publish,
          premium: premium,
          featured: featured,
          previewVideo: previewVideoLink,
          solutionVideoLink: solutionVideoLink,
          jobRole: jobroleIds,
          lmsContent: isLms
        };
        axios
          .put(
            `${BOOTCAMP_API}/practices/updatePracticeQuestion/${props.match.params.questionId}`,
            questionData
          )
          .then((response) => {
            setIsToast({
              show: true,
              message: "Successfully Updated Question",
              variant: "success",
            });
            setTimeout(() => {
              history.push(`/practices`);
            }, 2500);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getAllPracticeCategory = async () => {
    await axios
      .get(`${BOOTCAMP_API}/practices/getAllPracticeCategoryCms`)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setListOfCategories(
            response.data.result.data.filter((x) => {
              x.label = x.title;
              x.value = x._id;
              return x;
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllPracticetags = async () => {
    await axios
      .get(`${BOOTCAMP_API}/practices/getAllPracticeTagsCms`)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setListOftags(
            response.data.result.data.filter((x) => {
              x.label = x.title;
              x.value = x._id;
              return x;
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getQuestion = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/practices/getSpecificPracticeQuestionCms/${props.match.params.questionId}`
      )
      .then((response) => {
        setTitle(response.data.result.data?.questionTitle);
        setDescription(response.data.result.data?.description);
        setPriority(response.data.result.data?.priority);
        setPublish(response.data.result.data?.publish);
        setFeatured(response.data.result.data?.featured);
        setPremium(response.data.result.data?.premium);
        setPreCode(response.data.result.data?.preCode);
        setSolutionDescription(
          response.data.result.data.solution?.description || ""
        );
        setSolutionCode(response.data.result.data.solution?.code || "");
        setSolutionVideoLink(
          response.data.result.data.solution?.videoLink || ""
        );
        setConstraints(response.data.result.data?.constraints || "");
        setSelectedCategory({
          label: response.data.result.data.categoryId.title,
          value: response.data.result.data.categoryId._id,
        });
        setJobRole(response.data.result.data.jobRole || []);
        const tags = response.data.result.data.tags.filter((x) => {
          x.label = x.title;
          x.value = x._id;
          return x;
        });
        setSelectedTags(tags);
        setDifficulty({
          label: response.data.result.data.difficulty,
          value: response.data.result.data.difficulty,
        });
        setTestCases(response.data.result.data.testCases);
        setDefaultTestCases(response.data.result.data.defaultTestCases);
        setHints(response.data.result.data.hints);
        setPreviewVideoLink(response.data.result.data?.previewVideo || "");
        setIsLms(response.data?.result?.data?.lmsContent);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllPracticeCategory();
    getAllPracticetags();
    !url.includes("update") &&
      setPriority(parseInt(localStorage.getItem("practicesQuesLength")) + 1);
    url.includes("update") && getQuestion();
  }, []);
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header
        name={url.includes("update") ? "Update Question" : "Create Question"}
      />
      <Snackbar
        open={isToast.show}
        onClose={() => setIsToast({ ...isToast, show: false })}
      >
        <>
          <Alert
            onClose={() => setIsToast({ ...isToast, show: false })}
            severity={isToast.variant}
          >
            {isToast.message}
          </Alert>
        </>
      </Snackbar>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <h2>Priority</h2>
              <TextField
                id="outlined-multiline-static"
                label="Priority"
                multiline
                minRows={2}
                variant="outlined"
                fullWidth={true}
                value={priority}
                onChange={(e) => {
                  setPriority(e.target.value);
                }}
              />
              <br />
              <br />
              <FormControl className={classes.formControl}>
                <label className="event-label">Select Category</label>
                <Select
                  styles={customStyles}
                  options={listOfCategories}
                  isSearchable={true}
                  onChange={(value) => setSelectedCategory(value)}
                  value={selectedCategory}
                />
              </FormControl>
              <br />
              <br />
              <FormControl className={classes.formControl}>
                <label className="event-label">Select Tags</label>
                <Select
                  styles={customStyles}
                  options={listOftags}
                  isSearchable={true}
                  onChange={(value) => setSelectedTags(value)}
                  value={selectedTags}
                  isMulti
                />
              </FormControl>
              <br />
              <br />

              <h2>Title</h2>

              <TextField
                id="outlined-multiline-static"
                label="title"
                multiline
                minRows={2}
                variant="outlined"
                fullWidth={true}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <br />
              <br />
              <FormControl className={classes.formControl}>
                <label className="event-label">Difficulty</label>
                <Select
                  styles={customStyles}
                  options={options}
                  isSearchable={true}
                  onChange={(value) => setDifficulty(value)}
                  value={difficulty}
                />
              </FormControl>
              <br />
              <br />

              <h2>Description</h2>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={description}
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              <br />
              <br />

              <h2>Pre code</h2>
              <TextField
                id="outlined-multiline-static"
                label="Pre code"
                multiline
                minRows={2}
                variant="outlined"
                fullWidth={true}
                value={preCode}
                onChange={(e) => {
                  setPreCode(e.target.value);
                }}
              />
              <br />
              <br />

              <h2>Solution Description</h2>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={solutionDescription}
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSolutionDescription(data);
                }}
              />
              <br />
              <br />
              <h2>Solution Code</h2>
              <TextField
                id="outlined-multiline-static"
                label="Solution Code"
                multiline
                minRows={2}
                variant="outlined"
                fullWidth={true}
                value={solutionCode}
                onChange={(e) => {
                  setSolutionCode(e.target.value);
                }}
              />
              <br />
              <br />
              <h2>Default Test Cases</h2>
              <div className="d-flex align-items-center">
                <TextField
                  className="mb-3 mr-2"
                  id="outlined-multiline-static"
                  label="Input"
                  name="input"
                  multiline
                  minRows={1}
                  variant="outlined"
                  fullWidth={true}
                  value={defaultTestCases.input}
                  onChange={(event) => handleDefaultTestCasesFormChange(event)}
                />
                <TextField
                  className="mb-3 mr-2"
                  id="outlined-multiline-static"
                  label="ExpectedOutput"
                  name="expectedOutput"
                  multiline
                  minRows={1}
                  variant="outlined"
                  fullWidth={true}
                  value={defaultTestCases.expectedOutput}
                  onChange={(event) => handleDefaultTestCasesFormChange(event)}
                />
              </div>
              <br />
              <br />
              <h2>Test Cases</h2>
              {testCases.map((cases, index) => {
                return (
                  <div key={index} className="d-flex align-items-center">
                    <TextField
                      className="mb-3 mr-2"
                      id="outlined-multiline-static"
                      label="Input"
                      name="input"
                      multiline
                      minRows={1}
                      variant="outlined"
                      fullWidth={true}
                      value={cases.input}
                      onChange={(event) => handleFormChange(event, index)}
                    />
                    <TextField
                      className="mb-3 mr-2"
                      id="outlined-multiline-static"
                      label="ExpectedOutput"
                      name="expectedOutput"
                      multiline
                      minRows={1}
                      variant="outlined"
                      fullWidth={true}
                      value={cases.expectedOutput}
                      onChange={(event) => handleFormChange(event, index)}
                    />
                    <div
                      onClick={() => removeFields(index)}
                      className="addSpeakers mb-3 py-0"
                    >
                      <i className="mdi mdi-minus-circle-outline"></i>
                    </div>
                  </div>
                );
              })}
              <div onClick={addFields} className="addSpeakers">
                <i className="mdi mdi-plus-circle-outline"></i>
              </div>
              <br />
              <br />
              <h2>Solution Video Link</h2>
              <TextField
                id="outlined-multiline-static"
                label="Solution Video Link"
                multiline
                minRows={2}
                variant="outlined"
                fullWidth={true}
                value={solutionVideoLink}
                onChange={(e) => {
                  setSolutionVideoLink(e.target.value);
                }}
              />
              <br />
              <br />
              <h2>Solution Preview Video Link</h2>
              <TextField
                id="outlined-multiline-static"
                label="Solution Preview Video Link"
                multiline
                minRows={2}
                variant="outlined"
                fullWidth={true}
                value={previewVideoLink}
                onChange={(e) => {
                  setPreviewVideoLink(e.target.value);
                }}
              />
              <br />
              <br />
              <h2>Constraints</h2>
              <TextField
                id="outlined-multiline-static"
                label="Constraints"
                multiline
                minRows={2}
                variant="outlined"
                fullWidth={true}
                value={constraints}
                onChange={(e) => {
                  setConstraints(e.target.value);
                }}
              />
              <br />
              <br />
              <JobRole setJobRole={setJobRole} jobRole={jobRole} />
              <br />
              <br />
              <h2>Hints</h2>
              {hints.map((hint, index) => (
                <div className="d-flex" key={index}>
                  <input
                    name="hints"
                    placeholder="Hints"
                    onChange={(event) => handleHintsFormChange(event, index)}
                    value={hint}
                    className="form-control mb-3 mr-2"
                  />
                  <div
                    onClick={() => removeHintFields(index)}
                    className="addSpeakers mb-3 py-0"
                  >
                    <i className="mdi mdi-minus-circle-outline"></i>
                  </div>
                </div>
              ))}
              <div onClick={addHintFields} className="addSpeakers">
                <i className="mdi mdi-plus-circle-outline"></i>
              </div>
              <br />
              <br />
              {url.includes("update") && (
                <>
                  <span>Publish</span>
                  <Switch
                    checked={publish}
                    onChange={() => setPublish(!publish)}
                    name="publish"
                    inputProps={{
                      "aria-label": "secondary checkbox",
                    }}
                  />
                  <br />
                  <br />
                  <span>Premium</span>
                  <Switch
                    checked={premium}
                    onChange={() => setPremium(!premium)}
                    name="premium"
                    inputProps={{
                      "aria-label": "secondary checkbox",
                    }}
                  />
                  <br />
                  <br />
                  <span>Featured</span>
                  <Switch
                    checked={featured}
                    onChange={() => setFeatured(!featured)}
                    name="featured"
                    inputProps={{
                      "aria-label": "seconday checkbox",
                    }}
                  />
                  <br />
                  <br />
                </>
              )}
              <span>LMS</span>
              <Switch
                checked={isLms}
                onChange={(e) => setIsLms(e.target.checked)}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
              <br />
              <br />
              {url.includes("update") ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                  onClick={UpdateQuestion}
                >
                  Update Question
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                  onClick={CreateQuestion}
                >
                  Create Question
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
