import axios from 'axios';
import { BOOTCAMP_API } from '../../backend';

function GetAuthorizationConfig() {
    return {
        headers: {
            "Content-Type": "application/json",
            'Authorization': localStorage.getItem('jwt') ? JSON.parse(localStorage.getItem('jwt')).token : null
        },
    }
}

const QuestionService = {
    GetAllQuestionsOfAQuestionSet: (questionSetSlug, type) => {
        return new Promise((resolve, reject) => {
            axios
                .get(type ? `${BOOTCAMP_API}/admin/mock_question/${questionSetSlug}/fetch?type=${type}` : `${BOOTCAMP_API}/admin/mock_question/${questionSetSlug}/fetch`)
                .then((response) => {
                    return resolve(response.data.result.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    },
    PublishOrUnpublishAQuestion: (questionSetId, questionId, doPublish) => {
        return new Promise((resolve, reject) => {
            axios
                .put(doPublish ? `${BOOTCAMP_API}/admin/mock_question/${questionSetId}/${questionId}/publish` : `${BOOTCAMP_API}/admin/mock_question/${questionSetId}/${questionId}/unpublish`, {}, GetAuthorizationConfig())
                .then((response) => {
                    return resolve(response.data.result.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    },
    CreateQuestion: (questionSetId, question) => {
        return new Promise((resolve, reject) => {
            axios
                .post(`${BOOTCAMP_API}/admin/mock_question/${questionSetId}`, question, GetAuthorizationConfig())
                .then((response) => {
                    return resolve(response.data.result.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    },
    UpdateQuestion: (questionSetId, questionId, question) => {
        return new Promise((resolve, reject) => {
            axios
                .put(`${BOOTCAMP_API}/admin/mock_question/${questionSetId}/${questionId}`, question, GetAuthorizationConfig())
                .then((response) => {
                    return resolve(response.data.result.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    },
    FetchQuestion: (questionSlug) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${BOOTCAMP_API}/admin/mock_question/${questionSlug}`)
                .then((response) => {
                    return resolve(response.data.result.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    },
    FetchAllSkills: (paramData) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${BOOTCAMP_API}/skills/getAllSkills`,{ params: paramData })
                .then((response) => {
                    return resolve(response.data.result.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    },
    FetchAllJobRole: (paramData) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`${BOOTCAMP_API}/enterpriseAssessment/getAllJobRole`,{ params: paramData })
                .then((response) => {
                    return resolve(response.data.result.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        })
    }
}

export default QuestionService;