import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API } from "../../../backend";
import Loader from "../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import DownloadLoader from "../../DownloadUserData/Loader";
import EventLayout from "../../../components/EventLayout";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { FormControl, Menu, MenuItem } from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import logoSm from "../../../assets/images/logo-sm.png";
import Select from "react-select";
import { SketchPicker } from "react-color";
import { DropzoneArea } from "material-ui-dropzone";
import Pagination from "@material-ui/lab/Pagination";
import { Instagram } from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";

const moment = require("moment");
const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Heading,
    Indent,
    Table,
    TableToolbar,
    Link,
    List,
    MediaEmbed,
    CodeBlock,
    ImageResize,
    ImageToolbar,
    ImageStyle,
  ],
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "mediaEmbed",
    "insertTable",
    "codeBlock",
  ],
  mediaEmbed: {
    previewsInData: true
  },
  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },

  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  codeBlock: {
    languages: [
      // Do not render the CSS class for the plain text code blocks.
      { language: "plaintext", label: "Plain text", class: "" },

      // Use the "php-code" class for PHP code blocks.
      { language: "php", label: "PHP", class: "php-code" },

      // Use the "js" class for JavaScript code blocks.
      // Note that only the first ("js") class will determine the language of the block when loading data.
      {
        language: "javascript",
        label: "JavaScript",
        class: "js javascript js-code",
      },

      // Python code blocks will have the default "language-python" CSS class.
      { language: "python", label: "Python" },
    ],
  },
};

const useStyles = makeStyles({
  root: {
    minHeight: 206,
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardbody: {
    padding: "0 10px 10px 10px",
    borderRadius: "8px",
  },
  eventbanner: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "100px",
    objectFit: "fill",
    maxWidth: "100%",
  },
  eventName: {
    fontWeight: 500,
    display: "-webkit-box",
    overflow: "hidden",
    "-mozBoxOrient": "vertical",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": 2,
  },
  moreOptions: {
    background: "#ffffff",
    position: "absolute",
    right: "10px",
    top: "6px",
    borderRadius: "50px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f44d5e",
    fontSize: "20px",
  },
  optionMenu: {
    boxShadow: "none",
    border: "1px solid #dbdbdb",
    "& div": {
      boxShadow: "none",
      border: "1px solid #dbdbdb",
    },
  },
  optionMenuItem: {
    boxShadow: "none",
  },
  createEventDrawer: {
    width: "40%",
  },
  creatEventHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    overflowX: "hidden",
  },
  spacingCreateEvent: {
    padding: "10px 30px 10px 30px",
    height: "calc(100vh - 142px)",
    overflow: "auto",
  },
  eventType: {
    width: "50%",
    textAlign: "center",
    color: "gray",
    marginBottom: 0,
    padding: "8px",
  },
  border: {
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
  },
  selectedEventType: {
    background: "#f44d5e",
    color: "#ffffff",
    width: "50%",
    textAlign: "center",
    marginBottom: 0,
    padding: "8px",
  },
  textField: {
    marginRight: 10,
    border: "1px solid rgba(53, 53, 53, 0.17)",
    borderRadius: "4px",
    color: "#353535",
    width: "inherit",
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
  saveBtn: {
    border: "transparent",
    width: "150px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
});
var FileSaver = require("file-saver");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function VirtualBooth(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [openGroupDialog, setOpenGroupDialog] = React.useState(false);

  const [boothSize, setBoothSize] = useState("large");
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const [currentSelectImage, setCurrentSelectImage] = useState("image");
  const [filterBoothSize, setFilterBoothSize] = useState("");
  const [currentSelectPremiumBooth, setCurrentSelectPremiumBooth] =
    useState("");
  const [website, setWebsite] = React.useState("");
  const [linkedIn, setLinkedIn] = React.useState("");
  const [twitter, setTwitter] = React.useState("");
  const [fb, setfb] = React.useState("");
  const [insta, setInsta] = React.useState("");
  const [whatsApp, setWhatsApp] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [companyLogoImg, setCompanyLogoImg] = useState([]);
  const [comapanyTitle, setComapanyTitle] = useState("");
  const [contactUsEmail, setContactUsEmail] = useState("");
  const [logo, setLogo] = useState([]);
  const [aboutUs, setAboutUs] = useState("");
  const [pageBanner, setPageBanner] = useState([]);
  const [smallBanner, setSmallBanner] = useState([]);
  const [spotLightBanner, setSpotLightBanner] = useState([]);
  const [sponseredProductLabel, setSponseredProductLabel] = useState("");
  const [files, setFiles] = useState([]);
  const [priority, setPriority] = useState(1);
  const [contactNumber, setContactNumber] = useState("");
  const [sponsershipCategoryList, setsponsershipCategoryList] = useState([]);
  const [sponsershipType, setSponsershipType] = useState({});
  const [tags, setTags] = useState([]);
  const [address, setAddress] = useState("");
  const [isfeatured, setIsFeatured] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [SelectedList, setSelectedList] = React.useState([]);
  const [peopleData, setPeopleData] = useState([]);
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const [MasterChecked, setMasterChecked] = React.useState(false);
  const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  const [createCategory, setCreateCategory] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [isEditCategory, setIsEditCategory] = React.useState("");
  const [allExhibitors, setAllExhibitors] = React.useState([]);
  const [limit, setLimit] = React.useState(5);
  const [selectedPage, setSelectedPage] = useState(1);
  const [exhibitorsCount, setExhibitorsCount] = useState(0);
  const [scroll, setScroll] = React.useState("paper");
  const [sortType, setSortType] = useState("");
  const [filterCategory, setFilterCategory] = useState([]);
  const indexOfLastPost = selectedPage * limit;
  const indexOfFirstPost = indexOfLastPost - limit;
  const currentexhibitors = allExhibitors?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCategoryOptions(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const history = useHistory();

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const opendeletePeople = () => {
    setSelectedList(allExhibitors.filter((e) => e.selected));
    setOpenDeleteDialog(true);
    setOpenGroupDialog(false);
  };

  const [drawer, setDrawer] = React.useState({
    filter: false,
    addSession: false,
  });

  const onMasterCheck = (e) => {
    let tempList = allExhibitors;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));

    //Update State
    setAllExhibitors(tempList);
    setMasterChecked(e.target.checked);
    setSelectedList(allExhibitors.filter((e) => e.selected));
  };

  // Update List Item's state and Master Checkbox State
  const onItemCheck = (e, item) => {
    let tempList = allExhibitors;
    tempList.map((user) => {
      if (user._id === item._id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = allExhibitors.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;

    // Update State
    setAllExhibitors(tempList);
    setMasterChecked(totalItems === totalCheckedItems);
    setSelectedList(allExhibitors.filter((e) => e.selected));
  };
  const onSelecthandleChange = (selectedOption) => {
    console.log(`Option selected:`, selectedOption);
    setFilterCategory(selectedOption);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setEnd(!end);
  };
  const createNewCategory = () => {
    if (isEditCategory === "") {
      const newTrack = {
        title: categoryTitle,
        eventId: props.match.params.eventId,
        type: "booth",
      };
      axios
        .post(`${BOOTCAMP_API}/events/createSponserCategory`, newTrack)
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setCreateCategory(false);
            let categoryResponse = response.data.result.data;
            categoryResponse.label = categoryResponse.title;
            categoryResponse.value = categoryResponse._id;
            setsponsershipCategoryList((prev) => [...prev, categoryResponse]);
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    } else {
      const newTrack = {
        title: categoryTitle,
      };
      axios
        .put(
          `${BOOTCAMP_API}/events/updateSponserCategory/${isEditCategory}`,
          newTrack
        )
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrormesage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setCreateCategory(false);
            setsponsershipCategoryList((prev) =>
              prev.filter((x) => {
                if (x._id === isEditCategory) {
                  x.title = response.data.result.data.title;
                  x.label = response.data.result.data.title;
                  x.value = response.data.result.data._id;
                  setIsEditCategory("");
                  return x;
                }
                return prev;
              })
            );
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    }
  };
  const addVirtualBooth = async () => {
    const formData = new FormData();
    comapanyTitle && formData.append("comapanyTitle", comapanyTitle);
    contactUsEmail && formData.append("contactUsEmail", contactUsEmail);
    companyLogoImg.length !== 0 && formData.append("logo", companyLogoImg[0]);
    website && formData.append("website", website);
    aboutUs && formData.append("aboutUs", aboutUs);
    formData.append("publish", isPublish);
    pageBanner.length !== 0 && formData.append("pageBanner", pageBanner[0]);
    smallBanner.length !== 0 && formData.append("smallBanner", smallBanner[0]);
    spotLightBanner.length !== 0 &&
      formData.append("spotLightBanner", spotLightBanner[0]);
    boothSize && formData.append("boothSize", boothSize);
    sponseredProductLabel &&
      formData.append("sponseredProductLabel", sponseredProductLabel);
    formData.append("featured", isfeatured);
    files.length !== 0 && formData.append("files", files[0]);
    priority && formData.append("priority", priority);
    contactNumber && formData.append("contactNumber", contactNumber);
    formData.append("eventId", props.match.params.eventId);
    sponsershipType && formData.append("sponsershipType", sponsershipType._id);
    fb && formData.append("fb", fb);
    twitter && formData.append("twitter", twitter);
    linkedIn && formData.append("linkedIn", linkedIn);
    insta && formData.append("Instagram", insta);
    whatsApp && formData.append("whatsApp", whatsApp);
    address && formData.append("address", address);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .post(
        `${BOOTCAMP_API}/events/createCompanyExhibitors/booth`,
        formData,
        config
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          const newBooth = {
            ...response.data.result.addedExhibitor,
            companyData: response.data.result.data,
            selected: false,
            sponsershipData: {
              label: sponsershipType.title,
              value: sponsershipType._id,
              title: sponsershipType.title,
              _id: sponsershipType._id,
            },
          };
          setAllExhibitors((prev) => [...prev, newBooth]);
          setDrawer({ ...drawer, addSession: false });
          resetVirtualBoothData();
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const handlePaginationChange = (e, value) => {
    setSelectedPage(value);
  };
  const resetVirtualBoothData = () => {
    setComapanyTitle("");
    setContactUsEmail("");
    setPriority(0);
    setSponseredProductLabel("");
    setAboutUs("");
    setAddress("");
    // setBoothSize(0);
    setContactNumber("");
    setfb("");
    setInsta("");
    setLinkedIn("");
    setTwitter("");
    setWebsite("");
  };

  const deleteBooth = () => {
    const deletebooth = [];
    SelectedList.map((x) => {
      deletebooth.push(x._id);
    });
    axios
      .delete(
        `${BOOTCAMP_API}/events/deleteCompanyExhibitors/${props.match.params.eventId}`,
        {
          data: {
            exhibitorsIds: deletebooth,
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setOpenDeleteDialog(false);
          getAllExhibitors();
          setSelectedList([]);
          getAllExhibitors();
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  const deleteCategory = (category) => {
    axios
      .delete(
        `${BOOTCAMP_API}/events/deleteSponserCategoryData/${category._id}`
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setsponsershipCategoryList((prev) =>
            prev.filter((x) => {
              return x._id !== category._id;
            })
          );
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  const getCategoryList = () => {
    axios
      .get(
        `${BOOTCAMP_API}/events/getSponserCategoryUnderEvent/${props.match.params.eventId}/booth`
      )
      .then((response) => {
        let categoryList = response.data.result.data;
        categoryList.filter((x) => {
          x.label = x.title;
          x.value = x._id;
        });
        setsponsershipCategoryList(categoryList);
      })
      .catch((err) => console.log(err));
  };

  const getAllExhibitors = () => {
    const paramData = {
      limit: limit,
      current_page: selectedPage,
    };
    sortType && (paramData.sortType = sortType);
    const filtercat = [];
    filterCategory.filter((x) => {
      filtercat.push(x.value);
    });
    filtercat.length > 0 && (paramData.sponserCategory = filtercat.toString());
    filterBoothSize && (paramData.boothSize = filterBoothSize);
    currentSelectPremiumBooth &&
      (paramData.featuredType = currentSelectPremiumBooth);
    axios
      .get(
        `${BOOTCAMP_API}/events/getAllCompanyExhibitorsUnderEventId/${props.match.params.eventId}/booth`,
        {
          params: paramData,
        }
      )
      .then((response) => {
        let exhibitorsResponse = response.data.result.data.filter(
          (x, index) => {
            x.selected = false;
            return x;
          }
        );
        setAllExhibitors(exhibitorsResponse);
        setExhibitorsCount(response.data.result.totalCount);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllExhibitors();
  }, [
    limit,
    selectedPage,
    sortType,
    filterCategory.length,
    filterBoothSize,
    currentSelectPremiumBooth,
  ]);

  const getEventData = () => {
    axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getEventData();
    getCategoryList();
  }, []);

  return (
    <EventLayout>
      {start && (
        <Snackbar open={start} onClose={handleClose}>
          <>
            <Alert onClose={handleClose} severity="success">
              <DownloadLoader pexal="55px" />
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {end && (
        <Snackbar open={end} onClose={handleCloseEnd}>
          <>
            <Alert onClose={handleCloseEnd} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        {Object.keys(eventData).length > 0 ? (
          <div className="w-100 event-overview">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <br />
                <h4 className="mb-0">Virtual Booth</h4>
                <p>
                  Adding sessions allows you to configure streaming,
                  registrations, visibility, and more.
                </p>
              </div>
            </div>
            <div className="event-cardHld">
              <div className="d-flex align-items-center justify-content-between">
                <form className="app-search">
                  <div className="app-search-box">
                    <div className="input-group pl-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search"
                      />
                      <div className="input-group-append">
                        <button className="btn" type="submit">
                          <i className="fe-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={handleClickMenu}
                      className="event-primary-btn"
                    >
                      Sort Booths
                      <i className="mdi mdi-menu-down ml-1"></i>
                    </button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorElMenu}
                      keepMounted
                      open={Boolean(anchorElMenu)}
                      onClose={() => setAnchorElMenu(null)}
                    >
                      <MenuItem
                        onClick={() => (
                          setSortType("a-z"), setAnchorElMenu(null)
                        )}
                      >
                        Ascending(A-Z)
                      </MenuItem>
                      <MenuItem
                        onClick={() => (
                          setSortType("z-a"), setAnchorElMenu(null)
                        )}
                      >
                        Descending(Z-A)
                      </MenuItem>
                      <MenuItem
                        onClick={() => (
                          setSortType("createdAt"), setAnchorElMenu(null)
                        )}
                      >
                        Date Created
                      </MenuItem>
                    </Menu>
                  </div>

                  <button
                    className="mr-2 event-outlined-btn"
                    onClick={toggleDrawer("filter", true)}
                  >
                    <i className="mdi mdi-filter-outline"></i>
                  </button>
                  <button
                    className="mr-2 event-primary-btn"
                    onClick={toggleDrawer("addSession", true)}
                  >
                    Add Virtual Booths{" "}
                    <i className="mdi mdi-menu-down ml-1"></i>
                  </button>
                </div>
              </div>
              <hr />

              <table className="table">
                <thead>
                  {SelectedList.length === 0 && (
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          className=""
                          checked={MasterChecked}
                          id="mastercheck"
                          onChange={(e) => onMasterCheck(e)}
                        />
                      </th>
                      <th scope="col">BOOTHS</th>
                      <th scope="col">CONTACT</th>
                      <th scope="col">BOOTH SIZE</th>
                      <th scope="col">PRIORITY</th>
                      <th scope="col">CATEGORY</th>
                      <th scope="col">SOCIAL LINKS</th>
                    </tr>
                  )}
                  {SelectedList.length === 1 && (
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          className=""
                          checked={MasterChecked}
                          id="mastercheck"
                          onChange={(e) => onMasterCheck(e)}
                        />
                      </th>
                      <th scope="col">{SelectedList.length} Selected</th>
                      <th scope="col">
                        <div
                          className="cursor-pointer"
                          onClick={() =>
                            history.push(
                              `/event/${props.match.params.eventId}/virtual-booth/${SelectedList[0]._id}/basic-detail`
                            )
                          }
                        >
                          <span className="mr-1">
                            <i className="mdi mdi-pencil"></i>
                          </span>
                          <span className="brand-color">Edit</span>
                        </div>
                      </th>
                      <th scope="col">
                        <div
                          className="cursor-pointer"
                          onClick={opendeletePeople}
                        >
                          <span className="mr-1">
                            <i className="mdi mdi-delete"></i>
                          </span>
                          <span className="brand-color">Delete</span>
                        </div>
                      </th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  )}
                  {SelectedList.length > 1 && (
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          className=""
                          checked={MasterChecked}
                          id="mastercheck"
                          onChange={(e) => onMasterCheck(e)}
                        />
                      </th>
                      <th scope="col">{SelectedList.length} Selected</th>
                      <th scope="col">
                        <div
                          className="cursor-pointer"
                          onClick={opendeletePeople}
                        >
                          <span className="mr-1">
                            <i className="mdi mdi-delete"></i>
                          </span>
                          <span className="brand-color">Delete</span>
                        </div>
                      </th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                      <th scope="col"></th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {allExhibitors.map((exhibitor) => (
                    <tr
                      key={exhibitor._id}
                      className={exhibitor.selected ? "selected" : ""}
                    >
                      <th scope="row">
                        <input
                          type="checkbox"
                          checked={exhibitor.selected}
                          className=""
                          id="rowcheck{user.id}"
                          onChange={(e) => onItemCheck(e, exhibitor)}
                        />
                      </th>
                      <td>{exhibitor.companyData.comapanyTitle || "-"}</td>
                      <td>{exhibitor.companyData.contactUsEmail || "-"}</td>
                      <td style={{ textTransform: "uppercase" }}>
                        {exhibitor.boothSize}
                      </td>
                      <td>{exhibitor.priority || "-"}</td>
                      <td className="peopleGroup">
                        <span className="groupName">
                          {exhibitor?.sponsershipData?.title}
                        </span>
                      </td>
                      <td>
                        <div className="">
                          {exhibitor.companyData.website && (
                            <span
                              className="socialmediabtn mr-2"
                              onClick={() =>
                                window.open(
                                  exhibitor.companyData.website,
                                  "_blank"
                                )
                              }
                            >
                              <i
                                className="fa fa-globe"
                                style={{ color: "#f44d5e" }}
                              ></i>
                            </span>
                          )}
                          {exhibitor.companyData.fb && (
                            <span
                              className="socialmediabtn mr-2"
                              onClick={() =>
                                window.open(exhibitor.companyData.fb, "_blank")
                              }
                            >
                              <i
                                className="mdi mdi-facebook"
                                style={{ color: "#355087" }}
                              ></i>
                            </span>
                          )}
                          {exhibitor.companyData.linkedIn && (
                            <span
                              className="socialmediabtn mr-2"
                              onClick={() =>
                                window.open(
                                  exhibitor.companyData.linkedIn,
                                  "_blank"
                                )
                              }
                            >
                              <i
                                className="mdi mdi-linkedin"
                                style={{ color: "#0e76a8" }}
                              ></i>
                            </span>
                          )}
                          {exhibitor.companyData.twitter && (
                            <span
                              className="socialmediabtn mr-2"
                              onClick={() =>
                                window.open(
                                  exhibitor.companyData.twitter,
                                  "_blank"
                                )
                              }
                            >
                              <i
                                className="mdi mdi-twitter"
                                style={{ color: "#00acee" }}
                              ></i>
                            </span>
                          )}
                          {exhibitor.companyData.instagram && (
                            <span
                              className="socialmediabtn mr-2"
                              onClick={() =>
                                window.open(
                                  exhibitor.companyData.instagram,
                                  "_blank"
                                )
                              }
                            >
                              <i
                                className="mdi mdi-instagram"
                                style={{ color: "#cf005d" }}
                              ></i>
                            </span>
                          )}
                          {exhibitor.companyData.instagram && (
                            <span
                              className="socialmediabtn mr-2"
                              onClick={() =>
                                window.open(
                                  exhibitor.companyData.instagram,
                                  "_blank"
                                )
                              }
                            >
                              <i
                                class="mdi mdi-whatsapp"
                                style={{ color: "#00C957" }}
                              ></i>
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex align-items-center justify-content-between">
                <select
                  name="page"
                  id="page"
                  value={limit}
                  onChange={(e) => {
                    setLimit(e.target.value);
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                </select>
                <Pagination
                  count={
                    exhibitorsCount % limit === 0
                      ? parseInt(exhibitorsCount / limit)
                      : parseInt(exhibitorsCount / limit) + 1
                  }
                  defaultPage={1}
                  page={Number(selectedPage)}
                  variant="outlined"
                  shape="rounded"
                  // className={classes.root}
                  onChange={handlePaginationChange}
                  showFirstButton
                  showLastButton
                />
              </div>
              <br />
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
      </Row>
      <Drawer
        anchor="right"
        open={drawer.addSession}
        onClose={toggleDrawer("addSession", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Add Virtual Booths</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("addSession", false)}
                onKeyDown={toggleDrawer("addSession", false)}
              ></i>
            </Col>
          </div>

          <Col lg={12} className={classes.spacingCreateEvent}>
            <div className="row">
              <div className="col-4">
                <div className="addSession mt-2">
                  <DropzoneArea
                    acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                    onChange={(files) => setCompanyLogoImg(files)}
                    maxFileSize={9242880}
                  />
                </div>
              </div>
              <div className="col-8">
                <div>
                  <label htmlFor="title" className="event-label">
                    Booth Name
                    <span style={{ color: "red", fontSize: "10px" }}>*</span>
                  </label>
                  <input
                    id="title"
                    type="text"
                    className="form-control"
                    placeholder="Enter Booth Name"
                    value={comapanyTitle}
                    onChange={(e) => setComapanyTitle(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <label htmlFor="title" className="event-label">
                    Category
                  </label>
                  <div className="position-relative">
                    <div
                      className="customSelectControls"
                      onClick={() =>
                        setShowCategoryOptions(!showCategoryOptions)
                      }
                    >
                      <span>
                        {Object.keys(sponsershipType).length > 0
                          ? sponsershipType.title || sponsershipType.label
                          : "Select Category"}
                      </span>
                    </div>
                    {showCategoryOptions && (
                      <div className="customDropDown" ref={wrapperRef}>
                        {!createCategory ? (
                          <button
                            className="event-outlined-btn mb-2 ml-2"
                            onClick={() => setCreateCategory(true)}
                          >
                            ADD
                            <i className="mdi mdi-plus-circle-outline ml-1"></i>
                          </button>
                        ) : (
                          <div className="d-flex px-2 createCategoryHld">
                            <input
                              type="text"
                              placeholder="Enter category"
                              className="form-control"
                              value={categoryTitle}
                              onChange={(e) => setCategoryTitle(e.target.value)}
                            />
                            <div className="createcategoryinputOptions">
                              {categoryTitle ? (
                                <span
                                  onClick={createNewCategory}
                                  className="cursor-pointer"
                                >
                                  <i
                                    className="mdi mdi-checkbox-marked brand-color"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                </span>
                              ) : (
                                <span className="cursor-pointer">
                                  <i
                                    className="mdi mdi-checkbox-marked"
                                    style={{
                                      fontSize: "24px",
                                      color: "rgba(0, 0, 0, 0.26)",
                                    }}
                                  ></i>
                                </span>
                              )}
                              <span
                                className="cursor-pointer"
                                onClick={() => setCreateCategory(false)}
                              >
                                <i
                                  className="mdi mdi-close-box-outline brand-color"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </span>
                            </div>
                          </div>
                        )}

                        <hr />
                        <div className="optionsHld">
                          {sponsershipCategoryList.map((category) => (
                            <p
                              key={category._id}
                              className="mb-0"
                              onClick={() => (
                                setSponsershipType(category),
                                setShowCategoryOptions(false),
                                setCreateCategory(false)
                              )}
                            >
                              {category.title}
                              <span
                                style={{ fontSize: "24px", float: "right" }}
                                className="lightGrey-color"
                                onClick={(e) => (
                                  e.stopPropagation(), deleteCategory(category)
                                )}
                              >
                                <i className="mdi mdi-delete"></i>
                              </span>
                              <span
                                style={{ fontSize: "24px", float: "right" }}
                                className="lightGrey-color mr-2"
                                onClick={(e) => (
                                  e.stopPropagation(),
                                  setCreateCategory(true),
                                  setIsEditCategory(category._id),
                                  setCategoryTitle(category.title)
                                )}
                              >
                                <i className="mdi mdi-pencil-box-outline"></i>
                              </span>
                            </p>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-6">
                <label htmlFor="title" className="event-label">
                  Booth Size
                </label>
                <div className="d-flex align-items-center justify-content-between virtualBoothSize">
                  <div
                    className="virtualBoothSizeSection"
                    onClick={() => setBoothSize("large")}
                    style={{
                      backgroundColor: boothSize === "large" ? "#f44D54" : null,
                      borderRadius:
                        boothSize === "large" ? "5px 0px 0px 5px" : null,
                      color: boothSize === "large" ? "#ffffff" : null,
                    }}
                  >
                    <p>Large</p>
                  </div>
                  <div
                    className="virtualBoothSizeSection"
                    onClick={() => setBoothSize("medium")}
                    style={{
                      backgroundColor:
                        boothSize === "medium" ? "#f44D54" : null,
                      color: boothSize === "medium" ? "#ffffff" : null,
                    }}
                  >
                    <p>Medium</p>
                  </div>
                  <div
                    className="virtualBoothSizeSection"
                    onClick={() => setBoothSize("small")}
                    style={{
                      backgroundColor: boothSize === "small" ? "#f44D54" : null,
                      borderRadius:
                        boothSize === "small" ? "0px 5px 5px 0px" : null,
                      color: boothSize === "small" ? "#ffffff" : null,
                    }}
                  >
                    <p>Small</p>
                  </div>
                </div>
              </div>

              <div className="col-6">
                <label htmlFor="title" className="event-label">
                  Priority
                </label>
                <input
                  id="priority"
                  type="number"
                  className="form-control"
                  placeholder="Enter Priority"
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                />
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-12">
                <label htmlFor="title" className="event-label">
                  Sponsored Product/Service Label
                </label>
                <input
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder="Enter Sponsored Product/Service Label"
                  value={sponseredProductLabel}
                  onChange={(e) => setSponseredProductLabel(e.target.value)}
                />
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-12">
                <div className="virtualBoothAddBannerButtonText">
                  <p>Listing Page Banner 385x250px | 5MB (Maximum)</p>
                  <p>
                    This banner is displayed on a booth card on the exhibitor's
                    list.
                  </p>
                </div>

                <div>
                  <div className="addSession mt-2">
                    <DropzoneArea
                      acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                      onChange={(files) => setPageBanner(files)}
                      maxFileSize={9242880}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-12">
                <div className="virtualBoothAddBannerButtonText">
                  <p>Small Banner 385x250px | 5MB (Maximum)</p>
                  <p style={{ marginBottom: "0px" }}>
                    This banner is displayed on a booth card on the mobile
                    app,reception and sessions page.
                    <br />
                    <small>
                      A cropped version of the default image on mobile app will
                      be placed automatically if a separate imgae is not added
                      here
                    </small>
                  </p>
                </div>
                <div>
                  <div className="addSession mt-2">
                    <DropzoneArea
                      acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                      onChange={(files) => setSmallBanner(files)}
                      maxFileSize={9242880}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-12">
                <div className="row mt-3 mb-3">
                  <div className="col-12">
                    <div className="virtualBoothAddBannerButtonText">
                      <p>Spotlight Banner</p>
                      <p>
                        This banner is displayed on a booth card on the
                        exhibitor's list.
                      </p>
                    </div>

                    <div className="mt-2">
                      <div
                        className="col-4 d-flex align-items-center justify-content-between virtualBoothSize"
                        style={{ padding: "0px" }}
                      >
                        <div
                          className="virtualBoothSizeSection"
                          onClick={() => setCurrentSelectImage("image")}
                          style={{
                            backgroundColor:
                              currentSelectImage === "image" ? "#f44D54" : null,
                            borderRadius:
                              currentSelectImage === "image"
                                ? "5px 0px 0px 5px"
                                : null,
                            color:
                              currentSelectImage === "image" ? "#ffffff" : null,
                            width: "50%",
                          }}
                        >
                          <p>Image</p>
                        </div>
                        <div
                          className="virtualBoothSizeSection"
                          onClick={() => setCurrentSelectImage("video")}
                          style={{
                            backgroundColor:
                              currentSelectImage === "video" ? "#f44D54" : null,
                            borderRadius:
                              currentSelectImage === "video"
                                ? "0px 5px 5px 0px"
                                : null,
                            color:
                              currentSelectImage === "video" ? "#ffffff" : null,
                            width: "50%",
                          }}
                        >
                          <p>Video</p>
                        </div>
                      </div>
                      <div>
                        <div className="addSession mt-2">
                          <DropzoneArea
                            acceptedFiles={[
                              "image/jpeg",
                              "image/png",
                              "image/bmp",
                            ]}
                            onChange={(files) => setSpotLightBanner(files)}
                            maxFileSize={9242880}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-3 mb-3">
              <label htmlFor="title" className="event-label">
                About
              </label>
              <div className={classes.editor}>
                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  data={aboutUs}
                  onInit={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setAboutUs(data);
                  }}
                />
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-6">
                <label htmlFor="title" className="event-label">
                  Mobile Number
                </label>
                <input
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder="Enter Mobile Number"
                  value={contactNumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                />
              </div>

              <div className="col-6">
                <label htmlFor="title" className="event-label">
                  Contact Email ID
                </label>
                <input
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder="Enter Contact Email ID"
                  value={contactUsEmail}
                  onChange={(e) => setContactUsEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-12">
                <label htmlFor="title" className="event-label">
                  Address
                </label>
                <textarea
                  style={{ height: "150px" }}
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder="Enter address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-12">
                <div className="virtualBoothAddBannerButtonText">
                  <p>File</p>
                  <p>.DOCX,.DOC,.PPT,.PPTX,.PDF,.XLS,.XLSX,|100MB(Maximum)</p>
                </div>
                <div>
                  <div className="addSession mt-2">
                    <DropzoneArea
                      acceptedFiles={[
                        "application/pdf,.doc,.docx,.xls,.xlsx,.csv,.tsv,.ppt,.pptx,.pages,.odt,.rtf",
                      ]}
                      onChange={(files) => setFiles(files)}
                      maxFileSize={9242880}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3 mb-3 pl-2 pr-2">
              <FormControl className={classes.formControl}>
                <label htmlFor="title">Social Links</label>
                <input
                  id="title"
                  type="text"
                  className="form-control mb-2"
                  placeholder="https://website"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <input
                  id="title"
                  type="text"
                  className="form-control mb-2"
                  placeholder="https://linkedIn"
                  value={linkedIn}
                  onChange={(e) => setLinkedIn(e.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <input
                  id="title"
                  type="text"
                  className="form-control mb-2"
                  placeholder="https://twitter"
                  value={twitter}
                  onChange={(e) => setTwitter(e.target.value)}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <input
                  id="title"
                  type="text"
                  className="form-control mb-2"
                  placeholder="https://fb"
                  value={fb}
                  onChange={(e) => setfb(e.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <input
                  id="title"
                  type="text"
                  className="form-control mb-2"
                  placeholder="https://insta"
                  value={insta}
                  onChange={(e) => setInsta(e.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <input
                  id="title"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Enter WhatsApp Number"
                  value={whatsApp}
                  onChange={(e) => setWhatsApp(e.target.value)}
                />
              </FormControl>
            </div>

            {/* <div className="mt-3 mb-3">
              <div className="mt-3">
                <label htmlFor="title" className="event-label">
                  Custom Tags
                </label>
                <input
                  id="title"
                  type="text"
                  className="form-control"
                  placeholder="Enter tags"
                />
              </div>
            </div> */}

            {/* <div>
              <input
                className="virtualBoothCheckBox"
                type="checkbox"
                id="rateBooth"
                name="rateBooth"
                value="Bike"
              />
              <label htmlFor="vehicle1">Let attendees rate this booth</label>
            </div> */}

            <div>
              <input
                className="virtualBoothCheckBox"
                type="checkbox"
                id="featuredBooth"
                name="featuredBooth"
                value={isfeatured}
                onChange={() => setIsFeatured(!isfeatured)}
              />
              <label htmlFor="vehicle2"> Feature this Booth</label>
            </div>

            <div>
              <input
                className="virtualBoothCheckBox"
                type="checkbox"
                id="publishBooth"
                name="publishBooth"
                value={isPublish}
                onChange={() => setIsPublish(!isPublish)}
              />
              <label htmlFor="vehicle2">Publish</label>
            </div>

            <br />
          </Col>
          <div className="pt-3 px-3 d-flex align-items-center justify-content-between">
            <div>
              {comapanyTitle !== "" &&
              companyLogoImg.length > 0 &&
              Object.keys(sponsershipType).length !== 0 ? (
                <button className={classes.createBtn} onClick={addVirtualBooth}>
                  ADD
                </button>
              ) : (
                <button className="event-primary-btn mr-2" disabled>
                  ADD
                </button>
              )}
              <button
                className={classes.cancelBtn}
                onClick={toggleDrawer("addSession", false)}
                onKeyDown={toggleDrawer("addSession", false)}
              >
                CANCEL
              </button>
            </div>
            <div>
              {/* <button className={classes.saveBtn}>SAVE AND ADD MORE</button> */}
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={drawer.filter}
        onClose={toggleDrawer("filter", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Filter By</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("filter", false)}
                onKeyDown={toggleDrawer("filter", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <div>
              <label htmlFor="title">Group</label>
              <Select
                onChange={onSelecthandleChange}
                isMulti
                options={sponsershipCategoryList}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select Group"
              />
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-6">
                <label htmlFor="title" className="event-label">
                  Booth Size
                </label>
                <div className="d-flex align-items-center justify-content-between virtualBoothSize">
                  <div
                    className="virtualBoothSizeSection"
                    onClick={() => setFilterBoothSize("large")}
                    style={{
                      backgroundColor:
                        filterBoothSize === "large" ? "#f44D54" : null,
                      borderRadius:
                        filterBoothSize === "large" ? "5px 0px 0px 5px" : null,
                      color: filterBoothSize === "large" ? "#ffffff" : null,
                    }}
                  >
                    <p>Large</p>
                  </div>
                  <div
                    className="virtualBoothSizeSection"
                    onClick={() => setFilterBoothSize("medium")}
                    style={{
                      backgroundColor:
                        filterBoothSize === "medium" ? "#f44D54" : null,
                      color: filterBoothSize === "medium" ? "#ffffff" : null,
                    }}
                  >
                    <p>Medium</p>
                  </div>
                  <div
                    className="virtualBoothSizeSection"
                    onClick={() => setFilterBoothSize("small")}
                    style={{
                      backgroundColor:
                        filterBoothSize === "small" ? "#f44D54" : null,
                      borderRadius:
                        filterBoothSize === "small" ? "0px 5px 5px 0px" : null,
                      color: filterBoothSize === "small" ? "#ffffff" : null,
                    }}
                  >
                    <p>Small</p>
                  </div>
                </div>
              </div>

              <div className="col-6"></div>
            </div>

            <div className="row mt-3 mb-3">
              <div className="col-12">
                <div className="virtualBoothAddBannerButtonText">
                  <p>Premium Booth</p>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-2">
                  <div
                    className="col-3 d-flex align-items-center justify-content-between virtualBoothSize"
                    style={{ padding: "0px" }}
                  >
                    <div
                      className="virtualBoothSizeSection"
                      onClick={() => setCurrentSelectPremiumBooth("on")}
                      style={{
                        backgroundColor:
                          currentSelectPremiumBooth === "on" ? "#f44D54" : null,
                        borderRadius:
                          currentSelectPremiumBooth === "on"
                            ? "5px 0px 0px 5px"
                            : null,
                        color:
                          currentSelectPremiumBooth === "on" ? "#ffffff" : null,
                        width: "50%",
                      }}
                    >
                      <p>ON</p>
                    </div>
                    <div
                      className="virtualBoothSizeSection"
                      onClick={() => setCurrentSelectPremiumBooth("off")}
                      style={{
                        backgroundColor:
                          currentSelectPremiumBooth === "off"
                            ? "#f44D54"
                            : null,
                        borderRadius:
                          currentSelectPremiumBooth === "off"
                            ? "0px 5px 5px 0px"
                            : null,
                        color:
                          currentSelectPremiumBooth === "off"
                            ? "#ffffff"
                            : null,
                        width: "50%",
                      }}
                    >
                      <p>OFF</p>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn}>Apply</button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("filter", false)}
              onKeyDown={toggleDrawer("filter", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong>Booth deleted once can't be retrieved.</strong>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete this Booth"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deleteBooth}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </EventLayout>
  );
}
