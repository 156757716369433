import React from "react";
import {
	BrowserRouter,
	Switch,
	Route
} from "react-router-dom";
import PrivateRoute from "./auth/helper/PrivateRoute";
import Login from "./pages/auth/Login";
import ErrorPage from "./pages/ErrorPage";
import ForgetPassword from "./pages/account/Register";
import UserProfile from "./pages/User/UserProfile";
import Dashboard from "./pages/Dashboard";
import AllPractice from "./pages/Practice/getAllPractice";
import CreateHackathon from "./pages/Hackathon/CreateHackathon";
import UpdateHackathonPage from "./pages/Hackathon/UpdateHackathonPage";
import UpdatePractice from "./pages/Practice/UpdatePractice";
import CreatePractice from "./pages/Practice/CreatePractice";
import ChartBoard from "./pages/ChartBoard";
import AllUsers from "./pages/GlobalData/AllUsers";
import DownlaodUsers from "./pages/DownloadUserData/DownlaodUsers";
import CreateBootcamp from './pages/Bootcamp/CreateBootcamp';
import UpdateBootcamp from './pages/Bootcamp/UpdateBootcamp';
import GetAllBootcamps from './pages/Bootcamp/GetAllBootcamps';
import GetAllQuestionSets from './pages/Compiler/QuestionSets/GetAllQuestionSets';
import CreateQuestionSet from './pages/Compiler/QuestionSets/CreateQuestionSet';
import UpdateQuestionSet from './pages/Compiler/QuestionSets/UpdateQuestionSet';
import GetAllQuestions from './pages/Compiler/Questions/GetAllQuestions';
import CreateCodingTypeQuestion from './pages/Compiler/Questions/Coding/CreateCodingTypeQuestion';
import UpdateCodingTypeQuestion from './pages/Compiler/Questions/Coding/UpdateCodingTypeQuestion';
import CreateMCQTypeQuestion from './pages/Compiler/Questions/MCQ/CreateMCQTypeQuestion';
import UpdateMCQTypeQuestion from './pages/Compiler/Questions/MCQ/UpdateMCQTypeQuestion';
import AllPracticeModules from "./pages/Practice/AllPracticeModules";
import CreatePracticeModule from "./pages/Practice/CreatePracticeModule";
import UpdatePracticeModule from "./pages/Practice/UpdatePracticeModule";

/* Bootcamp */
import getAllBootcampModules from "./pages/NewBootcamp/getAllBootcampModules";
import CreateBootcampModule from "./pages/NewBootcamp/CreateBootcampModule";
import UpdateBootcampModule from "./pages/NewBootcamp/UpdateBootcampModule";
import AllCourses from "./pages/NewBootcamp/Courses/getAllBootcampCourses";
import AllSections from "./pages/NewBootcamp/Sections/getAllBootcampSections";
import AllTopics from "./pages/NewBootcamp/Topics/getAllBootcampTopics";
import CreateBootcampCourse from "./pages/NewBootcamp/Courses/CreateBootcampCourse";
import UpdateBootcampCourse from "./pages/NewBootcamp/Courses/UpdateBootcampCourse";
import CreateBootcampSection from "./pages/NewBootcamp/Sections/CreateBootcampSection";
import UpdateBootcampSection from "./pages/NewBootcamp/Sections/UpdateBootcampSection";
import CreateBootcampTopic from "./pages/NewBootcamp/Topics/CreateBootcampTopic";
import UpdateBootcampTopic from "./pages/NewBootcamp/Topics/UpdateBootcampTopic";
import CreateMajorBadge from "./pages/MocksNew/CreateMajorBadge";
import MajorBadgeList from "./pages/MocksNew/MajorBadgeList";
import UpdateMajorBadge from "./pages/MocksNew/UpdateMajorBadge";
import MinorBadgesList from "./pages/MocksNew/MinorBadgesList";
import CreateMinorBadge from "./pages/MocksNew/CreateMinorBadge";
import UpdateMinorBadge from "./pages/MocksNew/UpdateMinorBadge";
import CreateAssessmentCategory from "./pages/MocksNew/CreateAssessmentCategory";
import AssessmentCategoryList from "./pages/MocksNew/AssessmentCategoryList";
import UpdateAssessmentCategory from "./pages/MocksNew/UpdateAssessmentCategory";
import SubCategoryList from "./pages/MocksNew/SubcategoryList";
import CreateSubCategory from "./pages/MocksNew/CreateSubCategory";
import UpdateAssessmentSubCategory from "./pages/MocksNew/UpdateAssessmentSubCategory";
import GetAllQuestionSetsNew from "./pages/MocksNew/GetAllQuestionSetsNew";
import CreateQuestionSetNew from "./pages/MocksNew/CreateQuestionSetNew";
import UpdateQuestionSetNew from "./pages/MocksNew/UpdateQuestionSetNew";
import CreateNotification from "./pages/Notifications/CreateNotification";
import AllNotifications from "./pages/Notifications/AllNotifications";
import CreateCategory from "./pages/Hackathon/CreateCategory";
import AllCategories from "./pages/Hackathon/AllCategories";
import AllReviewers from "./pages/Hackathon/AllReviewers";
import CreateReviewer from "./pages/Hackathon/CreateReviewer";
import AllEvents from "./pages/Events";
import Overview from "./pages/Events/Event/Overview";
import Sessions from "./pages/Events/Event/Sessions/Sessions";
import SessionBasicDetails from "./pages/Events/Event/Sessions/SessionBasicDetails";
import SessionStreamOption from "./pages/Events/Event/Sessions/SessionStreamOption";
import Engagement from "./pages/Events/Event/Sessions/Engagement";
import SessionsAdvanced from "./pages/Events/Event/Sessions/SessionsAdvanced";
import Attendees from "./pages/Events/Event/Sessions/Attendees";
import People from "./pages/Events/Event/People";
import VirtualBooth from "./pages/Events/Event/VirtualBooth";
import Branding from "./pages/Events/Event/Branding";
import VirtualBoothDetails from "./pages/Events/Event/virtual-booth/VirtualBoothDetails";
import VirtualBoothProductVideos from "./pages/Events/Event/virtual-booth/VirtualBoothProductVideos";
import VirtualBoothProductImages from "./pages/Events/Event/virtual-booth/VirtualBoothProductImages";
import VirtualBoothTeamMembers from "./pages/Events/Event/virtual-booth/VirtualBoothTeamMembers";
import AllEventsNotification from "./pages/Notifications/AllEventsNotification";
import CreateEventNotification from "./pages/Notifications/CreateEventsNotification";
import Sponsors from "./pages/Events/Event/Sponsors";
import FormPopup from "./pages/Events/Event/FormPopup";
import Store from "./pages/Subscriptions/Store";
import RedeemCoins from "./pages/Subscriptions/RedeemCoins";
import SubscriptionsPlan from "./pages/Subscriptions/SubscriptionsPlan";
import CreateSubscriptionPlan from "./pages/Subscriptions/CreateSubscriptionPlan";
import CreateRedeemCoin from "./pages/Subscriptions/CreateRedeemCoin";
import Coins from "./pages/Subscriptions/Coins";
import CreateCoin from "./pages/Subscriptions/CreateCoin";
import SubscriptionPlanInfo from "./pages/Subscriptions/SubscriptionPlanInfo";
import SubscriptionPlanInfoCreate from "./pages/Subscriptions/SubscriptionPlanInfoCreate";
import SubscriptionPlanFaq from "./pages/Subscriptions/SubscriptionPlanFaq";
import SubscriptionPlanFaqCreate from "./pages/Subscriptions/SubscriptionPlanFaqCreate";
import OrderLists from "./pages/Subscriptions/OrderLists";
import Practices from "./pages/Practices";
import CreatePractices from "./pages/Practices/CreatePractices";
import HackathonSolution from "./pages/Hackathon/HackathonSolution";
import CreateHackathonSolutions from "./pages/Hackathon/CreateHackathonSolutions";
import Skills from "./pages/Skills";
import JobRole from "./pages/JobRole";
/* End Bootcamp*/

const Routes = () => {

	// if (localStorage.getItem("jwt")) {
	// 	let jwt = JSON.parse(localStorage.getItem("jwt"));
	// 	// console.log("jwt", jwt);
	// 	setAuthToken(jwt.token);
	// 	let userId = isAuthenticated().user._id;
	// 	console.log("userId", userId);
	// 	getUserInfo(userId)
	// 		.then((response) => {
	// 			// console.log("user", response.data.user);
	// 			store.dispatch({
	// 				type: "GET_USER_INFO",
	// 				payload: response.data.user,
	// 			});
	// 		})
	// 		.catch((err) => console.log(err));
	// }

	return (
		<BrowserRouter>
			<Switch>
				<Route path="/login" component={Login} />
				<Route path="/forget-password" component={ForgetPassword} />
				<PrivateRoute path="/createnotification" component={CreateNotification} />
				<PrivateRoute path="/notifications" component={AllNotifications} />
				<PrivateRoute path="/create-events-notification" component={CreateEventNotification} />
				<PrivateRoute path="/event-notifications" component={AllEventsNotification} />
				<PrivateRoute path="/blogathons" component={AllCategories} />
				<PrivateRoute path="/create-category" component={CreateCategory} />
				<PrivateRoute path="/reviewers" component={AllReviewers} />
				<PrivateRoute path="/create-reviewer" component={CreateReviewer} />
				<PrivateRoute path="/update-reviewer/:hackathonSlug" component={CreateReviewer} />
				<PrivateRoute path="/update-category/:blogathonSlug" component={CreateCategory} />
				<PrivateRoute path="/events" component={AllEvents} />
				<PrivateRoute exact path="/event/:eventId" component={Overview} />
				<PrivateRoute exact path="/event/:eventId/sessions" component={Sessions} />
				<PrivateRoute exact path="/event/:eventId/sessions/:sessionId/basic-detail" component={SessionBasicDetails} />
				<PrivateRoute exact path="/event/:eventId/sessions/:sessionId/stream-options" component={SessionStreamOption} />
				<PrivateRoute exact path="/event/:eventId/sessions/:sessionId/engagement" component={Engagement} />
				<PrivateRoute exact path="/event/:eventId/sessions/:sessionId/advanced" component={SessionsAdvanced} />
				<PrivateRoute exact path="/event/:eventId/sessions/:sessionId/attendees" component={Attendees} />
				<PrivateRoute exact path="/event/:eventId/people" component={People} />
				<PrivateRoute exact path="/event/:eventId/virtual-booth" component={VirtualBooth} />
				<PrivateRoute exact path="/event/:eventId/sponsors" component={Sponsors} />
				<PrivateRoute exact path="/event/:eventId/virtual-booth/:boothId/basic-detail" component={VirtualBoothDetails} />
				<PrivateRoute exact path="/event/:eventId/virtual-booth/:boothId/team-members" component={VirtualBoothTeamMembers} />
				<PrivateRoute exact path="/event/:eventId/virtual-booth/:boothId/product-images" component={VirtualBoothProductImages} />
				<PrivateRoute exact path="/event/:eventId/virtual-booth/:boothId/product-videos" component={VirtualBoothProductVideos} />
				<PrivateRoute exact path="/event/:eventId/sponsors/:boothId/basic-detail" component={VirtualBoothDetails} />
				<PrivateRoute exact path="/event/:eventId/sponsors/:boothId/team-members" component={VirtualBoothTeamMembers} />
				<PrivateRoute exact path="/event/:eventId/sponsors/:boothId/product-images" component={VirtualBoothProductImages} />
				<PrivateRoute exact path="/event/:eventId/sponsors/:boothId/product-videos" component={VirtualBoothProductVideos} />
				<PrivateRoute exact path="/event/:eventId/branding" component={Branding} />
				<PrivateRoute exact path="/event/:eventId/form-popup" component={FormPopup} />
				{/* Subscriptions */}
				<PrivateRoute exact path="/store" component={Store} />
				<PrivateRoute exact path="/redeem-coins" component={RedeemCoins} />
				<PrivateRoute exact path="/redeem-coins/create" component={CreateRedeemCoin} />
				<PrivateRoute exact path="/redeem-coins/update/:productId" component={CreateRedeemCoin} />
				<PrivateRoute exact path="/coins" component={Coins} />
				<PrivateRoute exact path="/coins/create" component={CreateCoin} />
				<PrivateRoute exact path="/coins/update/:coinId" component={CreateCoin} />
				<PrivateRoute exact path="/subscriptions" component={SubscriptionsPlan} />
				<PrivateRoute exact path="/subscriptions/create" component={CreateSubscriptionPlan} />
				<PrivateRoute exact path="/subscriptions-info" component={SubscriptionPlanInfo} />
				<PrivateRoute exact path="/subscriptions-info/create" component={SubscriptionPlanInfoCreate} />
				<PrivateRoute exact path="/subscriptions-info/update/:infoId" component={SubscriptionPlanInfoCreate} />
				<PrivateRoute exact path="/subscriptions-faq" component={SubscriptionPlanFaq} />
				<PrivateRoute exact path="/subscriptions-faq/create" component={SubscriptionPlanFaqCreate} />
				<PrivateRoute exact path="/subscriptions-faq/update/:faqId" component={SubscriptionPlanFaqCreate} />
				<PrivateRoute exact path="/orders" component={OrderLists} />
				<PrivateRoute exact path="/skills" component={Skills} />
				<PrivateRoute exact path="/job-role" component={JobRole} />
				{/* Subscriptions ends here */}
				<PrivateRoute path="/dashboard" component={Dashboard} />
				{/* <PrivateRoute path="/practice" component={AllPractice} /> */}
				<PrivateRoute path="/bootcamps" component={GetAllBootcamps} />
				<PrivateRoute
					path="/createHackathon"
					component={CreateHackathon}
				/>
				<PrivateRoute
					exact
					path="/hackathon-solutions"
					component={HackathonSolution}
				/>
				<PrivateRoute
					exact
					path="/hackathon-solutions/create"
					component={CreateHackathonSolutions}
				/>
				<PrivateRoute
					exact
					path="/hackathon-solutions/update/:hackathonhref"
					component={CreateHackathonSolutions}
				/>
				{/* <PrivateRoute
					path="/createPractice"
					component={CreatePractice}
				/> */}
				<PrivateRoute
					path="/createBootcamp"
					component={CreateBootcamp}
				/>
				<PrivateRoute
					path="/updateHackathon/:hackathonId"
					component={UpdateHackathonPage}
				/>
				{/* <PrivateRoute
					path="/updatePractice/:practiceId"
					component={UpdatePractice}
				/> */}
				<PrivateRoute
					path="/updatebootcamp/:bootcampId"
					component={UpdateBootcamp}
				/>

				<PrivateRoute path="/getAllQuestionSets" component={GetAllQuestionSets} />
				<PrivateRoute path="/createQuestionSet" component={CreateQuestionSet} />
				<PrivateRoute path="/updateQuestionSet/:questionSetSlug" component={UpdateQuestionSet} />

				<PrivateRoute path="/getAllQuestions/:questionSetSlug" component={GetAllQuestions} />
	
				<PrivateRoute path="/createCodingTypeQuestion/:questionSetSlug" component={CreateCodingTypeQuestion} />
				<PrivateRoute path="/updateCodingTypeQuestion/:questionSetSlug/:questionSlug" component={UpdateCodingTypeQuestion} /> 

				<PrivateRoute path="/createMCQTypeQuestion/:questionSetSlug" component={CreateMCQTypeQuestion} />
				<PrivateRoute path="/updateMCQTypeQuestion/:questionSetSlug/:questionSlug" component={UpdateMCQTypeQuestion} /> 

				<PrivateRoute path="/alluser" component={AllUsers} />
				<PrivateRoute path="/downloadUsers" component={DownlaodUsers} />
				<PrivateRoute path="/profile" component={UserProfile} />
				<PrivateRoute exact path="/" component={ChartBoard} />

				{/* Mock Routes */}

				<PrivateRoute path="/createMajorBadge" component={CreateMajorBadge} />

				<PrivateRoute path="/updateMajorBadge/:majorBadgeId" component={UpdateMajorBadge} />

				<PrivateRoute path="/majorBadgeList" component={MajorBadgeList} />

				<PrivateRoute path="/minorBadgesList/:majorBadgeId" component={MinorBadgesList} />

				<PrivateRoute path="/createMinorBadge/:majorBadgeId" component={CreateMinorBadge} />

				<PrivateRoute path="/updateMinorBadge/:minorBadgeId" component={UpdateMinorBadge} />

				<PrivateRoute path="/createAssessmentCategory" component={CreateAssessmentCategory} />

				<PrivateRoute path="/assessmentCategoryList" component={AssessmentCategoryList} />

				<PrivateRoute path="/updateAssessmentCategory/:assessmentCategoryId" component={UpdateAssessmentCategory} />

				<PrivateRoute path="/subCategoryList/:assessmentCategoryId" component={SubCategoryList} />

				<PrivateRoute path="/createSubCategory/:assessmentCategoryId" component={CreateSubCategory} />

				<PrivateRoute path="/updateAssessmentSubCategory/:assessmentCategoryId/:subCategoryId" component={UpdateAssessmentSubCategory} />

				<PrivateRoute path="/getAllQuestionSetNew/:assessmentCategoryId/:subCategoryId" component={GetAllQuestionSetsNew} />

				<PrivateRoute path="/getAllQuestionSetWithoutSubCategory/:assessmentCategoryId" component={GetAllQuestionSetsNew} />

				<PrivateRoute path="/createQuestionSetNew/:assessmentCategoryId/:subCategoryId" component={CreateQuestionSetNew} />

				<PrivateRoute path="/createQuestionSetNewWithouSubCategory/:assessmentCategoryId" component={CreateQuestionSetNew} />

				<PrivateRoute path="/updateQuestionSetNew/:assessmentCategoryId/:subCategoryId/:questionSetSlug" component={UpdateQuestionSetNew} />

				<PrivateRoute path="/updateQuestionSetNewWithouSubCategory/:assessmentCategoryId/:questionSetSlug" component={UpdateQuestionSetNew} />

				{/* Mock Routes end */}

				{/* Practice routes */}

				<PrivateRoute path="/practiceModules/:moduleId/:moduleObjectId" component={AllPractice} />

				<PrivateRoute
					path="/createPracticeModule"
					component={CreatePracticeModule}
				/>

				<PrivateRoute 
					path='/updatePracticeModule/:moduleId'
					component={UpdatePracticeModule}
				/>


				<PrivateRoute
					path="/updatePractice/:practiceId/:moduleId"
					component={UpdatePractice}
				/>

				<PrivateRoute path="/practiceModules" component={AllPracticeModules} />
				
				<PrivateRoute path="/createPractice/:moduleId/:moduleObjectId" component={CreatePractice} />

				{/* Bootcamp Routes */}
				<PrivateRoute path="/bootcampModules/:moduleID/:moduleObjectID" component={AllCourses} />
				<PrivateRoute path='/sections/:moduleID/:moduleObjectID' component={AllSections}/>
				<PrivateRoute path='/topics/:bootcampID/:moduleID/:moduleObjectID' component={AllTopics}/>


				<PrivateRoute path="/createCourse/:moduleID/:moduleObjectID" component={CreateBootcampCourse} />
				<PrivateRoute path="/updateCourse/:moduleID/:moduleObjectID/:bootcampID" component={UpdateBootcampCourse} />

				<PrivateRoute path="/createSection/:moduleID/:moduleObjectID" component={CreateBootcampSection} />
				<PrivateRoute path="/updateSection/:moduleID/:moduleObjectID/:sectionID" component={UpdateBootcampSection} />

				<PrivateRoute path="/createTopic/:bootcampID/:moduleID/:moduleObjectID" component={CreateBootcampTopic} />
				<PrivateRoute path="/updateTopic/:bootcampID/:moduleObjectID/:sectionName/:topicID" component={UpdateBootcampTopic} />

				<PrivateRoute path="/bootcampModules" component={getAllBootcampModules} />
				<PrivateRoute path="/createBootcampModule" component={CreateBootcampModule} />
				<PrivateRoute path='/updateBootcampModule/:moduleID' component={UpdateBootcampModule}/>

				{/* Practices */}
				<PrivateRoute exact path="/practices" component={Practices} />
				<PrivateRoute exact path="/practices/create" component={CreatePractices} />
				<PrivateRoute exact path="/practices/update/:questionId" component={CreatePractices} />
				
				<Route path="*" component={ErrorPage} />
			</Switch>
		</BrowserRouter>
	);
};

export default Routes;
