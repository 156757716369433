import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../../../components/AuthLayout";
import Loader from "../../../../components/Loader";
import Header from "../../../Header";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { useHistory } from "react-router-dom";
import EasyMDE from "easymde";
import QuestionService from "../../../../services/mocks/QuestionService";
import QuestionSetService from "../../../../services/mocks/QuestionSetService";
import Skills from "../Skills/Skill";
import JobRole from "../JobRole/JobRole";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },

  formControl: {
    margin: 0,
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UpdateMCQTypeQuestion(props) {
  const classes = useStyles();
  const history = useHistory();
  // let MDE;
  const [fullscreen, setFullsreen] = useState(false);
  const [messageData, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [duration, setDuration] = useState(0);
  const [options, setOptions] = useState([
    {
      option: "",
      is_correct: false,
    },
  ]);
  const [score, setScore] = useState(0);
  const [question, setQuestion] = useState({});
  const [questionSet, setQuestionSet] = useState({});
  const [onSubmit, setOnSubmit] = useState(false);
  const [skillSet, setSkillSet] = useState([]);
  const [jobRole, setJobRole] = useState([]);

  let MDE;

  const addNewOption = () => {
    setOptions([
      ...options,
      {
        option: "",
        is_correct: false,
      },
    ]);
  };

  const deleteCurrentOption = (position) => {
    const newOptions = options.slice();
    newOptions.splice(position, 1);
    setOptions(newOptions);
  };

  const handleClose = () => {
    setStatus(!status);
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };

  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };

  const GetQuestion = async () => {
    try {
      const question = await QuestionService.FetchQuestion(
        props.match.params.questionSlug
      );
      if (question) {
        // MDE.value(question.description);
        setQuestion(question);
        setTitle(question.title);
        // setDescription(question.description);
        setDuration(question.duration);
        setDifficulty(question.difficulty);
        setScore(question.score);
        setOptions(question.options);
        setSkillSet(question.skills);
        MDE.value(question.title);
        setJobRole(question.jobRole || [])
      } else {
        setErrorStatus(true);
        setErrorMessage("Exception in creating new Question Set!");
        setStatusLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetQuestion();

    MDE = new EasyMDE({
      element: document.getElementById("body"),
      autoDownloadFontAwesome: true,
      autofocus: true,
      status: false,
      singleLineBreaks: false,
      onToggleFullScreen: (opend) => setFullsreen(opend),
    });

    MDE.codemirror.on("change", () => {
      setTitle(MDE.value());
    });

    (async () => {
      try {
        const response = await QuestionSetService.FetchQuestionSetBySlug(
          props.match.params.questionSetSlug
        );
        if (response) {
          setQuestionSet(response);
        } else {
          setErrorStatus(true);
          setErrorMessage("Exception in fetching Question Set!");
          setStatusLoader(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [MDE]);

  const UpdateQuestion = async () => {
    try {
      setOnSubmit(true);
      if (
        title === "" ||
        parseInt(duration) === 0 ||
        difficulty === "" ||
        parseInt(score) === 0 ||
        options ===
          [
            {
              option: "",
              is_correct: false,
            },
          ] ||
        skillSet?.length === 0 ||
        jobRole?.length === 0
      ) {
        setErrorStatus(true);
        setErrorMessage("Field Missing! All Feild Required !!");
      } else {
        try {
          const jobroleIds = [];
          jobRole.filter((x) => {
            jobroleIds.push(x._id);
          });
          const updating_question = {
            type: "MCQ",
            title: title,
            description: description,
            duration: duration, //in seconds
            difficulty: difficulty,
            score: score,
            options: options,
            skills: skillSet,
            jobRole: jobroleIds,
          };
          const response = await QuestionService.UpdateQuestion(
            question.question_set._id,
            question._id,
            updating_question
          );
          if (response) {
            setStatus(true);
            setMessage("Updated question successfully!");
            setStatusLoader(false);
            setTimeout(() => {
              history.push(
                `/getAllQuestions/${props.match.params.questionSetSlug}`
              );
            }, 2500);
          } else {
            setErrorStatus(true);
            setErrorMessage("Exception in creating new Question Set!");
            setStatusLoader(false);
          }
        } catch (error) {
          setErrorStatus(true);
          setErrorMessage(error);
          setStatusLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Update MCQ Type Question" />

      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {status && (
              <Snackbar
                open={status}
                autoHideDuration={15000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </Snackbar>
            )}

            {errorStatus && (
              <Snackbar
                open={errorStatus}
                autoHideDuration={15000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            )}

            {statusLoader && (
              <Snackbar
                open={statusLoader}
                // autoHideDuration={95000}
                onClose={handleLoader}
              >
                <Alert onClose={handleLoader} severity="info">
                  <Loader />
                </Alert>
              </Snackbar>
            )}

            <Paper className={classes.paper}>
              <h2>Title</h2>

              <textarea id="body" name="body" value={MDE?.value()} />

              {/* <TextField
                                id="outlined-multiline-static"
                                label="Title"
                                multiline
                                rows={3}
                                variant="outlined"
                                fullWidth={true}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            /> */}

              {/* <br />
                            <br />
                            <h2>Description</h2>

                            <textarea id="body" name="body" value={MDE?.value()} /> */}

              <br />
              <br />

              <h2>Difficulty</h2>

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Difficulty
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={difficulty}
                  onChange={(e) => {
                    setDifficulty(e.target.value);
                  }}
                  label="Difficulty"
                >
                  <MenuItem value="BEGINNER">Beginner</MenuItem>
                  <MenuItem value="INTERMEDIATE">Intermediate</MenuItem>
                  <MenuItem value="ADVANCED">Advanced</MenuItem>
                </Select>
              </FormControl>

              <br />
              <br />

              <h2>Duration (in seconds)</h2>

              <TextField
                id="outlined-multiline-static"
                label="duration (in seconds)"
                type="number"
                rows={1}
                variant="outlined"
                fullWidth={true}
                value={duration}
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Score</h2>

              <TextField
                id="outlined-multiline-static"
                label="score"
                type="number"
                rows={1}
                variant="outlined"
                fullWidth={true}
                value={score}
                onChange={(e) => {
                  setScore(e.target.value);
                }}
              />

              <br />
              <br />
              <Skills skillSet={skillSet} setSkillSet={setSkillSet} />
              <br />
              <br />
              <JobRole setJobRole={setJobRole} jobRole={jobRole} />
              <br />
              <br />

              <h2>Options</h2>

              {options.map((option, index) => {
                return (
                  <>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignContent="center"
                      key={index}
                    >
                      <Grid item xs={8} md={8} sm={8} lg={8}>
                        <TextField
                          id="outlined-multiline-static"
                          label="Option"
                          multiline
                          rows={1}
                          variant="outlined"
                          fullWidth={true}
                          value={option.option}
                          onChange={(e) => {
                            let newOptions = options.slice();
                            let updatedOption = options[index];
                            let newOption = {
                              option: e.target.value,
                              is_correct: updatedOption.is_correct,
                            };
                            newOptions[index] = newOption;
                            setOptions(newOptions);
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} md={2} sm={2} lg={2}>
                        <FormControl
                          variant="outlined"
                          className={classes.formControl}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Answer
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={option.is_correct}
                            onChange={(e) => {
                              let newOptions = options.slice();
                              let updatedOption = options[index];
                              let newOption = {
                                option: updatedOption.option,
                                is_correct: e.target.value,
                              };
                              newOptions[index] = newOption;
                              setOptions(newOptions);
                            }}
                            label="Answer"
                          >
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        md={1}
                        sm={1}
                        lg={1}
                        style={{ margin: "auto", textAlign: "center" }}
                      >
                        <span
                          style={{
                            height: "50px",
                            width: "50px",
                            cursor: "pointer",
                          }}
                          onClick={() => addNewOption()}
                        >
                          <b>ADD MORE</b>
                        </span>
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        md={1}
                        sm={1}
                        lg={1}
                        style={{ margin: "auto", textAlign: "center" }}
                      >
                        {index > 0 && (
                          <ClearOutlinedIcon
                            style={{
                              height: "50px",
                              width: "50px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteCurrentOption(index)}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <br />
                  </>
                );
              })}

              <br />
              <br />

              <Button
                disabled={onSubmit}
                variant="contained"
                color="primary"
                size="large"
                style={{
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  backgroundColor: "rgb(250, 53, 88)",
                }}
                onClick={() => UpdateQuestion()}
              >
                Update Question
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
