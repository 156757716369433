import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import EasyMDE from 'easymde';
import QuestionSetService from "../../services/mocks/QuestionSetService";
import axios from "axios"
import { API } from "../../backend"
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        // textAlign: "center",
        color: theme.palette.text.secondary,
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 400,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 420,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UpdatePracticeModule(props) {
    const otherModuleReferences = [
        {
            key: 'href1',
            value: ''
        },
        {
            key: 'href2',
            value: ''
        },
        {
            key: 'href3',
            value: ''
        },
    ];

    const classes = useStyles();
    const history = useHistory();
    const token = useSelector((store) => store.auth.token);
    let MDE;
    const [fullscreen, setFullsreen] = useState(false);
    const [messageData, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);

    const [questionSet, setQuestionSet] = useState({});
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState('');
    const [duration, setDuration] = useState(0);
    const [difficulty, setDifficulty] = useState("");
    const [onSubmit, setOnSubmit] = useState(false);
    const [shortDescription, setShortDescription] = useState('');
    const [hrefs, setHrefs] = useState(otherModuleReferences);

    const [moduleNo, setModuleNo] = useState("");
    const [publish,setPublish] = useState(false);
    
    
    const getPracticeModuleDetails = () =>{
        
        axios.get(`${API}/practice_module_profile/${props.match.params.moduleId}`)
            .then((response) =>{
                
                // console.log(response.data.practice[0]);
                setTitle(response.data.module.title);
                setDescription(response.data.module.description);
                setShortDescription(response.data.module.short_description);
                setDifficulty(response.data.module.HLevel);
                setModuleNo(response.data.module.module_number);
                setPublish(response.data.module.Publish)
                MDE.value(response.data.module.description);
            })
            .catch((error) =>{
                console.log(error);
            })

    }

    const UpdateModule = () =>{
        setOnSubmit(true);
        setStatusLoader(true);
            if (title === "" || shortDescription === "" || difficulty === "" || description === "" || moduleNo === "") {
                setErrorStatus(true);
                setErrorMessage("Field Missing! All Feild Required !!");
                setStatusLoader(false);
            }

            else{
                axios.put(`${API}/update_practice_module/${props.match.params.moduleId}`,null,
                {
                    params:{
                        "title":title,
                        "description":description,
                        "short_description":shortDescription,
                        "level":difficulty,
                        "Publish":publish,
                        "moduleno":moduleNo
                    }
                })
                .then((response) =>{
                    if(response){

                        setStatus(true);
                        setMessage('Module updated successfully!');
                        setStatusLoader(false);
                        setTimeout(() => {
                            history.push("/practiceModules");
                        }, 2500);
                    }
                    else{
                        setErrorStatus(true);
                        setErrorMessage('Exception in updating practice module!');
                        setStatusLoader(false);
                    }
                })
            }

    }

    useEffect(() => {
        getPracticeModuleDetails();
        //getQuestionSet();
        MDE = new EasyMDE({
            element: document.getElementById('body'),
            autoDownloadFontAwesome: true,
            autofocus: true,
            status: false,
            singleLineBreaks: false,
            onToggleFullScreen: (opend) => setFullsreen(opend),
            initialValue: description
        });
        MDE.codemirror.on('change', () => {
            setDescription(MDE.value())
        });
    }, []);

    const handleClose = () => {
        setStatus(!status);
    };

    const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };
    const handleLoader = () => {
        setStatusLoader(!statusLoader);
    };

    

    return (
        <AuthLayout isMDEFullScreen={fullscreen && fullscreen === true ? true : false}>

            <Header name="Update Practice Module" />

            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        {status && (
                            <Snackbar
                                open={status}
                                autoHideDuration={15000}
                                onClose={handleClose}
                            >
                                <Alert onClose={handleClose} severity="success">
                                    {messageData}
                                </Alert>
                            </Snackbar>
                        )}


                        {errorStatus && (
                            <Snackbar
                                open={errorStatus}
                                autoHideDuration={15000}
                                onClose={handleCloseError}
                            >
                                <Alert
                                    onClose={handleCloseError}
                                    severity="error"
                                >
                                    {errorMessage}
                                </Alert>
                            </Snackbar>
                        )}


                        {statusLoader && (
                            <Snackbar
                                open={statusLoader}
                                // autoHideDuration={95000}
                                onClose={handleLoader}
                            >
                                <Alert onClose={handleLoader} severity="info">
                                    <Loader />
                                </Alert>
                            </Snackbar>
                        )}


                        <Paper className={classes.paper}>
                            <h2>Title</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="title"
                                multiline
                                rows={4}
                                variant="outlined"
                                fullWidth={true}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <h2>Module Number</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Module Number"
                                multiline
                                rows={1}
                                variant="outlined"
                                fullWidth={true}
                                value={moduleNo}
                                onChange={(e) => {
                                    setModuleNo(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <h2>Description</h2>

                            <textarea id="body" name="body" value={MDE?.value()} />

                            <br />
                            <br />

                            <h2>Short Description</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Short Description"
                                multiline
                                rows={2}
                                variant="outlined"
                                fullWidth={true}
                                value={shortDescription}
                                onChange={(e) => {
                                    setShortDescription(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <h2>Difficulty</h2>

                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Difficulty
                            </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={difficulty}
                                    onChange={(e) => {
                                        setDifficulty(e.target.value);
                                    }}
                                    label="Difficulty"
                                >
                                    <MenuItem value="Beginner">
                                        Beginner
                                </MenuItem>
                                    <MenuItem value="Intermediate">
                                        Intermediate
                                </MenuItem>
                                    <MenuItem value="Advanced">
                                        Advanced
                                </MenuItem>
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            {/* <h2>Duration (in seconds)</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="duration (in seconds)"
                                type='number'
                                rows={1}
                                variant="outlined"
                                fullWidth={true}
                                value={duration}
                                onChange={(e) => {
                                    setDuration(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <h2>HREFs (References of Other Module's)</h2>

                            {
                                hrefs.map((href, index) => {
                                   return(
                                        <TextField
                                        style={{
                                            marginTop: '15px'
                                        }}
                                        id="outlined-multiline-static"
                                        label={href.key}
                                        multiline
                                        rows={1}
                                        variant="outlined"
                                        fullWidth={true}
                                        value={href.value}
                                        onChange={(e) => {
                                            let newHrefs = hrefs.slice();
                                            let newHref = {
                                                key: href.key,
                                                value: e.target.value
                                            };
                                            newHrefs[index] = newHref;
                                            setHrefs(newHrefs);
                                        }}
                                    />
                                   )
                                })
                            }

                            <br />
                            <br /> */}

                            <Button disabled={onSubmit}
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                    backgroundColor: "rgb(250, 53, 88)",
                                }}
                                onClick={() => UpdateModule()}
                            >
                                Update Practice Module
                        </Button>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        </AuthLayout>
    );
}
