import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import { BOOTCAMP_API } from "../../backend";
import Switch from "@material-ui/core/Switch";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import { useHistory } from "react-router";
import Pagination from "@material-ui/lab/Pagination";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles({
  creatCategorytHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
  createEventDrawer: {
    width: "40%",
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createCatagoryHld: {
    background: "#f6f6f6",
    borderRadius: "10px",
    padding: "16px",
  },
  categorycardhld: {
    border: "1px solid #dbdbdb",
    borderRadius: "10px",
    padding: "16px",
    "&:hover": {
      "& $iconsHld": {
        display: "block",
      },
    },
  },
  iconsHld: {
    display: "none",
    "&:hover": {
      display: "block",
    },
  },
  questionshld: {
    background: "#ffffff",
    boxShadow: "7px 7px 20px 3px rgb(0 0 0 / 15%)",
    padding: "20px",
  },
  searchicon: {
    border: "1px solid #ced4da",
    borderTopRightRadius: "4px",
    paddingTop: "7px",
    paddingBottom: "7px",
    borderBottomRightRadius: "4px",
  },
});
export default function Practices(props) {
  const [fullscreen, setFullsreen] = useState(false);
  const [categoryDrawer, setCategoryDrawer] = React.useState(false);
  const [categoryStatus, setCategoryStatus] = React.useState(false);
  const [tagStatus, setTagStatus] = React.useState(false);
  const [categoryTitle, setCategoryTitle] = React.useState("");
  const [categoryPriority, setCategoryPriority] = React.useState("");
  const [practiceQuetions, setPracticeQuetions] = React.useState([]);
  const [listOfCategories, setListOfCategories] = React.useState([]);
  const [listOftags, setListOftags] = React.useState([]);
  const [isEditCategory, setIsEditCategory] = React.useState("");
  const [isEditTag, setIsEditTag] = React.useState("");
  const [categoryPublish, setCategoryPublish] = React.useState(false);
  const [tagsDrawer, setTagsDrawer] = React.useState(false);
  const [tagTitle, setTagTitle] = React.useState("");
  const [tagPriority, setTagPriority] = React.useState("");
  const [tagPublish, setTagPublish] = React.useState(false);
  const [categoryImage, setCategoryImage] = React.useState("");
  const [categoryBgColor, setCategoryBgColor] = React.useState("");
  const [isToast, setIsToast] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [check, setCheck] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [selectedQues, setSelectedQues] = React.useState({});
  const [searchText, setSearchText] = React.useState("");
  const [totalQuestions, setTotalQuestions] = React.useState(0);
  const [selectedPage, setSelectedPage] = useState("1");
  const [isLms, setIsLms] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setCategoryDrawer(open);
  };
  const toggleTagsDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setTagsDrawer(open);
  };
  const create_Category = () => {
    setCategoryPriority(listOfCategories?.length + 1);
    setCategoryStatus(true);
  };
  const createCategory = async () => {
    const body = {
      title: categoryTitle,
      priority: categoryPriority,
      radialGradient: categoryBgColor,
      categoryImage: categoryImage,
      lmsContent: isLms
    };
    await axios
      .post(`${BOOTCAMP_API}/practices/createPraciceCategory`, body)
      .then((response) => {
        if (response.status === 201) {
          setCategoryStatus(false);
          setListOfCategories((prev) => [...prev, response.data.result.data]);
          setIsToast({
            show: true,
            message: "successfully created",
            variant: "success",
          });
        } else {
          setIsToast({
            show: true,
            message: response.data.result.message,
            variant: "error",
          });
        }
      })
      .catch((error) => {
        setIsToast({
          show: true,
          message: error.response.data.result.message,
          variant: "error",
        });
        console.log(error);
      });
  };
  const getAllPracticeCategory = async () => {
    await axios
      .get(`${BOOTCAMP_API}/practices/getAllPracticeCategoryCms`)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setListOfCategories(response.data.result.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateCategory = async () => {
    const body = {
      title: categoryTitle,
      priority: categoryPriority,
      publish: categoryPublish,
      radialGradient: categoryBgColor,
      categoryImage: categoryImage,
      lmsContent: isLms
    };
    await axios
      .put(
        `${BOOTCAMP_API}/practices/updatePracticeCategory/${isEditCategory}`,
        body
      )
      .then((response) => {
        setListOfCategories(
          listOfCategories.filter((x) => {
            if (x._id === isEditCategory) {
              x.title = categoryTitle;
              x.priority = categoryPriority;
              x.publish = categoryPublish;
              x.radialGradient = categoryBgColor;
              x.categoryImage = categoryImage;
              x.lmsContent = isLms;
            }
            return listOfCategories;
          })
        );
        setIsEditCategory("");
      })
      .catch((error) => {
        setIsToast({
          show: true,
          message: error.response.data.result.message,
          variant: "error",
        });
        console.log(error);
      });
  };
  const CreateCategoryForm = (props) => {
    return (
      <div className={`mb-2 ${classes.createCatagoryHld}`}>
        <input
          name="title"
          id="title"
          type="text"
          className="form-control mb-2"
          placeholder="Title"
          value={categoryTitle}
          onChange={(e) => setCategoryTitle(e.target.value)}
        />
        <input
          name="priority"
          id="priority"
          type="number"
          className="form-control mb-2"
          placeholder="Priority"
          value={categoryPriority}
          onChange={(e) => setCategoryPriority(e.target.value)}
        />
        {props.status === "update" && (
          <>
            <Switch
              checked={categoryPublish}
              onChange={() => setCategoryPublish(!categoryPublish)}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <br />
          </>
        )}
        {props.status === "create" ? (
          <button className={classes.createBtn} onClick={createCategory}>
            ADD
          </button>
        ) : (
          <button className={classes.createBtn} onClick={updateCategory}>
            SAVE
          </button>
        )}
        <button
          className={`${classes.cancelBtn}`}
          onClick={() => {
            setCategoryStatus(false);
            setIsEditCategory("");
          }}
        >
          CANCEL
        </button>
      </div>
    );
  };
  const createTags = async () => {
    const body = {
      title: tagTitle,
      priority: tagPriority,
    };
    await axios
      .post(`${BOOTCAMP_API}/practices/createPraciceTag`, body)
      .then((response) => {
        console.log(response);
        if (response.status === 201) {
          setTagStatus(false);
          setListOftags((prev) => [...prev, response.data.result.data]);
          setIsToast({
            show: true,
            message: "successfully created",
            variant: "success",
          });
        } else {
          setIsToast({
            show: true,
            message: response.data.result.message,
            variant: "error",
          });
        }
      })
      .catch((error) => {
        setIsToast({
          show: true,
          message: error.response.data.result.message,
          variant: "error",
        });
        console.log(error);
      });
  };
  const updateTags = async () => {
    const body = {
      title: tagTitle,
      priority: tagPriority,
      publish: tagPublish,
    };
    await axios
      .put(`${BOOTCAMP_API}/practices/updatePracticeTag/${isEditTag}`, body)
      .then((response) => {
        setListOftags(
          listOftags.filter((x) => {
            if (x._id === isEditTag) {
              x.title = tagTitle;
              x.priority = tagPriority;
              x.publish = tagPublish;
            }
            return listOftags;
          })
        );
        setIsEditTag("");
      })
      .catch((error) => {
        setIsToast({
          show: true,
          message: error.response.data.result.message,
          variant: "error",
        });
        console.log(error);
      });
  };
  const CreateTagForm = (props) => {
    return (
      <div className={`mb-2 ${classes.createCatagoryHld}`}>
        <input
          name="title"
          id="title"
          type="text"
          className="form-control mb-2"
          placeholder="Title"
          value={tagTitle}
          onChange={(e) => setTagTitle(e.target.value)}
        />
        <input
          name="title"
          id="title"
          type="number"
          className="form-control mb-2"
          placeholder="Priority"
          value={tagPriority}
          onChange={(e) => setTagPriority(e.target.value)}
        />
        {props.status === "update" && (
          <>
            <Switch
              checked={tagPublish}
              onChange={() => setTagPublish(!tagPublish)}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <br />
          </>
        )}
        {props.status === "create" ? (
          <button className={classes.createBtn} onClick={createTags}>
            ADD
          </button>
        ) : (
          <button className={classes.createBtn} onClick={updateTags}>
            SAVE
          </button>
        )}
        <button
          className={`${classes.cancelBtn}`}
          onClick={() => {
            setTagStatus(false);
            setIsEditTag("");
          }}
        >
          CANCEL
        </button>
      </div>
    );
  };
  const openNewTagForm = () => {
    setTagStatus(true);
    setTagPriority(listOftags?.length + 1);
  };
  const getAllPracticetags = async () => {
    await axios
      .get(`${BOOTCAMP_API}/practices/getAllPracticeTagsCms`)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setListOftags(response.data.result.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onQuestionPaginationChange = (e, value) => {
    getPracticeQuestions(value, searchText);
    setSelectedPage(value);
  };
  const handleKeyDownSearch = (event) => {
    if (event.key === "Enter") {
      getPracticeQuestions(selectedPage, searchText);
    }
  };
  const getPracticeQuestions = async (pageNum, searchText) => {
    const paramData = {};
    if (searchText !== "") {
      paramData.searchString = searchText;
    }
    await axios
      .get(
        `${BOOTCAMP_API}/practices/getAllPracticeQuestionsCms?current_page=${pageNum}`,
        {
          params: paramData,
        }
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setPracticeQuetions(response.data.result.data);
          setTotalQuestions(response.data.result.totalCount);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteSolutions = async () => {
    await axios
      .delete(
        `${BOOTCAMP_API}/practices/deleteSpecificQuestionCms/${selectedQues._id}`
      )
      .then((response) => {
        setPracticeQuetions(
          practiceQuetions.filter((x) => {
            if (x._id === selectedQues._id) {
              return x._id !== selectedQues._id;
            }
            return practiceQuetions;
          })
        );
        setOpenDeleteModal(false);
      })
      .catch((err) => console.log(err));
  };
  const getAllpracticeQuestions = practiceQuetions.filter((el) => {
    let text = searchText.toLowerCase();
    if (text === "") {
      return el;
    } else {
      return (
        el.questionTitle.toLowerCase().includes(text) ||
        el.difficulty.toLowerCase().includes(text)
      );
    }
  });
  const onPublishChange = async (data, publishStatus) => {
    const requestData = data;
    requestData.publish = publishStatus;
    requestData.solutionDescription = requestData?.solution?.description;
    requestData.solutionVideoLink = requestData?.solution?.videoLink;
    requestData.solutionCode = requestData?.solution?.code;
    requestData.categoryId = requestData?.categoryId?._id;
    await axios
      .put(
        `${BOOTCAMP_API}/practices/updatePracticeQuestion/${data._id}`,
        requestData
      )
      .then((response) => {
        setPracticeQuetions(
          practiceQuetions.filter((x) => {
            if (x._id === data._id) {
              x.publish = response.data.result.data.publish;
            }
            return practiceQuetions;
          })
        );
      })
      .catch((err) => console.log(err));
  };
  const searchQuestions = (e) => {
    setSearchText(e.target.value);
  };
  const onSearchClick = () => {
    if (searchText !== "") {
      setSearchText("");
      getPracticeQuestions(selectedPage, "");
    } else {
      getPracticeQuestions(selectedPage, searchText);
    }
  };
  useEffect(() => {
    getPracticeQuestions("1", "");
    getAllPracticeCategory();
    getAllPracticetags();
  }, []);
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Practices" />
      <Snackbar
        open={isToast.show}
        onClose={() => setIsToast({ ...isToast, show: false })}
      >
        <>
          <Alert
            onClose={() => setIsToast({ ...isToast, show: false })}
            severity={isToast.variant}
          >
            {isToast.message}
          </Alert>
        </>
      </Snackbar>
      <div className="d-flex justify-content-between mb-2">
        <div className="col-6 pl-0 d-flex align-items-center">
          <input
            type="text"
            placeholder="Search Questions"
            className="form-control"
            value={searchText}
            onChange={searchQuestions}
            onKeyDown={handleKeyDownSearch}
            style={{
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          />
          <span
            className={`${classes.searchicon} px-2`}
            onClick={onSearchClick}
          >
            {searchText !== "" ? (
              <i className="mdi mdi-close"></i>
            ) : (
              <i className="fa fa-search"></i>
            )}
          </span>
        </div>
        <div className="">
          <Button
            variant="outlined"
            color="secondary"
            className="mr-3"
            onClick={() => {
              localStorage.setItem("practicesQuesLength", totalQuestions);
              history.push("/practices/create");
            }}
          >
            Create practices
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={toggleDrawer(true)}
            className="mr-3"
          >
            Manage Category
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={toggleTagsDrawer(true)}
          >
            Manage tags
          </Button>
        </div>
      </div>

      {practiceQuetions.map((question) => (
        <div className={`row my-3 ${classes.questionshld}`} key={question?._id}>
          <div className="col-lg-10">
            <div className="">
              <h4>Question Title: {question?.questionTitle}</h4>
              <h5>
                Description:{" "}
                <div
                  dangerouslySetInnerHTML={{ __html: question?.description }}
                ></div>
              </h5>
              <p>Difficulty Level: {question?.difficulty}</p>
              <p>Priority: {question?.priority}</p>
              <p>Publish: {question?.publish?.toString()}</p>
              <p>LMS: {question?.lmsContent?.toString()}</p>
              <p>Category: {question?.categoryId?.title}</p>
              <p>
                tags:{" "}
                {question.tags.map((tag, index) => (
                  <span key={index}>{tag?.title},</span>
                ))}
              </p>
              <p>AcceptanceRate: {question?.acceptanceRate}</p>
              <p>Likes: {question?.likes}</p>
              <p>DisLikes: {question?.disLikes}</p>
              <p>Solution Code: </p>
              {question?.solution?.code
                .split(/\r\n|\n|\r/gm)
                .map((line, index) => {
                  return (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
          <div className="col-lg-2 text-center">
            <Button
              variant="outlined"
              color="primary"
              className="mr-1 mb-2"
              onClick={() => history.push(`/practices/update/${question._id}`)}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="mr-1 mb-2"
              onClick={() => {
                setOpenDeleteModal(true);
                setSelectedQues(question);
              }}
            >
              Delete
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() =>
                onPublishChange(question, question.publish ? false : true)
              }
            >
              {question.publish ? "Unpublish" : "Publish"}
            </Button>
          </div>
        </div>
      ))}
      <div className="d-flex justify-content-center my-4">
        <Pagination
          count={
            totalQuestions % 20 === 0
              ? parseInt(totalQuestions / 20)
              : parseInt(totalQuestions / 20) + 1
          }
          defaultPage={1}
          page={Number(selectedPage)}
          variant="outlined"
          shape="rounded"
          className={classes.root}
          onChange={onQuestionPaginationChange}
          showFirstButton
          showLastButton
        />
      </div>

      <Drawer
        anchor="right"
        open={categoryDrawer}
        onClose={toggleDrawer(false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatCategorytHeader}>
            <Col lg={9}>
              <h4>Manage Category</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              ></i>
            </Col>
          </div>

          <div className="px-3 py-3">
            {categoryStatus && (
              <div className={`mb-2 ${classes.createCatagoryHld}`}>
                <input
                  name="title"
                  id="title"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Title"
                  value={categoryTitle}
                  onChange={(e) => setCategoryTitle(e.target.value)}
                />
                <input
                  name="priority"
                  id="priority"
                  type="number"
                  className="form-control mb-2"
                  placeholder="Priority"
                  value={categoryPriority}
                  onChange={(e) => setCategoryPriority(e.target.value)}
                />
                <input
                  name="image"
                  id="image"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Image"
                  value={categoryImage}
                  onChange={(e) => setCategoryImage(e.target.value)}
                />
                <input
                  name="BgColor"
                  id="BgColor"
                  type="text"
                  className="form-control mb-2"
                  placeholder="BgColor"
                  value={categoryBgColor}
                  onChange={(e) => setCategoryBgColor(e.target.value)}
                />
                <h5>LMS</h5>
                <div className="">
                <Switch
                  checked={isLms}
                  onChange={(e) => setIsLms(e.target.checked)}
                  name="checkedA"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
                </div>
                <button className={classes.createBtn} onClick={createCategory}>
                  ADD
                </button>
                <button
                  className={`${classes.cancelBtn}`}
                  onClick={() => {
                    setCategoryStatus(false);
                    setIsEditCategory("");
                  }}
                >
                  CANCEL
                </button>
              </div>
            )}
            <button
              className="event-outlined-btn mb-3"
              onClick={create_Category}
            >
              <i className="mdi mdi-plus-circle-outline mr-1"></i> ADD Category
            </button>
            {listOfCategories.map((category) => (
              <div className="" key={category._id}>
                {isEditCategory !== category._id ? (
                  <div
                    className={`mb-2 d-flex align-items-center justify-content-between ${classes.categorycardhld}`}
                    key={category._id}
                  >
                    <div>
                      <h5 className="mb-1 mt-0">Title: {category?.title}</h5>
                      <p className="mb-1">Priority: {category?.priority}</p>
                      <p className="mb-1">
                        Publish: {category?.publish?.toString()}
                      </p>
                      <p className="mb-1">LMS: {category?.lmsContent?.toString()}</p>
                      <p className="mb-1" style={{ wordBreak: "break-all" }}>
                        Image: {category?.categoryImage}
                      </p>
                      <p className="mb-0">
                        Gradient: {category?.radialGradient}
                      </p>
                    </div>
                    <div className={classes.iconsHld}>
                      <span
                        style={{ fontSize: "24px" }}
                        onClick={() => (
                          setIsEditCategory(category?._id),
                          setCategoryTitle(category?.title),
                          setCategoryPriority(category?.priority),
                          setCategoryPublish(category?.publish),
                          setCategoryImage(category?.categoryImage),
                          setCategoryBgColor(category?.radialGradient),
                          setIsLms(category?.lmsContent)
                        )}
                        className="cursor-pointer"
                      >
                        <i className="mdi mdi-pencil-box-outline brand-color"></i>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={`mb-2 ${classes.createCatagoryHld}`}>
                    <input
                      name="title"
                      id="title"
                      type="text"
                      className="form-control mb-2"
                      placeholder="Title"
                      value={categoryTitle}
                      onChange={(e) => setCategoryTitle(e.target.value)}
                    />
                    <input
                      name="priority"
                      id="priority"
                      type="number"
                      className="form-control mb-2"
                      placeholder="Priority"
                      value={categoryPriority}
                      onChange={(e) => setCategoryPriority(e.target.value)}
                    />
                    <input
                      name="image"
                      id="image"
                      type="text"
                      className="form-control mb-2"
                      placeholder="Image"
                      value={categoryImage}
                      onChange={(e) => setCategoryImage(e.target.value)}
                    />
                    <input
                      name="BgColor"
                      id="BgColor"
                      type="text"
                      className="form-control mb-2"
                      placeholder="BgColor"
                      value={categoryBgColor}
                      onChange={(e) => setCategoryBgColor(e.target.value)}
                    />
                    <h5>Publish</h5>
                    <Switch
                      checked={categoryPublish}
                      onChange={() => setCategoryPublish(!categoryPublish)}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <br />
                    <h5>LMS</h5>
                    <Switch
                      checked={isLms}
                      onChange={(e) => setIsLms(e.target.checked)}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <br />
                    <button
                      className={classes.createBtn}
                      onClick={updateCategory}
                    >
                      SAVE
                    </button>
                    <button
                      className={`${classes.cancelBtn}`}
                      onClick={() => {
                        setCategoryStatus(false);
                        setIsEditCategory("");
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Drawer>
      <Drawer
        anchor="right"
        open={tagsDrawer}
        onClose={toggleTagsDrawer(false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatCategorytHeader}>
            <Col lg={9}>
              <h4>Manage Tags</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleTagsDrawer(false)}
                onKeyDown={toggleTagsDrawer(false)}
              ></i>
            </Col>
          </div>

          <div className="px-3 py-3">
            {tagStatus && (
              <div className={`mb-2 ${classes.createCatagoryHld}`}>
                <input
                  name="title"
                  id="title"
                  type="text"
                  className="form-control mb-2"
                  placeholder="Title"
                  value={tagTitle}
                  onChange={(e) => setTagTitle(e.target.value)}
                />
                <input
                  name="title"
                  id="title"
                  type="number"
                  className="form-control mb-2"
                  placeholder="Priority"
                  value={tagPriority}
                  onChange={(e) => setTagPriority(e.target.value)}
                />
                {props.status === "update" && (
                  <>
                    <Switch
                      checked={tagPublish}
                      onChange={() => setTagPublish(!tagPublish)}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <br />
                  </>
                )}
                <button className={classes.createBtn} onClick={createTags}>
                  ADD
                </button>
                <button
                  className={`${classes.cancelBtn}`}
                  onClick={() => {
                    setTagStatus(false);
                    setIsEditTag("");
                  }}
                >
                  CANCEL
                </button>
              </div>
            )}
            <button
              className="event-outlined-btn mb-3"
              onClick={openNewTagForm}
            >
              <i className="mdi mdi-plus-circle-outline mr-1"></i> ADD Tag
            </button>
            {listOftags?.map((tags) => (
              <div className="" key={tags._id}>
                {isEditTag !== tags._id ? (
                  <div
                    className={`mb-2 d-flex align-items-center justify-content-between ${classes.categorycardhld}`}
                    key={tags._id}
                  >
                    <div>
                      <h5 className="mb-1 mt-0">Title: {tags?.title}</h5>
                      <p className="mb-1">Priority: {tags?.priority}</p>
                      <p className="mb-0">
                        Publish: {tags?.publish?.toString()}
                      </p>
                    </div>
                    <div className={classes.iconsHld}>
                      <span
                        style={{ fontSize: "24px" }}
                        onClick={() => (
                          setIsEditTag(tags?._id),
                          setTagTitle(tags?.title),
                          setTagPriority(tags?.priority),
                          setTagPublish(tags?.publish)
                        )}
                        className="cursor-pointer"
                      >
                        <i className="mdi mdi-pencil-box-outline brand-color"></i>
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={`mb-2 ${classes.createCatagoryHld}`}>
                    <input
                      name="title"
                      id="title"
                      type="text"
                      className="form-control mb-2"
                      placeholder="Title"
                      value={tagTitle}
                      onChange={(e) => setTagTitle(e.target.value)}
                    />
                    <input
                      name="title"
                      id="title"
                      type="number"
                      className="form-control mb-2"
                      placeholder="Priority"
                      value={tagPriority}
                      onChange={(e) => setTagPriority(e.target.value)}
                    />
                    <Switch
                      checked={tagPublish}
                      onChange={() => setTagPublish(!tagPublish)}
                      name="checkedA"
                      inputProps={{ "aria-label": "secondary checkbox" }}
                    />
                    <br />
                    <button className={classes.createBtn} onClick={updateTags}>
                      SAVE
                    </button>
                    <button
                      className={`${classes.cancelBtn}`}
                      onClick={() => {
                        setTagStatus(false);
                        setIsEditTag("");
                      }}
                    >
                      CANCEL
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Drawer>
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <p>
              <strong>Account deleted once can't be retrieved.</strong>
            </p>

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete this Solutions"
            />
            {/* <p>
							I'd like Analytics India to provide my contact
							information to &nbsp;
							<a href="https://aws.amazon.com/legal/marketingentities/">
								Amazon Web Services
							</a>{" "}
							&nbsp; so AWS can share the latest AWS news and
							offers with me by email, post or telephone.
						</p> */}
            {/* <p>By clicking "I Agree"</p> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteModal(false)}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deleteSolutions}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </AuthLayout>
  );
}
