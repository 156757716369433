let initialState = {
	events: [],
    event: {}
};

function eventsReducer(state = initialState, action) {
	if (action.type === "GET_ALL_EVENTS") {
		return {
			...state,
			events: action.payload,
		};
	}
    if (action.type === "EVENT_DATA") {
        return {
          ...state,
          event: action.payload
        };
      }
	return state;
}

export default eventsReducer;
