import React, { Component, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
	Dropdown,
	DropdownMenu,
	DropdownToggle,
	DropdownItem,
} from "reactstrap";

import { signout } from "../auth/helper";
import { useDispatch } from "react-redux";
// import { Link, useHistory, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { isAuthenticated } from "../auth/helper";
import setAuthToken from "../auth/helper/setToken";
import { getUserInfo } from "../user/helper/userapicall";
import { store } from "../store/store";

// if (localStorage.getItem("jwt")) {
// 	let jwt = JSON.parse(localStorage.getItem("jwt"));
// 	// console.log("jwt", jwt);
// 	setAuthToken(jwt.token);
// 	let userId = isAuthenticated().user._id;
// 	getUserInfo(userId)
// 		.then((response) => {
// 			console.log("user", response.data.user);
// 			store.dispatch({
// 				type: "GET_USER_INFO",
// 				payload: response.data.user,
// 			});
// 		})
// 		.catch((err) => console.log(err));
// }

function ProfileDropdown(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const user = useSelector((store) => store.user.user);

	const [dropdownOpen, setDropdownOpen] = useState(false);
	/*:: toggleDropdown: () => void */
	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const logout = () => {
		signout().then(() => {
			history.push("/login");
			dispatch({
				type: "LOGOUT_CURRENT_USER",
			});
		});
	};

	// render() {
	const profilePic = props.profilePic || null;

	return (
		<Dropdown
			isOpen={dropdownOpen}
			toggle={toggleDropdown}
			className="notification-list"
		>
			<DropdownToggle
				data-toggle="dropdown"
				tag="button"
				className="btn btn-link nav-link dropdown-toggle nav-user mr-0 waves-effect waves-light"
				onClick={toggleDropdown}
				aria-expanded={dropdownOpen}
			>
				<img src={user.Picture} className="rounded-circle" alt="user" />
				<span className="pro-user-name ml-1">
					{user.FName}&nbsp;{user.LName}{" "}
					<i className="mdi mdi-chevron-down"></i>{" "}
				</span>
			</DropdownToggle>
			<DropdownMenu right className="profile-dropdown">
				<div onClick={toggleDropdown}>
					<div className="dropdown-header noti-title">
						<h6 className="text-overflow m-0">Welcome !</h6>
					</div>
					{props.menuItems.map((item, i) => {
						return (
							<React.Fragment key={i + "-profile-menu"}>
								{item.hasDivider ? (
									<DropdownItem divider />
								) : null}
								<Link
									to={item.redirectTo}
									className="dropdown-item notify-item"
								>
									<i className={`${item.icon} mr-1`}></i>
									<span>{item.label}</span>
								</Link>
							</React.Fragment>
						);
					})}
					<React.Fragment key={"-profile-menu"}>
						{/* {item.hasDivider ? ( */}
						<DropdownItem divider />
						{/* ) : null} */}
						<Link
							to={"#"}
							className="dropdown-item notify-item"
							onClick={logout}
						>
							<i className="fe-log-out mr-1"></i>
							<span>Logout</span>
						</Link>
					</React.Fragment>
				</div>
			</DropdownMenu>
		</Dropdown>
	);
	// }
}

export default ProfileDropdown;
