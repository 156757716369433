import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { BOOTCAMP_API, EVENT_URL } from "../../../backend";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import DownloadLoader from "../../DownloadUserData/Loader";
import EventLayout from "../../../components/EventLayout";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { FormControl } from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import logoSm from "../../../assets/images/logo-sm.png";
import Switch from "@material-ui/core/Switch";

const moment = require("moment");
const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Heading,
    Indent,
    Table,
    TableToolbar,
    Link,
    List,
    MediaEmbed,
    CodeBlock,
    ImageResize,
    ImageToolbar,
    ImageStyle,
  ],
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "mediaEmbed",
    "insertTable",
    "codeBlock",
  ],
  mediaEmbed: {
    previewsInData: true
  },
  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },

  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  codeBlock: {
    languages: [
      // Do not render the CSS class for the plain text code blocks.
      { language: "plaintext", label: "Plain text", class: "" },

      // Use the "php-code" class for PHP code blocks.
      { language: "php", label: "PHP", class: "php-code" },

      // Use the "js" class for JavaScript code blocks.
      // Note that only the first ("js") class will determine the language of the block when loading data.
      {
        language: "javascript",
        label: "JavaScript",
        class: "js javascript js-code",
      },

      // Python code blocks will have the default "language-python" CSS class.
      { language: "python", label: "Python" },
    ],
  },
};
const useStyles = makeStyles({
  root: {
    minHeight: 206,
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardbody: {
    padding: "0 10px 10px 10px",
    borderRadius: "8px",
  },
  eventbanner: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "100px",
    objectFit: "fill",
    maxWidth: "100%",
  },
  eventName: {
    fontWeight: 500,
    display: "-webkit-box",
    overflow: "hidden",
    "-mozBoxOrient": "vertical",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": 2,
  },
  moreOptions: {
    background: "#ffffff",
    position: "absolute",
    right: "10px",
    top: "6px",
    borderRadius: "50px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f44d5e",
    fontSize: "20px",
  },
  optionMenu: {
    boxShadow: "none",
    border: "1px solid #dbdbdb",
    "& div": {
      boxShadow: "none",
      border: "1px solid #dbdbdb",
    },
  },
  optionMenuItem: {
    boxShadow: "none",
  },
  createEventDrawer: {
    width: "40%",
  },
  creatEventHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    overflowX: "hidden",
  },
  spacingCreateEvent: {
    padding: "10px 30px 10px 30px",
    height: "calc(100vh - 142px)",
    overflow: "auto",
  },
  eventType: {
    width: "50%",
    textAlign: "center",
    color: "gray",
    marginBottom: 0,
    padding: "8px",
  },
  border: {
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
  },
  selectedEventType: {
    background: "#f44d5e",
    color: "#ffffff",
    width: "50%",
    textAlign: "center",
    marginBottom: 0,
    padding: "8px",
  },
  textField: {
    marginRight: 10,
    border: "1px solid rgba(53, 53, 53, 0.17)",
    borderRadius: "4px",
    color: "#353535",
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Overview(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [eventType, setEventType] = React.useState("");
  const [eventTypeValue, setEventTypeValue] = React.useState("");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [eventTiltle, setEventTitle] = React.useState("");
  const [eventDescription, setEventDescription] = React.useState("");
  const [eventStartDate, setEventStartDate] = React.useState("");
  const [eventEndDate, setEventEndDate] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [linkedIn, setLinkedIn] = React.useState("");
  const [twitter, setTwitter] = React.useState("");
  const [fb, setfb] = React.useState("");
  const [shortDescription, setEventShortDescription] = React.useState("");
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [publishStatus, setPublishStatus] = React.useState();
  const [insta, setInsta] = React.useState("");
  const [IsCopy, setIsCopy] = React.useState(false);
  const [chatStatus, setChatStatus] = React.useState(true);
  const [meetStatus, setMeetStatus] = React.useState(true);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleChange = (event) => {
    setEventTypeValue(event.target.value);
    event.target.value !== "Other"
      ? setEventShortDescription(event.target.value)
      : setEventShortDescription("");
  };

  const saveEvent = (publishStatus) => {
    const createEventBody = {
      title: eventTiltle,
      description: eventDescription,
      featured: true,
      publish: publishStatus,
      eventStartDate: eventStartDate,
      eventEndDate: eventEndDate,
      shortDescription: shortDescription,
      typeOfEvent: eventType,
      enablechat: chatStatus,
      enableMeet: meetStatus,
    };
    if (website) {
      createEventBody.website = website;
    }
    if (fb) {
      createEventBody.fb = fb;
    }
    if (website) {
      createEventBody.twitter = twitter;
    }
    if (website) {
      createEventBody.Instagram = insta;
    }
    if (website) {
      createEventBody.linkedIn = linkedIn;
    }
    axios
      .put(
        `${BOOTCAMP_API}/events/updateEvents/${eventData._id}`,
        createEventBody
      )
      .then((response) => {
        console.log(response);
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setState({ ...state, ["right"]: false });
          setMessageData(response.data.result.message);
          updateState(response.data.result.data);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  const list = (anchor) => (
    <div role="presentation">
      <div className={classes.creatEventHeader}>
        <Col lg={9}>
          <h4>Edit the Event</h4>
        </Col>
        <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
          <i
            className="mdi mdi-close"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          ></i>
        </Col>
      </div>
      <Col lg={12} className={classes.spacingCreateEvent}>
        <FormControl className={classes.formControl}>
          <label htmlFor="title">
            Event Title
            <span style={{ color: "red", verticalAlign: "" }}>*</span>
          </label>
          <input
            id="title"
            type="text"
            className="form-control"
            placeholder="Example: Fireside chat with Panellist Name on AI"
            value={eventTiltle}
            onChange={(e) => setEventTitle(e.target.value)}
          />
        </FormControl>
        <br />
        <br />
        <label htmlFor="title">Event Description</label>
        <div className={classes.editor}>
          <CKEditor
            editor={ClassicEditor}
            config={editorConfiguration}
            data={eventDescription}
            onInit={(editor) => {
              // You can store the "editor" and use when it is needed.
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              setEventDescription(data);
            }}
            onBlur={(event, editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              console.log("Focus.", editor);
            }}
          />
        </div>
        <br />
        <Divider />
        <br />
        <h4>
          Event Type <span style={{ color: "red", verticalAlign: "" }}>*</span>
        </h4>
        <p>Choose the event type that you are interested in hosting.</p>
        <div
          className="d-flex align-items-center"
          style={{
            background: "#f6f6f6",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          <p
            className={`${
              eventType === "internal"
                ? classes.selectedEventType
                : classes.eventType
            }`}
            onClick={() => setEventType("internal")}
          >
            Internal
          </p>
          <p
            className={`${
              eventType === "external"
                ? classes.selectedEventType
                : classes.eventType
            }`}
            onClick={() => setEventType("external")}
          >
            External
          </p>
        </div>
        {eventType && (
          <>
            <h4>Select the Event</h4>
            <div className={classes.border}>
              {eventType === "internal" ? (
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    className="p-2"
                    value={eventTypeValue}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Corporate All-Hands"
                      control={<Radio />}
                      label="Corporate All-Hands"
                    />
                    <FormControlLabel
                      value="Employee Onboarding"
                      control={<Radio />}
                      label="Employee Onboarding"
                    />
                    <FormControlLabel
                      value="Employee Training"
                      control={<Radio />}
                      label="Employee Training"
                    />
                    <FormControlLabel
                      value="Hackathon"
                      control={<Radio />}
                      label="Hackathon"
                    />
                    <FormControlLabel
                      value="Leadership Meeting"
                      control={<Radio />}
                      label="Leadership Meeting"
                    />
                    <FormControlLabel
                      value="Office Party"
                      control={<Radio />}
                      label="Office Party"
                    />
                    <FormControlLabel
                      value="Sales Kickoff"
                      control={<Radio />}
                      label="Sales Kickoff"
                    />
                    <FormControlLabel
                      value="Team Building"
                      control={<Radio />}
                      label="Team Building"
                    />
                    <FormControlLabel
                      value="Virtual Town Hall"
                      control={<Radio />}
                      label="Virtual Town Hall"
                    />
                    <FormControlLabel
                      value="Workshop"
                      control={<Radio />}
                      label="Workshop"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              ) : (
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    className="p-2"
                    value={eventTypeValue}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Conferences"
                      control={<Radio />}
                      label="Conferences"
                    />
                    <FormControlLabel
                      value="Customer Advisory Boardg"
                      control={<Radio />}
                      label="Customer Advisory Board"
                    />
                    <FormControlLabel
                      value="Exhibitions"
                      control={<Radio />}
                      label="Exhibitions"
                    />
                    <FormControlLabel
                      value="External Job Fair "
                      control={<Radio />}
                      label="External Job Fair "
                    />
                    <FormControlLabel
                      value="Lead Generation Activities"
                      control={<Radio />}
                      label="Lead Generation Activities"
                    />
                    <FormControlLabel
                      value="Marketing Event"
                      control={<Radio />}
                      label="Marketing Event"
                    />
                    <FormControlLabel
                      value="Product Launch"
                      control={<Radio />}
                      label="Product Launch"
                    />
                    <FormControlLabel
                      value="Roadshow"
                      control={<Radio />}
                      label="Team Building"
                    />
                    <FormControlLabel
                      value="User Group Meetings"
                      control={<Radio />}
                      label="User Group Meetings"
                    />
                    <FormControlLabel
                      value="Other"
                      control={<Radio />}
                      label="Other"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </div>
          </>
        )}
        <br />
        {eventTypeValue === "Other" && (
          <>
            <FormControl className={classes.formControl}>
              <label htmlFor="title">
                What You Would Like To Accomplish Through This Event?
                <span style={{ color: "red", verticalAlign: "" }}>*</span>
              </label>
              <input
                id="title"
                type="text"
                className="form-control"
                placeholder="Example: Annual Appreciation"
                value={shortDescription}
                onChange={(e) => setEventShortDescription(e.target.value)}
              />
            </FormControl>
            <br />
          </>
        )}
        <br />
        <Divider />
        <br />
        <div className="d-flex">
          <div className="col-6 pl-0">
            <label htmlFor="title">
              Event Start Date
              <span style={{ color: "red", verticalAlign: "" }}>*</span>
            </label>
            <TextField
              id="datetime-local"
              type="datetime-local"
              className={classes.textField}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: "#353535",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                input: "#353535",
                label: "#353535",
              }}
              value={eventEndDate}
              onChange={(e) => setEventStartDate(e.target.value)}
              disabled
            />
          </div>
          <div className="col-6">
            <label htmlFor="title">
              Event End Date
              <span style={{ color: "red", verticalAlign: "" }}>*</span>
            </label>
            <TextField
              id="datetime-local"
              type="datetime-local"
              className={classes.textField}
              InputProps={{
                disableUnderline: true,
                style: {
                  color: "#353535",
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                input: "#353535",
                label: "#353535",
              }}
              value={eventEndDate}
              onChange={(e) => setEventEndDate(e.target.value)}
              disabled
            />
          </div>
        </div>

        <br />
        <br />
        <FormControl className={classes.formControl}>
          <label htmlFor="title">Social Engagement Links</label>
          <input
            id="title"
            type="text"
            className="form-control mb-2"
            placeholder="https://website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <input
            id="title"
            type="text"
            className="form-control mb-2"
            placeholder="https://linkedIn"
            value={linkedIn}
            onChange={(e) => setLinkedIn(e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <input
            id="title"
            type="text"
            className="form-control mb-2"
            placeholder="https://twitter"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <input
            id="title"
            type="text"
            className="form-control mb-2"
            placeholder="https://fb"
            value={fb}
            onChange={(e) => setfb(e.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <input
            id="title"
            type="text"
            className="form-control mb-2"
            placeholder="https://insta"
            value={insta}
            onChange={(e) => setInsta(e.target.value)}
          />
        </FormControl>
        <div className="my-2">
          <label htmlFor="title">Chat</label>
          <Switch
            checked={chatStatus}
            onChange={() => setChatStatus(!chatStatus)}
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
            className="float-right"
          />
        </div>
        <div className="my-2">
          <label htmlFor="title">Meet</label>
          <Switch
            checked={meetStatus}
            onChange={() => setMeetStatus(!meetStatus)}
            name="checkedA"
            inputProps={{ "aria-label": "secondary checkbox" }}
            className="float-right"
          />
        </div>
      </Col>
      <div className="pt-3 px-3">
        {eventTiltle &&
        eventDescription &&
        eventStartDate &&
        eventEndDate &&
        eventType &&
        shortDescription ? (
          <button
            className={classes.createBtn}
            onClick={() => saveEvent(eventData.publish)}
          >
            SAVE
          </button>
        ) : (
          <button
            className={classes.createBtn}
            disabled
            style={{
              background: "rgba(0, 0, 0, 0.12)",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              color: "rgba(0, 0, 0, 0.26)",
            }}
          >
            SAVE
          </button>
        )}
        <button
          className={classes.cancelBtn}
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          CANCEL
        </button>
      </div>
    </div>
  );

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setEnd(!end);
  };

  const copyURL = () => {
    navigator.clipboard.writeText(`${EVENT_URL}/events/${eventData.slug}/home`);
    setIsCopy(true);
  };

  const getEventData = () => {
    axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        console.log(response);
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
        const eventResponseData = response.data.result.data;
        updateState(eventResponseData);
      })
      .catch((err) => console.log(err));
  };
  const updateState = (eventResponseData) => {
    setEventTitle(eventResponseData.title);
    setEventType(eventResponseData.typeOfEvent);
    setEventTypeValue(eventResponseData.shortDescription);
    setEventShortDescription(eventResponseData.shortDescription);
    setEventStartDate(eventResponseData.eventStartDate);
    setEventEndDate(eventResponseData.eventEndDate);
    setWebsite(eventResponseData.website);
    setfb(eventResponseData.fb);
    setInsta(eventResponseData.instagram);
    setLinkedIn(eventResponseData.linkedIn);
    setTwitter(eventResponseData.twitter);
    setEventDescription(eventResponseData.description);
    setPublishStatus(eventResponseData.publish);
    setChatStatus(eventResponseData.enablechat);
    setMeetStatus(eventResponseData.enableMeet);
  };
  useEffect(() => {
    getEventData();
  }, []);

  return (
    <EventLayout>
      {start && (
        <Snackbar open={start} onClose={handleClose}>
          <>
            <Alert onClose={handleClose} severity="success">
              <DownloadLoader pexal="55px" />
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {end && (
        <Snackbar open={end} onClose={handleCloseEnd}>
          <>
            <Alert onClose={handleCloseEnd} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        <div className="w-100 event-overview">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <br />
              <h4 className="mb-0">Overview</h4>
              <p>
                This is a quick summary of your event. You can also access key
                sections here
              </p>
            </div>
            <button
              className="event-secondary-btn"
              onClick={() =>
                saveEvent(eventData.publish === true ? false : true)
              }
            >
              {eventData.publish ? "UNPUBLISH" : "PUBLISH"}
            </button>
          </div>
          <div className="event-cardHld d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span className="mr-4 paragraph">
                Your event is{" "}
                {moment().isAfter(eventData?.eventStartDate?.split(".")[0]) ? (
                  <b>LIVE</b>
                ) : (
                  <b>
                    live on{" "}
                    {moment(eventData.eventStartDate?.split(".")[0]).format(
                      "Do MMM YYYY, h:mm a"
                    )}
                  </b>
                )}
                <i
                  className="mdi mdi-checkbox-marked-circle-outline"
                  style={{ color: "green" }}
                ></i>
              </span>
              <p className="event-link-generated mb-0">
                {`${EVENT_URL}/events/${eventData.slug}/home`}
              </p>{" "}
              <span
                style={{ color: "#f44d5e", cursor: "pointer" }}
                className="ml-2"
                onClick={copyURL}
              >
                <i className="mdi mdi-content-copy"></i>
                {IsCopy ? "copied" : "copy"}
              </span>
            </div>
            <button
              className="float-right event-secondary-btn"
              onClick={() =>
                window.open(
                  `${EVENT_URL}/events/${eventData.slug}/home`,
                  "_blank"
                )
              }
            >
              Go to Event
            </button>
          </div>
          <br />
          <div className="event-cardHld">
            <span
              className="float-right"
              style={{
                fontSize: "24px",
                color: "#f44d5e",
                cursor: "pointer",
              }}
              onClick={toggleDrawer("right", true)}
            >
              <i className="mdi mdi-pencil-box-outline"></i>
            </span>
            <h4 className="mb-3">{eventData.title}</h4>
            <span className="mr-2">
              <i className="mdi mdi-calendar-blank mr-1"></i>
              {moment(eventData.eventStartDate?.split(".")[0]).format(
                "Do MMM YYYY, h:mm a"
              )}{" "}
              -{" "}
              {moment(eventData.eventEndDate?.split(".")[0]).format(
                "D MMM YYYY, h:mm a"
              )}
            </span>
            <span className="mr-2">
              <i className="mdi mdi-clock mr-1"></i>(UTC +05:30) Asia/Kolkata
              (IST)
            </span>
            <div className="my-2">
              {eventData.website && (
                <span
                  className="socialmediabtn mr-2"
                  onClick={() => window.open(eventData.website, "_blank")}
                >
                  <i className="fa fa-globe" style={{ color: "#f44d5e" }}></i>
                </span>
              )}
              {eventData.fb && (
                <span
                  className="socialmediabtn mr-2"
                  onClick={() => window.open(eventData.fb, "_blank")}
                >
                  <i
                    className="mdi mdi-facebook"
                    style={{ color: "#355087" }}
                  ></i>
                </span>
              )}
              {eventData.linkedIn && (
                <span
                  className="socialmediabtn mr-2"
                  onClick={() => window.open(eventData.linkedIn, "_blank")}
                >
                  <i
                    className="mdi mdi-linkedin"
                    style={{ color: "#0e76a8" }}
                  ></i>
                </span>
              )}
              {eventData.twitter && (
                <span
                  className="socialmediabtn mr-2"
                  onClick={() => window.open(eventData.twitter, "_blank")}
                >
                  <i
                    className="mdi mdi-twitter"
                    style={{ color: "#00acee" }}
                  ></i>
                </span>
              )}
              {eventData.instagram && (
                <span
                  className="socialmediabtn mr-2"
                  onClick={() => window.open(eventData.instagram, "_blank")}
                >
                  <i
                    className="mdi mdi-instagram"
                    style={{ color: "#cf005d" }}
                  ></i>
                </span>
              )}
            </div>
            <div
              className="paragraph"
              dangerouslySetInnerHTML={{ __html: eventData.description }}
            ></div>
          </div>
          <br />
          <div className="event-cardHld">
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <div className="">
                  <p>ACTIVE USERS TODAY</p>
                  <h3 className="brand-color">2</h3>
                  <h6 className="brand-color">
                    <i className="mdi mdi-arrow-down"></i>-50% SINCE YESTERDAY
                  </h6>
                </div>
              </Grid>
              <Grid item xs={3}>
                <p>FEED POSTS TODAY</p>
                <h3 className="brand-color">-</h3>
              </Grid>
              <Grid item xs={3}>
                <p>ONE-ON-ONE MEETINGS DONE</p>
                <h3 className="brand-color">-</h3>
              </Grid>
              <Grid item xs={3}>
                <p>LOUNGE MEETINGS</p>
                <h3 className="brand-color">-</h3>
              </Grid>
            </Grid>
          </div>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <div className="event-cardHld">
                <p>
                  PEOPLE <i className="mdi mdi-arrow-right"></i>
                </p>
                <h3 className="brand-color">883</h3>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="event-cardHld">
                <p>
                  SESSIONS <i className="mdi mdi-arrow-right"></i>
                </p>
                <h3 className="brand-color">12</h3>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="event-cardHld">
                <p>
                  BOOTHS <i className="mdi mdi-arrow-right"></i>
                </p>
                <h3 className="brand-color">14</h3>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="event-cardHld">
                <p>
                  ONE-TO-ONE MEETINGS <i className="mdi mdi-arrow-right"></i>
                </p>
                <h3 className="brand-color">11</h3>
              </div>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <div className="event-cardHld">
                <p>RECENT LOGINS</p>
                <div className="d-flex align-items-center recent-logins">
                  <img src={logoSm} alt="picture" className="img-fluid" />
                  <div className="col-5 pr-0">
                    <p className="mb-0">Aishwarya Verma</p>
                    <span className="mb-0 designation">
                      Associate Data Scientist
                    </span>
                  </div>
                  <p className="mb-0">22 Mar 2022,03:27 PM</p>
                </div>
                <hr className="mb-0" style={{ borderColor: "#f2f2f2" }} />
              </div>
            </Grid>
            <br />
          </Grid>
        </div>
      </Row>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        {list("right")}
      </Drawer>
    </EventLayout>
  );
}
