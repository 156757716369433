let initialState = {
	practices: [],
};

function practicesReducer(state = initialState, action) {
	if (action.type === "GET_ALL_PRACTICES") {
		return {
			...state,
			practices: action.payload,
		};
	}
	return state;
}

export default practicesReducer;
