import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { BOOTCAMP_API } from "../../backend";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useHistory, useLocation } from "react-router";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateCoin(props) {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [coin, setCoin] = useState(0);
  const [messageData, setMessageData] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [href, setHref] = useState("");
  const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  const [sponsershipType, setSponsershipType] = useState({});
  const [createCategory, setCreateCategory] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [sponsershipCategoryList, setsponsershipCategoryList] = useState([]);
  const [isEditCategory, setIsEditCategory] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [priority, setPriority] = React.useState(0);
  const [coinPriority, setCoinPriority] = React.useState(0);
  const [publish, setPublish] = React.useState(false);
  const [description, setDescription] = React.useState("");
  const history = useHistory();
  const location = useLocation();
  const wrapperRef = useRef(null);
  const url = location.pathname.split("/");
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowCategoryOptions(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const handleClose = () => {
    setStatus(!status);
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };
  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };
  const CreateCoins = () => {
    if (
      title === "" ||
      href === "" ||
      coin === "" ||
      Object.keys(sponsershipType).length === 0 ||
      priority === ""
    ) {
      setErrorStatus(true);
      setErrorMessage("Field Missing! All Feild Required !!");
    } else {
      const requestData = {
        title: title,
        description: description,
        redirectionLink: href,
        earnMhCoinCategoryId: sponsershipType._id,
        priority: coinPriority,
        coins: coin,
      };
      setStatusLoader(true);
      axios
        .post(`${BOOTCAMP_API}/mhCoins/createMhCoinChallenge`, requestData)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setStatus(true);
            setMessageData(response.data.result.message);
            setStatusLoader(false);
            history.push("/coins");
          } else {
            setErrorStatus(true);
            setErrorMessage(response.data.result.message);
            setStatusLoader(false);
          }
        })
        .catch((error) => {
          setErrorStatus(true);
          setErrorMessage(error);
          setStatusLoader(false);
          console.log(error);
        });
    }
  };

  const createNewCategory = () => {
    if (isEditCategory === "") {
      const newCategory = {
        title: categoryTitle,
        priority: 1,
      };
      axios
        .post(`${BOOTCAMP_API}/mhCoins/createMhCoinCategory`, newCategory)
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrorMessage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setCreateCategory(false);
            let categoryResponse = response.data.result.data;
            categoryResponse.label = categoryResponse.title;
            categoryResponse.value = categoryResponse._id;
            setsponsershipCategoryList((prev) => [...prev, categoryResponse]);
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    } else {
      const newCat = {
        title: categoryTitle,
        publish: publish,
        priority: priority,
      };
      axios
        .put(
          `${BOOTCAMP_API}/mhCoins/updateMhCoinCategory/${isEditCategory}`,
          newCat
        )
        .then((response) => {
          if (response.data.error) {
            setErrorStatus(true);
            setErrorMessage(response.data.result.message);
          } else {
            setSuccessMessage(true);
            setMessageData(response.data.result.message);
            setCreateCategory(false);
            setsponsershipCategoryList((prev) =>
              prev.filter((x) => {
                if (x._id === isEditCategory) {
                  x.title = response.data.result.data.title;
                  x.label = response.data.result.data.title;
                  x.value = response.data.result.data._id;
                  x.publish = response.data.result.data.publish;
                  setIsEditCategory("");
                  return x;
                }
                return prev;
              })
            );
          }
        })
        .catch((error) => {
          setSuccessMessage(false);
          setErrorStatus(false);
          console.log(error);
        });
    }
  };

  const deleteCategory = (category) => {
    axios
      .delete(
        `${BOOTCAMP_API}/mhCoins/deleteSpecificEarnMhCategoryCms/${category._id}`
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrorMessage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setsponsershipCategoryList((prev) =>
            prev.filter((x) => {
              return x._id !== category._id;
            })
          );
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const getCategoryList = () => {
    axios
      .get(`${BOOTCAMP_API}/mhCoins/getAllEarnMhCategoryDataCms`)
      .then((response) => {
        let categoryList = response.data.result.data;
        categoryList.filter((x) => {
          x.label = x.title;
          x.value = x._id;
        });
        setsponsershipCategoryList(categoryList);
      })
      .catch((err) => console.log(err));
  };

  const getCoinData = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/mhCoins/getSpecificMhCoinChallengeCms/${props.match.params.coinId}`
      )
      .then((response) => {
        setTitle(response.data.result.data.title);
        setCoin(response.data.result.data.coins);
        setCoinPriority(response.data.result.data.priority);
        setPublish(response.data.result.data.publish);
        setHref(response.data.result.data.redirectionLink);
        setSponsershipType(response.data.result.data.earnMhCoinCategoryId);
      })
      .catch((err) => console.log(err));
  };

  const UpdateCoins = () => {
    if (
      title === "" ||
      href === "" ||
      coin === "" ||
      Object.keys(sponsershipType).length === 0 ||
      priority === ""
    ) {
      setErrorStatus(true);
      setErrorMessage("Field Missing! All Feild Required !!");
    } else {
      const requestData = {
        title: title,
        description: description,
        redirectionLink: href,
        earnMhCoinCategoryId: sponsershipType._id,
        priority: coinPriority,
        coins: coin,
        publish: publish
      };
      setStatusLoader(true);
      axios
        .put(`${BOOTCAMP_API}/mhCoins/updateMhCoinChallenge/${props.match.params.coinId}`, requestData)
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setStatus(true);
            setMessageData(response.data.result.message);
            setStatusLoader(false);
            history.push("/coins");
          } else {
            setErrorStatus(true);
            setErrorMessage(response.data.result.message);
            setStatusLoader(false);
          }
        })
        .catch((error) => {
          setErrorStatus(true);
          setErrorMessage(error);
          setStatusLoader(false);
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getCategoryList();
    url.includes("update") && getCoinData();
  }, []);

  return (
    <AuthLayout>
      <Header name={url.includes("update") ? "Update Coins" : "Create Coins"} />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {status && (
              <Snackbar
                open={status}
                autoHideDuration={15000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </Snackbar>
            )}
            {errorStatus && (
              <Snackbar
                open={errorStatus}
                autoHideDuration={15000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            )}
            {statusLoader && (
              <Snackbar open={statusLoader} onClose={handleLoader}>
                <Alert onClose={handleLoader} severity="info">
                  <Loader />
                </Alert>
              </Snackbar>
            )}

            <Paper className={classes.paper}>
              <div className="mt-3">
                <h2>Category</h2>
                <div className="position-relative">
                  <div
                    className="customSelectControls"
                    onClick={() => setShowCategoryOptions(!showCategoryOptions)}
                  >
                    <span>
                      {Object.keys(sponsershipType).length > 0
                        ? sponsershipType.title || sponsershipType.label
                        : "Select Category"}
                    </span>
                  </div>
                  {showCategoryOptions && (
                    <div className="customDropDown" ref={wrapperRef}>
                      {!createCategory ? (
                        <button
                          className="event-outlined-btn mb-2 ml-2"
                          onClick={() => setCreateCategory(true)}
                        >
                          ADD
                          <i className="mdi mdi-plus-circle-outline ml-1"></i>
                        </button>
                      ) : (
                        <div className="d-flex px-2 createCategoryHld align-items-center">
                          <input
                            type="text"
                            placeholder="Enter category"
                            className="form-control col-4 mr-3"
                            value={categoryTitle}
                            onChange={(e) => setCategoryTitle(e.target.value)}
                          />
                          <input
                            type="number"
                            placeholder="Priority"
                            className="form-control col-4 mr-3"
                            value={priority}
                            onChange={(e) => setPriority(e.target.value)}
                          />
                          {isEditCategory !== "" && (
                            <>
                              <span>Publish</span>
                              <Switch
                                checked={publish}
                                onChange={() => setPublish(!publish)}
                                name="publish"
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                              />
                            </>
                          )}
                          <div className="createcategoryinputOptions">
                            {categoryTitle && priority ? (
                              <span
                                onClick={createNewCategory}
                                className="cursor-pointer"
                              >
                                <i
                                  className="mdi mdi-checkbox-marked brand-color"
                                  style={{ fontSize: "24px" }}
                                ></i>
                              </span>
                            ) : (
                              <span className="cursor-pointer">
                                <i
                                  className="mdi mdi-checkbox-marked"
                                  style={{
                                    fontSize: "24px",
                                    color: "rgba(0, 0, 0, 0.26)",
                                  }}
                                ></i>
                              </span>
                            )}
                            <span
                              className="cursor-pointer"
                              onClick={() => setCreateCategory(false)}
                            >
                              <i
                                className="mdi mdi-close-box-outline brand-color"
                                style={{ fontSize: "24px" }}
                              ></i>
                            </span>
                          </div>
                        </div>
                      )}

                      <hr />
                      <div className="optionsHld">
                        {sponsershipCategoryList.map((category) => (
                          <p
                            key={category._id}
                            className="mb-0"
                            onClick={() => (
                              setSponsershipType(category),
                              setShowCategoryOptions(false),
                              setCreateCategory(false)
                            )}
                          >
                            {category.title}
                            <span
                              style={{
                                fontSize: "24px",
                                float: "right",
                              }}
                              className="lightGrey-color"
                              onClick={(e) => (
                                e.stopPropagation(), deleteCategory(category)
                              )}
                            >
                              <i className="mdi mdi-delete"></i>
                            </span>
                            <span
                              style={{
                                fontSize: "24px",
                                float: "right",
                              }}
                              className="lightGrey-color mr-2"
                              onClick={(e) => (
                                e.stopPropagation(),
                                setCreateCategory(true),
                                setIsEditCategory(category._id),
                                setCategoryTitle(category.title),
                                setPriority(category.priority),
                                setPublish(category.publish)
                              )}
                            >
                              <i className="mdi mdi-pencil-box-outline"></i>
                            </span>
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <br />
              <br />
              <h2>Title</h2>
              <TextField
                id="outlined-multiline-static"
                label="Title"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth={true}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Description</h2>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth={true}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Coin</h2>

              <TextField
                id="standard-number"
                label="Coin"
                type="number"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={coin}
                onChange={(e) => {
                  setCoin(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Href</h2>
              <TextField
                id="outlined-multiline-static"
                label="Href"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth={true}
                value={href}
                onChange={(e) => {
                  setHref(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Priority</h2>

              <TextField
                id="standard-number"
                label="Priority"
                type="number"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={coinPriority}
                onChange={(e) => {
                  setCoinPriority(e.target.value);
                }}
              />

              <br />
              <br />

              {url.includes("update") ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={UpdateCoins}
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                >
                  Save changes
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={CreateCoins}
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                >
                  Create Coins
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
