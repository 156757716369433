import React from "react";
import { useHistory, useLocation } from "react-router";
const SessionSidebar = ({eventId,sessionId}) => {
  let location = useLocation();
  const history = useHistory();
  var temp = location.pathname.split("/");
  var activeNav = temp[temp.length - 1];
  return (
    <div className="event-cardHld">
      <p
        className="sessionDetails mb-0"
        style={{
          color: `${activeNav === "basic-detail" ? "#f44d5e" : ""}`,
          background: `${activeNav === "basic-detail" ? "#fff0f0" : ""}`,
        }}
        onClick={()=>history.push(`/event/${eventId}/sessions/${sessionId}/basic-detail`)}
      >
        BASIC DETAIL
      </p>
      <p
        className="sessionDetails mb-0"
        style={{
          color: `${activeNav === "stream-options" ? "#f44d5e" : ""}`,
          background: `${activeNav === "stream-options" ? "#fff0f0" : ""}`,
        }}
        onClick={()=>history.push(`/event/${eventId}/sessions/${sessionId}/stream-options`)}
      >
        STREAM OPTION
      </p>
      {/* <p
        className="sessionDetails mb-0"
        style={{
          color: `${activeNav === "engagement" ? "#f44d5e" : ""}`,
          background: `${activeNav === "engagement" ? "#fff0f0" : ""}`,
        }}
        onClick={()=>history.push(`/event/${eventId}/sessions/${sessionId}/engagement`)}
      >
        ENGAGEMENT
      </p> */}
      {/* <p
        className="sessionDetails mb-0"
        style={{
          color: `${activeNav === "advanced" ? "#f44d5e" : ""}`,
          background: `${activeNav === "advanced" ? "#fff0f0" : ""}`,
        }}
        onClick={()=>history.push(`/event/${eventId}/sessions/${sessionId}/advanced`)}
      >
        ADVANCED
      </p> */}
      <p
        className="sessionDetails mb-0"
        style={{
          color: `${activeNav === "attendees" ? "#f44d5e" : ""}`,
          background: `${activeNav === "attendees" ? "#fff0f0" : ""}`,
        }}
        onClick={()=>history.push(`/event/${eventId}/sessions/${sessionId}/attendees`)}
      >
        ATTENDEES
      </p>
    </div>
  );
};
export default SessionSidebar;
