import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import Button from "@material-ui/core/Button";
import { BOOTCAMP_API } from "../../backend";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import { useHistory } from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles({
  solutionshld: {
    background: "#ffffff",
    boxShadow: "7px 7px 20px 3px rgb(0 0 0 / 15%)",
    padding: "20px",
  },
});
export default function Practices(props) {
  const [fullscreen, setFullsreen] = useState(false);
  const [solutions, setSolutions] = React.useState([]);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [check, setCheck] = React.useState(false);
  const [selectedSoln, setSelectedSoln] = React.useState({});
  const [scroll, setScroll] = React.useState("paper");
  const [isToast, setIsToast] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const classes = useStyles();
  const history = useHistory();
  const getPracticeQuestions = async () => {
    await axios
      .get(`${BOOTCAMP_API}/hackathons/getAllHackathonSolutionCms`)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setSolutions(response.data.result.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteSolutions = async () => {
    await axios
      .delete(
        `${BOOTCAMP_API}/hackathons/deleteSpecificHackathonSolutionUser/${selectedSoln.hackathonHref}`
      )
      .then((response) => {
        setSolutions(
          solutions.filter((x) => {
            if (x._id === selectedSoln._id) {
              return x._id !== selectedSoln._id;
            }
            return solutions;
          })
        );
        setOpenDeleteModal(false)
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getPracticeQuestions();
  }, []);
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Hackathon Solutions" />
      <Snackbar
        open={isToast.show}
        onClose={() => setIsToast({ ...isToast, show: false })}
      >
        <>
          <Alert
            onClose={() => setIsToast({ ...isToast, show: false })}
            severity={isToast.variant}
          >
            {isToast.message}
          </Alert>
        </>
      </Snackbar>
      <div className="d-flex justify-content-end mb-2">
        <Button
          variant="outlined"
          color="secondary"
          className="mr-3"
          onClick={() => history.push("/hackathon-solutions/create")}
        >
          Create Solutions
        </Button>
      </div>

      {solutions.map((soln) => (
        <div className={`row my-3 ${classes.solutionshld}`} key={soln._id}>
          <div className="col-lg-10">
            <div className="">
              <h4>Hackathon Href: {soln?.hackathonHref}</h4>
              <div>
                <p>Short Description:</p>{" "}
                <div
                  dangerouslySetInnerHTML={{ __html: soln?.shortDescription }}
                ></div>
              </div>
              <div>
                <p>Long Description:</p>{" "}
                <div
                  dangerouslySetInnerHTML={{ __html: soln?.longDescription }}
                ></div>
              </div>
              <p>Publish: {soln?.publish?.toString()}</p>
              <div>
                solutions:{" "}
                {soln?.solutions.map((x) => (
                  <div className="" key={x._id}>
                    <p>winnerNumber: {x.winnerNumber}</p>
                    <p>winnerIpynbFile: {x.winnerIpynbFile}</p>
                    <p>winnerHtmlFile: {x.winnerHtmlFile}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col-lg-2 text-center">
            <Button
              variant="outlined"
              color="primary"
              className="mr-1 mb-2"
              onClick={() =>
                history.push(
                  `/hackathon-solutions/update/${soln?.hackathonHref}`
                )
              }
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              className="mr-1 mb-2"
              onClick={() => {
                setOpenDeleteModal(true);
                setSelectedSoln(soln);
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      ))}
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <p>
              <strong>Account deleted once can't be retrieved.</strong>
            </p>

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete this Solutions"
            />
            {/* <p>
							I'd like Analytics India to provide my contact
							information to &nbsp;
							<a href="https://aws.amazon.com/legal/marketingentities/">
								Amazon Web Services
							</a>{" "}
							&nbsp; so AWS can share the latest AWS news and
							offers with me by email, post or telephone.
						</p> */}
            {/* <p>By clicking "I Agree"</p> */}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDeleteModal(false)}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deleteSolutions}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </AuthLayout>
  );
}
