import React, { useEffect, useState } from "react";
import axios from "axios";
import { BOOTCAMP_API } from "../../backend";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useHistory, useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SubscriptionPlanFaqCreate(props) {
  const classes = useStyles();
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [priority, setPriority] = useState(0);
  const [messageData, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [publish, setPublish] = useState(false);
  const history = useHistory();
  const location = useLocation();
  var url = location.pathname.split("/");
  const handleClose = () => {
    setStatus(!status);
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };
  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };
  const CreateFAQ = () => {
    if (question === "" || answer === "" || priority === "") {
      setErrorStatus(true);
      setErrorMessage("Field Missing! All Feild Required !!");
    } else {
      const requestData = {
        question: question,
        answer: answer,
        priority: priority,
      };
      setStatusLoader(true);
      axios
        .post(
          `${BOOTCAMP_API}/subscriptions/createSubscriptionFaq`,
          requestData
        )
        .then((response) => {
          console.log(response);
          if (response.status === 201 || response.status === 200) {
            setStatus(true);
            setMessage(response.data.result.message);
            setStatusLoader(false);
            history.push("/subscriptions-faq");
          } else {
            setErrorStatus(true);
            setErrorMessage(response.data.result.message);
            setStatusLoader(false);
          }
        })
        .catch((error) => {
          setErrorStatus(true);
          setErrorMessage(error);
          setStatusLoader(false);
          console.log(error);
        });
    }
  };
  const getFaqs = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/subscriptions/getSpecificSubscriptionFaqCms/${props.match.params.faqId}`
      )
      .then((response) => {
        setQuestion(response.data.result.data.question);
        setAnswer(response.data.result.data.answer);
        setPriority(response.data.result.data.priority);
        setPublish(response.data.result.data.publish);
      })
      .catch((err) => console.log(err));
  };
  const UpdateFaq = () => {
    if (question === "" || answer === "" || priority === "") {
      setErrorStatus(true);
      setErrorMessage("Field Missing! All Feild Required !!");
    } else {
      const requestData = {
        question: question,
        answer: answer,
        priority: priority,
        publish: publish
      };
      setStatusLoader(true);
      axios
        .put(
          `${BOOTCAMP_API}/subscriptions/updateSubscriptionFaq/${props.match.params.faqId}`,
          requestData
        )
        .then((response) => {
          console.log(response);
          if (response.status === 201 || response.status === 200) {
            setStatus(true);
            setMessage(response.data.result.message);
            setStatusLoader(false);
            history.push("/subscriptions-faq");
          } else {
            setErrorStatus(true);
            setErrorMessage(response.data.result.message);
            setStatusLoader(false);
          }
        })
        .catch((error) => {
          setErrorStatus(true);
          setErrorMessage(error);
          setStatusLoader(false);
          console.log(error);
        });
    }
  };
  useEffect(() => {
    url.includes("update") && getFaqs();
  }, []);

  return (
    <AuthLayout>
      <Header name={url.includes("update") ? "Update FAQ's" : "Create FAQ's"} />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {status && (
              <Snackbar
                open={status}
                autoHideDuration={15000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </Snackbar>
            )}
            {errorStatus && (
              <Snackbar
                open={errorStatus}
                autoHideDuration={15000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            )}
            {statusLoader && (
              <Snackbar open={statusLoader} onClose={handleLoader}>
                <Alert onClose={handleLoader} severity="info">
                  <Loader />
                </Alert>
              </Snackbar>
            )}

            <Paper className={classes.paper}>
              <h2>Question</h2>
              <TextField
                id="outlined-multiline-static"
                label="Question"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth={true}
                value={question}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Answer</h2>
              <TextField
                id="outlined-multiline-static"
                label="Answer"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth={true}
                value={answer}
                onChange={(e) => {
                  setAnswer(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Priority</h2>

              <TextField
                id="standard-number"
                label="Priority"
                type="number"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={priority}
                onChange={(e) => {
                  setPriority(e.target.value);
                }}
              />

              <br />
              <br />

              {url.includes("update") ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={UpdateFaq}
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                >
                  Save changes
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={CreateFAQ}
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                >
                  Create FAQ
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
