import React, { useState } from "react";
import axios, { post } from "axios";
import { API } from "../../backend";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";

import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
// import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
// import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
// import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { DropzoneArea } from "material-ui-dropzone";
import Loader from "../../components/Loader";
import { Row, Col, Card, CardBody } from "reactstrap";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import Header from "../Header";

import { useSelector, useDispatch } from "react-redux";
const { inspect } = require("util");
const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Heading,
    Indent,
    Table,
    TableToolbar,
    Link,
    List,
    MediaEmbed,
    CodeBlock,
    ImageResize,
    ImageToolbar,
    ImageStyle,
  ],
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "mediaEmbed",
    "insertTable",
    "codeBlock",
  ],
  mediaEmbed: {
    previewsInData: true
  },
  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },

  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  codeBlock: {
    languages: [
      // Do not render the CSS class for the plain text code blocks.
      { language: "plaintext", label: "Plain text", class: "" },

      // Use the "php-code" class for PHP code blocks.
      { language: "php", label: "PHP", class: "php-code" },

      // Use the "js" class for JavaScript code blocks.
      // Note that only the first ("js") class will determine the language of the block when loading data.
      {
        language: "javascript",
        label: "JavaScript",
        class: "js javascript js-code",
      },

      // Python code blocks will have the default "language-python" CSS class.
      { language: "python", label: "Python" },
    ],
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateHackathon() {
  const classes = useStyles();

  const token = useSelector((store) => store.auth.token);
  // console.log("token", token);
  console.log("defult", axios.defaults.headers.common["Authorization"]);
  const dispatch = useDispatch();

  var metric_type_array = [
    "custom",
    "custom_root_mean_squared_1",
    "custom_root_mean_squared_2",
    "twod_average_accuracy",
    "root_mean_squared_error",
    "root_mean_squared_log_error",
    "accuracy_score",
    "log_loss",
    "f1_score",
    "r2_score",
    "mean_absolute_errror",
    "mean_squared_error",
    "mean_squared_log_error",
    "mean_absolute_percentage_error",
    "indus_mapk4",
    "recall_score",
    "roc_auc_score",
    "cosine_similarity",
    "balanced_accuracy_score",
  ];

  const [hname, setHname] = useState("");
  const [hstart, setHstart] = useState("");
  const [hend, setHend] = useState("");
  const [hcategory, setHcategory] = useState("");
  const [hsort, setHsort] = useState("");
  const [hlevel, setHlevel] = useState("");
  let [hoverview, setHoverview] = useState("");
  let [hrules, setHrules] = useState("");
  let [heval, setHeval] = useState("");
  let [hprize, setHprize] = useState("");
  const [image_1, setSelectedFile_1] = useState([]);
  const [image_2, setSelectedFile_2] = useState([]);
  const [datasets, setSelectedDatasets] = useState([]);
  const [metric_type, setMetric_type] = useState("");
  const [require_pr_pu_lb, setRequire_pr_pu_lb] = useState(false);
  const [glb_calculation, setGlb_calculation] = useState(false);
  const [yruth_file, setSelectedYruth_file] = useState([]);
  const [submission_threshold, setSubmission_threshold] = useState(0);
  const [team_size_threshold, setTeam_size_threshold] = useState(0);
  const [target_column, setTarget_column] = useState("");
  const [publish, setPublish] = useState(false);
  const [coming_soon, setComing_soon] = useState(false);
  const [Sponsored, setSponsored] = useState(false);
  const [sponsor_terms, setSponsor_terms] = useState("");
  const [public_index, setPublic_index] = useState([]);
  const [hiringHackathon, setHiringHackathon] = useState(false);

  const [messageData, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [hprizesandawards, setHPrizesAndAwards] = useState("");
  const [htotalfund, setHTotalFund] = useState("");
  const [companyLogo, setCompanyLogo] = useState([]);
  const [hdata, setHdata] = useState("");

  const handleClose = () => {
    setStatus(!status);
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };
  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };

  // console.log(token);

  const CreateHackathon = () => {
    const formData = new FormData();
    if (hcategory === "Blogathon") {
		if (
			hname === "" ||
			hstart === "" ||
			hend === "" ||
			hcategory === "" ||
			// hsort === "" ||
			hlevel === "" ||
			hoverview === "" ||
			hrules === "" ||
			heval === "" ||
			hprize === "" ||
			// metric_type === "" ||
			submission_threshold === "" ||
      team_size_threshold === "" ||
			(Sponsored === true && sponsor_terms === "") ||
			!(Array.isArray(image_1) && image_1.length) ||
			!(Array.isArray(image_2) && image_2.length)
			// !(Array.isArray(datasets) && datasets.length) ||
			// !(Array.isArray(yruth_file) && yruth_file.length)
		  ) {
			setErrorStatus(true);
			setErrorMessage("Field Missing! All Feild Required !!");
		  } else {
			hoverview = hoverview.replace(/oembed/g, "iframe");
			hoverview = hoverview.replace(/url/g, "src");
			hrules = hrules.replace(/oembed/g, "iframe");
			hrules = hrules.replace(/url/g, "src");
			heval = heval.replace(/oembed/g, "iframe");
			heval = heval.replace(/url/g, "src");
			hprize = hprize.replace(/oembed/g, "iframe");
			hprize = hprize.replace(/url/g, "src");
	
			formData.append("hname", hname);
			formData.append("hstart", hstart);
			formData.append("hend", hend);
			formData.append("hcategory", hcategory);
			hsort && formData.append("hsort", hsort);
			formData.append("hlevel", hlevel);
			formData.append("hoverview", hoverview);
			formData.append("hrules", hrules);
			formData.append("heval", heval);
			formData.append("hprize", hprize);
			formData.append("image_1", image_1[0]);
			formData.append("image_2", image_2[0]);
			datasets.length > 0 && formData.append("datasets", datasets[0]);
			metric_type && formData.append("metric_type", metric_type);
			formData.append("require_pr_pu_lb", require_pr_pu_lb);
			formData.append("glb_calculation", glb_calculation);
			yruth_file.length > 0 && formData.append("yruth_file", yruth_file[0]);
			formData.append("submission_threshold", submission_threshold);
      formData.append("team_size_threshold", team_size_threshold);
			target_column && formData.append("target_column", target_column);
			formData.append("Sponsored", Sponsored);
			formData.append("sponsor_terms", sponsor_terms);
			formData.append("coming_soon", coming_soon);
			formData.append("publish", publish);
			public_index.length > 0 && formData.append("public_index", public_index[0]);
			formData.append("hiring", hiringHackathon);
			formData.append("prizes_and_awards", hprizesandawards);
			formData.append("total_fund", htotalfund);
			formData.append("company_logo", companyLogo[0]);
			hdata && formData.append("hdata", hdata);
	
			const config = {
			  headers: {
				"content-type": "multipart/form-data",
			  },
			};
			console.log("formData");
			// inspect
			console.log(inspect(formData));
	
			setStatusLoader(true);
	
			axios
			  .post(`${API}/createhackathon`, formData, config)
			  .then((response) => {
				console.log(response);
	
				if (response.data.error) {
				  // setStatus(true);
				  setErrorStatus(true);
				  setErrorMessage(response.data.error);
				  setStatusLoader(false);
				} else {
				  setStatus(true);
				  setMessage(response.data.message);
				  setStatusLoader(false);
				}
			  })
			  .catch((error) => {
				// setStatus(true);
				setErrorStatus(true);
				setErrorMessage(error);
				setStatusLoader(false);
				console.log(error);
			  });
		  }
    } else {
      if (
        hname === "" ||
        hstart === "" ||
        hend === "" ||
        hcategory === "" ||
        hsort === "" ||
        hlevel === "" ||
        hoverview === "" ||
        hrules === "" ||
        heval === "" ||
        hprize === "" ||
        metric_type === "" ||
        submission_threshold === "" ||
        team_size_threshold === "" ||
        (Sponsored === true && sponsor_terms === "") ||
        !(Array.isArray(image_1) && image_1.length) ||
        !(Array.isArray(image_2) && image_2.length) ||
        !(Array.isArray(datasets) && datasets.length) ||
        !(Array.isArray(yruth_file) && yruth_file.length)
      ) {
        setErrorStatus(true);
        setErrorMessage("Field Missing! All Feild Required !!");
      } else {
        hoverview = hoverview.replace(/oembed/g, "iframe");
        hoverview = hoverview.replace(/url/g, "src");
        hrules = hrules.replace(/oembed/g, "iframe");
        hrules = hrules.replace(/url/g, "src");
        heval = heval.replace(/oembed/g, "iframe");
        heval = heval.replace(/url/g, "src");
        hprize = hprize.replace(/oembed/g, "iframe");
        hprize = hprize.replace(/url/g, "src");

        formData.append("hname", hname);
        formData.append("hstart", hstart);
        formData.append("hend", hend);
        formData.append("hcategory", hcategory);
        formData.append("hsort", hsort);
        formData.append("hlevel", hlevel);
        formData.append("hoverview", hoverview);
        formData.append("hrules", hrules);
        formData.append("heval", heval);
        formData.append("hprize", hprize);
        formData.append("image_1", image_1[0]);
        formData.append("image_2", image_2[0]);
        formData.append("datasets", datasets[0]);
        formData.append("metric_type", metric_type);
        formData.append("require_pr_pu_lb", require_pr_pu_lb);
        formData.append("glb_calculation", glb_calculation);
        formData.append("yruth_file", yruth_file[0]);
        formData.append("submission_threshold", submission_threshold);
        formData.append("team_size_threshold", team_size_threshold);
        formData.append("target_column", target_column);
        formData.append("Sponsored", Sponsored);
        formData.append("sponsor_terms", sponsor_terms);
        formData.append("coming_soon", coming_soon);
        formData.append("publish", publish);
        formData.append("public_index", public_index[0]);
        formData.append("hiring", hiringHackathon);
        formData.append("prizes_and_awards", hprizesandawards);
        formData.append("total_fund", htotalfund);
        formData.append("company_logo", companyLogo[0]);
        formData.append("hdata", hdata);

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        console.log("formData");
        // inspect
        console.log(inspect(formData));

        setStatusLoader(true);

        axios
          .post(`${API}/createhackathon`, formData, config)
          .then((response) => {
            console.log(response);

            if (response.data.error) {
              // setStatus(true);
              setErrorStatus(true);
              setErrorMessage(response.data.error);
              setStatusLoader(false);
            } else {
              setStatus(true);
              setMessage(response.data.message);
              setStatusLoader(false);
            }
          })
          .catch((error) => {
            // setStatus(true);
            setErrorStatus(true);
            setErrorMessage(error);
            setStatusLoader(false);
            console.log(error);
          });
      }
    }
  };

  return (
    // <>
    <AuthLayout>
      <Header name="Create Hackathon" />
      <div className={classes.root}>
        <Grid container>
          {/* <Grid item xs={1}>
						<Paper
							className={classes.paper}
							style={{ display: "none" }}
						></Paper>
					</Grid> */}
          <Grid item xs={12}>
            {status && (
              <Snackbar
                open={status}
                autoHideDuration={15000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </Snackbar>
            )}
            {errorStatus && (
              <Snackbar
                open={errorStatus}
                autoHideDuration={15000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            )}
            {statusLoader && (
              <Snackbar
                open={statusLoader}
                // autoHideDuration={95000}
                onClose={handleLoader}
              >
                <Alert onClose={handleLoader} severity="info">
                  <Loader />
                </Alert>
              </Snackbar>
            )}

            <Paper className={classes.paper}>
              <h2>Hackthon Name</h2>
              <TextField
                id="outlined-multiline-static"
                label="HName"
                multiline
                rows={4}
                variant="outlined"
                fullWidth={true}
                value={hname}
                onChange={(e) => {
                  setHname(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Hackthon Start Date Time</h2>
              <TextField
                id="outlined-multiline-static"
                label="hstart"
                multiline
                variant="outlined"
                fullWidth={true}
                value={hstart}
                onChange={(e) => {
                  setHstart(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Hackthon End Date Time</h2>
              <TextField
                id="outlined-multiline-static"
                label="hend"
                multiline
                variant="outlined"
                fullWidth={true}
                value={hend}
                onChange={(e) => {
                  setHend(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Hackthon Category </h2>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  hcategory
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={hcategory}
                  onChange={(e) => {
                    setHcategory(e.target.value);
                  }}
                  label="hcategory"
                >
                  <MenuItem value="Hackathon">Hackathon</MenuItem>
                  <MenuItem value="Practice">Practice</MenuItem>
                  <MenuItem value="Visualization">Visualization</MenuItem>
                  <MenuItem value="Blogathon">Blogathon</MenuItem>
                  <MenuItem value="Data_Engineering">Data Engineering</MenuItem>
                </Select>
              </FormControl>

              <br />
              <br />

              <h2>LB Sort Order </h2>

              <TextField
                id="outlined-multiline-static"
                label="hsort"
                multiline
                variant="outlined"
                fullWidth={true}
                value={hsort}
                onChange={(e) => {
                  setHsort(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Hackthon Level</h2>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  hlevel
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={hlevel}
                  onChange={(e) => {
                    setHlevel(e.target.value);
                  }}
                  label="hcategory"
                >
                  <MenuItem value="Beginner">Beginner</MenuItem>
                  <MenuItem value="Intermediate">Intermediate</MenuItem>
                  <MenuItem value="Advanced">Advanced</MenuItem>
                  {/* <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <br />
              <br />
              <h2>Hackthon Overview</h2>

              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data="<p>Hello from CKEditor 5!</p>"
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setHoverview(data);
                  console.log("data composed");
                  console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />

              <br />
              <br />
              <h2>Hackthon Rules</h2>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data="<p>Hello from CKEditor 5!</p>"
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setHrules(data);
                  console.log({ data });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              <br />
              <br />
              <h2>Hackthon Evaluations</h2>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data="<p>Hello from CKEditor 5!</p>"
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setHeval(data);
                  console.log({ data });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              <br />
              <br />
              <h2>Hackthon Prize</h2>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data="<p>Hello from CKEditor 5!</p>"
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setHprize(data);
                  console.log({ data });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              <br />
              <br />
              <h2>Hackthon Banner Image</h2>
              <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                onChange={(files) => setSelectedFile_1(files)}
                maxFileSize={9242880}
              />

              <br />
              <br />
              <h2> Hackthon Small Image</h2>
              <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                onChange={(files) => setSelectedFile_2(files)}
                maxFileSize={9242880}
              />

              <br />
              <br />
              <h2>Hackthon Dataset</h2>
              <DropzoneArea
                acceptedFiles={["application/*"]}
                onChange={(files) => setSelectedDatasets(files)}
                maxFileSize={500000000}
              />

              <br />
              <br />

              <h2>Hackthon metric_type</h2>
              {/* <TextField
							id="outlined-multiline-static"
							label="metric_type"
							multiline
							variant="outlined"
							fullWidth={true}
							value={metric_type}
							onChange={(e) => {
								setMetric_type(e.target.value);
							}}
						/> */}

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  metric_type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={metric_type}
                  onChange={(e) => {
                    setMetric_type(e.target.value);
                  }}
                  label="metric_type"
                >
                  {metric_type_array.map((metric) => (
                    <MenuItem value={metric} key={metric}>
                      {metric}
                    </MenuItem>
                  ))}

                  {/* <MenuItem value="Intermediate">
									Intermediate
								</MenuItem> */}
                  {/* <MenuItem value="Practice">Advanced</MenuItem> */}
                  {/* <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>

              <br />
              <br />
              <h2>Enable Private Leaderboard</h2>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={require_pr_pu_lb}
                    onChange={() => {
                      setRequire_pr_pu_lb(!require_pr_pu_lb);
                    }}
                    name="checkedG"
                  />
                }
                label="Enable Private Leaderboard"
              />

              <br />
              <br />
              <h2>Calculate Global Score</h2>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={glb_calculation}
                    onChange={() => {
                      setGlb_calculation(!glb_calculation);
                    }}
                    name="checkedG"
                  />
                }
                label="Calculate Global Score"
              />

              <br />
              <br />
              <h2>Ytruth File</h2>
              <DropzoneArea
                acceptedFiles={[
                  ".csv",
                  "application/vnd.ms-excel",
                  "text/csv",
                  ".xlsx",
                ]}
                onChange={(files) => setSelectedYruth_file(files)}
                maxFileSize={9242880}
              />

              <br />
              <br />
              <h2>Hackthon target_column</h2>
              <TextField
                id="outlined-multiline-static"
                label="target_column"
                multiline
                variant="outlined"
                fullWidth={true}
                value={target_column}
                onChange={(e) => {
                  setTarget_column(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Hackthon submission_threshold</h2>

              <TextField
                id="standard-number"
                label="Number"
                type="number"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={submission_threshold}
                onChange={(e) => {
                  setSubmission_threshold(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Hackthon Team size_threshold</h2>

              <TextField
                id="standard-number"
                label="Number"
                type="number"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={team_size_threshold}
                onChange={(e) => {
                  setTeam_size_threshold(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Coming Soon</h2>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={coming_soon}
                    onChange={() => {
                      setComing_soon(!coming_soon);
                    }}
                    name="checkedG"
                  />
                }
                label="Coming Soon"
              />

              <br />
              <br />

              <h2>Hiring Hackathon</h2>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={hiringHackathon}
                    onChange={() => {
                      setHiringHackathon(!hiringHackathon);
                    }}
                    name="hiringHackathon"
                  />
                }
                label="Hiring Hackathon"
              />

              <br />
              <br />

              <h2>Sponsored</h2>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={Sponsored}
                    onChange={() => {
                      setSponsored(!Sponsored);
                    }}
                    name="checkedG"
                  />
                }
                label="Sponsored"
              />

              <br />
              <br />

              {Sponsored ? (
                <>
                  <h2>Sponsor Terms</h2>
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data="<p>Hello from CKEditor 5!</p>"
                    onInit={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setSponsor_terms(data);
                      console.log({ data });
                    }}
                    onBlur={(event, editor) => {
                      console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      console.log("Focus.", editor);
                    }}
                  />
                  <br />
                  <br />
                </>
              ) : (
                ""
              )}

              <h2>Publish</h2>
              <FormControlLabel
                control={
                  <GreenCheckbox
                    checked={publish}
                    onChange={() => {
                      setPublish(!publish);
                    }}
                    name="checkedG"
                  />
                }
                label="Publish"
              />

              <br />
              <br />

              <h2>Prizes and Awards</h2>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Prizes and Awards
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={hprizesandawards}
                  onChange={(e) => {
                    setHPrizesAndAwards(e.target.value);
                  }}
                  label="hcategory"
                >
                  <MenuItem value="Monetary">Monetary</MenuItem>
                  <MenuItem value="Knowledge">Knowledge</MenuItem>
                  <MenuItem value="Hiring">Hiring</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>

              <br />
              <br />

              <h2>Total Fund</h2>
              <TextField
                id="outlined-multiline-static"
                label="Total Fund"
                multiline
                variant="outlined"
                fullWidth={true}
                value={htotalfund}
                onChange={(e) => {
                  setHTotalFund(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Company Logo</h2>
              <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                onChange={(files) => setCompanyLogo(files)}
                maxFileSize={9242880}
              />

              <br />
              <br />

              <h2>Hackathon Data</h2>

              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data="<p>Hello from CKEditor 5!</p>"
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setHdata(data);
                  console.log("data composed");
                  console.log({ event, editor, data });
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />

              <br />
              <br />

              <h2>Public Index</h2>
              <DropzoneArea
                acceptedFiles={[
                  ".csv",
                  "application/vnd.ms-excel",
                  "text/csv",
                  ".xlsx",
                ]}
                onChange={(files) => setPublic_index(files)}
                maxFileSize={9242880}
              />

              <br />
              <br />

              <br />
              <br />
              <br />
              <br />

              <Button
                variant="contained"
                color="primary"
                onClick={CreateHackathon}
                size="large"
                style={{
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  backgroundColor: "rgb(250, 53, 88)",
                }}
              >
                Create Hackathon
              </Button>
            </Paper>
          </Grid>
          {/* <Grid item xs={1}>
						<Paper
							className={classes.paper}
							style={{ display: "none" }}
						></Paper>
					</Grid> */}
        </Grid>
      </div>
    </AuthLayout>
    // </>
  );
}
