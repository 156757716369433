import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API } from "../../../backend";
import Loader from "../../../components/Loader";
// import online from ""
import online from "../../../assets/images/online.png";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DownloadLoader from "../../DownloadUserData/Loader";
import Header from "../../Header";
import { getLoggedInUser } from "../../../helpers/authUtils";
import AuthLayout from "../../../components/AuthLayout";
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
const moment = require('moment');

var FileSaver = require("file-saver");

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			marginTop: theme.spacing(2),
		},
	},
}));

export default function GetAllTopics(props) {
	const bootcamps = useSelector((store) => store.bootcamps.bootcamps);
	const dispatch = useDispatch();

	const [open, setOpen] = React.useState(false);
	const [limit, setLimit] = useState("");
	const [sort, setSort] = useState("PublicScore");
	const [start, setStart] = useState(false);
	const [end, setEnd] = useState(false);
	const [messageData, setMessageData] = useState("");
	const [HREFName, setHREFName] = useState("");
	const [topicList, settopicList] = useState([]);
	const [isEmptyList, setIsEmptyList] = useState(false);
	const [totalCourses, settotalCourses] = useState(0);
	const [selectedPage, setSelectedPage] = useState("");

	const classes = useStyles();

	const handleClickOpen = (HREFName) => {
		setHREFName(HREFName);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseEnd = () => {
		setEnd(!end);
	};

	// const downloadFileHackathon = () => {
	// 	setStart(true);
	// 	setMessageData("File Download Started ...");
	// 	var formData = new FormData();
	// 	formData.append("limit", limit);
	// 	formData.append("sortby", sort);

	// 	axios({
	// 		url: `${API}/lb/export/${HREFName}`,
	// 		method: "POST",
	// 		headers: {
	// 			"content-type": "multipart/form-data",
	// 		},
	// 		data: formData,
	// 		responseType: "blob",
	// 	})
	// 		.then((response) => {
	// 			console.log(response);
	// 			FileSaver.saveAs(response.data, "userdata.csv");
	// 			// FileDownload(response.data, "report.csv");
	// 			// setSubmissions(response.data.submissions);
	// 			// console.log("here");
	// 			setStart(false);
	// 			setEnd(true);
	// 			setMessageData("File Download Completed");
	// 		})
	// 		.catch((err) => console.log(err));
	// };

	// const deletebootcamp = (bootcampHREFName) =>{

	// 	var r = window.confirm("Are you sure want to delete the bootcamp");

	// 	if(r === true){
	// 		axios.delete(`${API}/delete_bootcamp/${bootcampHREFName}`)
	// 			.then((response) =>{
	// 				if(response)
	// 					getAllTopics(selectedPage);
	// 			})
	// 	}

	// }

	const getAllTopics = () => {
		// console.log('BOOTCAMP PROPS', props)
		axios
			.get(`${BOOTCAMP_API}/bootcamp/getAllTopicsUnderSection/${props.match.params.moduleObjectID}`)
			.then((response) => {
				if (response.data.result) {
					console.log(response.data.result.data)
					if (response.data.result.data.length > 0) {
						settopicList(response.data.result.data);
						// settotalCourses(response.data.total_bootcamps)
					}
					else
						setIsEmptyList(true);
				}
			})
			.catch((err) => console.log(err));
	};

	// const nextbootcampSet = (e,value) =>{

	// 	setSelectedPage(value);

	// 	getAllTopics(value);
	// }

	// console.log("hackthon", hackathons);
	useEffect(() => {
		getAllTopics();
	}, []);

	// console.log("hackathon", Object.keys(hackathons).length);

	return (
		<AuthLayout>
			<React.Fragment>
				<div className="">
					{/* preloader */}
					{/* {this.props.loading && <Loader />} */}
					<Header name={props.match.params.moduleID} />
					<Row>
						<Col>{console.log(props)}
							<Link
								to={`/createTopic/${props.match.params.bootcampID}/${props.match.params.moduleID}/${props.match.params.moduleObjectID}`}
							>
								<ButtonToggle
									color="dark"
									style={{
										paddingLeft:
											"30px",
										paddingRight:
											"30px",
										margin: "15px",
										float: 'right'
									}}
								>
									Add Topics
								</ButtonToggle>
							</Link>
						</Col>
					</Row>

					<Row>
						<Col lg={12}>
							<>
								{start && (
									<Snackbar
										open={start}
										onClose={handleClose}
									>
										<>
											<Alert
												onClose={handleClose}
												severity="success"
											>
												<DownloadLoader pexal="55px" />
												{messageData}
											</Alert>
										</>
									</Snackbar>
								)}
								{end && (
									<Snackbar
										open={end}
										onClose={handleCloseEnd}
									>
										<>
											<Alert
												onClose={handleCloseEnd}
												severity="success"
											>
												{messageData}
											</Alert>
										</>
									</Snackbar>
								)}

								{topicList.length > 0 ? (
									topicList.map((bootcamp, index) => (
										<Card key={index}>
											<Row>
												<Col lg={8}>
													<CardBody>
														{" "}
														<h4>
															{bootcamp.title}
														</h4>
														<p>
															<span className="font-weight-bold">Type:</span> {bootcamp.type}<br />
															<span className="font-weight-bold">Duration:</span> {((bootcamp.duration) / 3600).toFixed(2)} Hours<br />
															<span className="font-weight-bold">Created At:</span> {moment(bootcamp.created_at).format('MMMM Do, YYYY  h:mma')}<br />
															<span className="font-weight-bold">Order:</span> {bootcamp.topic_order}
														</p>{" "}
													</CardBody>
												</Col>
												<Col
													lg={2}
													style={{
														textAlign: "right",
													}}
												>
													<CardBody
														style={{
															paddingLeft: "0px",
															paddingRight: "0px",
														}}
													>
														{" "}
														<Link
															to={`/updateTopic/${props.match.params.bootcampID}/${props.match.params.moduleObjectID}/${props.match.params.moduleID}/${bootcamp._id}`}
														>
															<ButtonToggle
																color="danger"
																style={{
																	paddingLeft:
																		"30px",
																	paddingRight:
																		"30px",
																}}
															>
																Edit
															</ButtonToggle>
														</Link>

													</CardBody>
												</Col>
												{/* <Col 
													lg={2}
													>
														
														<CardBody
														style={{
															paddingLeft: "0px",
															paddingRight: "0px",
														}}
													>
														
														<ButtonToggle
																color="danger"
																style={{
																	paddingLeft:
																		"30px",
																	paddingRight:
																		"30px",
																}}
																onClick={(e) => deletebootcamp(bootcamp.HREFName)}
															>
																Delete
														</ButtonToggle>
													</CardBody>
												</Col> */}
											</Row>
										</Card>
									))
								) : (
									<>
										{
											isEmptyList ? (
												<div style={{ textAlign: "center" }}>
													No Topics Available
												</div>
											) : (
												<div style={{ textAlign: "center" }}>
													<Loader />
												</div>
											)
										}

									</>
								)}

								{/* <Card>
									<Col lg={12}>
										<Row style={{justifyContent:"center",paddingBottom:"20px"}}>
											<Pagination count={totalCourses % 10 === 0 ? parseInt(totalCourses / 10) : parseInt(totalCourses / 10)+1 }
														defaultPage={1}
														page={Number(selectedPage)}
														variant="outlined" 
                                						shape="rounded"
														className={classes.root}
														onChange={nextbootcampSet} 
														showFirstButton showLastButton  />
										</Row>
									</Col>
								</Card> */}

								<Dialog
									open={open}
									onClose={handleClose}
									aria-labelledby="alert-dialog-title"
									aria-describedby="alert-dialog-description"
									fullWidth={true}
									maxWidth="md"
								>
									<DialogTitle id="alert-dialog-title">
										Downlaod Public/Private Leaderboard Data
									</DialogTitle>
									<DialogContent>
										{/* <DialogContentText id="alert-dialog-description">
						Let Google help apps determine location. This means
						sending anonymous location data to Google, even when no
						apps are running.
					</DialogContentText> */}
										<br />
										<div class="form-group row">
											<label
												class="col-sm-2  col-form-label"
												for="simpleinput"
											>
												Limit
											</label>
											<div class="col-sm-10">
												<input
													type="text"
													class="form-control"
													value={limit}
													onChange={(e) => {
														setLimit(
															e.target.value.trim()
														);
													}}
												/>
											</div>
										</div>

										<div class="form-group row">
											<label
												for="sel1"
												class="col-sm-2  col-form-label"
											>
												Sortby
											</label>
											<div class="col-sm-10">
												<select
													class="form-control"
													id="sel1"
													onChange={(e) => {
														setSort(e.target.value);
													}}
													value={sort}
												>
													<option value="PublicScore">
														PublicScore
													</option>
													<option value="PrivateScore">
														PrivateScore
													</option>
												</select>
											</div>
										</div>
										<br />
									</DialogContent>
									<DialogActions>
										<ButtonToggle
											color="danger"
											style={{
												paddingLeft: "30px",
												paddingRight: "30px",
											}}
											onClick={handleClose}
										>
											Cancel
										</ButtonToggle>
										{/* <button
											class="btn btn-pink waves-effect waves-light"
											onClick={downloadFileHackathon}
										>
											{" "}
											<span>Download</span> &nbsp;
											<i class="fas fa-download"></i>{" "}
										</button> */}
									</DialogActions>
								</Dialog>
							</>
						</Col>
					</Row>
				</div>
			</React.Fragment>
		</AuthLayout>
	);
}
