import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import DownloadLoader from "../../DownloadUserData/Loader";
import Header from "../../Header";
import AuthLayout from "../../../components/AuthLayout";
import QuestionSetService from '../../../services/mocks/QuestionSetService';
import QuestionService from '../../../services/mocks/QuestionService';
import Pagination from "@material-ui/lab/Pagination";
import '../../../App.css';
const moment = require('moment');

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function GetAllQuestionSets(props) {
	const [open, setOpen] = React.useState(false);
	const [start, setStart] = useState(false);
	const [end, setEnd] = useState(false);
	const [messageData, setMessage] = useState("");
	const [questionSets, setQuestionSets] = useState([])
	const [loader, setLoader] = useState(false)
	const [loaderAt, setLoaderAt] = useState(-1)
	const [emptyQuestionSets, setEmptyQuestionSets] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [errorStatus, setErrorStatus] = useState(false);
	const [status, setStatus] = useState(false);
	const [statusLoader, setStatusLoader] = useState(false);
	const [totalQuestionSet, setTotalQuestionSet] = useState(0);
	const [selectedPage, setSelectedPage] = useState("1");


	const handleClose = () => {
		setStatus(false);
	};

	const handleCloseEnd = () => {
		setEnd(!end);
	};

	const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };

	const onPaginationChange = (e, value) => {
		FetchAllQuestionSets(value);
		setSelectedPage(value);
	  };

	const FetchAllQuestionSets = async (value) => {
		try {
			const questionSets = await QuestionSetService.GetAllQuestionSets(value);
			if (questionSets && questionSets.data.length > 0) {
				setQuestionSets(questionSets.data);
				setTotalQuestionSet(questionSets.totalCount);
			} else {
				setEmptyQuestionSets(true);
			}
		} catch (error) {
			console.log(error);
		}
	};


	const updateFlags = async (index, doPublish) => {
		try {
			setLoaderAt(index);
			const noOfQuestionsAvailable = await QuestionService.GetAllQuestionsOfAQuestionSet(questionSets[index].slug);
			if (noOfQuestionsAvailable && noOfQuestionsAvailable.length > 0) {
				const response = await QuestionSetService.PublishOrUnpublishAQuestionSet(questionSets[index]._id, doPublish);
				const questionSetsDup = questionSets.slice();
				questionSetsDup[index].flags.draft = !doPublish;
				setQuestionSets(questionSetsDup);
				setLoaderAt(-1);
				setStatus(true);
				setMessage(doPublish ? `Published Successfully!` : 'Unpublished Successfully!');
				setStatusLoader(false);
			} else {
				setErrorStatus(true);
				setErrorMessage(`No questions found for a question set to ${doPublish ? 'Publish' : 'Unpublish'}`);
				setStatusLoader(false);
				setLoaderAt(-1);
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		FetchAllQuestionSets(selectedPage);
	}, []);


	return (
    <AuthLayout>
      <React.Fragment>
        <div className="">
          <Row>
            <Col>
              <Header name="All Question Sets" />
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <>
                {status && (
                  <Snackbar
                    open={status}
                    autoHideDuration={5000}
                    onClose={handleClose}
                  >
                    <>
                      <Alert onClose={handleClose} severity="success">
                        {messageData}
                      </Alert>
                    </>
                  </Snackbar>
                )}

                {errorStatus && (
                  <Snackbar
                    open={errorStatus}
                    autoHideDuration={7000}
                    onClose={handleCloseError}
                  >
                    <Alert onClose={handleCloseError} severity="error">
                      {errorMessage}
                    </Alert>
                  </Snackbar>
                )}

                {questionSets.length > 0 ? (
                  questionSets.map((questionSet, index) => (
                    <Card key={questionSet.slug}>
                      <Row>
                        <Col lg={7}>
                          <CardBody>
                            <h4>{questionSet.title}</h4>
                            <span>
                              <b>Short Description: </b>{" "}
                              {questionSet.short_description
                                ? questionSet.short_description
                                : ""}
                            </span>
                            <br />
                            <span>
                              <b>Description: </b> {questionSet.description}
                            </span>
                            <br />
                            <span>
                              <b>Difficulty: </b> {questionSet.difficulty}
                            </span>
                            <br />
                            <span>
                              <b>QSetType: </b>{" "}
                              {questionSet.questionSetType
                                ? questionSet.questionSetType
                                : "Not Set"}
                            </span>
                            <br />
                            <span>
                              <b>Duration: </b> {questionSet.duration} seconds
                            </span>
                            <br />
                            <b>Created At: </b>
                            <span>
                              {questionSet.timestamps &&
                              questionSet.timestamps.created_at
                                ? moment(
                                    questionSet.timestamps.created_at
                                  ).format("MMMM Do, YYYY  h:mma")
                                : "N/A"}
                            </span>
                            <br />
                          </CardBody>
                        </Col>

                        <Col
                          lg={5}
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <CardBody
                            style={{
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                          >
                            {index === loaderAt ? (
                              <div
                                style={{ margin: "auto", textAlign: "left" }}
                              >
                                <Loader />
                              </div>
                            ) : (
                              <>
                                <ButtonToggle
                                  className={
                                    questionSet.flags.draft
                                      ? "publish"
                                      : "unpublish"
                                  }
                                  onClick={() =>
                                    updateFlags(
                                      index,
                                      questionSet.flags.draft ? true : false
                                    )
                                  }
                                >
                                  {questionSet.flags.draft
                                    ? "PUBLISH"
                                    : "UNPUBLISH"}
                                </ButtonToggle>

                                <Link
                                  to={`/updateQuestionSet/${questionSet.slug}`}
                                >
                                  <ButtonToggle
                                    color="danger"
                                    style={{
                                      paddingLeft: "30px",
                                      paddingRight: "30px",
                                      margin: "15px",
                                    }}
                                  >
                                    Edit
                                  </ButtonToggle>
                                </Link>

                                <Link
                                  to={`/getAllQuestions/${questionSet.slug}`}
                                >
                                  <ButtonToggle
                                    color="dark"
                                    style={{
                                      paddingLeft: "30px",
                                      paddingRight: "30px",
                                      margin: "15px",
                                    }}
                                  >
                                    View Questions
                                  </ButtonToggle>
                                </Link>
                              </>
                            )}
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  ))
                ) : (
                  <>
                    {emptyQuestionSets ? (
                      <h4 style={{ textAlign: "center" }}>
                        No QuestionSets found
                      </h4>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <Loader />
                      </div>
                    )}
                  </>
                )}
              </>
              <div className="d-flex justify-content-center my-4">
                <Pagination
                  count={
                    totalQuestionSet % 10 === 0
                      ? parseInt(totalQuestionSet / 10)
                      : parseInt(totalQuestionSet / 10) + 1
                  }
                  defaultPage={1}
                  page={Number(selectedPage)}
                  variant="outlined"
                  shape="rounded"
                  onChange={onPaginationChange}
                  showFirstButton
                  showLastButton
                />
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    </AuthLayout>
  );
}
