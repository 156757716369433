import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API, EVENT_URL } from "../../../backend";
import Loader from "../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import DownloadLoader from "../../DownloadUserData/Loader";
import EventLayout from "../../../components/EventLayout";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import { FormControl } from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import Button from "@material-ui/core/Button";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router";
import { DropzoneArea } from "material-ui-dropzone";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "react-select";

const moment = require("moment");

const options = [
  { value: "YouTube", label: "youtube" },
  { value: "Vimeo", label: "vimeo" },
];
const useStyles = makeStyles({
  root: {
    minHeight: 206,
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardbody: {
    padding: "0 10px 10px 10px",
    borderRadius: "8px",
  },
  eventbanner: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "100px",
    objectFit: "fill",
    maxWidth: "100%",
  },
  eventName: {
    fontWeight: 500,
    display: "-webkit-box",
    overflow: "hidden",
    "-mozBoxOrient": "vertical",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": 2,
  },
  moreOptions: {
    background: "#ffffff",
    position: "absolute",
    right: "10px",
    top: "6px",
    borderRadius: "50px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f44d5e",
    fontSize: "20px",
  },
  optionMenu: {
    boxShadow: "none",
    border: "1px solid #dbdbdb",
    "& div": {
      boxShadow: "none",
      border: "1px solid #dbdbdb",
    },
  },
  optionMenuItem: {
    boxShadow: "none",
  },
  createEventDrawer: {
    width: "40%",
  },
  creatEventHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    overflowX: "hidden",
  },
  spacingCreateEvent: {
    padding: "10px 30px 10px 30px",
    height: "calc(100vh - 142px)",
    overflow: "auto",
  },
  eventType: {
    width: "50%",
    textAlign: "center",
    color: "gray",
    marginBottom: 0,
    padding: "8px",
  },
  border: {
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
  },
  selectedEventType: {
    background: "#f44d5e",
    color: "#ffffff",
    width: "50%",
    textAlign: "center",
    marginBottom: 0,
    padding: "8px",
  },
  textField: {
    marginRight: 10,
    border: "1px solid rgba(53, 53, 53, 0.17)",
    borderRadius: "4px",
    color: "#353535",
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
});
var FileSaver = require("file-saver");

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Branding(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [limit, setLimit] = useState("");
  const [sort, setSort] = useState("PublicScore");
  const [start, setStart] = useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [HREFName, setHREFName] = useState("");
  const [eventType, setEventType] = React.useState("");
  const [eventTypeValue, setEventTypeValue] = React.useState("");
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [eventTiltle, setEventTitle] = React.useState("");
  const [eventDescription, setEventDescription] = React.useState("");
  const [eventStartDate, setEventStartDate] = React.useState("");
  const [eventEndDate, setEventEndDate] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [linkedIn, setLinkedIn] = React.useState("");
  const [twitter, setTwitter] = React.useState("");
  const [fb, setfb] = React.useState("");
  const [shortDescription, setEventShortDescription] = React.useState("");
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [publishStatus, setPublishStatus] = React.useState();
  const [insta, setInsta] = React.useState("");
  const [IsCopy, setIsCopy] = React.useState(false);
  const [streamOption, setstreamOption] = React.useState(true);
  const [bannerImg, setBannerImg] = useState([]);
  const [communityBanners, setCommunityBanners] = useState([]);
  const [logo, setLogo] = useState([]);
  const [loginBanners, setLoginBanners] = useState([]);
  const [welcomeVideo, setWelcomeVideo] = useState([]);
  const [communityImages, setCommunityImages] = useState([]);
  const [logoImg, setLogoImg] = useState({});
  const [loginImgs, setLoginImgs] = useState([]);
  const [welcomeVideoLink, setWelcomeVideoLink] = useState({});
  const [isEditWelcomeVideo, setIsEditWelcomeVideo] = useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [check, setCheck] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
    addVideo: false,
  });
  const [openImgDel, setOpenImgDel] = React.useState({
    show: false,
    data: {},
    type: "",
  });
  const [videoType, setVideoType] = React.useState({});
  const [videoLink, setVideoLink] = React.useState("");
  const [videoTitle, setVideoTitle] = React.useState("");
  const [videoDescription, setVideoDescription] = React.useState("");
  const [popupwhenLogin, setpopupwhenLogin] = React.useState(false);
  const [showOnReception, setshowOnReception] = React.useState(false);
  const onStreamOptionhandleChange = (event) => {
    setstreamOption(true);
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const saveEvent = (publishStatus) => {
    const createEventBody = {
      title: eventTiltle,
      description: eventDescription,
      featured: true,
      publish: publishStatus,
      eventStartDate: eventStartDate,
      eventEndDate: eventEndDate,
      shortDescription: shortDescription,
      typeOfEvent: eventType,
    };
    if (website) {
      createEventBody.website = website;
    }
    if (fb) {
      createEventBody.fb = fb;
    }
    if (website) {
      createEventBody.twitter = twitter;
    }
    if (website) {
      createEventBody.Instagram = insta;
    }
    if (website) {
      createEventBody.linkedIn = linkedIn;
    }
    axios
      .put(
        `${BOOTCAMP_API}/events/updateEvents/${eventData._id}`,
        createEventBody
      )
      .then((response) => {
        console.log(response);
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setState({ ...state, ["right"]: false });
          setMessageData(response.data.result.message);
          getEventData();
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  const addVideo = () => {
    const body = {};
    body["welcomeVideo"] = {
      videoType: videoType.value,
      videoLink: videoLink,
      videoDescription: videoDescription,
      popUpAfterFirstLogin: popupwhenLogin,
      showOnReception: showOnReception,
    };
    axios
      .put(
        `${BOOTCAMP_API}/events/addEventBranding/${props.match.params.eventId}`,
        body
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setState({ ...state, addVideo: false });
          addDataToRedux(response.data.result.data);
        }
      })
      .catch((error) => {
        setErrorStatus(true);
        console.log(error);
      });
  };

  const addUrl = () => {
    const brandingDatabody = {};
    if (openImgDel.type === "carouselImages") {
      brandingDatabody[openImgDel.type] = [];
      communityImages.filter((x) => {
        if (x._id === openImgDel.data._id) {
          const imgBody = {
            _id: x._id,
            redirection: imageUrl,
            imageLink: openImgDel.data.imageLink,
          };
          brandingDatabody[openImgDel.type].push(imgBody);
          console.log("add video ", brandingDatabody);
        } else {
          brandingDatabody[openImgDel.type].push(x);
        }
      });
    } else if (openImgDel.type === "logo") {
      const imgBody = {
        redirection: imageUrl,
        imageLink: openImgDel.data.imageLink,
      };
      brandingDatabody[openImgDel.type] = imgBody;
    }
    axios
      .put(
        `${BOOTCAMP_API}/events/addEventBranding/${props.match.params.eventId}`,
        brandingDatabody
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setState({ ...state, right: false });
          addDataToRedux(response.data.result.data);
        }
      })
      .catch((error) => {
        setErrorStatus(true);
        console.log(error);
      });
  };
  const openUrlDrawer = (image, type) => {
    setOpenImgDel({ ...openImgDel, data: image, type: type });
    setState({ ...state, right: true });
  };

  const list = (anchor) => (
    <div role="presentation">
      <div className={classes.creatEventHeader}>
        <Col lg={9}>
          <h4>Add URL</h4>
        </Col>
        <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
          <i
            className="mdi mdi-close"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
          ></i>
        </Col>
      </div>
      <Col lg={12} className={classes.spacingCreateEvent}>
        <FormControl className={classes.formControl}>
          <label htmlFor="title" className="event-label">
            Event URL
          </label>
          <input
            id="title"
            type="text"
            className="form-control"
            placeholder="https://"
            value={imageUrl || openImgDel.data.redirection}
            onChange={(e) => setImageUrl(e.target.value)}
          />
        </FormControl>
        <p className="inputInfo">
          People will be redirected to the URL in a new tab when clicked on the
          community.
        </p>
        <br />
      </Col>
      <div className="pt-3 px-3">
        {imageUrl ? (
          <button className={classes.createBtn} onClick={addUrl}>
            ADD
          </button>
        ) : (
          <button
            className={classes.createBtn}
            disabled
            style={{
              background: "rgba(0, 0, 0, 0.12)",
              border: "1px solid rgba(0, 0, 0, 0.12)",
              color: "rgba(0, 0, 0, 0.26)",
            }}
          >
            ADD
          </button>
        )}
        <button
          className={classes.cancelBtn}
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          CANCEL
        </button>
      </div>
    </div>
  );

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCloseEnd = () => {
    setEnd(!end);
  };

  const copyURL = () => {
    navigator.clipboard.writeText(`${EVENT_URL}/events/${eventData.slug}/home`);
    setIsCopy(true);
  };

  const saveBrandingData = () => {
    const communityformData = new FormData();
    communityBanners.filter((x) => {
      communityformData.append("", x);
    });
    communityBanners.length > 0 &&
      postBannerImges(communityformData, "carouselImages");
    const logoformData = new FormData();
    logo.length > 0 && logoformData.append("", logo[0]);
    logo.length > 0 && postBannerImges(logoformData, "logo");
    const loginformData = new FormData();
    loginBanners.filter((x) => {
      loginformData.append("", x);
    });
    loginBanners.length > 0 && postBannerImges(loginformData, "loginBanners");
    const welcomeformData = new FormData();
    welcomeVideo.length > 0 && welcomeformData.append("", welcomeVideo[0]);
    welcomeVideo.length > 0 && postBannerImges(welcomeformData, "welcomeVideo");
  };

  const postBannerImges = async (formdata, bannerType) => {
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    await axios
      .post(`${BOOTCAMP_API}/events/upload/${props.match.params.eventId}`, formdata, config)
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          const s3URls = response.data.result.data;
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          const brandingDatabody = {};
          if (bannerType === "carouselImages") {
            brandingDatabody[bannerType] = [];
            let imageArr = [];
            communityImages.length > 0
              ? (imageArr = communityImages.concat(s3URls))
              : (imageArr = s3URls);
            imageArr.filter((x) => {
              const imgBody = {
                redirection: x.redirection || "",
                imageLink: x.s3_url || x.imageLink,
              };
              brandingDatabody[bannerType].push(imgBody);
            });
          } else if (bannerType === "logo") {
            s3URls.filter((x) => {
              const imgBody = {
                redirection: "",
                imageLink: x.s3_url,
              };
              brandingDatabody[bannerType] = imgBody;
            });
          } else if (bannerType === "loginBanners") {
            brandingDatabody[bannerType] = [];
            let imageArr = [];
            loginImgs.length > 0
              ? (imageArr = loginImgs.concat(s3URls))
              : (imageArr = s3URls);
            imageArr.filter((x) => {
              brandingDatabody[bannerType].push(x.s3_url || x);
            });
          } else {
            s3URls.filter((x) => {
              brandingDatabody[bannerType] = x.s3_url;
            });
          }
          axios
            .put(
              `${BOOTCAMP_API}/events/addEventBranding/${props.match.params.eventId}`,
              brandingDatabody
            )
            .then((response) => {
              if (response.data.error) {
                setErrorStatus(true);
                setErrormesage(response.data.result.message);
              } else {
                setSuccessMessage(true);
                setMessageData(response.data.result.message);
                setLogo([]);
                setCommunityBanners([]);
                setLoginBanners([]);
                setWelcomeVideo([]);
                addDataToRedux(response.data.result.data);
              }
            })
            .catch((error) => {
              setErrorStatus(true);
              console.log(error);
            });
        }
      })
      .catch((error) => {
        setErrorStatus(true);
        console.log(error);
      });
  };

  const deleteImage = () => {
    let deleteImgType = "";
    const brandingDatabody = {};
    if (openImgDel.type === "carouselImgId") {
      deleteImgType = "deleteComunityBannerImages";
      brandingDatabody[openImgDel.type] = [];
      communityImages.filter((x) => {
        if (x._id === openImgDel.data._id) {
          brandingDatabody[openImgDel.type] = x._id;
        }
      });
    } else if (openImgDel.type === "logo") {
      deleteImgType = "addEventBranding";
      const imgBody = {
        redirection: imageUrl,
        imageLink: "",
      };
      brandingDatabody[openImgDel.type] = imgBody;
    } else if (openImgDel.type === "loginBannerLink") {
      deleteImgType = "deleteLoginBannerImages";
      brandingDatabody[openImgDel.type] = [];
      loginImgs.filter((x) => {
        if (x === openImgDel.data) {
          brandingDatabody[openImgDel.type] = x;
        }
      });
    } else {
      deleteImgType = "deleteWelcomeVideo";
      // console.log("video link is", openImgDel.type);
      // console.log("x is",openImgDel.data);
    }
    axios
      .put(
        `${BOOTCAMP_API}/events/${deleteImgType}/${props.match.params.eventId}`,
        brandingDatabody
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setOpenImgDel({ ...openImgDel, show: false });
          addDataToRedux(response.data.result.data);
        }
      })
      .catch((error) => {
        setErrorStatus(true);
        console.log(error);
      });
  };

  const getEventData = () => {
    axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        addDataToRedux(response.data.result.data);
      })
      .catch((err) => console.log(err));
  };
  const addDataToRedux = (eventResponseData) => {
    dispatch({
      type: "EVENT_DATA",
      payload: eventResponseData,
    });
    setCommunityImages(eventResponseData.carouselImages);
    setLoginImgs(eventResponseData.loginBanners);
    setLogoImg(eventResponseData.logo);
    setWelcomeVideoLink(eventResponseData.welcomeVideo);
    // setVideoType({label:eventResponseData.welcomeVideo.vi ,value: eventResponseData.welcomeVideo})
  };
  const editWelcomeVideo = () => {
    setState({...state,addVideo:true});
    setVideoType({label:welcomeVideoLink.videoType,value:welcomeVideoLink.videoType});
    setVideoTitle(welcomeVideoLink.videoLink);
    setVideoDescription(welcomeVideoLink.videoDescription);
    setVideoLink(welcomeVideoLink.videoLink);
  }
  useEffect(() => {
    getEventData();
  }, []);

  return (
    <EventLayout>
      {messageData && (
        <Snackbar
          open={successMessage}
          onClose={() => setSuccessMessage(false)}
        >
          <>
            <Alert onClose={() => setSuccessMessage(false)} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar open={errorStatus} onClose={() => setErrorStatus(false)}>
          <>
            <Alert onClose={() => setErrorStatus(false)} severity="error">
              {errorMessage}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        <div className="w-100 event-overview">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <br />
              <h4 className="mb-0">Brand Your Event</h4>
              <p>
                You are able to configure the domain name, select your colour
                theme and upload your own community banners, login banner and
                event logo. This will help the attendee to connect with your
                brand and buy into your brand promise.
              </p>
            </div>
          </div>
          <div className="event-cardHld">
            <h4 className="mb-0">Event Domain</h4>
            <span>Know more in Event Branding.</span>
            <h4 className="mt-3">Event Landing Page</h4>
            <div className="d-flex justify-content-between align-items-center">
              <label className="event-label">
                Enabling this option would allow you to see the event landing
                page.
              </label>
              <Switch
                checked={streamOption}
                onChange={onStreamOptionhandleChange}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
            <br />
            <label htmlFor="title">Community Banners</label>
            <span className="inputInfo">1200x400px | 5MB (Maximum)</span>
            <p className="inputInfo">
              Maximum 50 allowed. Know more in Event Branding.
            </p>
            <div className="addSession">
              <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                onChange={(files) => setCommunityBanners(files)}
                maxFileSize={9242880}
                filesLimit={50}
              />
            </div>
            {communityImages.length !== 0 && (
              <div className="row">
                {communityImages.map((image, index) => (
                  <div
                    className="col-lg-4 col-md-4 col-6 my-2 eventCurvedBorder ImgHld"
                    key={index}
                  >
                    <img
                      src={image?.imageLink}
                      alt="banners"
                      className="img-fluid eventCurvedBorder"
                    />
                    <div className="Imagesoverlay mx-2 d-flex">
                      <span
                        onClick={() => openUrlDrawer(image, "carouselImages")}
                      >
                        <i
                          className="mdi mdi-link-variant brand-color"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </span>
                      <span
                        onClick={() =>
                          setOpenImgDel({
                            ...openImgDel,
                            show: true,
                            data: image,
                            type: "carouselImgId",
                          })
                        }
                      >
                        <i
                          className="mdi mdi-delete brand-color"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <br />
            <label htmlFor="title">Event Logo</label>
            <span className="inputInfo">
              Width or Height has to be at least 300px | 5MB (Maximum)
            </span>
            <p className="inputInfo">Know more in Event Branding.</p>
            <div className="addSession">
              <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                onChange={(files) => setLogo(files)}
                maxFileSize={9242880}
                filesLimit={1}
              />
            </div>
            {logoImg && logoImg?.imageLink !== "" && (
              <div className="BrandinglogoImgHld my-2 p-1 ImgHld">
                <img
                  src={logoImg?.imageLink}
                  alt="banners "
                  className="img-fluid eventCurvedBorder"
                />
                <div className="Imagesoverlay d-flex">
                  <span onClick={() => openUrlDrawer(logoImg, "logo")}>
                    <i
                      className="mdi mdi-link-variant brand-color"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </span>
                  <span
                    onClick={() =>
                      setOpenImgDel({
                        ...openImgDel,
                        show: true,
                        data: logoImg,
                        type: "logo",
                      })
                    }
                  >
                    <i
                      className="mdi mdi-delete brand-color"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </span>
                </div>
              </div>
            )}
            <br />
            <label htmlFor="title">Login Banners</label>
            <span className="inputInfo">1280x1080px | 5MB (Maximum)</span>
            <p className="inputInfo">
              To prevent the login banner from getting cropped across different
              devices, it is recommended to follow the banner design guideline.
              Learn More
            </p>
            <div className="addSession">
              <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                onChange={(files) => setLoginBanners(files)}
                maxFileSize={9242880}
              />
            </div>
            {loginImgs.length !== 0 && (
              <div className="row">
                {loginImgs.map((image, index) => (
                  <div
                    className="col-lg-4 col-md-4 col-6 my-2 eventCurvedBorder ImgHld"
                    key={index}
                  >
                    <img
                      src={image}
                      alt="banners"
                      className="img-fluid eventCurvedBorder"
                    />
                    <div className="Imagesoverlay mx-2 d-flex">
                      <span
                        onClick={() =>
                          setOpenImgDel({
                            ...openImgDel,
                            show: true,
                            data: image,
                            type: "loginBannerLink",
                          })
                        }
                      >
                        <i
                          className="mdi mdi-delete brand-color"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <br />

            <label htmlFor="title">Welcome Video</label>
            <p className="inputInfo mb-0">
              Greet your attendee with a welcome video. Learn More
            </p>
            <p className="inputInfo mb-0">
              If the youtube video that you provide contains copyrighted
              material, it might not play.
            </p>
            <h5>Video: Welcome to {eventData.title}</h5>
            {Object.keys(welcomeVideoLink || {})?.length === 0 && (
              <button
                className="event-primary-btn d-block mb-2"
                onClick={toggleDrawer("addVideo", true)}
              >
                Add Welcome Video
              </button>
            )}
            {/* <div className="addSession">
              <DropzoneArea
                acceptedFiles={[".mp4", ".mkv", ".avi"]}
                onChange={(files) => setWelcomeVideo(files)}
                maxFileSize={59242880}
                filesLimit={1}
              />
            </div> */}
            {Object.keys(welcomeVideoLink || {})?.length !== 0 && (
              <div className="ImgHld col-4">
                <video controls controlsList={"nodownload"}>
                  {/* <iframe
                    src={welcomeVideoLink.videoLink}
                    type="video/mp4"
                    className="eventCurvedBorder"
                  ></iframe> */}

                  <source
                    src={welcomeVideoLink.videoLink}
                    type="video/mp4"
                    className="eventCurvedBorder"
                  />
                </video>
                <div className="Imagesoverlay mx-2 d-flex">
                  <span
                    // onClick={() =>
                    //   openUrlDrawer(welcomeVideoLink, "welcomeVideo")
                    // }
                    onClick={editWelcomeVideo}
                  >
                    <i
                      className="mdi mdi-link-variant brand-color"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </span>
                  <span
                    onClick={() =>
                      setOpenImgDel({
                        ...openImgDel,
                        show: true,
                        data: welcomeVideoLink,
                        type: "welcomeVideo",
                      })
                    }
                  >
                    <i
                      className="mdi mdi-delete brand-color"
                      style={{ fontSize: "30px" }}
                    ></i>
                  </span>
                </div>
              </div>
            )}
            <br />
            <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />
            <div className="mr-auto d-flex justify-content-end">
              <button className="event-outlined-btn">CANCEL</button>
              {communityBanners.length > 0 ||
              logo.length > 0 ||
              loginBanners.length > 0 ||
              welcomeVideo.length > 0 ? (
                <button
                  className="event-primary-btn ml-2"
                  onClick={saveBrandingData}
                >
                  SAVE
                </button>
              ) : (
                <button className="event-primary-btn ml-2" disabled>
                  SAVE
                </button>
              )}
            </div>
          </div>
          <br />
        </div>
      </Row>
      <Drawer
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        {list("right")}
      </Drawer>
      <Drawer
        anchor="right"
        open={state["addVideo"]}
        onClose={toggleDrawer("addVideo", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Add Video</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("addVideo", false)}
                onKeyDown={toggleDrawer("addVideo", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <FormControl className={classes.formControl}>
              <label className="event-label">Video Type</label>
              <Select
                options={options}
                isSearchable={false}
                onChange={(value) => setVideoType(value)}
                value={videoType}
              />
            </FormControl>
            <br />
            <br />
            <FormControl className={classes.formControl}>
              <label htmlFor="title" className="event-label">
                Video Link
              </label>
              <input
                id="title"
                type="text"
                className="form-control"
                placeholder="Example: Fireside chat with Panellist Name on AI"
                value={videoLink}
                onChange={(e) => setVideoLink(e.target.value)}
              />
            </FormControl>
            <br />
            <br />
            <FormControl className={classes.formControl}>
              <label htmlFor="title" className="event-label">
                Video Title
                <span style={{ color: "red", verticalAlign: "" }}>*</span>
              </label>
              <input
                id="title"
                type="text"
                className="form-control"
                placeholder="Enter video title"
                value={videoTitle}
                onChange={(e) => setVideoTitle(e.target.value)}
              />
            </FormControl>
            <br />
            <br />
            <FormControl className={classes.formControl}>
              <label htmlFor="title" className="event-label">
                Video Description
              </label>
              <TextField
                id="outlined-multiline-static"
                multiline
                rows={5}
                variant="outlined"
                fullWidth={true}
                placeholder="Enter short video description"
                value={videoDescription}
                onChange={(e) => setVideoDescription(e.target.value)}
              />
            </FormControl>
            <br />
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={popupwhenLogin}
                  onChange={() => {
                    setpopupwhenLogin(!popupwhenLogin);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="PopUp After First Login"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={showOnReception}
                  onChange={() => {
                    setshowOnReception(!showOnReception);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Show On Reception"
            /> */}
          </Col>
          <div className="pt-3 px-3">
            {videoLink && videoTitle ? (
              <button className={classes.createBtn} onClick={addVideo}>
                ADD
              </button>
            ) : (
              <button
                className={classes.createBtn}
                disabled
                style={{
                  background: "rgba(0, 0, 0, 0.12)",
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  color: "rgba(0, 0, 0, 0.26)",
                }}
              >
                ADD
              </button>
            )}
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("addVideo", false)}
              onKeyDown={toggleDrawer("addVideo", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>
      <Dialog
        open={openImgDel.show}
        onClose={() => setOpenImgDel({ ...openImgDel, show: false })}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong>Image deleted once can't be retrieved.</strong>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete Image"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenImgDel({ ...openImgDel, show: false })}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deleteImage}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </EventLayout>
  );
}