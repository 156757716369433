import React from "react";
import { useHistory, useLocation } from "react-router";
const SessionSidebar = ({eventId,boothId,boothType}) => {
  let location = useLocation();
  const history = useHistory();
  var temp = location.pathname.split("/");
  var activeNav = temp[temp.length - 1];
  return (
    <div className="event-cardHld">
      <p
        className="sessionDetails mb-0"
        style={{
          color: `${activeNav === "basic-detail" ? "#f44d5e" : ""}`,
          background: `${activeNav === "basic-detail" ? "#fff0f0" : ""}`,
        }}
        onClick={()=>history.push(`/event/${eventId}/${boothType}/${boothId}/basic-detail`)}
      >
        BASIC DETAIL
      </p>
      <p
        className="sessionDetails mb-0"
        style={{
          color: `${activeNav === "team-members" ? "#f44d5e" : ""}`,
          background: `${activeNav === "team-members" ? "#fff0f0" : ""}`,
        }}
        onClick={()=>history.push(`/event/${eventId}/${boothType}/${boothId}/team-members`)}
      >
        TEAM MEMBERS 
      </p>
      {/* <p
        className="sessionDetails mb-0"
        style={{
          color: `${activeNav === "engagement" ? "#f44d5e" : ""}`,
          background: `${activeNav === "engagement" ? "#fff0f0" : ""}`,
        }}
        onClick={()=>history.push(`/event/${eventId}/sessions/${sessionId}/engagement`)}
      >
        ENGAGEMENT
      </p> */}
      <p
        className="sessionDetails mb-0"
        style={{
          color: `${activeNav === "product-images" ? "#f44d5e" : ""}`,
          background: `${activeNav === "product-images" ? "#fff0f0" : ""}`,
        }}
        onClick={()=>history.push(`/event/${eventId}/${boothType}/${boothId}/product-images`)}
      >
        PRODUCT IMAGES
      </p>
      <p
        className="sessionDetails mb-0"
        style={{
          color: `${activeNav === "product-videos" ? "#f44d5e" : ""}`,
          background: `${activeNav === "product-videos" ? "#fff0f0" : ""}`,
        }}
        onClick={()=>history.push(`/event/${eventId}/${boothType}/${boothId}/product-videos`)}
      >
        PRODUCT VIDEOS
      </p>
    </div>
  );
};
export default SessionSidebar;
