import React, { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { API } from "../../backend";
import { DropzoneArea } from "material-ui-dropzone";
import "./createblogathon.css";
import { FormControlLabel } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import Checkbox from "@material-ui/core/Checkbox";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    "&$checked": {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);
export default function CreateReviewer(props) {
  const otherModuleReferences = [
    {
      key: "href1",
      value: "",
    },
    {
      key: "href2",
      value: "",
    },
    {
      key: "href3",
      value: "",
    },
  ];

  const classes = useStyles();
  const history = useHistory();
  const [fullscreen, setFullsreen] = useState(false);
  const [messageData, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [twitter, setTwitter] = useState("");
  const [github, setGithub] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [onSubmit, setOnSubmit] = useState(false);
  const [slug, setSlug] = useState("");
  const [image, setSelectedFile] = useState([]);
  const [samples, setSamples] = useState([]);
  const [publish, setPublish] = useState(false);
  const [href, sethref] = useState("");
  const [hackathons, sethackathons] = useState([]);
  const inputEl = useRef(null);
  const token = JSON.parse(localStorage.getItem("jwt"));
  const [samplesList, setSamplesList] = useState("");
  let location = useLocation();
  var hackathonUrl = location.pathname.split("/")[1];
  const handleClose = () => {
    setStatus(!status);
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };
  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };

  const getReviewerdata = () => {
    axios
      .get(`${API}/hackathon/reviewer/${props.match.params.hackathonSlug}`)
      .then((response) => {
        console.log(response);
        setName(response.data.message.name);
        setDesignation(response.data.message.designation);
        setSelectedFile(response.data.message.profile_pic);
        setTwitter(response.data.message.twitter);
        setGithub(response.data.message.github);
        setLinkedIn(response.data.message.linkedin);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllHackathons = () => {
    axios
      .get(`${API}/competitions`)
      .then((response) => {
        sethackathons(response.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllHackathons();
    hackathonUrl.includes("update") && getReviewerdata();
  }, []);

  const CreateNewReviewer = () => {
    try {
      setOnSubmit(true);
      if (name === "" || designation === "" || image === "" || href === "") {
        setErrorStatus(true);
        setErrorMessage("Field Missing! All Feild Required !!");
      } else {
        let headers = {
          "Content-Type": "application/json",
          Authorization: token.token,
        };

        const formData = new FormData();
        formData.append("name", name);
        formData.append("designation", designation);
        formData.append("profile_pic", image[0]);
        twitter && formData.append("twitter", twitter);
        github && formData.append("github", github);
        linkedIn && formData.append("linkedin", linkedIn);

        axios
          .post(`${API}/hackathon/reviewer/create/${href}`, formData, headers)
          .then((response) => {
            if (response.data) {
              setStatus(true);
              setMessage("Successfully created reviewer");
              setStatusLoader(false);
              setTimeout(() => {
                history.push(`/reviewers`);
              }, 2500);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const UpdateCategory = () => {
    try {
      setOnSubmit(true);
      if (name === "" || designation === "" || image === "") {
        setErrorStatus(true);
        setErrorMessage("Field Missing! All Feild Required !!");
      } else {
        let headers = {
          "Content-Type": "application/json",
          Authorization: token.token,
        };

        const formData = new FormData();
        formData.append("name", name);
        formData.append("designation", designation);
        image.length > 0 && formData.append("profile_pic", image[0]);
        twitter && formData.append("twitter", twitter);
        github && formData.append("github", github);
        linkedIn && formData.append("linkedin", linkedIn);
        formData.append("publish", publish);
        axios
          .put(
            `${API}/hackathon/reviewer/update/${props.match.params.hackathonSlug}`,
            formData
          )
          .then((response) => {
            if (response.data) {
              setStatus(true);
              setMessage("Successfully updated Reviewer");
              setStatusLoader(false);
              setTimeout(() => {
                history.push(`/reviewers`);
              }, 2500);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Create Reviewer" />

      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {status && (
              <Snackbar
                open={status}
                autoHideDuration={15000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </Snackbar>
            )}

            {errorStatus && (
              <Snackbar
                open={errorStatus}
                autoHideDuration={15000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            )}

            {statusLoader && (
              <Snackbar open={statusLoader} onClose={handleLoader}>
                <Alert onClose={handleLoader} severity="info">
                  <Loader />
                </Alert>
              </Snackbar>
            )}

            <Paper className={classes.paper}>
              <h2>Name</h2>

              <TextField
                id="outlined-multiline-static"
                label="Title"
                multiline
                rows={3}
                variant="outlined"
                fullWidth={true}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Designation</h2>

              <TextField
                id="outlined-multiline-static"
                label="Short Description"
                multiline
                rows={2}
                variant="outlined"
                fullWidth={true}
                value={designation}
                onChange={(e) => {
                  setDesignation(e.target.value);
                }}
              />
              <br />
              <br />
              <h2>Profile Pic</h2>
              <DropzoneArea
                acceptedFiles={["image/jpeg", "image/png", "image/bmp"]}
                onChange={(files) => setSelectedFile(files)}
                maxFileSize={9242880}
              />
              <br />
              {hackathonUrl.includes("update") && (
                <img src={image} alt="" hight="100" width="150" />
              )}

              <br />
              <br />

              <h2>Twitter</h2>

              <TextField
                id="outlined-multiline-static"
                label="Slug"
                multiline
                rows={2}
                variant="outlined"
                fullWidth={true}
                value={twitter}
                onChange={(e) => {
                  setTwitter(e.target.value);
                }}
              />
              <br />
              <br />
              <h2>Github</h2>

              <TextField
                id="outlined-multiline-static"
                label="Slug"
                multiline
                rows={2}
                variant="outlined"
                fullWidth={true}
                value={github}
                onChange={(e) => {
                  setGithub(e.target.value);
                }}
              />
              <br />
              <br />

              <h2>LinkedIn</h2>

              <TextField
                id="outlined-multiline-static"
                label="Slug"
                multiline
                rows={2}
                variant="outlined"
                fullWidth={true}
                value={linkedIn}
                onChange={(e) => {
                  setLinkedIn(e.target.value);
                }}
              />
              <br />
              <br />
              {hackathonUrl.includes("update") && (
                <>
                  <h2>Publish</h2>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={publish}
                        onChange={() => {
                          setPublish(!publish);
                        }}
                        name="checkedG"
                      />
                    }
                    label="Publish"
                  />
                  <br />
                  <br />
                </>
              )}
              {!hackathonUrl.includes("update") && (
                <>
                  <h2>Select Hackathon</h2>
                  <select
                    className="form-control"
                    id="sel1"
                    onChange={(e) => {
                      sethref(e.target.value);
                    }}
                    value={href}
                  >
                    <option value="None">None</option>
                    {hackathons.length > 0
                      ? hackathons.map((hackathon, index) => (
                          <option value={hackathon.HREFName} key={index}>
                            {hackathon.HName}
                          </option>
                        ))
                      : ""}
                  </select>
                  <br />
                  <br />
                </>
              )}
              {hackathonUrl.includes("update") ? (
                <Button
                  disabled={onSubmit}
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                  onClick={() => UpdateCategory()}
                >
                  Update Reviewer
                </Button>
              ) : (
                <Button
                  disabled={onSubmit}
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                  onClick={() => CreateNewReviewer()}
                >
                  Create Reviewer
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
