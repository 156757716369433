import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { API, BOOTCAMP_API } from "../../../../backend";
import Loader from "../../../../components/Loader";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import EventLayout from "../../../../components/EventLayout";
import Drawer from "@material-ui/core/Drawer";
import { FormControl } from "@material-ui/core";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import List from "@ckeditor/ckeditor5-list/src/list";
import Link from "@ckeditor/ckeditor5-link/src/link";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import { useHistory, useLocation } from "react-router";
import Grid from "@material-ui/core/Grid";
import logoSm from "../../../../assets/images/logo-sm.png";
import { DropzoneArea } from "material-ui-dropzone";
import SessionSidebar from "./VirtualboothSidebar";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { isAuthenticated } from "../../../../auth/helper";

const moment = require("moment");
const useStyles = makeStyles({
  root: {
    minHeight: 206,
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardbody: {
    padding: "0 10px 10px 10px",
    borderRadius: "8px",
  },
  eventbanner: {
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    height: "100px",
    objectFit: "fill",
    maxWidth: "100%",
  },
  eventName: {
    fontWeight: 500,
    display: "-webkit-box",
    overflow: "hidden",
    "-mozBoxOrient": "vertical",
    "-webkitBoxOrient": "vertical",
    "-webkitLineClamp": 2,
  },
  moreOptions: {
    background: "#ffffff",
    position: "absolute",
    right: "10px",
    top: "6px",
    borderRadius: "50px",
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#f44d5e",
    fontSize: "20px",
  },
  optionMenu: {
    boxShadow: "none",
    border: "1px solid #dbdbdb",
    "& div": {
      boxShadow: "none",
      border: "1px solid #dbdbdb",
    },
  },
  optionMenuItem: {
    boxShadow: "none",
  },
  createEventDrawer: {
    width: "40%",
  },
  creatEventHeader: {
    backgroundColor: "#f6f6f6",
    padding: 16,
    alignItems: "center",
    display: "flex",
  },
  formControl: {
    width: "100%",
  },
  editor: {
    overflowX: "hidden",
  },
  spacingCreateEvent: {
    padding: "10px 30px 10px 30px",
    height: "calc(100vh - 142px)",
    overflow: "auto",
  },
  eventType: {
    width: "50%",
    textAlign: "center",
    color: "gray",
    marginBottom: 0,
    padding: "8px",
  },
  border: {
    border: "1px solid #dbdbdb",
    borderRadius: "8px",
  },
  selectedEventType: {
    background: "#f44d5e",
    color: "#ffffff",
    width: "50%",
    textAlign: "center",
    marginBottom: 0,
    padding: "8px",
  },
  textField: {
    marginRight: 10,
    border: "1px solid rgba(53, 53, 53, 0.17)",
    borderRadius: "4px",
    color: "#353535",
    width: "inherit",
    "& input::placeholder": {
      fontSize: "12px",
    },
  },
  cancelBtn: {
    border: "1px solid #f44d5e",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#ffffff",
    fontSize: "12px",
    color: "#f44d5e",
  },
  createBtn: {
    border: "transparent",
    width: "100px",
    height: "30px",
    borderRadius: "4px",
    fontWeight: "500",
    background: "#f44d5e",
    fontSize: "12px",
    color: "#ffffff",
    marginRight: "10px",
  },
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function VirtualBoothTeamMembers(props) {
  const eventData = useSelector((store) => store.events.event);
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);
  const [end, setEnd] = useState(false);
  const [messageData, setMessageData] = useState("");
  const [showColorPicker, setShowColorPicker] = React.useState(false);
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const eventId = props.match.params.eventId;
  const sessionId = props.match.params.sessionId;
  const boothId = props.match.params.boothId;
  const [drawer, setDrawer] = React.useState({
    team_member: false,
    sponsers: false,
    addSession: false,
  });
  const [errorMessage, setErrormesage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState(false);
  const [errorStatus, setErrorStatus] = React.useState(false);
  const [sessionDates, setSessionDates] = React.useState([]);
  const [datetabsValue, setdatetabsValue] = React.useState("");
  const [openPosterDel, setopenPosterDel] = React.useState({
    show: false,
    data: {},
    imagetype: "",
  });
  const [scroll, setScroll] = React.useState("paper");
  const [check, setCheck] = React.useState(false);
  // const [pageBannerImg, setpageBannerImg] = React.useState("");
  const [companyLogoImg, setCompanyLogoImg] = useState([]);
  const [comapanyTitle, setComapanyTitle] = useState("");
  const [contactUsEmail, setContactUsEmail] = useState("");
  // const [logo, setLogo] = useState([]);
  const [aboutUs, setAboutUs] = useState("");
  const [pageBanner, setPageBanner] = useState([]);
  const [smallBanner, setSmallBanner] = useState([]);
  const [spotLightBanner, setSpotLightBanner] = useState([]);
  const [sponseredProductLabel, setSponseredProductLabel] = useState("");
  const [files, setFiles] = useState([]);
  const [priority, setPriority] = useState(0);
  const [contactNumber, setContactNumber] = useState({
    countryCode: "",
    countryName: "",
    phoneNumber: "",
  });
  const [sponsershipCategoryList, setsponsershipCategoryList] = useState([]);
  const [sponsershipType, setSponsershipType] = useState({});
  const [address, setAddress] = useState("");
  const [isfeatured, setIsFeatured] = useState(false);
  const [isPublish, setIsPublish] = useState(false);
  const [showCategoryOptions, setShowCategoryOptions] = useState(false);
  const [createCategory, setCreateCategory] = useState(false);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [isEditCategory, setIsEditCategory] = React.useState("");
  const [boothData, setBoothData] = React.useState([]);
  const [boothSize, setBoothSize] = useState("large");
  const [currentSelectImage, setCurrentSelectImage] = useState("image");
  const [website, setWebsite] = React.useState("");
  const [linkedIn, setLinkedIn] = React.useState("");
  const [twitter, setTwitter] = React.useState("");
  const [fb, setfb] = React.useState("");
  const [insta, setInsta] = React.useState("");
  const [whatsApp, setWhatsApp] = React.useState("");

  const [tableData, setTableData] = useState([]);
  const [SelectedList, setSelectedList] = React.useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [MasterChecked, setMasterChecked] = React.useState(false);

  const [searchText, setSearchText] = React.useState("");
  const [allPeople, setAllPeople] = React.useState([]);
  const [isSearchDrop, setIsSearchDrop] = React.useState(false);
  const [selecteduserList, setSelecteduserList] = React.useState([]);
  // const [smallBannerIMg, setSmallBannerImg] = React.useState("");
  // const [fileImg, setfileImg] = React.useState("");
  let location = useLocation();
  var temp = location.pathname.split("/");
  var activeNav = temp[3];
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsSearchDrop(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer({ ...drawer, [anchor]: open });
  };

  const addVirtualBooth = async () => {
    const formData = new FormData();
    comapanyTitle && formData.append("comapanyTitle", comapanyTitle);
    contactUsEmail && formData.append("contactUsEmail", contactUsEmail);
    companyLogoImg.length !== 0
      ? formData.append("logo", companyLogoImg[0])
      : boothData?.companyId?.logo &&
        formData.append("logo", boothData?.companyId?.logo);
    website && formData.append("website", website);
    aboutUs && formData.append("aboutUs", aboutUs);
    formData.append("publish", isPublish);
    pageBanner.length !== 0
      ? formData.append("pageBanner", pageBanner[0])
      : boothData?.companyId?.pageBanner &&
        formData.append("pageBanner", boothData?.companyId?.pageBanner);
    smallBanner.length !== 0 ||
      (boothData?.companyId?.smallBanner &&
        formData.append(
          "smallBanner",
          smallBanner[0] || boothData?.companyId?.smallBanner
        ));
    spotLightBanner.length !== 0 ||
      (boothData?.companyId?.spotLightBanner &&
        formData.append(
          "spotLightBanner",
          spotLightBanner[0] || boothData?.companyId?.spotLightBanner
        ));
    boothSize && formData.append("boothSize", boothSize);
    sponseredProductLabel &&
      formData.append("sponseredProductLabel", sponseredProductLabel);
    formData.append("featured", isfeatured);
    files.length !== 0 ||
      (boothData?.companyId?.files &&
        formData.append("files", files[0] || boothData?.companyId?.files));
    priority && formData.append("priority", priority);
    contactNumber && formData.append("contactNumber", contactNumber);
    formData.append("eventId", props.match.params.eventId);
    sponsershipType &&
      formData.append(
        "sponsershipType",
        sponsershipType._id || sponsershipType.value
      );
    fb && formData.append("fb", fb);
    twitter && formData.append("twitter", twitter);
    linkedIn && formData.append("linkedIn", linkedIn);
    insta && formData.append("Instagram", insta);
    whatsApp && formData.append("whatsApp", whatsApp);
    address && formData.append("address", address);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    axios
      .put(
        `${BOOTCAMP_API}/events/updateCompanyExhibitors/${boothData.companyId._id}`,
        formData,
        config
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          console.log(response);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const deletePoster = () => {
    const paramData = {};
    paramData[openPosterDel.imagetype] = openPosterDel.data;
    axios
      .delete(
        `${BOOTCAMP_API}/events/deleteImageCompanyExhibitors/${boothData.companyId._id}/${eventId}`,
        { params: paramData }
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setBoothData((prev) => ({
            ...prev,
            [openPosterDel.imagetype]: "",
          }));
          setopenPosterDel({ ...openPosterDel, show: false });
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };
  const resetAllData = () => {};
  const getBoothData = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificCompanyExhibitors/${props.match.params.boothId}`
      )
      .then((response) => {
        setBoothData(response.data.result.data);
        addDataToInputField(response.data.result.data);
      })
      .catch((err) => console.log(err));
  };
  const addDataToInputField = (data) => {
    setComapanyTitle(data.companyId?.comapanyTitle);
    setContactNumber(data.companyId?.contactNumber?.phoneNumber);
    setSponsershipType({
      label: data.sponsershipType.title,
      value: data.sponsershipType._id,
    });
    setBoothSize(data.companyId.boothSize);
    setSponseredProductLabel(data.companyId.sponseredProductLabel);
    setAboutUs(data.companyId.aboutUs);
    setContactUsEmail(data.companyId.contactUsEmail);
    setAddress(data.companyId.address);
    setfb(data.companyId.fb);
    setInsta(data.companyId.Instagram);
    setLinkedIn(data.companyId.linkedIn);
    setWebsite(data.companyId.website);
    setWhatsApp(data.companyId.whatsApp);
    setIsPublish(data.companyId.publish);
    setIsFeatured(data.companyId.featured);
  };
  const getEventData = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/events/getSpecificEvent/${props.match.params.eventId}`
      )
      .then((response) => {
        dispatch({
          type: "EVENT_DATA",
          payload: response.data.result.data,
        });
        let eventResponse = response.data.result.data.dates;
        let sessionDates = [];
        eventResponse.filter((x, index) => {
          let data = {};
          data.value = x;
          data.label = `Day ${index + 1} - ${moment(x).format("Do MMM YYYY")}`;
          sessionDates.push(data);
        });
        setdatetabsValue(sessionDates[0].label);
        setSessionDates(sessionDates);
      })
      .catch((err) => console.log(err));
  };
  const deleteSelecteduser = (user) => {
    setSelecteduserList((prev) =>
      prev.filter((x) => {
        return x.Email !== user.Email;
      })
    );
  };
  const onSearchChange = (e) => {
    setIsSearchDrop(true);
    setSearchText(e.target.value);
    e.target.value.length > 3 && getAllRegisteredPeople(e.target.value);
  };
  const getAllRegisteredPeople = async (text) => {
    const paramData = {};
    if (isAuthenticated()) {
      paramData.user_id = isAuthenticated().user._id;
    }
    await axios
      .get(`${API}/events/people/search/${eventData.slug}/${text}`, {
        params: paramData,
      })
      .then((response) => {
        console.log(response);
        setAllPeople(response.data.data.booth_members);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const AddTeamMember = (user) => {
    const newMember = {
      eventId: props.match.params.eventId,
      userId: user.userId,
    };
    axios
      .put(
        `${BOOTCAMP_API}/events/addTeamMembersTocompanyExhibitors/${boothData.companyId._id}`,
        newMember
      )
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setDrawer({ ...drawer, team_member: false });
          getAllTableData();
          setIsSearchDrop(false);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  const getAllTableData = async () => {
    if (eventData._id) {
      const companyId = props.match.params.boothId;
      await axios
        .get(
          `${BOOTCAMP_API}/events/getAllTeamMembersCms/${eventData.slug}/${companyId}`
        )
        .then((response) => {
          setTableData(response.data.result.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const opendeletePeople = () => {
    setSelectedList(tableData.filter((e) => e.selected));
    setOpenDeleteDialog(true);
    // setOpenGroupDialog(false);
  };

  const onMasterCheck = (e) => {
    let tempList = tableData;
    // Check/ UnCheck All Items
    tempList.map((user) => (user.selected = e.target.checked));

    //Update State
    // setList(tempList);
    setTableData(tempList);
    setMasterChecked(e.target.checked);
    setSelectedList(tableData.filter((e) => e.selected));
  };

  // Update List Item's state and Master Checkbox State
  const onItemCheck = (e, item) => {
    let tempList = tableData;
    tempList.map((user) => {
      if (user._id === item._id) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = tableData.length;
    const totalCheckedItems = tableData.filter((e) => e.selected).length;

    // Update State
    // setList(tempList);
    setTableData(tempList);
    setMasterChecked(totalItems === totalCheckedItems);
    setSelectedList(tableData.filter((e) => e.selected));
  };

  const deletePeople = () => {
    const deleteUsersId = [];
    SelectedList.map((x) => {
      deleteUsersId.push(x._id);
    });

    const deletedAttendesIds = {
      registrationId: deleteUsersId,
    };
    axios
      .put(`${BOOTCAMP_API}/events/deleteTeamMembers`, deletedAttendesIds)
      .then((response) => {
        if (response.data.error) {
          setErrorStatus(true);
          setErrormesage(response.data.result.message);
        } else {
          setSuccessMessage(true);
          setMessageData(response.data.result.message);
          setOpenDeleteDialog(false);
          getAllTableData();
          setSelectedList([]);
        }
      })
      .catch((error) => {
        setSuccessMessage(false);
        setErrorStatus(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getEventData();
    getBoothData();
  }, []);

  useEffect(() => {
    getAllTableData();
  }, [eventData]);

  return (
    <EventLayout>
      {messageData && (
        <Snackbar
          open={successMessage}
          onClose={() => setSuccessMessage(false)}
        >
          <>
            <Alert onClose={() => setSuccessMessage(false)} severity="success">
              {messageData}
            </Alert>
          </>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar open={errorStatus} onClose={() => setErrorStatus(false)}>
          <>
            <Alert onClose={() => setErrorStatus(false)} severity="error">
              {errorMessage}
            </Alert>
          </>
        </Snackbar>
      )}
      <Row>
        {Object.keys(eventData).length > 0 ? (
          <div className="w-100 event-overview">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div>
                <br />
                <ul className="eventsBreadcrumb p-0 d-flex align-items-center">
                  <li
                    className="cursor-pointer"
                    onClick={() =>
                      history.push(
                        `/event/${props.match.params.eventId}/${activeNav}`
                      )
                    }
                  >
                    {activeNav === "virtual-booth" ? "Virtual Booths" : "Sponsors"}
                  </li>
                  <li style={{ color: "#353535" }}>{eventData.title}</li>
                </ul>
              </div>
            </div>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <SessionSidebar eventId={eventId} boothId={boothId} boothType={activeNav} />
              </Grid>
              <Grid item xs={8}>
                <div className="event-cardHld">
                  <h3>Team Members</h3>
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mr-2 mb-0">
                      Add and Manage team members to this virtual booth. They
                      will be showcased on the profile and people can meet and
                      chat with them at the event.
                    </p>
                    <button
                      className="event-primary-btn"
                      onClick={toggleDrawer("team_member", true)}
                    >
                      Add&nbsp;Team&nbsp;Member
                    </button>
                  </div>
                  {/* <hr style={{ borderColor: "#dbdbdb" }} className="my-3" />

                  <br /> */}

                  {tableData.length > 0 && (
                    <table className="table mt-4">
                      <thead>
                        {SelectedList.length === 0 && (
                          <tr>
                            <th scope="col">
                              <input
                                type="checkbox"
                                className=""
                                checked={MasterChecked}
                                id="mastercheck"
                                onChange={(e) => onMasterCheck(e)}
                              />
                            </th>
                            <th scope="col">NAME</th>
                            <th scope="col">DESIGNATION</th>
                            <th scope="col">EMAIL</th>
                          </tr>
                        )}
                        {SelectedList.length > 0 && (
                          <tr>
                            <th scope="col">
                              <input
                                type="checkbox"
                                className=""
                                checked={MasterChecked}
                                id="mastercheck"
                                onChange={(e) => onMasterCheck(e)}
                              />
                            </th>
                            <th scope="col">{SelectedList.length} Selected</th>
                            <th scope="col">
                              <div className="cursor-pointer">
                                <span className="mr-1">
                                  <i className="mdi mdi-delete"></i>
                                </span>
                                <span
                                  className="brand-color"
                                  onClick={opendeletePeople}
                                >
                                  Delete
                                </span>
                              </div>
                            </th>
                            <th></th>
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {tableData.map((user) => (
                          <tr
                            key={user._id}
                            className={user.selected ? "selected" : ""}
                          >
                            <th scope="row" className="align-middle">
                              <input
                                type="checkbox"
                                checked={user.selected}
                                className=""
                                id="rowcheck{user.id}"
                                onChange={(e) => onItemCheck(e, user)}
                              />
                            </th>
                            <td className="align-middle">
                              <div>
                                <img
                                  src={user.userId.Picture}
                                  className=""
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    borderRadius: "50%",
                                    marginRight: "20px",
                                  }}
                                />
                                {user.userId.Name || "-"}
                              </div>
                            </td>
                            <td className="align-middle">
                              <div className="d-flex flex-column">
                                <span>
                                  {user.userId.LatestDesignation || "-"}
                                </span>
                                <span>{user.userId.Organization || "-"}</span>
                              </div>
                            </td>
                            <td className="align-middle">
                              {user.userId.Email}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  <Dialog
                    open={openDeleteDialog}
                    onClose={() => setOpenDeleteDialog(false)}
                    scroll={scroll}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                  >
                    <DialogContent dividers={scroll === "paper"}>
                      <DialogContentText
                        id="scroll-dialog-description"
                        tabIndex={-1}
                      >
                        <strong>People deleted once can't be retrieved.</strong>
                        <br />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={check}
                              onChange={() => {
                                setCheck(!check);
                              }}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="Are you sure you want to delete this user"
                        />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() => setOpenDeleteDialog(false)}
                        style={{
                          backgroundColor: "rgb(250, 53, 88)",
                          color: "#fff",
                        }}
                      >
                        No
                      </Button>
                      {check ? (
                        <Button
                          onClick={deletePeople}
                          style={{
                            backgroundColor: "#019031",
                            color: "#fff",
                          }}
                        >
                          Yes
                        </Button>
                      ) : (
                        <Button
                          style={{
                            backgroundColor: "#dbdbdb",
                            color: "#fff",
                          }}
                        >
                          Yes
                        </Button>
                      )}
                    </DialogActions>
                  </Dialog>
                  {/* <div className=" mr-auto d-flex justify-content-end">
                    <button
                      className="event-primary-btn"
                      onClick={addVirtualBooth}
                    >
                      SAVE
                    </button>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Loader />
          </div>
        )}
      </Row>
      <Drawer
        anchor="right"
        open={drawer.team_member}
        onClose={toggleDrawer("team_member", false)}
        classes={{ paper: classes.createEventDrawer }}
      >
        <div role="presentation">
          <div className={classes.creatEventHeader}>
            <Col lg={9}>
              <h4>Add Team Member</h4>
            </Col>
            <Col lg={3} className="text-right" style={{ fontSize: "20px" }}>
              <i
                className="mdi mdi-close"
                onClick={toggleDrawer("team_member", false)}
                onKeyDown={toggleDrawer("team_member", false)}
              ></i>
            </Col>
          </div>
          <Col lg={12} className={classes.spacingCreateEvent}>
            <FormControl className={classes.formControl}>
              <input
                id="title"
                type="text"
                className="form-control mb-2"
                placeholder="Search"
                value={searchText}
                onChange={onSearchChange}
                onClick={() => setIsSearchDrop(true)}
              />
              {isSearchDrop && (
                <div className="peopleDropdown" ref={wrapperRef}>
                  {allPeople.map((user, index) => (
                    <div
                      className="d-flex align-items-center cursor-pointer px-1 py-1"
                      key={index}
                      onClick={() => AddTeamMember(user)}
                    >
                      <img
                        src={user?.Picture}
                        alt=""
                        className="img-fluid mr-2"
                        style={{ width: "40px", height: "40px" }}
                      />
                      <div className="">
                        <h5 className="my-0">{user?.Name}</h5>
                        <p className="mb-0">{user?.Email}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </FormControl>
            {selecteduserList.map((user, index) => (
              <div
                className="event-cardHld text-center d-flex align-items-center justify-content-between cursor-pointer mb-2"
                key={index}
              >
                <img
                  src={user?.Picture}
                  alt=""
                  className="img-fluid mr-2"
                  style={{ width: "40px", height: "40px" }}
                />
                <div className="text-left">
                  <h5 className="mt-0">{user?.Name}</h5>
                  <p className="mb-0">{user?.Email}</p>
                </div>
                <span
                  className="float-right ml-auto"
                  onClick={() => deleteSelecteduser(user)}
                >
                  <i
                    className="mdi mdi-delete brand-color"
                    style={{ fontSize: "30px" }}
                  ></i>
                </span>
              </div>
            ))}
            <br />
          </Col>
          <div className="pt-3 px-3">
            <button className={classes.createBtn} onClick={AddTeamMember}>
              ADD
            </button>
            <button
              className={classes.cancelBtn}
              onClick={toggleDrawer("team_member", false)}
              onKeyDown={toggleDrawer("team_member", false)}
            >
              CANCEL
            </button>
          </div>
        </div>
      </Drawer>

      <Dialog
        open={openPosterDel.show}
        onClose={() => setopenPosterDel({ ...openPosterDel, show: false })}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong>Image deleted once can't be retrieved.</strong>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete this Image"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setopenPosterDel({ ...openPosterDel, show: false })}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deletePoster}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </EventLayout>
  );
}
