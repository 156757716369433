import React, { useEffect, useState } from "react";
// import { Chart } from "react-charts";
import axios, { post } from "axios";
import { API } from "../backend";

// import { BarChart, LineChart } from "@jhonnold/react-chart.js";
import AuthLayout from "../components/AuthLayout";
import { Row, Col, Card, CardBody } from "reactstrap";
import Header from "./Header";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

export default function ChartBoard() {
	const [label, setLabel] = useState([
		"03/2018",
		"04/2018",
		"05/2018",
		"06/2018",
		"07/2018",
		"08/2018",
		"09/2018",
		"10/2018",
		"11/2018",
		"12/2018",
		"01/2019",
		"02/2019",
		"03/2019",
		"04/2019",
		"05/2019",
		"06/2019",
		"07/2019",
		"08/2019",
		"09/2019",
		"10/2019",
		"11/2019",
		"12/2019",
		"01/2020",
		"02/2020",
		"03/2020",
		"04/2020",
		"05/2020",
		"06/2020",
		"07/2020",
		"08/2020",
		"09/2020",
	]);
	const [dataChart, setData] = useState([
		30,
		11,
		70,
		34,
		46,
		37,
		28,
		20,
		22,
		56,
		90,
		173,
		256,
		102,
		73,
		266,
		185,
		146,
		44,
		58,
		229,
		96,
		82,
		54,
		55,
		99,
		187,
		159,
		102,
		94,
		62,
	]);

	const [userLevel, setUserLevel] = useState([
		"01/2018",
		"02/2018",
		"03/2018",
		"04/2018",
		"05/2018",
		"06/2018",
		"07/2018",
		"08/2018",
		"09/2018",
		"10/2018",
		"11/2018",
		"12/2018",
		"01/2019",
		"02/2019",
		"03/2019",
		"04/2019",
		"05/2019",
		"06/2019",
		"07/2019",
		"08/2019",
		"09/2019",
		"10/2019",
		"11/2019",
		"12/2019",
		"01/2020",
		"02/2020",
		"03/2020",
		"04/2020",
		"05/2020",
		"06/2020",
		"07/2020",
		"08/2020",
		"09/2020",
	]);
	const [labels,setLabels] = useState([]);
	const [values,setValues] = useState([]);
	const [labelsm,setLabelsm] = useState([]);
	const [valuesm,setValuesm] = useState([]);
	const [ratioLabel,setRatioLabel] = useState([]);
	const [ratioValue,setRatioValue] = useState([]);
	const [userDataCount, setuserdataCount] = useState([
		3,
		1,
		270,
		119,
		424,
		226,
		288,
		186,
		201,
		146,
		179,
		590,
		575,
		875,
		1624,
		688,
		393,
		1595,
		1010,
		851,
		468,
		475,
		1635,
		761,
		1014,
		570,
		635,
		854,
		981,
		893,
		1162,
		965,
		798,
	]);
	// Use any data object you want

	var dataStoreDic = {};
	const getDataGlobalRanking = () => {

		axios
			.get(`${API}/global/leaderboard`)
			.then((response) => {

				// dispatch({
				// 	type: "GET_ALL_GLOBALRANKING",
				// 	payload: response.data.global_lb,
				// });

				response.data.global_lb.forEach((user) => {
					var joindata = user.member_since.split("/");
					if (
						!dataStoreDic[
							`${joindata[1]}/${joindata[2].split(" ")[0]}`
						]
					) {
						dataStoreDic[
							`${joindata[1]}/${joindata[2].split(" ")[0]}`
						] = 1;
					} else {

						dataStoreDic[
							`${joindata[1]}/${joindata[2].split(" ")[0]}`
						] =
							dataStoreDic[
								`${joindata[1]}/${joindata[2].split(" ")[0]}`
							] + 1;
					}
				});

				setLabel(Object.keys(dataStoreDic));
				setData(Object.values(dataStoreDic));

				const ordered = {};
				Object.keys(dataStoreDic)
					.sort()
					.forEach(function (key) {
						ordered[key] = dataStoreDic[key];
					});
			})
			.catch((err) => console.log(err));

		// let str = "atul kumar srivastava";
		// let obj = {};
		// for (let s of str)
		// 	if (!obj[s]) obj[s] = 1;
		// 	else obj[s] = obj[s] + 1;
		// console.log("object", obj);
	}; 
	const getMonthWiseData = () => {
		axios
				.get(`${API}/globalmonthwise`)
				.then((response) => {
					setLabels(response.data.message?.label);
					setValues(response.data.message?.values);
				})
				.catch((error) => {
					// setStatus(true);
					console.log(error);
				});
	}
	const getMonthWiseUser = () => {
		axios
				.get(`${API}/monthwise`)
				.then((response) => {
					setLabelsm(response.data.message.label);
					setValuesm(response.data.message.values);
				})
				.catch((error) => {
					// setStatus(true);
					console.log(error);
				});
	}
	const getHackathonRatio = () => {
		axios
				.get(`${API}/ratio`)
				.then((response) => {
					setRatioLabel(response.data.message.label);
					setRatioValue(response.data.message.values);
				})
				.catch((error) => {
					// setStatus(true);
					console.log(error);
				});
	}
	const getDataAllUser = () => {

		axios
			// .get(`${API}/global/leaderboard`)
			.post(`${API}/registered/users/?per_page=21460&page=1`)
			.then((response) => {

				// dispatch({
				// 	type: "GET_ALL_GLOBALRANKING",
				// 	payload: response.data.global_lb,
				// });

				response.data.users.forEach((user) => {
					var joindata = user.created.split("/");
					if (
						!dataStoreDic[
							`${joindata[1]}/${joindata[2].split(" ")[0]}`
						]
					) {
						dataStoreDic[
							`${joindata[1]}/${joindata[2].split(" ")[0]}`
						] = 1;
					} else {
						dataStoreDic[
							`${joindata[1]}/${joindata[2].split(" ")[0]}`
						] =
							dataStoreDic[
								`${joindata[1]}/${joindata[2].split(" ")[0]}`
							] + 1;
					}
				});

				setLabel(Object.keys(dataStoreDic));
				setData(Object.values(dataStoreDic));

				const ordered = {};
				Object.keys(dataStoreDic)
					.sort()
					.forEach(function (key) {
						ordered[key] = dataStoreDic[key];
					});
			})
			.catch((err) => console.log(err));

		// let str = "atul kumar srivastava";
		// let obj = {};
		// for (let s of str)
		// 	if (!obj[s]) obj[s] = 1;
		// 	else obj[s] = obj[s] + 1;
		// console.log("object", obj);
	};

	// if (Object.keys(dataStoreDic).length === 0) {
	// 	getDataForChart();
	// }
	useEffect(() => {
		// getDataGlobalRanking();
		// getDataAllUser();
		getMonthWiseData();
		getMonthWiseUser();
		getHackathonRatio();
	}, []);

	// console.log(Object.values(dataStoreDic));
	// console.log(Object.keys(dataStoreDic));
	// console.log(Object.keys(dataStoreDic).length);
	return (
		
		<AuthLayout>
			<Header name="User Count" />

			<Row>
				<Col lg={6}>
					<Card className="shadow-sm p-3 mb-5 bg-white rounded">
						<Line
							data={{
								labels: label,
								datasets: [
									{
										label: "Global Ranking",
										borderColor: "rgb(255, 99, 132)",
										data: dataChart,
									},
								],
							}}
						/>
					</Card>
				</Col>
				<Col lg={6}>
					<Card className="shadow-sm p-3 mb-5 bg-white rounded">
						<Line
							data={{
								labels: userLevel,
								datasets: [
									{
										label: "User Registration",
										// backgroundColor: "rgb(255, 99, 132)",
										borderColor: "rgb(255, 99, 132)",
										data: userDataCount,
									},
								],
							}}
						/>
					</Card>
				</Col>
				<Col lg={6}>
				<Card className="shadow-sm p-3 mb-5 bg-white rounded">
				<Bar
							data={{
								labels: labels,
								datasets: [
									{
										label: ["Month Wise Global"],
										// backgroundColor: "rgb(255, 99, 132)",
										backgroundColor: "rgb(70,130,180)",
										data: values
									},
								],
							}}
						/>
				</Card>
				</Col>
				<Col lg={6}>
				<Card className="shadow-sm p-3 mb-5 bg-white rounded">
				<Bar
							data={{
								labels: labelsm,
								datasets: [
									{
										label: ["Month Wise"],
										// backgroundColor: "rgb(255, 99, 132)",
										backgroundColor: "rgb(70,130,180)",
										data: valuesm
									},
								],
							}}
						/>
						</Card>
				</Col>
				<Col lg={12}>
				<Card className="shadow-sm p-3 mb-5 bg-white rounded">
				<Bar
							data={{
								labels: ratioLabel,
								datasets: [
									{
										label: ["Ratio"],
										// backgroundColor: "rgb(255, 99, 132)",
										backgroundColor: "rgb(70,130,180)",
										data: ratioValue
									},
								],
							}}
						/>
						</Card>
				</Col>
			</Row>
			<br />
			<br />
			<br />
		</AuthLayout>
	);
}
