import React, { useEffect, useState } from "react";
import axios from "axios";
import { BOOTCAMP_API } from "../../backend";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useHistory, useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },
  formControl: {
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SubscriptionPlanInfoCreate(props) {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [priority, setPriority] = useState(0);
  const [messageData, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [description, setDescription] = useState("");
  const [image, setImage] = useState([]);
  const [publish, setPublish] = useState(false);
  const history = useHistory();
  let location = useLocation();
  var url = location.pathname.split("/");
  const handleClose = () => {
    setStatus(!status);
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };
  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };
  const CreateInfo = () => {
    if (title === "" || description === "" || priority === "" || image === "") {
      setErrorStatus(true);
      setErrorMessage("Field Missing! All Feild Required !!");
    } else {
      const requestData = {
        title: title,
        image: image,
        description: description,
        priority: priority,
      };
      setStatusLoader(true);
      axios
        .post(
          `${BOOTCAMP_API}/subscriptions/createSubscriptionInfo`,
          requestData
        )
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setStatus(true);
            setMessage(response.data.result.message);
            setStatusLoader(false);
            history.push("/subscriptions-info");
          } else {
            setErrorStatus(true);
            setErrorMessage(response.data.result.message);
            setStatusLoader(false);
          }
        })
        .catch((error) => {
          setErrorStatus(true);
          setErrorMessage(error);
          setStatusLoader(false);
          console.log(error);
        });
    }
  };
  const getInfo = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/subscriptions/getSpecificSubscriptionInfoCms/${props.match.params.infoId}`
      )
      .then((response) => {
        setTitle(response.data.result.data.title);
        setDescription(response.data.result.data.description);
        setPriority(response.data.result.data.priority);
        setImage(response.data.result.data.image);
        setPublish(response.data.result.data.publish)
      })
      .catch((err) => console.log(err));
  };
  const UpdateInfo = () => {
    if (title === "" || description === "" || priority === "" || image === "") {
      setErrorStatus(true);
      setErrorMessage("Field Missing! All Feild Required !!");
    } else {
      const requestData = {
        title: title,
        image: image,
        description: description,
        priority: priority,
        publish: publish
      };
      setStatusLoader(true);
      axios
        .put(
          `${BOOTCAMP_API}/subscriptions/updateSubscriptionInfo/${props.match.params.infoId}`,
          requestData
        )
        .then((response) => {
          if (response.status === 201 || response.status === 200) {
            setStatus(true);
            setMessage(response.data.result.message);
            setStatusLoader(false);
            history.push("/subscriptions-info");
          } else {
            setErrorStatus(true);
            setErrorMessage(response.data.result.message);
            setStatusLoader(false);
          }
        })
        .catch((error) => {
          setErrorStatus(true);
          setErrorMessage(error);
          setStatusLoader(false);
          console.log(error);
        });
    }
  };
  useEffect(() => {
    url.includes("update") && getInfo();
  }, []);

  return (
    <AuthLayout>
      <Header name={url.includes("update") ? "Update Subscription FAQ" : "Create Subscription FAQ"} />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {status && (
              <Snackbar
                open={status}
                autoHideDuration={15000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </Snackbar>
            )}
            {errorStatus && (
              <Snackbar
                open={errorStatus}
                autoHideDuration={15000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            )}
            {statusLoader && (
              <Snackbar open={statusLoader} onClose={handleLoader}>
                <Alert onClose={handleLoader} severity="info">
                  <Loader />
                </Alert>
              </Snackbar>
            )}

            <Paper className={classes.paper}>
              <h2>Title</h2>
              <TextField
                id="outlined-multiline-static"
                label="Title"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth={true}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Description</h2>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                minRows={4}
                variant="outlined"
                fullWidth={true}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />

              <br />
              <br />
              <h2>Image S3 URl</h2>
              <TextField
                id="standard-number"
                label="Image"
                type="text"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={image}
                onChange={(e) => {
                  setImage(e.target.value);
                }}
              />

              <br />
              <br />

              <h2>Priority</h2>

              <TextField
                id="standard-number"
                label="Priority"
                type="number"
                fullWidth={true}
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={priority}
                onChange={(e) => {
                  setPriority(e.target.value);
                }}
              />

              <br />
              <br />

              {url.includes("update") ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={UpdateInfo}
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                >
                  Save changes
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={CreateInfo}
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                >
                  Create Subscription Info
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
