import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import Button from "@material-ui/core/Button";
import { API, BOOTCAMP_API } from "../../backend";
import Switch from "@material-ui/core/Switch";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import EasyMDE from "easymde";
import Select from "react-select";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Paper from "@material-ui/core/Paper";
import { useHistory, useLocation } from "react-router";

import CKEditor from "@ckeditor/ckeditor5-react";
// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";

import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import { DropzoneArea } from "material-ui-dropzone";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));
const options = [
  {
    id: "1",
    label: "Beginner",
    value: "Beginner",
  },
  {
    id: "2",
    label: "Intermediate",
    value: "Intermediate",
  },
  {
    id: "3",
    label: "Advanced",
    value: "Advanced",
  },
];
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    zIndex: 999,
  }),
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const editorConfiguration = {
  plugins: [
    Essentials,
    Bold,
    Italic,
    Paragraph,
    Image,
    ImageUpload,
    Base64UploadAdapter,
    Heading,
    Indent,
    Table,
    TableToolbar,
    Link,
    List,
    MediaEmbed,
    CodeBlock,
    ImageResize,
    ImageToolbar,
    ImageStyle,
  ],
  toolbar: [
    "heading",
    "bold",
    "italic",
    "imageUpload",
    "link",
    "bulletedList",
    "numberedList",
    "indent",
    "outdent",
    "mediaEmbed",
    "insertTable",
    "codeBlock",
  ],
  mediaEmbed: {
    previewsInData: true
  },
  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },

  image: {
    resizeOptions: [
      {
        name: "imageResize:original",
        label: "Original",
        value: null,
      },
      {
        name: "imageResize:50",
        label: "50%",
        value: "50",
      },
      {
        name: "imageResize:75",
        label: "75%",
        value: "75",
      },
    ],
    toolbar: [
      "imageResize",
      "|",
      "imageStyle:full",
      "imageStyle:side",
      "|",
      "imageTextAlternative",
    ],
  },

  link: {
    addTargetToExternalLinks: true,
  },

  heading: {
    options: [
      {
        model: "paragraph",
        title: "Paragraph",
        class: "ck-heading_paragraph",
      },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
      {
        model: "heading5",
        view: "h5",
        title: "Heading 5",
        class: "ck-heading_heading5",
      },
      {
        model: "heading6",
        view: "h6",
        title: "Heading 6",
        class: "ck-heading_heading6",
      },
    ],
  },
  codeBlock: {
    languages: [
      // Do not render the CSS class for the plain text code blocks.
      { language: "plaintext", label: "Plain text", class: "" },

      // Use the "php-code" class for PHP code blocks.
      { language: "php", label: "PHP", class: "php-code" },

      // Use the "js" class for JavaScript code blocks.
      // Note that only the first ("js") class will determine the language of the block when loading data.
      {
        language: "javascript",
        label: "JavaScript",
        class: "js javascript js-code",
      },

      // Python code blocks will have the default "language-python" CSS class.
      { language: "python", label: "Python" },
    ],
  },
};
export default function CreateHackathonSolutions(props) {
  const [fullscreen, setFullsreen] = useState(false);
  const [listOfhackathons, setListOfHackathons] = React.useState([]);
  const [selectedHackathonhref, setSelectedHackathonhref] = useState({});
  const [shortDescription, setShortDescription] = useState("");
  const [LongDescription, setLongDescription] = useState("");
  const [noOfSolutions, setNoOfSolutions] = useState("");
  const [files, setfiles] = useState([
    {
      winnerNumber: 1,
      winnerIpynbFile: [],
      winnerHtmlFile: [],
    },
  ]);
  const [isToast, setIsToast] = React.useState({
    show: false,
    message: "",
    variant: "",
  });
  const [publish, setPublish] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const url = location.pathname.split("/");

  function handleFileChange(event, index, target) {
      if(target === "winnerNumber" ? event !== "" : event.length !== 0){
        let data = [...files];
        data[index][target] = event;
        setfiles(data);
      }
    
  }

  const addFields = () => {
    let object = {
      winnerNumber: files.length + 1,
      winnerIpynbFile: [],
      winnerHtmlFile: [],
    };
    setfiles([...files, object]);
  };

  const removeFields = (index) => {
    let data = [...files];
    data.splice(index, 1);
    setfiles(data);
  };

  const CreateSolution = () => {
    try {
      if (
        shortDescription === "" ||
        LongDescription === "" ||
        selectedHackathonhref === "" ||
        noOfSolutions === "" ||
        files.length !== 3
      ) {
        setIsToast({
          show: true,
          message: "Field Missing! All Feild Required !!",
          variant: "error",
        });
      } else {
        const formData = new FormData();
        formData.append("hackathonHref", selectedHackathonhref.value);
        formData.append("shortDescription", shortDescription);
        formData.append("longDescription", LongDescription);
        formData.append("numberOfSolutions", noOfSolutions);
        files.map((file, index) => {
          formData.append(`solutions.${index}.winnerNumber`, file.winnerNumber);
          formData.append(
            `solutions.${index}.winnerIpynbFile`,
            file.winnerIpynbFile[0]
          );
          formData.append(
            `solutions.${index}.winnerHtmlFile`,
            file.winnerHtmlFile[0]
          );
        });
        axios
          .post(`${BOOTCAMP_API}/hackathons/createHackathonSolution`, formData)
          .then((response) => {
            setIsToast({
              show: true,
              message: "Successfully created question",
              variant: "success",
            });
            setTimeout(() => {
              history.push(`/hackathon-solutions`);
            }, 2500);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const UpdateSolution = () => {
    try {
        if (
          shortDescription === "" ||
          LongDescription === "" ||
          selectedHackathonhref === "" ||
          noOfSolutions === "" ||
          files.length !== parseInt(noOfSolutions)
        ) {
          setIsToast({
            show: true,
            message: "Field Missing! All Feild Required !!",
            variant: "error",
          });
        } else {
          const formData = new FormData();
          formData.append("hackathonHref", selectedHackathonhref.value);
          formData.append("shortDescription", shortDescription);
          formData.append("longDescription", LongDescription);
          formData.append("numberOfSolutions", noOfSolutions);
          formData.append("publish", publish);
          files.map((file, index) => {
            formData.append(`solutions.${index}.winnerNumber`, file.winnerNumber);
            formData.append(
              `solutions.${index}.winnerIpynbFile`,
              typeof file.winnerIpynbFile !== "string" ?  file.winnerIpynbFile[0] : file.winnerIpynbFile
            );
            formData.append(
              `solutions.${index}.winnerHtmlFile`,
              typeof file.winnerIpynbFile !== "string" ? file.winnerHtmlFile[0] : file.winnerHtmlFile
            );
          });
          axios
            .put(`${BOOTCAMP_API}/hackathons/updateHackathonSolution/${props.match.params.hackathonhref}`, formData)
            .then((response) => {
              setIsToast({
                show: true,
                message: "Successfully created question",
                variant: "success",
              });
              setTimeout(() => {
                history.push(`/hackathon-solutions`);
              }, 2500);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } catch (err) {
        console.log(err);
      }
  };
  const getSolution = async () => {
    await axios
      .get(
        `${BOOTCAMP_API}/hackathons/getSpecificHackathonSolutionCms/${props.match.params.hackathonhref}`
      )
      .then((response) => {
        setSelectedHackathonhref({label:response.data.result.data.hackathonHref,value:response.data.result.data.hackathonHref});
        setLongDescription(response.data.result.data.longDescription);
        setShortDescription(response.data.result.data.shortDescription);
        setPublish(response.data.result.data.publish);
        setNoOfSolutions(response.data.result.data.solutions.length);
        setfiles(response.data.result.data.solutions);
      })
      .catch((err) => console.log(err));
  };
  const getAllHackathons = () => {
    axios
      .get(`${API}/competitions`)
      .then((response) => {
        const hackathons = response.data.filter((x) => {
          x.label = x.HName;
          x.value = x.HREFName;
          return x;
        });
        setListOfHackathons(hackathons);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getAllHackathons();
    url.includes("update") && getSolution();
  }, []);
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header
        name={url.includes("update") ? "Update Solution" : "Create Solution"}
      />
      <Snackbar
        open={isToast.show}
        onClose={() => setIsToast({ ...isToast, show: false })}
      >
        <>
          <Alert
            onClose={() => setIsToast({ ...isToast, show: false })}
            severity={isToast.variant}
          >
            {isToast.message}
          </Alert>
        </>
      </Snackbar>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
            <FormControl className={classes.formControl}>
                <label className="event-label">Select Hackathon</label>
                <Select
                  styles={customStyles}
                  options={listOfhackathons}
                  isSearchable={true}
                  onChange={(value) => setSelectedHackathonhref(value)}
                  value={selectedHackathonhref}
                />
              </FormControl>
              <br />
              <br />
              <h2>Short Description</h2>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={shortDescription}
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setShortDescription(data);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              <br />
              <br />
              <h2>Long Description</h2>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                data={LongDescription}
                onInit={(editor) => {
                  // You can store the "editor" and use when it is needed.
                  console.log("Editor is ready to use!", editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setLongDescription(data);
                }}
                onBlur={(event, editor) => {
                  console.log("Blur.", editor);
                }}
                onFocus={(event, editor) => {
                  console.log("Focus.", editor);
                }}
              />
              <br />
              <br />
              <h2>No of Solutions</h2>

              <TextField
                id="outlined-multiline-static"
                label="No of Solutions"
                type="number"
                multiline
                minRows={2}
                variant="outlined"
                fullWidth={true}
                value={noOfSolutions}
                onChange={(e) => {
                    setNoOfSolutions(e.target.value);
                }}
              />
              <h2>Winner</h2>
              {files.map((file, index) => {
                return (
                  <div key={index} className="row align-items-center">
                    <div className="col-12">
                    <h2>Winner Number</h2>
                    <TextField
                      className=""
                      id="outlined-multiline-static"
                      label="Winner Number"
                      multiline
                      minRows={2}
                      variant="outlined"
                      fullWidth={true}
                      value={file.winnerNumber}
                      onChange={(e) => {
                        handleFileChange(e.target.value, index, "winnerNumber");
                      }}
                    />
                    </div>
                    <div className="col-5">
                    <h2>Ipynb File</h2>
                    <DropzoneArea
                      acceptedFiles={[".ipynb",".zip"]}
                      onChange={(files) =>
                        handleFileChange(files, index, "winnerIpynbFile")
                      }
                      maxFileSize={9242880}
                      maxFiles={1}
                    />
                    </div>
                    <div className="col-5">
                    <h2>Html File</h2>
                    <DropzoneArea
                      acceptedFiles={[".html"]}
                      onChange={(files) =>
                        handleFileChange(files, index, "winnerHtmlFile")
                      }
                      maxFileSize={9242880}
                      maxFiles={1}
                      className="col-6"
                    />
                    </div>
                    <div
                      onClick={() => removeFields(index)}
                      className="addSpeakers mt-4 py-0"
                    >
                      <span class="mdi mdi-close-circle-outline"></span>
                    </div>
                  </div>
                );
              })}
              <br />
              <div onClick={addFields} className="addSpeakers">
                <i className="mdi mdi-plus-circle-outline"></i>
              </div>
              <br />
              <br />
              {url.includes("update") && (
                <>
                  <span>Publish</span>
                  <Switch
                    checked={publish}
                    onChange={() => setPublish(!publish)}
                    name="publish"
                    inputProps={{
                      "aria-label": "secondary checkbox",
                    }}
                  />
                  <br />
                  <br />
                </>
              )}
              {url.includes("update") ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                  onClick={UpdateSolution}
                >
                  Update Solution
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    paddingLeft: "50px",
                    paddingRight: "50px",
                    paddingTop: "15px",
                    paddingBottom: "15px",
                    backgroundColor: "rgb(250, 53, 88)",
                  }}
                  onClick={CreateSolution}
                >
                  Create Solution
                </Button>
              )}
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
