import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";

export default function Header(props) {
	return (
		<Row>
			<Col lg={12}>
				<div className="page-title-box">
					<div className="page-title-right">
						<ol className="breadcrumb m-0">
							<li className="breadcrumb-item">
								<a href="/">Admin</a>
							</li>
							<li className="breadcrumb-item active">
								{props.name}
							</li>
						</ol>
					</div>
					<h4 className="page-title">{props.name}</h4>
				</div>
			</Col>
		</Row>
	);
}
