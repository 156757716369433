import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col, Card, CardBody } from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useHistory } from "react-router-dom";
import QuestionSetSerice from "../../services/mocks/QuestionSetService";
import axios from "axios";
import { API, BOOTCAMP_API, NOTIFICATION_SERVICE } from "../../backend";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
const moment = require("moment");

function AlertComponent(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AllNotifications(props) {
  //   const notifications = useSelector(
  //     (store) => store.notifications.notifications
  //   );
  const dispatch = useDispatch();
  const otherModuleReferences = [
    {
      key: "href1",
      value: "",
    },
    {
      key: "href2",
      value: "",
    },
    {
      key: "href3",
      value: "",
    },
  ];

  const classes = useStyles();
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const [fullscreen, setFullsreen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [qType, setQType] = useState("");
  const [duration, setDuration] = useState(0);
  const [onSubmit, setOnSubmit] = useState(false);
  const [shortDescription, setShortDescription] = useState("");
  const [hrefs, setHrefs] = useState(otherModuleReferences);
  const [badgesNotAssigned, setBadgesNotAssigned] = useState([]);
  const [assessmentCategory, setAssessmentCategory] = useState("");
  const [badge, setBadge] = useState("");
  const [majorBadgeId, setMajorBadgeId] = useState("");
  const [minorBadgeId, setMinorBadgeId] = useState("");
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [slug, setSlug] = useState("");
  const [openTerms, setOpenTerms] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [check, setCheck] = React.useState(false);
  const [notificationDeleted, setNotificationDeleted] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  const token = JSON.parse(localStorage.getItem("jwt"));

  let MDE;
  const handleClose = () => {
    setStatus(!status);
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };
  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };

  const getAllNotifications = () => {
    axios
      .get(`${NOTIFICATION_SERVICE}/notification/general/get`)
      .then((response) => {
        setIsLoading(false);
        setNotifications(response.data);
        dispatch({
          type: "GET_ALL_NOTIFICATIONS",
          payload: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const handleCloseTerms = () => {
    setOpenTerms(!openTerms);
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  const handleClickOpen = (notification) => {
    setNotificationDeleted(notification);
    setOpenTerms(true);
  };

  const handleCloseMsg = () => {
    setStatus(!status);
  };
  const formatDate = (dateTime) => {
    var string = dateTime;

    var temp = string.split(" ");
    var strdate = temp[0].split("/");
    var strTime = temp[1].split(":");

    var year = strdate[2];
    var month = strdate[1];
    var day = strdate[0];

    var hour = strTime[0];
    var minute = strTime[1];
    var seconds = strTime[2];

    var differenceFromNow = moment({
      year: Number(year),
      month: Number(month) - 1,
      day: Number(day),
      hour: Number(hour),
      minutes: Number(minute),
      seconds: Number(seconds),
    }).format("MMMM Do, YYYY  h:mma");

    return differenceFromNow;
  };

  const deleteNotification = () => {
    axios
      .delete(
        `${NOTIFICATION_SERVICE}notification/delete?type=general&notification_id=${notificationDeleted._id}`,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          setStatus(true);
          setNotifications(
            notifications.filter((x) => {
              if (x._id === notificationDeleted._id) {
                return x._id !== notificationDeleted._id;
              }
              return notifications;
            })
          );
          setCheck(false);
          setMessage("Deleted Notification Successfully");
          setOpenTerms(false);
          setNotificationDeleted({});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      {status && (
        <Snackbar
          open={status}
          autoHideDuration={15000}
          onClose={handleCloseMsg}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <AlertComponent onClose={handleCloseMsg} severity="success">
            {message}
          </AlertComponent>
        </Snackbar>
      )}
      <Header name="All Notifications" />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <Loader />
              </div>
            ) : (
              <>
                {Object.keys(notifications).length > 0 ? (
                  notifications?.map((notification) => 
                    <Card key={notification._id}>
                      <Row>
                        <Col lg={9}>
                          <CardBody>
                            <h4>{notification.title}</h4> <br />
                            <span>
                              <b>Description: </b> {notification.message}
                            </span>
                            <br />
                            <span>
                              <b>Seen count: </b>{" "}
                              {notification.is_seen
                                ? notification.is_seen.length
                                : 0}
                            </span>
                            <br />
                            <span>
                              <b>Notification Type: </b>{" "}
                              {notification.notification_type}
                            </span>
                            <br />
                            <span>
                              <b>Slug: </b>{" "}
                              <a href={notification.slug} target="_blank">
                                {notification.slug}
                              </a>
                            </span>
                            <br />
                            <span>
                              <b>Created At: </b>{" "}
                              {formatDate(notification.created)}
                            </span>
                            <br />
                          </CardBody>
                        </Col>
                        <Col
                          lg={3}
                          style={{ textAlign: "right" }}
                          className="pr-4"
                        >
                          <CardBody
                            style={{
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                          >
                            <Button
                              onClick={() => {
                                handleClickOpen(notification);
                              }}
                              style={{
                                backgroundColor: "rgb(250, 53, 88)",
                                color: "#fff",
                                fontSize: "10px",
                              }}
                            >
                              Delete
                            </Button>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  )) : "No Notifications"}
              </>
            )}
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={openTerms}
        onClose={handleCloseTerms}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <p>
              <strong>Notification deleted once can't be retrieved.</strong>
            </p>

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete Notification"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseTerms}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deleteNotification}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </AuthLayout>
  );
}
