import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import NotificationDropdown from "./NotificationDropdown";
import ProfileDropdown from "./ProfileDropdown";
import logoSm from "../assets/images/logo-sm.png";
import logo from "../assets/images/1.png";
import profilePic from "../assets/images/users/user-1.jpg";
import { EVENT_URL } from "../backend";

const EventTopbar = (props) => {
  const eventData = useSelector((store) => store.events.event);
  return (
    <React.Fragment>
      <div className="navbar-custom">
        <ul className="list-unstyled topnav-menu event-topmenu mb-0 d-flex justify-content-between align-items-center h-100 pl-2">
          <li className="d-none d-sm-block">
            <img
              src={eventData?.logo?.imageLink}
              alt="event-logo"
              className="img-fluid"
              style={{ width: "50px" }}
              onClick={() =>
                window.open(
                  `${eventData?.logo?.redirection}`,
                  "_blank"
                )
              }
            />
            <span className="ml-2">{eventData?.title}</span>
          </li>

          <li className="dropdown notification-list float-right">
            <button
              className="goToEventBtn"
              onClick={() =>
                window.open(
                  `${EVENT_URL}/events/${eventData?.slug}/home`,
                  "_blank"
                )
              }
            >
              Go to Event
            </button>
          </li>
        </ul>
        {/* <div className="logo-box">
						<Link to="/" className="logo text-center">
							<span className="logo-lg">
								<img src={logo} alt="" height="45" />
							</span>
							<span className="logo-sm">
								<img src={logoSm} alt="" height="24" />
							</span>
						</Link>
					</div> */}
      </div>
    </React.Fragment>
  );
};

export default connect()(EventTopbar);
