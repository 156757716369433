import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useHistory } from "react-router-dom";
import EasyMDE from "easymde";
import QuestionSetSerice from "../../services/mocks/QuestionSetService";
import axios from "axios";
import { API, BOOTCAMP_API, NOTIFICATION_SERVICE } from "../../backend";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateNotification(props) {
  const otherModuleReferences = [
    {
      key: "href1",
      value: "",
    },
    {
      key: "href2",
      value: "",
    },
    {
      key: "href3",
      value: "",
    },
  ];

  const classes = useStyles();
  const history = useHistory();
  const [fullscreen, setFullsreen] = useState(false);
  const [messageData, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [qType, setQType] = useState("");
  const [duration, setDuration] = useState(0);
  const [onSubmit, setOnSubmit] = useState(false);
  const [shortDescription, setShortDescription] = useState("");
  const [hrefs, setHrefs] = useState(otherModuleReferences);
  const [badgesNotAssigned, setBadgesNotAssigned] = useState([]);
  const [assessmentCategory, setAssessmentCategory] = useState("");
  const [badge, setBadge] = useState("");
  const [majorBadgeId, setMajorBadgeId] = useState("");
  const [minorBadgeId, setMinorBadgeId] = useState("");
  const [messageText, setMessageText] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [slug, setSlug] = useState("");

  const token = JSON.parse(localStorage.getItem("jwt"));

  let MDE;
  const handleClose = () => {
    setStatus(!status);
  };

  const handleCloseError = () => {
    setErrorStatus(!errorStatus);
  };
  const handleLoader = () => {
    setStatusLoader(!statusLoader);
  };

  useEffect(() => {}, []);

  const CreateNewNotification = () => {
    try {
      setOnSubmit(true);

      if (
        title === "" ||
        messageText === "" ||
        notificationType === "" ||
        slug === ""
      ) {
        setErrorStatus(true);
        setErrorMessage("Field Missing! All Feild Required !!");
      } else {
        let headers = {
          "Content-Type": "application/json",
          Authorization: token.token,
        };

        let notificationData = {};

        notificationData = {
          notification_type: notificationType,
          message: messageText,
          slug: slug,
          title: title,
          postedby: token.user._id,
        };

        axios
          .post(
            `${NOTIFICATION_SERVICE}notification/general/create?title=${title}&message=${messageText}&notification_type=${notificationType}&slug=${slug}&postedby=${token.user._id}`,
            null,
            headers
          )
          .then((response) => {
            if (response.data) {
              setStatus(true);
              setMessage("Successfully created notification");
              setStatusLoader(false);
              setTimeout(() => {
                history.push(`/notifications`);
              }, 2500);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const CreateQuestionSet = async () => {
    try {
      setOnSubmit(true);
      if (
        title === "" ||
        shortDescription === "" ||
        parseInt(duration) === 0 ||
        difficulty === ""
      ) {
        setErrorStatus(true);
        setErrorMessage("Field Missing! All Feild Required !!");
      } else {
        try {
          let questionSet = {};

          if (assessmentCategory === "ml_skills") {
            questionSet = {
              title: title,
              description: description,
              short_description: shortDescription,
              duration: duration, //in seconds
              difficulty: difficulty,
              questionSetType: qType,
              // referenced_hrefs: hrefs.map((href) => href.value),
              category: props.match.params.assessmentCategoryId,
              subCategory: props.match.params.subCategoryId,
              minorBadge: minorBadgeId,
            };
          } else if (assessmentCategory === "with_sub_cat") {
            questionSet = {
              title: title,
              description: description,
              short_description: shortDescription,
              duration: duration, //in seconds
              difficulty: difficulty,
              questionSetType: qType,
              category: props.match.params.assessmentCategoryId,
              subCategory: props.match.params.subCategoryId,
              // referenced_hrefs: hrefs.map((href) => href.value)
            };
          } else {
            questionSet = {
              title: title,
              description: description,
              short_description: shortDescription,
              duration: duration, //in seconds
              difficulty: difficulty,
              questionSetType: qType,
              category: props.match.params.assessmentCategoryId,
              // referenced_hrefs: hrefs.map((href) => href.value)
            };
          }

          const response = await QuestionSetSerice.CreateQuestionSet(
            questionSet
          );
          if (response) {
            setStatus(true);
            setMessage("Successfully created Question Set!");
            setStatusLoader(false);
            setTimeout(() => {
              if (assessmentCategory === "without_sub_cat")
                history.push(
                  `/getAllQuestionSetWithoutSubCategory/${props.match.params.assessmentCategoryId}`
                );
              else
                history.push(
                  `/getAllQuestionSetNew/${props.match.params.assessmentCategoryId}/${props.match.params.subCategoryId}`
                );
            }, 2500);
          } else {
            setErrorStatus(true);
            setErrorMessage("Exception in creating new Question Set!");
            setStatusLoader(false);
          }
        } catch (error) {
          setErrorStatus(true);
          setErrorMessage(error);
          setStatusLoader(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Create Notification" />

      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {status && (
              <Snackbar
                open={status}
                autoHideDuration={15000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success">
                  {messageData}
                </Alert>
              </Snackbar>
            )}

            {errorStatus && (
              <Snackbar
                open={errorStatus}
                autoHideDuration={15000}
                onClose={handleCloseError}
              >
                <Alert onClose={handleCloseError} severity="error">
                  {errorMessage}
                </Alert>
              </Snackbar>
            )}

            {statusLoader && (
              <Snackbar
                open={statusLoader}
                // autoHideDuration={95000}
                onClose={handleLoader}
              >
                <Alert onClose={handleLoader} severity="info">
                  <Loader />
                </Alert>
              </Snackbar>
            )}

            <Paper className={classes.paper}>
              <h2>Title</h2>

              <TextField
                id="outlined-multiline-static"
                label="Title"
                multiline
                rows={3}
                variant="outlined"
                fullWidth={true}
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />

              {/* <br />
                            <br />
                            <h2>Description</h2>

                            <textarea id="body" name="body" value={MDE?.value()} /> */}

              <br />
              <br />

              <h2>Message</h2>

              <TextField
                id="outlined-multiline-static"
                label="Short Description"
                multiline
                rows={2}
                variant="outlined"
                fullWidth={true}
                value={messageText}
                onChange={(e) => {
                  setMessageText(e.target.value);
                }}
              />
              <br />
              <br />

              <h2>Slug</h2>

              <TextField
                id="outlined-multiline-static"
                label="Slug"
                multiline
                rows={2}
                variant="outlined"
                fullWidth={true}
                value={slug}
                onChange={(e) => {
                  setSlug(e.target.value);
                }}
              />
              <br />
              <br />

              <h2>Notification Type</h2>

              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Notification Type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={notificationType}
                  onChange={(e) => {
                    setNotificationType(e.target.value);
                  }}
                  label="Difficulty"
                >
                  <MenuItem value="Hackathon">Hackathon</MenuItem>
                  <MenuItem value="Practice">Practice</MenuItem>
                  <MenuItem value="Bootcamp">Bootcamp</MenuItem>
                  <MenuItem value="Assessment">Assessment</MenuItem>
                  <MenuItem value="Job">Job</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>

              <br />
              <br />

              <Button
                disabled={onSubmit}
                variant="contained"
                color="primary"
                size="large"
                style={{
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  paddingTop: "15px",
                  paddingBottom: "15px",
                  backgroundColor: "rgb(250, 53, 88)",
                }}
                onClick={() => CreateNewNotification()}
              >
                Create Notifcation
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </div>
    </AuthLayout>
  );
}
