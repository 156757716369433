import React, { Component, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Redirect, Link } from "react-router-dom";
// import { useDispatch } from "react-redux";

import {
	Container,
	Row,
	Col,
	Card,
	CardBody,
	Label,
	FormGroup,
	Button,
	Alert,
} from "reactstrap";
import {
	AvForm,
	AvField,
	AvGroup,
	AvInput,
	AvFeedback,
} from "availity-reactstrap-validation";

import { loginUser } from "../../redux/actions";
import { isUserAuthenticated } from "../../helpers/authUtils";
import Loader from "../../components/Loader";
import logo from "../../assets/images/1.png";
import { getUserInfo } from "../../user/helper/userapicall";
import setAuthToken from "../../auth/helper/setToken";

import {
	signup,
	signin,
	authenticate,
	isAuthenticated,
} from "../../auth/helper";

import { store } from "../../store/store";

export default function Login(props) {
	const dispatch = useDispatch();
	const [loginEmail, setLoginEmail] = useState("");
	const [loginPassword, setLoginPassword] = useState("");
	const [error, setError] = useState("");
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [didRedirect, setDidRedirect] = useState(false);
	const [cardAnimaton, setCardAnimation] = useState("cardHidden");

	setTimeout(function () {
		setCardAnimation("");
	}, 700);

	const handleLoginEmail = (e) => {
		setError("");
		setMessage("");
		setLoginEmail(e.target.value);
	};

	const handleLoginPassword = (e) => {
		// console.log(e.target.value);
		setError("");
		setMessage("");
		setLoginPassword(e.target.value);
	};

	const performRedirect = () => {
		if (isAuthenticated() || didRedirect) {
			// console.log("yooooo here");
			setAuthToken(isAuthenticated().token);
			let userId = isAuthenticated().user._id;

			getUserInfo(userId)
				.then((response) => {
					// console.log("user", response.data.user);
					store.dispatch({
						type: "GET_USER_INFO",
						payload: response.data.user,
					});
				})
				.catch((err) => console.log(err));

			return <Redirect to="/" />;
		}
	};

	const handleLoginSubmit = (e) => {
		e.preventDefault();
		setError("");
		setMessage("");
		setLoading(true);
		if (!loginEmail || !loginPassword) {
			return setError("Field Required");
		} else {
			const newUser = {
				email: loginEmail,
				password: loginPassword,
			};
			// console.log(newUser);
			signin(newUser)
				.then((response) => {
					// console.log("yooooo", response.data);
					if (response.data.error) {
						setLoading(false);
						setError(response.data.error);
					} else {
						authenticate(response.data, () => {
							console.log("res user", response.data.user);
							setDidRedirect(true);
							dispatch({
								type: "SET_CURRENT_USER",
								payload: response.data.user,
								token: response.data.token,
							});
							// history.push("/");
							// performRedirect();
						});
					}
				})
				.catch((err) => console.log(err));
		}
	};

	// _isMounted = false;

	// constructor(props) {
	// 	super(props);

	// 	this.handleValidSubmit = this.handleValidSubmit.bind(this);
	// 	 = {
	// 		loginEmail: "test",
	// 		password: "test",
	// 	};
	// }

	// componentDidMount() {
	// 	this._isMounted = true;
	// 	document.body.classList.add("authentication-bg");
	// }

	// componentWillUnmount() {
	// 	this._isMounted = false;
	// 	document.body.classList.remove("authentication-bg");
	// }

	/**
	 * Handles the submit
	 */
	// handleValidSubmit = (event, values) => {
	// 	loginUser(
	// 		values.loginEmail,
	// 		values.password,
	// 		history
	// 	);
	// };

	/**
	 * Redirect to root
	 */
	// renderRedirectToRoot = () => {
	// 	const isAuthTokenValid = isUserAuthenticated();
	// 	if (isAuthTokenValid) {
	// 		return <Redirect to="/" />;
	// 	}
	// };

	// render() {
	// const isAuthTokenValid = isUserAuthenticated();
	return (
		<React.Fragment>
			{/* {this.renderRedirectToRoot()} */}
			{/* {(this._isMounted || !isAuthTokenValid) && ( */}
			<React.Fragment>
				<div className="home-btn d-none d-sm-block">
					<Link to="/">
						<i className="fas fa-home h2 text-dark"></i>
					</Link>
				</div>

				<div className="account-pages mt-5 mb-5">
					<Container>
						<Row className="justify-content-center">
							<Col md={8} lg={6} xl={5}>
								<div className="text-center">
									<Link to="/">
										<span>
											<img
												src={logo}
												alt=""
												height="64"
											/>
										</span>
									</Link>
									<p className="text-muted mt-2 mb-4">
										{/* Responsive Admin Dashboard */}
									</p>
								</div>
								<Card>
									<CardBody className="p-4 position-relative">
										{/* preloader */}
										{/* {loading && <Loader />} */}

										<div className="text-center mb-4">
											<h4 className="text-uppercase mt-0">
												Sign In
											</h4>
										</div>

										{error && (
											<Alert
												color="danger"
												isOpen={error ? true : false}
											>
												<div>{error}</div>
											</Alert>
										)}

										<AvForm>
											<AvField
												name="username"
												label="Username"
												placeholder="Enter your username"
												value={loginEmail}
												onChange={handleLoginEmail}
												required
											/>

											<AvGroup className="mb-3">
												<Label for="password">
													Password
												</Label>
												<AvInput
													type="password"
													name="password"
													id="password"
													placeholder="Enter your password"
													value={loginPassword}
													onChange={
														handleLoginPassword
													}
													required
												/>
												<AvFeedback>
													This field is invalid
												</AvFeedback>
											</AvGroup>

											<FormGroup>
												<Button
													color="primary"
													className="btn-block"
													onClick={handleLoginSubmit}
												>
													Log In
												</Button>
											</FormGroup>

											{/* <p>
												<strong>Username:</strong> test
												&nbsp;&nbsp;{" "}
												<strong>Password:</strong> test
											</p> */}
										</AvForm>
									</CardBody>
								</Card>
							</Col>
						</Row>

						<Row className="mt-1">
							<Col className="col-12 text-center">
								<p>
									<Link
										to="/forget-password"
										className="text-muted ml-1"
									>
										<i className="fa fa-lock mr-1"></i>
										Forgot your password?
									</Link>
								</p>
								{/* <p className="text-muted">
									Don't have an account?{" "}
									<Link
										to="/register"
										className="text-dark ml-1"
									>
										<b>Register</b>
									</Link>
								</p> */}
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
			// )
			{performRedirect()}
		</React.Fragment>
	);
	// }
}

// const mapStateToProps = (state) => {
// 	const { user, loading, error } = state.Auth;
// 	return { user, loading, error };
// };

// export default connect(mapStateToProps, { loginUser })(Login);
