import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import axios from "axios";
import { BOOTCAMP_API } from "../../backend";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    minHeight: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },
  control: {
    padding: theme.spacing(2),
  },
  image: {
    minHeight: 150,
    objectFit: "cover",
  },
}));
const stores = [
  {
    id: "1",
    name: "Redeem",
    desc: "Redeem your earned points here",
    viewlink: "/redeem-coins",
    createLink: "/redeem-coins/create",
  },
  {
    id: "2",
    name: "Earn MH Coins",
    desc: "Earn MH coins by your daily MH activities",
    viewlink: "/coins",
    createLink: "/coins/create",
  },
  {
    id: "3",
    name: "Premium",
    desc: "Earn MH coins by your daily MH activities",
    viewlink: "/subscriptions",
    createLink: "/subscriptions/create",
  },
  {
    id: "4",
    name: "View Orders",
    desc: "List of your and their details",
    viewlink: "/subscriptions",
    createLink: "/subscriptions/create",
  },
];
function SubscriptionPlanFaq() {
  const [fullscreen, setFullsreen] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  const getAllSubscriptionFAQ = async () => {
    await axios
      .get(`${BOOTCAMP_API}/subscriptions/getAllSubscriptionFaqCms`)
      .then((response) => {
        setFaqs(response.data.result.data);
      })
      .catch((err) => console.log(err));
  };

  const onPublishChange = async (data, publishStatus) => {
    const requestData = {
      publish: publishStatus,
    };
    await axios
      .put(
        `${BOOTCAMP_API}/subscriptions/updateSubscriptionFaq/${data._id}`,
        requestData
      )
      .then((response) => {
        setFaqs(
          faqs.filter((x) => {
            if (x._id === data._id) {
              x.publish = response.data.result.data.publish;
            }
            return faqs;
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const deleteFAQ = async (data) => {
    await axios
      .delete(
        `${BOOTCAMP_API}/subscriptions/deleteSpecificSubscriptionFaqCms/${data._id}`
      )
      .then((response) => {
        setFaqs(
          faqs.filter((x) => {
            if (x._id === data._id) {
              return x._id !== data._id;
            }
            return faqs;
          })
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllSubscriptionFAQ();
  }, []);
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Subscription FAQ" />{console.log(faqs)}
      <Grid container className="mt-4">
        <Grid container spacing={6}>
          {faqs.map((data) => (
            <Grid key={data._id} item xs={4} className="">
              <Paper className={classes.paper + " " + "p-2"}>
                <p>Question : {data.question}</p>
                <p>Answer : {data.answer}</p>
                <p>Priority : {data.priority}</p>
                <div className="">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mr-1"
                    onClick={() => history.push(`/subscriptions-faq/update/${data._id}`)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="mr-1"
                    onClick={() => deleteFAQ(data)}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => onPublishChange(data, data.publish ? false : true)}
                  >
                    {data.publish ? "Unpublish" : "Publish"}
                  </Button>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

export default SubscriptionPlanFaq;
