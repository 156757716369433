import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../../components/AuthLayout";
import Loader from "../../../components/Loader";
import Header from "../../Header";
import { useHistory } from "react-router-dom";
import EasyMDE from 'easymde';
// import sectionSerice from '../../../services/mocks/sectionService';
import axios, { post } from "axios";
import { BOOTCAMP_API } from "../../../backend";

// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import CKEditor from "@ckeditor/ckeditor5-react";

// NOTE: Use the editor from source (not a build)!
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";

import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials"; //THIS PLUGIN DOES NOT WORK
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import Base64UploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import SubscriptEditing from "@ckeditor/ckeditor5-basic-styles/src/subscript/subscriptediting";
import SubscriptUI from "@ckeditor/ckeditor5-basic-styles/src/subscript/subscriptui";
import SuperScriptEditing from "@ckeditor/ckeditor5-basic-styles/src/superscript/superscriptediting";
import SuperScriptUI from "@ckeditor/ckeditor5-basic-styles/src/superscript/superscriptui";
import AlignmentEditing from "@ckeditor/ckeditor5-alignment/src/alignmentediting";
import AlignmentUI from "@ckeditor/ckeditor5-alignment/src/alignmentui";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";


const editorConfiguration = {
    plugins: [
        Essentials,
        Bold,
        Italic,
        Paragraph,
        Image,
        ImageUpload,
        Base64UploadAdapter,
        Heading,
        Indent,
        Table,
        TableToolbar,
        Link,
        List,
        MediaEmbed,
        CodeBlock,
        ImageResize,
        ImageToolbar,
        ImageStyle,
        SubscriptEditing,
        SubscriptUI,
        SuperScriptEditing,
        SuperScriptUI,
        AlignmentEditing,
        AlignmentUI
    ],
    toolbar: [
        "heading",
        "bold",
        "italic",
        "imageUpload",
        "link",
        "bulletedList",
        "numberedList",
        "alignment",
        "indent",
        "outdent",
        "mediaEmbed",
        "insertTable",
        "codeBlock",
        "subscript",
        "superscript"
    ],
    mediaEmbed: {
        previewsInData: true
    },
    image: {
        resizeOptions: [
            {
                name: "imageResize:original",
                label: "Original",
                value: null,
            },
            {
                name: "imageResize:50",
                label: "50%",
                value: "50",
            },
            {
                name: "imageResize:75",
                label: "75%",
                value: "75",
            },
        ],
        toolbar: [
            "imageResize",
            "|",
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "imageTextAlternative",
        ],
    },
    table: {
        contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },

    image: {
        resizeOptions: [
            {
                name: "imageResize:original",
                label: "Original",
                value: null,
            },
            {
                name: "imageResize:50",
                label: "50%",
                value: "50",
            },
            {
                name: "imageResize:75",
                label: "75%",
                value: "75",
            },
        ],
        toolbar: [
            "imageResize",
            "|",
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "imageTextAlternative",
        ],
    },

    link: {
        decorators: {
            openInNewTab: {
                mode: "manual",
                label: "Open in a new tab",
                attributes: {
                    target: "_blank",
                    rel: "noopener noreferrer",
                },
            },
        },
    },

    heading: {
        options: [
            {
                model: "paragraph",
                title: "Paragraph",
                class: "ck-heading_paragraph",
            },
            {
                model: "heading1",
                view: "h1",
                title: "Heading 1",
                class: "ck-heading_heading1",
            },
            {
                model: "heading2",
                view: "h2",
                title: "Heading 2",
                class: "ck-heading_heading2",
            },
            {
                model: "heading3",
                view: "h3",
                title: "Heading 3",
                class: "ck-heading_heading3",
            },
            {
                model: "heading4",
                view: "h4",
                title: "Heading 4",
                class: "ck-heading_heading4",
            },
            {
                model: "heading5",
                view: "h5",
                title: "Heading 5",
                class: "ck-heading_heading5",
            },
            {
                model: "heading6",
                view: "h6",
                title: "Heading 6",
                class: "ck-heading_heading6",
            },
        ],
    },
    codeBlock: {
        languages: [
            // Do not render the CSS class for the plain text code blocks.
            { language: "plaintext", label: "Plain text", class: "" },

            // Use the "php-code" class for PHP code blocks.
            { language: "php", label: "PHP", class: "php-code" },

            // Use the "js" class for JavaScript code blocks.
            // Note that only the first ("js") class will determine the language of the block when loading data.
            {
                language: "javascript",
                label: "JavaScript",
                class: "js javascript js-code",
            },

            // Python code blocks will have the default "language-python" CSS class.
            { language: "python", label: "Python" },
        ],
    },
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        // textAlign: "center",
        color: theme.palette.text.secondary,
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 400,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 420,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UpdateTopic(props) {

    const bootcamps = useSelector((store) => store.bootcamps.bootcamps);
    const dispatch = useDispatch();

    const otherModuleReferences = [
        {
            key: 'href1',
            value: ''
        },
        {
            key: 'href2',
            value: ''
        },
        {
            key: 'href3',
            value: ''
        },
    ];

    const classes = useStyles();
    const history = useHistory();
    const [fullscreen, setFullsreen] = useState(false);
    const [messageData, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [difficulty, setDifficulty] = useState("");
    const [qType, setQType] = useState("");
    const [duration, setDuration] = useState(0);
    const [onSubmit, setOnSubmit] = useState(false);
    const [readingContent, setReadingContent] = useState('');
    const [hrefs, setHrefs] = useState(otherModuleReferences);
    const [order, setOrder] = useState('');
    const [type, setType] = useState('');
    const [videoURL, setVideoURL] = useState('');
    const [notebookURL, setNotebookURL] = useState('');


    let MDE;
    const handleClose = () => {
        setStatus(!status);
    };

    const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };
    const handleLoader = () => {
        setStatusLoader(!statusLoader);
    };

    const token = JSON.parse(localStorage.getItem('jwt'));

    const getTopicData = async () => {
        console.log(props)
        const config = {
            headers: {
                "Content-Type": "application/json",
                'Authorization': token.token
            },
        };
        try {
            await axios
                .get(`${BOOTCAMP_API}/bootcamp/getBootCampTopic/${props.match.params.topicID}`, config)
                .then((response) => {

                    const updateTopic = response.data.result.data
                    // console.log(response)
                    setReadingContent(updateTopic.reading_content)
                    setVideoURL(updateTopic.video_url)
                    setNotebookURL(updateTopic.Notebook_url)
                    setTitle(updateTopic.title)
                    setDescription(updateTopic.description)
                    setOrder(updateTopic.topic_order)
                    setType(updateTopic.type)
                    setDuration(updateTopic.duration)
                    MDE.value(updateTopic.description)
                    console.log(response.data.result.data)
                }
                )
                .catch((err) => console.log(err));
        } catch (error) {
            alert("Something went wrong")
        }
    }
    useEffect(() => {
        getTopicData()
        MDE = new EasyMDE({
            element: document.getElementById('body'),
            autoDownloadFontAwesome: true,
            autofocus: true,
            status: false,
            singleLineBreaks: false,
            onToggleFullScreen: (opend) => setFullsreen(opend)
        });

        MDE.codemirror.on('change', () => {
            setDescription(MDE.value());
        });
    }, [MDE]);

    const UpdateTopic = async () => {
        try {
            setOnSubmit(true);
            if (order === "" ) {
                setErrorStatus(true);
                setErrorMessage("Field Missing! All Feild Required !!");
            } else {
                    const section = {
                        title: title,
                        description: description,
                        topic_order: order,
                        type: type,
                        duration: duration, //in seconds,
                        video_url: videoURL ? videoURL : "",
                        Notebook_url: notebookURL ? notebookURL : "",
                        reading_content: readingContent ? readingContent : "",
                        bootcampCourseId: props.match.params.bootcampID,
                        bootCampSectionId: props.match.params.moduleObjectID,
                        timeStamp: new Date()
                    };
                    console.log('DATA TO UPDATE:', section)
                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': token.token
                        },
                    };
                    axios
                        .put(`${BOOTCAMP_API}/bootcamp/updateBootCampTopic/${props.match.params.topicID}`, section, config)
                        .then((response) => {
                            console.log(response)
                            setStatus(true);
                            setMessage('Successfully created Section');
                            setStatusLoader(false);
                            console.log('all props', props.match,'undefined 1', props.match.params.moduleID, 'undefined 2', props.match.params.moduleObjectID)
                            history.push(`/topics/${props.match.params.bootcampID}/${props.match.params.sectionName}/${props.match.params.moduleObjectID}`);
                        })
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <AuthLayout isMDEFullScreen={fullscreen && fullscreen === true ? true : false}>

            <Header name="Update Topic" />
            Section: {props.match.params.sectionName}
            <br/>
            <br/>
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        {status && (
                            <Snackbar
                                open={status}
                                autoHideDuration={15000}
                                onClose={handleClose}
                            >
                                <Alert onClose={handleClose} severity="success">
                                    {messageData}
                                </Alert>
                            </Snackbar>
                        )}


                        {errorStatus && (
                            <Snackbar
                                open={errorStatus}
                                autoHideDuration={15000}
                                onClose={handleCloseError}
                            >
                                <Alert
                                    onClose={handleCloseError}
                                    severity="error"
                                >
                                    {errorMessage}
                                </Alert>
                            </Snackbar>
                        )}


                        {statusLoader && (
                            <Snackbar
                                open={statusLoader}
                                // autoHideDuration={95000}
                                onClose={handleLoader}
                            >
                                <Alert onClose={handleLoader} severity="info">
                                    <Loader />
                                </Alert>
                            </Snackbar>
                        )}


                        <Paper className={classes.paper}>
                            <h2>Title</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Title"
                                multiline
                                rows={1}
                                variant="outlined"
                                fullWidth={true}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />
                            <br />
                            <br />

                            <h2>Duration</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Seconds"
                                type='number'
                                rows={1}
                                variant="outlined"
                                fullWidth={true}
                                value={duration}
                                onChange={(e) => {
                                    setDuration(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <h2>Order</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Topic Order"
                                type='number'
                                rows={1}
                                variant="outlined"
                                fullWidth={true}
                                value={order}
                                onChange={(e) => {
                                    setOrder(e.target.value);
                                }}
                            />

                            <br/>
                            <br/>

                            <h2>Description</h2>

                            <textarea id="body" name="body" value={MDE?.value()} />

                            <br />
                            <br />

                            <h2>Type</h2>

                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <InputLabel id="demo-simple-select-outlined-label">
                                    Type
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={type}
                                    onChange={(e) => {
                                        setType(e.target.value);
                                    }}
                                    label="slug"
                                >
                                    <MenuItem value="video">
										Video
									</MenuItem>
									<MenuItem value="notebook">
										Notebook
									</MenuItem>
									<MenuItem value="reading">
										Reading
									</MenuItem>

                                    {/* <MenuItem value="Intermediate">
									Intermediate
								</MenuItem> */}
                                    {/* <MenuItem value="Practice">Advanced</MenuItem> */}
                                    {/* <MenuItem value={30}>Thirty</MenuItem> */}
                                </Select>
                            </FormControl>

                            <br />
                            <br />

                            {
                                type == 'video' ?
                                    <><h2>Video Link</h2>

                                    <TextField
                                        id="outlined-multiline-static"
                                        label="URL"
                                        multiline
                                        rows={1}
                                        variant="outlined"
                                        fullWidth={true}
                                        value={videoURL}
                                        onChange={(e) => {
                                            setVideoURL(e.target.value);
                                        }}
                                    /></>
                                    : type == 'reading' ?
                                        <><h2>Reading Content</h2>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={editorConfiguration}
                                                data={readingContent}
                                                onInit={(editor) => {
                                                    // You can store the "editor" and use when it is needed.
                                                    console.log(
                                                        "Editor is ready!",
                                                        editor
                                                    );
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setReadingContent(data);
                                                    console.log("data composed");
                                                    console.log({ event, editor, data });
                                                }}
                                                onBlur={(event, editor) => {
                                                    console.log("Blur.", editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    console.log("Focus.", editor);
                                                }}
                                            />
                                        </>

                                        : type == 'notebook' ?
                                            <><h2>Notebook Link</h2>

                                            <TextField
                                                id="outlined-multiline-static"
                                                label="URL"
                                                multiline
                                                rows={1}
                                                variant="outlined"
                                                fullWidth={true}
                                                value={notebookURL}
                                                onChange={(e) => {
                                                    setNotebookURL(e.target.value);
                                                }}
                                            /></> : ''
                            }
                            <br/>
                            <br/>
                            <Button disabled={onSubmit}
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                    backgroundColor: "rgb(250, 53, 88)",
                                }}
                                onClick={() => UpdateTopic()}
                            >
                                Update Topic
							</Button>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        </AuthLayout>
    );
}

