import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import axios from "axios";
import { BOOTCAMP_API } from "../../backend";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    minHeight: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },
  control: {
    padding: theme.spacing(2),
  },
  image: {
    minHeight: 150,
    objectFit: "cover",
  },
}));
function RedeemCoins() {
  const [fullscreen, setFullsreen] = useState(false);
  const [redeemCoins, setRedeemCoins] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  const getAllRedeemCoins = async () => {
    await axios
      .get(`${BOOTCAMP_API}/mhCoins/getAllMhCoinProductsDataCms`)
      .then((response) => {
        setRedeemCoins(response.data.result.data);
      })
      .catch((err) => console.log(err));
  };

  const deleteProduct = async (data) => {
    await axios
      .delete(
        `${BOOTCAMP_API}/mhCoins/deleteSpecificMhCoinProductCms/${data._id}`
      )
      .then((response) => {
        setRedeemCoins(
          redeemCoins.filter((x) => {
            if (x._id === data._id) {
              return x._id !== data._id;
            }
            return redeemCoins;
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const onPublishChange = async (data, publishStatus) => {
    const requestData = {
      publish: publishStatus,
    };
    await axios
      .put(
        `${BOOTCAMP_API}/mhCoins/updateMhCoinProduct/${data._id}`,
        requestData
      )
      .then((response) => {
        setRedeemCoins(
          redeemCoins.filter((x) => {
            if (x._id === data._id) {
              x.publish = response.data.result.data.publish;
            }
            return redeemCoins;
          })
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllRedeemCoins();
  }, []);
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Redeem" />
      <Grid container className="mt-4">
        <Grid container spacing={6}>
          {redeemCoins.map((data) => (
            <Grid key={data._id} item xs={4} className="">
              <Paper className={classes.paper + " " + "p-2"}>
                <img
                  src={data.productImage}
                  alt=""
                  className={classes.image + " " + "img-fluid"}
                />
                <h4>{data.productName}</h4>
                {data.description && <p>{data.description}</p>}
                <p>Coins: {data.coins}</p>
                <p>Priority: {data.priority}</p>
                <p>Category: {data.productCategory}</p>
                <p>Deliverable:{`${data.deliverable}`}</p>
                {data.productSize && <p>Size: {data.productSize}</p>}
                {data.apperalSize.length > 0 && <p>Apperal Size: {data.apperalSize.map((item,index) => <span className="mr-2" key={index}>{item}</span>)}</p>}
                {data?.subscriptionPlanId && <p>Plan: {data?.subscriptionPlanId.name} </p>}
                <div className="">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mr-1"
                    onClick={() => history.push(`/redeem-coins/update/${data._id}`)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="mr-1"
                    onClick={() => deleteProduct(data)}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => onPublishChange(data, data.publish ? false : true)}
                  >
                     {data.publish ? "Unpublish" : "Publish"}
                  </Button>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

export default RedeemCoins;
