import { API } from "../../backend";
import axios from "axios";

//console.log(API);
//signup
export const signup = (user) => {
	return axios
		.post(`${API}/register`, user)
		.then((response) => {
			// console.log(response);
			return response;
		})
		.catch((err) => console.log(err));
};
//for login need to call /cms/login api after the hackathon fineshes
export const signin = (user) => {
	return axios
		.post(`${API}/cms/login`, user)
		.then((response) => {
			console.log(response);
			return response;
		})
		.catch((err) => console.log(err));
};

//authenticate or save json web toke to local storage
export const authenticate = (data, next) => {
	if (typeof window !== "undefined") {
		localStorage.setItem("jwt", JSON.stringify(data));
		localStorage.setItem("loginTime", Date.now());
		next();
	}
};

//signout of local storage and remove token
export const signout = () => {
	if (typeof window !== "undefined") {
		localStorage.removeItem("jwt");

		return axios
			.get(`${API}/logout/access`)
			.then((response) => {
				return response;
			})
			.catch((err) => {
				console.log(err);
			});
	}
};

export const isAuthenticated = () => {
	if (typeof window === "undefined") {
		return false;
	}

	if (localStorage.getItem("jwt")) {
		// let jwt = JSON.parse(localStorage.getItem("jwt"));
		// console.log(jwt);
		// store.dispatch({
		//   type: "SET_CURRENT_USER",
		//   payload: jwt.user,
		//   token: jwt.token
		// });
		if (
			Number(localStorage.getItem("loginTime")) <
			Date.now() - 1000 * 86400
		) {
			localStorage.removeItem("jwt");
			localStorage.removeItem("loginTime");
			window.location.replace("/login");
			// return false;
		}
		return JSON.parse(localStorage.getItem("jwt"));
	} else {
		return false;
	}
};
