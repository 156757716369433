import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import axios from "axios";
import { BOOTCAMP_API } from "../../backend";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    minHeight: 150,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },
  control: {
    padding: theme.spacing(2),
  },
  image: {
    minHeight: 150,
    objectFit: "cover",
  },
}));

function SubscriptionPlanInfo() {
  const [fullscreen, setFullsreen] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const classes = useStyles();
  const history = useHistory();

  const getAllSubscriptionInfo = async () => {
    await axios
      .get(`${BOOTCAMP_API}/subscriptions/getAllSubscriptionInfoCms`)
      .then((response) => {
        setSubscriptions(response.data.result.data);
      })
      .catch((err) => console.log(err));
  };

  const onPublishChange = async (data, publishStatus) => {
    const requestData = {
      publish: publishStatus,
    };
    await axios
      .put(
        `${BOOTCAMP_API}/subscriptions/updateSubscriptionInfo/${data._id}`,
        requestData
      )
      .then((response) => {
        setSubscriptions(
          subscriptions.filter((x) => {
            if (x._id === data._id) {
              x.publish = response.data.result.data.publish;
            }
            return subscriptions;
          })
        );
      })
      .catch((err) => console.log(err));
  };

  const deleteInfo = async (data) => {
    await axios
      .delete(
        `${BOOTCAMP_API}/subscriptions/deleteSpecificSubscriptionInfoCms/${data._id}`
      )
      .then((response) => {
        setSubscriptions(
          subscriptions.filter((x) => {
            if (x._id === data._id) {
              return x._id !== data._id;
            }
            return subscriptions;
          })
        );
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllSubscriptionInfo();
  }, []);
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Subscription Info" />
      {console.log(subscriptions)}
      <Grid container className="mt-4">
        <Grid container spacing={6}>
          {subscriptions.map((data) => (
            <Grid key={data._id} item xs={4} className="">
              <Paper className={classes.paper + " " + "p-2"}>
                <img
                  src={data.image}
                  alt={data.title}
                  className="img-fluid mb-1"
                  width="200"
                  height="200"
                />
                <p>Title: {data.title}</p>
                <p>Description: {data.description}</p>
                <p>Priority: {data.priority}</p>
                <div className="">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mr-1"
                    onClick={() => history.push(`/subscriptions-info/update/${data._id}`)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    className="mr-1"
                    onClick={() => deleteInfo(data)}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => onPublishChange(data, data.publish ? false : true)}
                  >
                    {data.publish ? "Unpublish" : "Publish"}
                  </Button>
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

export default SubscriptionPlanInfo;
