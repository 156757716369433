import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Row, Col, Card, CardBody, ButtonToggle } from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useHistory } from "react-router-dom";
import QuestionSetSerice from "../../services/mocks/QuestionSetService";
import axios from "axios";
import { API, BOOTCAMP_API, NOTIFICATION_SERVICE } from "../../backend";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
const moment = require("moment");

function AlertComponent(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: "center",
    color: theme.palette.text.secondary,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 400,
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 420,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AllCategories(props) {
  const dispatch = useDispatch();
  const otherModuleReferences = [
    {
      key: "href1",
      value: "",
    },
    {
      key: "href2",
      value: "",
    },
    {
      key: "href3",
      value: "",
    },
  ];

  const classes = useStyles();
  const history = useHistory();
  const [blogathons, setBlogathons] = useState([]);
  const [categories, setCategories] = useState([]);
  const [fullscreen, setFullsreen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [status, setStatus] = useState(false);
  const [statusLoader, setStatusLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [difficulty, setDifficulty] = useState("");
  const [qType, setQType] = useState("");
  const [duration, setDuration] = useState(0);
  const [onSubmit, setOnSubmit] = useState(false);
  const [shortDescription, setShortDescription] = useState("");
  const [hrefs, setHrefs] = useState(otherModuleReferences);
  const [badgesNotAssigned, setBadgesNotAssigned] = useState([]);
  const [assessmentCategory, setAssessmentCategory] = useState("");
  const [badge, setBadge] = useState("");
  const [majorBadgeId, setMajorBadgeId] = useState("");
  const [minorBadgeId, setMinorBadgeId] = useState("");
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [slug, setSlug] = useState("");
  const [openTerms, setOpenTerms] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [check, setCheck] = React.useState(false);
  const [selectedBlogathon, setSelectedBlogathon] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);
  const token = JSON.parse(localStorage.getItem("jwt"));

  const getAllcategories = () => {
    axios
      .get(`${API}/blogathon/categories`)
      .then((categoryRes) => {
        setCategories(categoryRes.data.message);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const handleCloseTerms = () => {
    setOpenTerms(!openTerms);
  };

  useEffect(() => {
    getAllcategories();
  }, []);

  const handleClickOpen = (category) => {
    setSelectedBlogathon(category);
    setOpenTerms(true);
  };

  const handleCloseMsg = () => {
    setStatus(!status);
  };

  const deleteCategory = () => {
    axios
      .delete(`${API}/blogathon/category/delete/${selectedBlogathon._id}`, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        if (response.data.error) {
          console.log(response.data.error);
        } else {
          setStatus(true);
          setCheck(false);
          setMessage("Deleted Category Successfully");
          setOpenTerms(false);
          setSelectedBlogathon({});
          setCategories(
            categories.filter((x) => {
              return x._id !== selectedBlogathon._id;
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updatePublish = (data, index) => {
    const formData = new FormData();
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("logo", data.logo);
    formData.append("skills", data.skills);
    data.samples.filter((x) => {
      formData.append("samples", x);
    });
    formData.append("publish", data.publish);
    axios
      .put(`${API}/blogathon/category/update/${data._id}`, formData)
      .then((response) => {
        if (response.data.message) {
          setStatus(true);
          setMessage(
            !data.Publish
              ? `Published Successfully!`
              : "Unpublished Successfully!"
          );
          setCategories(
            categories.filter((x) => {
              if (x._id === data._id) {
                x.Publish = !data.Publish;
              }
              return categories;
            })
          );
          // getAllcategories();
        } else {
          setErrorStatus(true);
          setErrorMessage(
            `No practice Module found to ${
              data.Publish ? "Publish" : "Unpublish"
            }`
          );
          setStatusLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      {status && (
        <Snackbar
          open={status}
          autoHideDuration={15000}
          onClose={handleCloseMsg}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <AlertComponent onClose={handleCloseMsg} severity="success">
            {message}
          </AlertComponent>
        </Snackbar>
      )}
      <Header name="All Categories" />
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            {isLoading ? (
              <div style={{ textAlign: "center" }}>
                <Loader />
              </div>
            ) : (
              <>
                <div className="" style={{ textAlign: "end" }}>
                  <Button
                    onClick={() => {
                      history.push(`/create-category`);
                    }}
                    style={{
                      backgroundColor: "#5CB85C",
                      color: "#fff",
                      fontSize: "10px",
                      marginRight: "10px",
                    }}
                    className="mb-3"
                  >
                    Create Category
                  </Button>
                </div>
                {Object.keys(categories)?.length > 0
                  ? categories?.map((category, index) => (
                      <Card key={index}>
                        <Row>
                          <Col lg={12}>
                            <CardBody>
                              <div key={index}>
                                <Row>
                                  <Col lg={9}>
                                    <h4>Category {index + 1}</h4>
                                    <span>
                                      <b>Title: </b> {category.title}
                                    </span>
                                    <br />
                                    <span>
                                      <b>Description: </b>{" "}
                                      {category.description}
                                    </span>
                                    <br />
                                    <span>
                                      <b>Href: </b> {category.href}
                                    </span>
                                    <br />
                                    <span>
                                      <b>Publish: </b> {category.publish.toString()}
                                    </span>
                                    <br />
                                    <span>
                                      <b>Skills: </b>
                                      <a
                                        href={category.skills}
                                        key={index}
                                        style={{
                                          color: "#6c757d",
                                          textDecoration: "underline",
                                        }}
                                        target="_blank"
                                      >
                                        {category.skills}
                                      </a>
                                    </span>
                                    <br />
                                    <span>
                                      <b>Resources: </b>{" "}
                                      {category.samples.map((links, index) => (
                                          <div className="mb-0" dangerouslySetInnerHTML={{__html:links}}></div>
                                      ))}
                                    </span>
                                    <br />
                                  </Col>
                                  <Col lg={3} className="text-center">
                                    {/* <ButtonToggle
                                      className={
                                        category.Publish
                                          ? "unpublish"
                                          : "publish"
                                      }
                                      onClick={() =>
                                        updatePublish(category, index)
                                      }
                                    >
                                      {category.Publish
                                        ? "UNPUBLISH"
                                        : "PUBLISH"}
                                    </ButtonToggle> */}
                                    <ButtonToggle
                                      color="danger"
                                      style={{
                                        paddingLeft: "30px",
                                        paddingRight: "30px",
                                        margin: "10px"
                                      }}
                                      onClick={() => {
                                        history.push(
                                          `/update-category/${category._id}`
                                        );
                                      }}
                                    >
                                      Update
                                    </ButtonToggle>
                                    <ButtonToggle
                                      color="danger"
                                      style={{
                                        paddingLeft: "30px",
                                        paddingRight: "30px",
                                      }}
                                      onClick={() => handleClickOpen(category)}
                                    >
                                      Delete
                                    </ButtonToggle>
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                            <br />
                          </Col>
                        </Row>
                      </Card>
                    ))
                  : "No Notifications"}
              </>
            )}
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={openTerms}
        onClose={handleCloseTerms}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            <strong>Category deleted once can't be retrieved.</strong>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={check}
                  onChange={() => {
                    setCheck(!check);
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Are you sure you want to delete Category"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseTerms}
            style={{
              backgroundColor: "rgb(250, 53, 88)",
              color: "#fff",
            }}
          >
            No
          </Button>
          {check ? (
            <Button
              onClick={deleteCategory}
              style={{
                backgroundColor: "#019031",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: "#dbdbdb",
                color: "#fff",
              }}
            >
              Yes
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </AuthLayout>
  );
}
