import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import AuthLayout from "../../components/AuthLayout";
import Loader from "../../components/Loader";
import Header from "../Header";
import { useHistory } from "react-router-dom";
import EasyMDE from 'easymde';
import axios from "axios"
import { API, BOOTCAMP_API } from "../../backend"
import FormControlLabel from "@material-ui/core/FormControlLabel";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        // textAlign: "center",
        color: theme.palette.text.secondary,
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 400,
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 420,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CreateMajorBadge() {
    const otherModuleReferences = [
        {
            key: 'href1',
            value: ''
        },
        {
            key: 'href2',
            value: ''
        },
        {
            key: 'href3',
            value: ''
        },
    ];

    const classes = useStyles();
    const history = useHistory();
    const [fullscreen, setFullsreen] = useState(false);
    const [messageData, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);
    const [status, setStatus] = useState(false);
    const [statusLoader, setStatusLoader] = useState(false);
    const [title, setTitle] = useState("");
    const [badgeImage, setBadgeImage] = useState("");
    const [badgeDescription, setBadgeDescription] = useState("");
    const [publish, setPublish] = useState(false);
    // const [description, setDescription] = useState("");
    // const [difficulty, setDifficulty] = useState("");
    // const [duration, setDuration] = useState(0);
    const [onSubmit, setOnSubmit] = useState(false);
    // const [shortDescription, setShortDescription] = useState('');
    // const [hrefs, setHrefs] = useState(otherModuleReferences);

    let MDE;
    const handleClose = () => {
        setStatus(!status);
    };

    const handleCloseError = () => {
        setErrorStatus(!errorStatus);
    };
    const handleLoader = () => {
        setStatusLoader(!statusLoader);
    };

    useEffect(() => {
        MDE = new EasyMDE({
            element: document.getElementById('body'),
            autoDownloadFontAwesome: true,
            autofocus: true,
            status: false,
            singleLineBreaks: false,
            onToggleFullScreen: (opend) => setFullsreen(opend)
        });

        // MDE.codemirror.on('change', () => {
        //     setDescription(MDE.value());
        // });
    }, [MDE]);

    const newMajorBadge = () =>{

        setOnSubmit(true);
        setStatusLoader(true);
        // if (title === "" || shortDescription === "" || difficulty === "" || description === "") {
        if (title === "" || badgeDescription === "" || badgeImage === "") {
            setErrorStatus(true);
            setErrorMessage("Required Field Missing!!");
            setStatusLoader(false);
        }
        else{

            axios.post(`${BOOTCAMP_API}/assessmentMock/createMajorBadge`,null,{
                data:{
                    "badgeTitle":title,
                    "badgeImage":badgeImage,
                    "description":badgeDescription,
                    "publish":false
                }
            }).then((response) =>{

                if(response){
                        setStatus(true);
                        setMessage(response.data.result.message);
                        setStatusLoader(false);
                        setTimeout(() => {
                            history.push("/");
                        }, 2500);
                }
                else{
                        setErrorStatus(true);
                        setErrorMessage('Exception in creating new Practice Module!');
                        setStatusLoader(false);
                }


            })
            .catch((error) =>{
                    setErrorStatus(true);
                    setErrorMessage(error);
                    setStatusLoader(false);

                setStatusLoader(false);
            })
        }
        
    }

    return (
        <AuthLayout isMDEFullScreen={fullscreen && fullscreen === true ? true : false}>

            <Header name="Create Major Badge" />

            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        {status && (
                            <Snackbar
                                open={status}
                                autoHideDuration={15000}
                                onClose={handleClose}
                            >
                                <Alert onClose={handleClose} severity="success">
                                    {messageData}
                                </Alert>
                            </Snackbar>
                        )}


                        {errorStatus && (
                            <Snackbar
                                open={errorStatus}
                                autoHideDuration={15000}
                                onClose={handleCloseError}
                            >
                                <Alert
                                    onClose={handleCloseError}
                                    severity="error"
                                >
                                    {errorMessage}
                                </Alert>
                            </Snackbar>
                        )}


                        {statusLoader && (
                            <Snackbar
                                open={statusLoader}
                                // autoHideDuration={95000}
                                onClose={handleLoader}
                            >
                                <Alert onClose={handleLoader} severity="info">
                                    <Loader />
                                </Alert>
                            </Snackbar>
                        )}


                        <Paper className={classes.paper}>
                            <h2>Badge Title</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Title"
                                multiline
                                rows={3}
                                variant="outlined"
                                fullWidth={true}
                                value={title}
                                onChange={(e) => {
                                    setTitle(e.target.value);
                                }}
                            />

                            <br />
                            <br />

                            <h2>Badge Image</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Badge Image"
                                multiline
                                rows={3}
                                variant="outlined"
                                fullWidth={true}
                                value={badgeImage}
                                onChange={(e) => {
                                        setBadgeImage(e.target.value);
                                    }}
                            />

                            <br />
                            <br />

                            <h2>Badge Description</h2>

                            <TextField
                                id="outlined-multiline-static"
                                label="Badge description"
                                multiline
                                rows={3}
                                variant="outlined"
                                fullWidth={true}
                                value={badgeDescription}
                                onChange={(e) => {
                                    setBadgeDescription(e.target.value);
                                    }}
                            />

                            <br />
                            <br />

                            <Button disabled={onSubmit}
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{
                                    paddingLeft: "50px",
                                    paddingRight: "50px",
                                    paddingTop: "15px",
                                    paddingBottom: "15px",
                                    backgroundColor: "rgb(250, 53, 88)",
                                }}
                                onClick={(e) => newMajorBadge()}
                            >
                                Create Major Badge
							</Button>
                        </Paper>
                    </Grid>

                </Grid>
            </div>
        </AuthLayout>
    );
}

