import React from "react";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";

import { useSelector } from "react-redux";
import { isAuthenticated } from "../../auth/helper";
import setAuthToken from "../../auth/helper/setToken";
import { getUserInfo } from "../../user/helper/userapicall";
import { store } from "../../store/store";

import "../../assets/libs/custombox/custombox.min.css";

import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";

// if (localStorage.getItem("jwt")) {
// 	let jwt = JSON.parse(localStorage.getItem("jwt"));
// 	// console.log("jwt", jwt);
// 	setAuthToken(jwt.token);
// 	let userId = isAuthenticated().user._id;
// 	getUserInfo(userId)
// 		.then((response) => {
// 			// console.log("user", response.data.user);
// 			store.dispatch({
// 				type: "GET_USER_INFO",
// 				payload: response.data.user,
// 			});
// 		})
// 		.catch((err) => console.log(err));
// }

export default function UserProfile() {
	const user = useSelector((store) => store.user.user);

	return (
		<AuthLayout>
			<Header name="Profile" />
			<div className="wrapper">
				<div className="container-fluid">
					<div class="row">
						<div class="col-sm-8">
							<div class="bg-picture card-box">
								<div class="profile-info-name">
									<img
										src={user.Picture}
										class="rounded-circle avatar-xl img-thumbnail float-left mr-3"
										alt="profile-image"
									/>

									<div class="profile-info-detail overflow-hidden">
										<ul class="list-group mb-0 user-list">
											<li class="list-group-item">
												<p className="text-muted font-13">
													<h2 class="m-0">
														{user.FName}&nbsp;
														{user.LName}
													</h2>
												</p>
											</li>
											<li class="list-group-item">
												<p className="text-muted font-13">
													<h5>
														Role :
														<span className="">
															&nbsp;{user.Role}
														</span>
													</h5>
												</p>
											</li>
											<li class="list-group-item">
												<p className="text-muted font-13">
													<h5>
														<strong>
															<i class=" mdi mdi-email-mark-as-unread"></i>
															&nbsp; Email :
														</strong>{" "}
														<span className="ml-1">
															{user.Email}
														</span>
													</h5>
												</p>
											</li>

											<li class="list-group-item">
												<p className="text-muted font-13">
													<h5>
														<strong>
															{" "}
															<i class="mdi mdi-phone-outline"></i>{" "}
															&nbsp; Mobile :
														</strong>
														<span className="ml-2">
															{user.Mobile}
														</span>
													</h5>
												</p>
											</li>

											<li class="list-group-item">
												<p className="text-muted font-13">
													<h5>
														<strong>
															<i class="mdi mdi-map-marker-check"></i>
															&nbsp; Location :
														</strong>{" "}
														<span className="ml-2">
															{user.Location}
														</span>
													</h5>
												</p>
											</li>
										</ul>

										<ul class="social-list list-inline mt-3 mb-0">
											<li class="list-inline-item">
												<a
													href="javascript: void(0);"
													class="social-list-item border-info text-info"
												>
													<i class="mdi mdi-twitter"></i>
												</a>
											</li>
											<li class="list-inline-item">
												<a
													href="javascript: void(0);"
													class="social-list-item border-secondary text-secondary"
												>
													<i class="mdi mdi-github-circle"></i>
												</a>
											</li>
										</ul>
									</div>

									<div class="clearfix"></div>
								</div>
							</div>
						</div>

						<div class="col-sm-4">
							<div
								class="card-box"
								style={{ backgroundColor: "#fff" }}
							>
								<ul class="list-group mb-0 user-list">
									<li class="list-group-item">
										<p className="text-muted font-13">
											<h5>
												<strong>
													<i class="  mdi mdi-home-group"></i>{" "}
													&nbsp; Organization :
												</strong>{" "}
												<span className="ml-2">
													{user.Organization}
												</span>
											</h5>
										</p>
									</li>

									<li class="list-group-item">
										<p className="text-muted font-13">
											<h5>
												<strong>
													<i class=" mdi mdi-calendar-text-outline"></i>{" "}
													&nbsp; LatestDesignation :
												</strong>{" "}
												<span className="ml-2">
													{user.LatestDesignation}
												</span>
											</h5>
										</p>
									</li>

									<li class="list-group-item">
										<p className="text-muted font-13">
											<h5>
												<strong>
													<i class=" mdi mdi-briefcase-check"></i>{" "}
													&nbsp; Experience :
												</strong>{" "}
												<span className="ml-2">
													{user.Experience}
												</span>
											</h5>
										</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}
