import React, { Component, Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Loadable from "react-loadable";

import Routes from "./routes";

// setup fake backend
import { configureFakeBackend } from "./helpers";
import { isUserAuthenticated } from "./helpers/authUtils";

import { isAuthenticated } from "./auth/helper";

import AuthLayout from "./components/AuthLayout.js";
import NonAuthLayout from "./components/NonAuthLayout.js";

import { useSelector, useDispatch } from "react-redux";

// Themes
import "./assets/scss/DefaultTheme.scss";

// import "./assets/css/app.min.css";

// Lazy loading and code splitting -
// Derieved idea from https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const loading = () => <div></div>;

// All layouts/containers
// import NonAuthLayout from "./components/NonAuthLayout";

// configure fake backend
configureFakeBackend();

/**
 * Exports the component with layout wrapped to it
 * @param {} WrappedComponent
 */
const withLayout = (WrappedComponent) => {
	const HOC = class extends Component {
		render() {
			return <WrappedComponent {...this.props} />;
		}
	};

	return connect()(HOC);
};

/**
 * Main app component
 */
function App() {
	/**
	 * Returns the layout component based on different properties
	 * @param {*} props
	 */

	// render() {

	// const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);
	// console.log("NonAuthLayout", isAuthenticated);

	return (
		// rendering the router with layout
		<BrowserRouter>
			<Switch>
				<React.Fragment>
					<Routes />
				</React.Fragment>
			</Switch>
		</BrowserRouter>
	);
	// }
}

export default App;
