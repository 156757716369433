import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import AuthLayout from "../../components/AuthLayout";
import Header from "../Header";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    minHeight: 180,
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    cursor: "pointer",
  },
  control: {
    padding: theme.spacing(2),
  },
}));
const stores = [
  {
    id: "1",
    name: "Redeem",
    desc: "Redeem your earned points here",
    viewlink: "/redeem-coins",
    createLink: "/redeem-coins/create"
  },
  {
    id: "2",
    name: "Earn MH Coins",
    desc: "Earn MH coins by your daily MH activities",
    viewlink: "/coins",
    createLink: "/coins/create"
  },
  {
    id: "3",
    name: "Premium",
    desc: "Earn MH coins by your daily MH activities",
    viewlink: "/subscriptions",
    createLink: "/subscriptions/create"
  },
  {
    id: "4",
    name: "Premium Info",
    desc: "Premium Info",
    viewlink: "/subscriptions-info",
    createLink: "/subscriptions-info/create"
  },
  {
    id: "5",
    name: "Premium FAQ",
    desc: "Premium FAQ",
    viewlink: "/subscriptions-faq",
    createLink: "/subscriptions-faq/create"
  },
  {
    id: "6",
    name: "Orders",
    desc: "User orders",
    viewlink: "/orders",
    createLink: "dont show"
  },
];
function Store() {
  const [fullscreen, setFullsreen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  return (
    <AuthLayout
      isMDEFullScreen={fullscreen && fullscreen === true ? true : false}
    >
      <Header name="Store" />
      <Grid container className="mt-3">
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {stores.map((data) => (
              <Grid key={data.id} item xs={2} className="text-center">
                <Paper className={classes.paper + " " + "p-2"}>
                  <h4>{data.name}</h4>
                  <p>{data.desc}</p>
                  <div className="">
                    <Button variant="outlined" color="primary" className="mr-1" onClick={()=>history.push(`${data.viewlink}`)}>
                      View
                    </Button>
                    {data.createLink !== "dont show" && <Button variant="outlined" color="secondary" onClick={()=>history.push(`${data.createLink}`)}>
                      Create
                    </Button>}
                  </div>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </AuthLayout>
  );
}

export default Store;
