import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import Header from "../../Header";
import AuthLayout from "../../../components/AuthLayout";
import GetAllMCQTypeQuestions from "./MCQ/GetAllMCQTypeQuestions";
import GetAllCodingTypeQuestions from "./Coding/GetAllCodingTypeQuestions";

export default function GetAllQuestions(props) {
    const [selectedType, setSelectedType] = useState("");
    const [questionTypes, SetQuestionTypes] = useState(['MCQ', 'Coding']);

    useEffect(() => {
        setSelectedType("MCQ");
    }, []);


    return (
        <AuthLayout>
            <React.Fragment>
                <div className="">
                    <Row>
                        <Col>
                            <Header name="All Questions" />
                        </Col>
                    </Row>

                    <div style={{
                        padding: '15px',
                        borderBottom: 'solid 0.5px'
                    }}>
                        {
                            questionTypes.map((question_type, index) => (
                                <a key={index} style={{ marginLeft: '20px', cursor: 'pointer' }} onClick={() => setSelectedType(question_type)}>
                                    <b style={selectedType == question_type ? { color: 'blue' } : { color: 'black' }}>{question_type}</b>
                                </a>
                            ))
                        }
                    </div>

                    {
                        selectedType === 'MCQ'
                            ?
                            <GetAllMCQTypeQuestions questionSetSlug={props.match.params.questionSetSlug}/>
                            :
                            <GetAllCodingTypeQuestions questionSetSlug={props.match.params.questionSetSlug}/>
                    }
                </div>
            </React.Fragment>
        </AuthLayout>
    );
}
